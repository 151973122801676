import axios from 'axios';
import services from '../../../api/services';

interface HandleCheckEmailExistProps {
  message: 'linkUser' | 'google.com' | boolean;
}
const handleCheckEmailExist = async (
  email: string,
): Promise<HandleCheckEmailExistProps> => {
  const response = await axios.post(services.postCheckEmailExist(), {
    email,
  }, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response.data;
};

export default handleCheckEmailExist;
