import { alpha, createTheme, type Theme } from '@mui/material/styles';

// Typescript
import {
  FontPropType,
  OwnerStateThemeType,
  PaletteOptionsProps,
} from './TypeScriptProps';

const getFontStyle: FontPropType = (tmpWeight, tmpSize) => ({
  fontWeight: tmpWeight,
  fontSize: tmpSize,
});

const colors: PaletteOptionsProps = {
  primary: {
    main: '#59D9D9',
    light: '#DEF7F7',
    900: '#1A7474',
    800: '#229696',
    700: '#2AB7B7',
    600: '#37D2D2',
    500: '#59D9D9',
    400: '#7AE1E1',
    300: '#9BE8E8',
    200: '#BCF0F0',
    100: '#DEF7F7',
    50: '#EEFBFB',
  },
  secondary: {
    main: '#B6E461',
    500: '#B6E461',
    50: '#F7FCEE',
  },
  tertiary: {
    main: '#FF9839',
    500: '#FF9839',
    50: '#FFF4EB',
    contrastText: '#fff',
  },
  quaternary: {
    main: '#D9CA20',
    500: '#D9CA20',
    50: '#FBFCEA',
  },
  info: {
    main: '#0288D1',
  },
  error: {
    main: '#D32F2F',
    900: '#7A2222',
    800: '#932121',
    700: '#B12424',
    600: '#D32F2F',
    500: '#E74C4C',
    400: '#F17878',
    300: '#F8A9A9',
    200: '#FBCDCD',
    100: '#FDE3E3',
    50: '#FDF3F3',
  },
  warning: {
    main: '#ED6C02',
  },
  success: {
    main: '#2E7D32',
  },
  disabled: {
    main: '#EEEEEE',
  },
  neutral: {
    main: '#9E9E9E',
    max: '#000000',
    900: '#212121',
    800: '#424242',
    700: '#616161',
    600: '#757575',
    500: '#9E9E9E',
    400: '#BDBDBD',
    300: '#E0E0E0',
    200: '#EEEEEE',
    100: '#F5F5F5',
    50: '#FAFAFA',
    min: '#ffffff',
  },
};

const basicTheme = createTheme({
  typography: {
    fontSize: 16,
    buttonText1: { ...getFontStyle('600', '1rem') }, // 600 16px
    buttonText2: { ...getFontStyle('600', '0.875rem') }, // 600 14px
    buttonText3: { ...getFontStyle('600', '0.75rem') }, // 600 12px
    link1: { ...getFontStyle('400', '1rem') }, // 400 16px
    link2: { ...getFontStyle('400', '0.875rem') }, // 400 14px
    link3: { ...getFontStyle('400', '0.75rem') }, // 400 12px
  },
  palette: { ...colors },
  breakpoints: {
    values: {
      xs: 0,
      sm: 360,
      md: 768,
      lg: 1200,
      xl: 1536,
    },
  },
  // material-ui v5 覆寫 components style 的方式
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontStyle: 'normal',
        },
        h1: {
          ...getFontStyle('600', '6rem'), // 600 96px
        },
        h2: {
          ...getFontStyle('600', '3.75rem'), // 600 60px
        },
        h3: {
          ...getFontStyle('600', '3rem'), // 600 48px
        },
        h4: {
          ...getFontStyle('600', '2.125rem'), // 600 34px
        },
        h5: {
          ...getFontStyle('600', '1.5rem'), // 600 24px
        },
        h6: {
          ...getFontStyle('600', '1.25rem'), // 600 20px
        },
        caption: {
          ...getFontStyle('400', '0.75rem'), // 400 12px
        },
        subtitle1: {
          ...getFontStyle('600', '1rem'), // 600 16px
        },
        subtitle2: {
          ...getFontStyle('600', '0.875rem'), // 600 14px
        },
        body1: {
          ...getFontStyle('400', '1rem'), // 400 16px
        },
        body2: {
          ...getFontStyle('400', '0.875rem'), // 400 14px
        },
      },
    },
  },
});

// extend basic custom theme
const customTheme = createTheme(basicTheme, {
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          margin: '8px 0 0 0',
          ...getFontStyle('400', '0.75rem'), // 400 12px
        },
      },
    },
    MuiGrid: {
      defaultProps: {
        columns: {
          xs: 4,
          sm: 8,
          md: 12,
          lg: 12,
        },
      },
      styleOverrides: {
        root: { width: '100%' },
      },
    },
    MuiChip: {
      defaultProps: {},
      styleOverrides: {
        root: {
          color: 'white',
        },
      },
    },
    MuiButton: {
      variants: [{ props: { variant: 'secondary' } }],
      styleOverrides: {
        root: ({ ownerState, theme }: OwnerStateThemeType) => {
          const variant = ownerState.variant || 'text';
          const color = ownerState.color || 'primary';
          const isFocus = ownerState['data-focus'];

          return {
            '&.MuiButton-root': {
              textTransform: 'none',
              boxShadow: 'unset',
              // contained
              ...(variant === 'contained' && {
                color: theme.palette?.neutral.min,
                backgroundColor: theme.palette?.primary?.main,
                ...(isFocus
                  ? {
                    backgroundColor: theme.palette?.primary?.[700],
                    boxShadow: `0 0 16px 0 ${alpha(
                      theme.palette?.primary?.[500],
                      0.3,
                    )}`,
                  }
                  : {}),
                '&:hover': { backgroundColor: theme.palette?.primary?.[700] },
                // "&:focus": {
                //   backgroundColor: theme.palette?.primary?.[700],
                //   boxShadow: `0 0 16px 0 ${alpha(
                //     theme.palette?.primary?.[500],
                //     0.3,
                //   )}`,
                // },
                '&:disabled': {
                  color: theme.palette?.neutral?.[400],
                  backgroundColor: theme.palette?.disabled?.main,
                },
              }),
              ...(variant === 'contained' &&
                color === 'error' && {
                color: theme.palette?.neutral.min,
                backgroundColor: theme.palette?.error?.main,
                '&:hover': {
                  backgroundColor: theme.palette?.error?.[700],
                },
                '&:disabled': {
                  color: theme.palette?.neutral?.[400],
                  backgroundColor: theme.palette?.disabled?.main,
                },
              }),
              ...(variant === 'contained' &&
                color === 'info' && {
                color: theme.palette?.neutral.min,
                backgroundColor: theme.palette?.neutral?.[600],
                '&:hover': {
                  backgroundColor: theme.palette?.neutral?.[800],
                },
                '&:disabled': {
                  color: theme.palette?.neutral?.[400],
                  backgroundColor: theme.palette?.disabled?.main,
                },
              }),
              // secondary
              ...(variant === 'secondary' && {
                color: basicTheme.palette.primary[500],
                backgroundColor: theme.palette.primary[50],
                ...(isFocus
                  ? {
                    backgroundColor: theme.palette?.primary?.[100],
                    boxShadow: `0 0 16px 0 ${alpha(
                      theme.palette?.primary?.[500],
                      0.3,
                    )}`,
                  }
                  : {}),
                '&:hover': {
                  backgroundColor: theme.palette.primary[100],
                },
                // "&:focus": {
                //   backgroundColor: theme.palette?.primary?.[100],
                //   boxShadow: `0 0 16px 0 ${alpha(
                //     theme.palette?.primary?.[500],
                //     0.3,
                //   )}`,
                // },
                '&:disabled': {
                  color: theme.palette.neutral[400],
                  backgroundColor: theme.palette.neutral[200],
                },
              }),
              ...(variant === 'secondary' &&
                color === 'info' && {
                color: theme.palette?.neutral[600],
                backgroundColor: theme.palette?.neutral?.[50],
                '&:hover': {
                  backgroundColor: theme.palette?.neutral?.[100],
                },
                // '&:focus': {
                //   backgroundColor: theme.palette?.neutral?.[600],
                //   boxShadow: `0 0 16px 0 ${alpha(
                //     theme.palette?.neutral?.[500],
                //     0.3,
                //   )}`,
                // },
                '&:disabled': {
                  color: theme.palette?.neutral?.[400],
                  backgroundColor: theme.palette?.disabled?.main,
                },
              }),
              // outlined
              ...(variant === 'outlined' && {
                color: theme.palette?.primary?.main,
                border: `${theme.palette?.primary?.[500]} 1px solid`,
                ...(isFocus
                  ? {
                    backgroundColor: theme.palette?.primary?.[100],
                    boxShadow: `0 0 16px 0 ${alpha(
                      theme.palette?.primary?.[500],
                      0.3,
                    )}`,
                  }
                  : {}),
                '&:hover': {
                  color: theme.palette?.primary?.[500],
                  backgroundColor: theme.palette?.primary?.[100],
                },
                // "&:focus": {
                //   backgroundColor: theme.palette?.primary?.[100],
                //   boxShadow: `0 0 16px 0 ${alpha(
                //     theme.palette?.primary?.[500],
                //     0.3,
                //   )}`,
                // },
                '&:disabled': {
                  color: theme.palette?.neutral?.[400],
                  border: `${theme.palette?.neutral?.[200]} 1px solid `,
                },
              }),
              ...(variant === 'outlined' &&
                color === 'error' && {
                color: theme.palette?.error?.main,
                border: `${theme.palette?.error?.[600]} 1px solid`,
                '&:hover': {
                  color: theme.palette?.error?.[600],
                  backgroundColor: theme.palette?.error?.[100],
                },
                '&:disabled': {
                  color: theme.palette?.neutral?.[400],
                  border: `${theme.palette?.neutral?.[200]} 1px solid `,
                },
              }),
              // text
              ...(variant === 'text' && {
                color: theme.palette?.primary?.main,
                ...(isFocus
                  ? {
                    backgroundColor: theme.palette?.primary?.[100],
                    boxShadow: `0 0 16px 0 ${alpha(
                      theme.palette?.primary?.[500],
                      0.3,
                    )}`,
                  }
                  : {}),
                '&:hover': {
                  color: theme.palette?.primary?.[500],
                  backgroundColor: theme.palette?.primary?.[100],
                },
                // "&:focus": {
                //   backgroundColor: theme.palette?.primary?.[100],
                //   boxShadow: `0 0 16px 0 ${alpha(
                //     theme.palette?.primary?.[500],
                //     0.3,
                //   )}`,
                // },
                '&:disabled': {
                  color: theme.palette?.neutral?.[400],
                },
              }),
            },
          };
        },
        sizeLarge: ({ theme }: { theme: Theme }) => ({
          borderRadius: '8px',
          lineHeight: '18.75px',
          padding: '1rem 1.25rem',
          ...theme.typography?.buttonText1,
        }),
        sizeMedium: ({ theme }: { theme: Theme }) => ({
          borderRadius: '6px',
          lineHeight: '16.41px',
          padding: '0.75rem 1rem',
          ...theme.typography?.buttonText2,
        }),
        sizeSmall: ({ theme }: { theme: Theme }) => ({
          borderRadius: '6px',
          lineHeight: '14.06px',
          padding: '0.5rem 0.75rem',
          ...theme.typography?.buttonText3,
        }),
      },
    },
    // MuiOutlinedInput: {
    //   styleOverrides: {
    //     root: {
    //       borderRadius: '0.5rem',
    //     },
    //     input: {
    //       height: '27px',
    //       padding: '12px 16px 12px 16px',
    //     },
    //   },
    // },
    // MuiFormLabel: {
    //   styleOverrides: {
    //     root: {
    //       // lineHeight: '1.2rem',
    //     },
    //   },
    // },
    // MuiAutocomplete: {
    //   styleOverrides: {
    //     root: {
    //       // padding: '0',
    //       // lineHeight: '1.2rem',
    //     },
    //     // input: {
    //     //   padding: '12px 16px 12px 16px !important',
    //     // },
    //   },
    // },
  },
});

export default customTheme;
