const customerManagementPage = {
    "customerManagement.common.deleteCustomer":"刪除客戶",
    "customerManagement.pageTitle":"客戶管理",
    "customerManagement.pageTitle.null":"目前暫無客戶",
    "customerManagement.pageTitle.add":"新增客戶",
    "customerManagement.pageTitle.edit":"編輯客戶",
    "customerManagement.page.subtitle.companyInformation":"公司資訊",
    "customerManagement.page.subtitle.customerInformation":"聯絡資訊",
    "customerManagement.page.subtitle.notice":"注意事項",
    "customerManagement.modal.hint.common.success.title":"成功",
    "customerManagement.modal.hint.common.failed.title":"失敗",
    "customerManagement.modal.hint.add.success.text":"資料創建成功",
    "customerManagement.modal.hint.add.failed.text":"資料創建失敗",
    "customerManagement.modal.hint.edit.success.text":"資料編輯成功",
    "customerManagement.modal.hint.edit.failed.text":"資料編輯失敗",
    "customerManagement.modal.hint.delete.success.text":"資料刪除成功",
    "customerManagement.modal.hint.delete.failed.text":"資料刪除失敗",
    "customerManagement.search.placeholder":"搜尋",
    "customerManagement.th.companyName":"公司名稱",
    "customerManagement.th.name":"客戶名稱",
    "customerManagement.th.emailOrPhone":"電子信箱/手機號碼",
    "customerManagement.th.action":"動作",
    "customerManagement.th.info":"詳細資訊",
    "customerManagement.label.companyName":"公司名稱",
    "customerManagement.label.name":"客戶名稱",
    "customerManagement.label.email":"電子信箱",
    "customerManagement.label.phone":"手機號碼",
    "customerManagement.description.notice":"請注意，當您刪除客戶後，該客戶的所有資訊都將被刪除且無法復原。",
    "customerManagement.description.deleteCustomer":"刪除客戶後，該客戶的所有資訊將被永久移除。"
}

export default customerManagementPage
