import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';

interface CloseButtonProps {
  handleClose?: () => void;
  handleCloseRedux?:(isOpen: boolean) => void | undefined;
  handleCloseDefault: () => void;
}
const CloseButton :React.FC<CloseButtonProps> = ({
  handleClose,
  handleCloseDefault,
  handleCloseRedux,
}): React.JSX.Element => {
  const handleClick = () => {
    if (handleCloseRedux) {
      handleCloseRedux(false);
    } else if (handleClose) {
      handleClose();
    } else {
      handleCloseDefault();
    }
  };

  return (
    <Box
      style={{
        cursor: 'pointer',
        width: '2rem',
        height: '2rem',
      }}
      display="flex"
      justifyContent="center"
      alignItems="center"
      onClick={handleClick}
      sx={{
        '&:hover': { backgroundColor: 'primary.50' },
      }}
    >
      <CloseIcon />
    </Box>
  );
};

export default CloseButton;
