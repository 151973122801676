import groupInfo from "./App-groupInfo";

const authority = {
  /** user */
  // 登入時如未驗證email會在此跳modal
  SignIn: [
    groupInfo.Admin.id,
    groupInfo.Developer.id,
    groupInfo.TUV.id,
    groupInfo.ServiceProvider.id,
    groupInfo.TSGCmanager.id,
    groupInfo.Handler.id,
    groupInfo.Anonymous.id,
    groupInfo.TwHandler.id,
  ],
  // 註冊時有個流程會在signUp停留
  SignUp: [
    groupInfo.Admin.id,
    groupInfo.Developer.id,
    groupInfo.TUV.id,
    groupInfo.ServiceProvider.id,
    groupInfo.TSGCmanager.id,
    groupInfo.Handler.id,
    groupInfo.Anonymous.id,
    groupInfo.TwHandler.id,
  ],
  // 登入才能驗證
  VerifyAccount: [
    groupInfo.Admin.id,
    groupInfo.Developer.id,
    groupInfo.TUV.id,
    groupInfo.ServiceProvider.id,
    groupInfo.TSGCmanager.id,
    groupInfo.Handler.id,
    groupInfo.TwHandler.id,
    // groupInfo.Anonymous.id,
  ],
  // 忘記密碼，僅有登出的狀態使用者可用
  ForgotPassword: [groupInfo.Anonymous.id],
  // 目前沒用到，原firebase 寄驗證信及修改密碼驗證信連結時用到
  UserStateChange: [groupInfo.Anonymous.id],
  // 目前沒用到，原firebase 寄驗證信及修改密碼驗證信連結時用到
  VerifyEmail: [groupInfo.Anonymous.id],
  // 重設密碼，僅有登出狀態的使用者可用
  ResetPassword: [groupInfo.Anonymous.id],
  // 可進入，會根據此頁面跳轉
  Redirection: [
    groupInfo.Admin.id,
    groupInfo.Developer.id,
    groupInfo.TUV.id,
    groupInfo.ServiceProvider.id,
    groupInfo.TSGCmanager.id,
    groupInfo.Handler.id,
    groupInfo.Anonymous.id,
    groupInfo.TwHandler.id,
  ],
  // 連結帳戶，僅有登出狀態的使用者可用
  LinkUser: [groupInfo.Anonymous.id],

  /** dashboard */
  // 僅有登入狀態可用
  Settings: [
    groupInfo.Admin.id,
    groupInfo.Developer.id,
    groupInfo.TUV.id,
    groupInfo.ServiceProvider.id,
    groupInfo.TSGCmanager.id,
    groupInfo.Handler.id,
    groupInfo.TwHandler.id,
  ],
  EditProfile: [
    groupInfo.Admin.id,
    groupInfo.Developer.id,
    groupInfo.TUV.id,
    groupInfo.ServiceProvider.id,
    groupInfo.TSGCmanager.id,
    groupInfo.Handler.id,
    groupInfo.TwHandler.id,
  ],
  Contracts: [
    groupInfo.Admin.id,
    // groupInfo.Developer.id,
    // groupInfo.TUV.id,
    // groupInfo.ServiceProvider.id,
    // groupInfo.TSGCmanager.id,
    // groupInfo.Handler.id,
  ],
  Coupons: [
    groupInfo.Admin.id,
    // groupInfo.Developer.id,
    // groupInfo.TUV.id,
    // groupInfo.ServiceProvider.id,
    // groupInfo.TSGCmanager.id,
    // groupInfo.Handler.id,
  ],
  RentalEquip: [
    groupInfo.Admin.id,
    // groupInfo.Developer.id,
    // groupInfo.ServiceProvider.id,
    // groupInfo.TSGCmanager.id,
    // groupInfo.Handler.id,
  ],
  RentalContract: [groupInfo.Admin.id, groupInfo.ServiceProvider.id],
  Equipments: [
    groupInfo.Admin.id,
    groupInfo.Developer.id,
    groupInfo.ServiceProvider.id,
  ],
  RecyclingPlan: [groupInfo.Admin.id],
  // 僅有登入狀態可用
  ChangePassword: [
    groupInfo.Admin.id,
    groupInfo.Developer.id,
    groupInfo.TUV.id,
    groupInfo.ServiceProvider.id,
    groupInfo.TSGCmanager.id,
    groupInfo.Handler.id,
    groupInfo.TwHandler.id,
  ],
  // 僅有登入狀態可用
  Orders: [
    groupInfo.Admin.id,
    groupInfo.Developer.id,
    groupInfo.Handler.id,
    groupInfo.TwHandler.id,
  ],
  RecyclingHistorys: [groupInfo.Admin.id, groupInfo.TUV.id],
  ManagementAuthentications: [groupInfo.Admin.id],
  ManagementCouponsetting: [groupInfo.Admin.id],
  ManagementAccount: [groupInfo.Admin.id],
  ManagementContract: [groupInfo.Admin.id],
  ManagementCustomer: [groupInfo.TwHandler.id, groupInfo.Admin.id],
  /** home */
  Home: [
    groupInfo.Admin.id,
    groupInfo.Developer.id,
    groupInfo.TUV.id,
    groupInfo.ServiceProvider.id,
    groupInfo.TSGCmanager.id,
    groupInfo.Handler.id,
    groupInfo.Anonymous.id,
    groupInfo.TwHandler.id,
  ],
  Dataset: [groupInfo.Admin.id, groupInfo.Developer.id],

  Legal: [
    groupInfo.Admin.id,
    groupInfo.Developer.id,
    groupInfo.TUV.id,
    groupInfo.ServiceProvider.id,
    groupInfo.TSGCmanager.id,
    groupInfo.Handler.id,
    groupInfo.Anonymous.id,
    groupInfo.TwHandler.id,
  ],
  Console: [
    groupInfo.Admin.id,
    groupInfo.Developer.id,
    groupInfo.TUV.id,
    groupInfo.ServiceProvider.id,
    groupInfo.TSGCmanager.id,
    groupInfo.Handler.id,
    groupInfo.TwHandler.id,
  ],
  Payment: [
    groupInfo.Admin.id,
    groupInfo.Developer.id,
    // groupInfo.TUV.id,
    // groupInfo.ServiceProvider.id,
    // groupInfo.TSGCmanager.id,
    groupInfo.Handler.id,
    groupInfo.TwHandler.id,
  ],
  //
  OutsourcingRecycling: [groupInfo.Admin.id, groupInfo.TwHandler.id],
  //
  TestPdf: [groupInfo.Admin.id],
  TestPaypal: [groupInfo.Admin.id],
  TestPaypalClient: [groupInfo.Admin.id],
  //
  SpInfo: [groupInfo.Admin.id, groupInfo.Developer.id],
  // work orders
  WorkOrders: [
    groupInfo.Admin.id,
    groupInfo.Developer.id,
    groupInfo.TSGCmanager.id,
    groupInfo.Handler.id,
    groupInfo.TwHandler.id,
  ],
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default authority;
