import React, {
  ChangeEvent, Dispatch, SetStateAction, useEffect, useRef, useState,
} from 'react';

// mui
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
/* components */
import EasyBackIcon from '../../../../../commons/components/EasyIcons/EasyBackIcon';
import EasyIconButton from '../../../../../commons/components/EasyIconButton/EasyIconButton';
import EasyButton from '../../../../../commons/components/EasyButton/EasyButton';
import ImageBlock from './ImageBlock';
import EasyDialog from '../../../../../commons/components/modal/EasyDialog';
// image
import postDocumentIcon from '../../../../../images/setting/postDocument.png';
// firebase
import { auth, storage } from '../../../../../config/config-firebase';
import {
  ref, uploadBytes, listAll, getDownloadURL,
} from 'firebase/storage';
/* hooks */
import { useAppSelector } from '../../../../../reduxStore/utils/reduxHooks';
/* utils */
import { useIntl } from 'react-intl';
/* config */
import { pageMode } from '../config';
import EasyEyeIcon from '../../../../../commons/components/EasyIcons/EasyEyeIcon';
import { IconColor } from '../../../../../commons/components/EasyIcons/EasyEditIcon';
import CloseIcon from '@mui/icons-material/Close';

const Loading = () => (
  <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%" mt="1rem">
    <CircularProgress size="5rem" />
  </Box>
);
const UploadDocumentArea = ({ setMode }:{ setMode:Dispatch<SetStateAction<string>> }) => {
  /** react intl */
  const { formatMessage } = useIntl();
  const id2text = (id:string) => formatMessage({ id });
  //
  /* 文件上傳中切換 */
  const [isLoading, setIsLoading] = useState(true);

  /* user資料 */
  const userData = useAppSelector((state) => state.userDataStore);
  const userGroupRole = useAppSelector((state) => state.userGroupRoleStore);
  const authUserEmailVerified = auth.currentUser?.emailVerified;

  /* 資料暫存區 */
  const [fileList, setFileList] = useState<File[] | null>(null);
  const files = fileList ? [...fileList] : [];
  const inputRef = useRef<HTMLInputElement | null>(null);
  /* 視窗開關 */
  const [isOpen, setIsOpen] = useState(false);
  /* 視窗文字 */
  const modalTextObj = {
    uploadError: id2text('setting.upload.modal.hint.uploadError'),
    loadError: id2text('setting.upload.modal.hint.loadError'),
    filesFormatError:
        id2text('setting.upload.modal.hint.filesFormatError'),
  };
  const [modalText, setModalText] = useState(modalTextObj.loadError);

  /* 上傳到網頁 */
  const handleUploadAreaClick = () => {
    if (!authUserEmailVerified) return;
    inputRef.current?.click();
  };

  /* input檔案改變 */
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const tempFiles = e.target.files;
    const validFileTypes = ['application/pdf', 'image/png', 'image/jpeg'];
    if (tempFiles) {
      const isValidFileTypes = Array.from(tempFiles).some((item) => validFileTypes.includes(item.type));
      /* 判斷是否為指定格式 */
      if (isValidFileTypes) {
        const newFiles = Array.from(tempFiles).filter((file) => validFileTypes.includes(file.type));
        setFileList([...files, ...newFiles]);
      } else {
        setFileList(null);
        setModalText(modalTextObj.filesFormatError);
        setIsOpen(true);
      }
    }
  };

  /* 刪除上傳文件 */
  const handleRemove = (selectedIndex:number) => {
    setFileList((prevList) => {
      if (prevList) {
        return Array.from(prevList).filter((_, idx) => idx !== selectedIndex);
      }
      return prevList;
    });
  };

  /* 清空檔案列表 */
  const handleClearList = () => {
    setFileList(null);
  };

  /* 如使用者已上傳檔案，即會呈現 */
  interface FirestoreFileListProps {
    id :number;
    name :string;
    downloadURL :string;
  }
  const [firestoreFileList, setFirestoreFileList] = useState<FirestoreFileListProps[] | []>([]);
  const listRef = ref(storage, `${process.env.REACT_APP_FS_DOCUMENT}/${userData?.firebaseID}`);
  /* 獲取資料 */
  const fetchData = async () => {
    setIsLoading(true);
    const group = userGroupRole && userGroupRole.map((item) => item.group);
    try {
      if (group && group.includes('handler')) {
        const res = await listAll(listRef);
        const dataPromise = res.items.map(async (itemRef, index) => {
          const url = await getDownloadURL(itemRef);
          return { id: index, name: itemRef.name, downloadURL: url };
        });
        const dataArray = await Promise.all(dataPromise);
        setFirestoreFileList(dataArray);
      }
    } catch (error) {
      console.log(error);
      setIsOpen(true);
      setModalText(modalTextObj.loadError);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [userData]);

  /* 上傳至firestore */
  const handleUploadClick = () => {
    if (!fileList) {
      return;
    }
    const uploadPromise = fileList.map((file) => {
      const storageRef = ref(storage, `${process.env.REACT_APP_FS_DOCUMENT}/${userData?.firebaseID}/${file.name}`);
      return uploadBytes(storageRef, file);
    });
    setIsLoading(true);
    Promise.all(uploadPromise).then(() => {
      setIsLoading(false);
      handleClearList();
      fetchData();
    }).catch(() => {
      setIsLoading(false);
      setModalText(modalTextObj.uploadError);
      setIsOpen(true);
    });
  };

  /* 上傳讀取 */
  if (isLoading) {
    return (
      <Loading />
    );
  }

  return (
  // eslint-disable-next-line react/jsx-no-useless-fragment
    <Box>
      {/* 提示modal */}
      <EasyDialog
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        showCloseIcon
        title="Error"
        body={(
          <Typography variant="body1" my="6%">
            {modalText}
          </Typography>
          )}
        sx={{
          minHeight: {
            xl: '0vh',
            lg: '0vh',
            md: '0vh',
            sm: '0vh',
            xs: '0vh',
          },
        }}
      />
      {/* Back */}
      <Box width="100%" display="flex" alignItems="center" mt="1.5rem">
        <EasyIconButton onClick={() => setMode(pageMode.SETTING)}>
          <EasyBackIcon />
        </EasyIconButton>
        <Typography variant="buttonText1">
          {id2text('common.back')}
        </Typography>
      </Box>
      {/* title */}
      <Typography variant="h6" mt="1.5rem">
        {id2text('setting.upload.area.mode.title')}
      </Typography>
      {firestoreFileList ? firestoreFileList.map((item, listIndex) => (
        <Box
          mt="0.5rem"
          p="0.5rem 1rem"
          width="100%"
          key={`${listIndex.toString()}`}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{ border: '1px solid grey', borderRadius: '0.5rem' }}
        >
          <Grid container justifyContent="space-between" flexWrap="wrap" alignItems="center">
            <Grid item container xs={4} sm={8} md={4} justifyContent="space-between" rowSpacing={1} flexWrap="wrap" alignItems="center">
              <Typography>
                {item.name}
              </Typography>
            </Grid>
            <Grid
              item
              container
              xs={4}
              sm={8}
              md={8}
              justifyContent={{
                xl: 'end',
                lg: 'end',
                md: 'end',
                sm: 'space-between',
                xs: 'space-between',
              }}
              alignItems="center"
            >
              <Box mr={{
                xl: '1.5rem',
                lg: '1.5rem',
                md: '1.5rem',
                sm: '0',
              }}
              >
                <ImageBlock image={{ name: item.name, url: item.downloadURL }} />
              </Box>
              <Box display="flex">
                <Box>
                  <EasyIconButton onClick={() => {
                    window.open(item.downloadURL, '_blank');
                  }}
                  >
                    <EasyEyeIcon
                      size="sm"
                      color={('primary') as IconColor}
                    />
                  </EasyIconButton>
                </Box>
                <Box ml="0.5rem">
                  <EasyIconButton onClick={() => {}}>
                    <CloseIcon />
                  </EasyIconButton>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )) : null}
      {/* {fileList && fileList.length !== 0 */}
      {/*  ? ( */}

      {/* ) */}
      {/*: ( */}
      <Grid
        container
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        mt="1rem"
        width="100%"
        sx={{
          minHeight: '315px',
          border: '1px dashed grey',
          borderRadius: '0.5rem',
          cursor: !authUserEmailVerified ? 'auto' : 'pointer',
          backgroundColor: !authUserEmailVerified ? '#E6E6E6' : 'transparent',
        }}
        onClick={handleUploadAreaClick}
      >
        <Box>
          <img width={89} height={89} src={postDocumentIcon} alt="please upload your document" />
          <input ref={inputRef} type="file" multiple onChange={handleFileChange} style={{ display: 'none' }} accept="application/pdf, image/png, image/jpeg" />
        </Box>
        <Box mt="1.5rem">
          {authUserEmailVerified ? (
            <>
              <Typography variant="subtitle1" display="flex" justifyContent="center">
                {id2text('setting.upload.area.title')}
              </Typography>
              <Typography color="neutral.600" variant="subtitle1" display="flex" justifyContent="center">
                {id2text('setting.upload.area.hint')}
              </Typography>
            </>
          ) : (
            <Typography variant="subtitle1">
              {id2text('setting.upload.area.hint.emailVerified')}
            </Typography>
          )}
        </Box>
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="end"
      >
        {/* <Typography variant="h6">Upload Buffer</Typography> */}
        {files.map((item, idx) => (
          <Box
            mt="0.5rem"
            p="0.5rem 1rem"
            width="100%"
            key={`${idx.toString()}`}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{ border: '1px solid grey', borderRadius: '0.5rem' }}
          >
            <Grid container justifyContent="space-between" flexWrap="wrap" alignItems="center">
              <Grid item container xs={4} sm={8} md={4} justifyContent="space-between" rowSpacing={1} flexWrap="wrap" alignItems="center">
                <Typography>
                  {item.name}
                </Typography>
              </Grid>
              <Grid
                item
                container
                xs={4}
                sm={8}
                md={8}
                justifyContent={{
                  xl: 'end',
                  lg: 'end',
                  md: 'end',
                  sm: 'space-between',
                  xs: 'space-between',
                }}
                alignItems="center"
              >
                <Box mr={{
                  xl: '1.5rem',
                  lg: '1.5rem',
                  md: '1.5rem',
                  sm: '0',
                }}
                >
                  <ImageBlock image={{ name: item.name, url: URL.createObjectURL(item) }} />
                </Box>
                <Box display="flex">
                  <Box>
                    <EasyIconButton onClick={() => {
                      window.open(URL.createObjectURL(item), '_blank');
                    }}
                    >
                      <EasyEyeIcon
                        size="sm"
                        color={('primary') as IconColor}
                      />
                    </EasyIconButton>
                  </Box>
                  <Box ml="0.5rem">
                    <EasyIconButton onClick={() => handleRemove(idx)}>
                      <CloseIcon />
                    </EasyIconButton>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        ))}
        {/* 按鈕區 */}
        <Box display="flex" mt="0.5rem">
          <EasyButton disabled={fileList == null} size="small" variant="contained" onClick={handleUploadClick}>
            <Typography>
              {id2text('setting.upload.button.upload')}
            </Typography>
          </EasyButton>
          <Box ml="1rem">
            <EasyButton
              size="small"
              disabled={fileList == null}
              variant="contained"
              style={{ backgroundColor: 'grey' }}
              onClick={handleClearList}
            >
              <Typography>
                {id2text('setting.upload.button.cancel')}
              </Typography>
            </EasyButton>
          </Box>
        </Box>
      </Grid>
      {/* )} */}
    </Box>
  );
};

export default UploadDocumentArea;
