import React from 'react';

// MUI
import Button, { type ButtonProps } from '@mui/material/Button';

// configs
const sizes = { large: '2.5rem', medium: '2rem', small: '1.5rem' }; // 40px、32px、24px
const childrenSizes = { large: '2rem', medium: '1.5rem', small: '1rem' }; // 32px、24px、16px
const borderRadiusSizes = {
  large: '0.5rem', // 8px
  medium: '0.375rem', // 6px
  small: '0.25rem', // 4px
};

interface EasyIconButtonProps extends ButtonProps {
  onClick?: (() => void) | ((e: React.MouseEvent<HTMLButtonElement, MouseEvent>)=>void);
  children: React.ReactElement;
}

const EasyIconButton: React.FC<EasyIconButtonProps> = ({
  sx,
  size = 'medium',
  color = 'primary',
  variant = 'text',
  children,
  ...props
}): React.JSX.Element => (
  <Button
    color={color}
    variant={variant}
    sx={{
      minWidth: sizes[size],
      width: sizes[size],
      height: sizes[size],
      padding: 0,
      borderRadius: borderRadiusSizes[size],
      ...sx,
    }}
    {...props}
  >
    {React.cloneElement(children, {
      sx: { width: childrenSizes[size], height: childrenSizes[size] },
    })}
  </Button>
);

export default EasyIconButton;
