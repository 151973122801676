import React, { lazy } from "react";
// auth
import authority from "./App-authority";
// config
import routes from "./config/routes";
// components
import Home from "./pages/Home/Home";
import SignIn from "./pages/Users/SignIn/SignInPage";
import SignUp from "./pages/Users/SignUp/SingUpPage";
import SettingPage from "./pages/Users/UserDashboard/Settings/SettingPage";
import PolicyPage from "./pages/Legal/LegalPolicy/PolicyPage";
import ServicePage from "./pages/Legal/LegalService/ServicePage";
import ChangePasswordPage from "./pages/Users/ChangePassword/ChangePasswordPage";
import CustomerManagementPage from "./pages/Users/UserDashboard/CustomerManagement/CustomerManagementPage";
import OutsourcingRecyclingPage from "./pages/Users/UserDashboard/OutsourcingRecycling/OutsourcingRecyclingPage";
import Mark from "./pages/Workers/Platforms/Mark";
import WorkOrders from "./pages/Workers/WorkOrders";
// lazy load

const VerifyAccountPage = lazy(
  () => import("./pages/Users/SignUp/VerifyAccount/VerifyAccountPage"),
);
const ForgotPassword = lazy(
  () => import("./pages/Users/ForgotPassword/ForgotPasswordPage"),
);
const VerifyEmail = lazy(
  () => import("./pages/Users/UserStateChange/VerifyEmail/VerifyEmailPage"),
);
const ResetPassword = lazy(
  () => import("./pages/Users/UserStateChange/ResetPassword/ResetPasswordPage"),
);
const RedirectionPage = lazy(
  () => import("./pages/Users/UserDashboard/RedirectionPage"),
);

// const SettingPage = lazy(() => import('./pages/Users/UserDashboard/Settings/SettingPage'));
const LinkUser = lazy(() => import("./pages/Users/LinkUser/LinkUserPage"));
const Legal = lazy(() => import("./pages/Legal/LegalPage"));

/**
 * example
 id: "route-01",
 label: "Index",
 path: "/",
 public: true,  // 是否開放此頁面
 authority: authority.Index,
 component: Index,
 childMenu: 子選單
 */
export interface RoutesPageProps {
  id: string;
  label: string;
  path: string;
  public: boolean;
  authority: string[];
  component: React.FC;
  childMenu?: Array<React.FC>;
  accPageSelect?: boolean;
}
// HTML Body: URL Redirect

const routesPage: RoutesPageProps[] = [
  {
    id: "route-01",
    label: "Home",
    path: routes.HOME,
    public: true,
    authority: authority.Home,
    component: Home,
    childMenu: [],
  },
  // user
  {
    id: "route-02",
    label: "SignIn",
    path: routes.SIGNIN,
    public: true,
    authority: authority.SignIn,
    component: SignIn,
    childMenu: [],
  },
  {
    id: "route-03",
    label: "SignUp",
    path: routes.SIGNUP,
    public: true,
    authority: authority.SignUp,
    component: SignUp,
    childMenu: [],
  },
  {
    id: "route-04",
    label: "VerifyAccount",
    path: routes.VERIFYACCOUNT,
    public: true,
    authority: authority.VerifyAccount,
    component: VerifyAccountPage,
    childMenu: [],
    accPageSelect: false,
  },
  {
    id: "route-05",
    label: "ForgotPassword",
    path: routes.FORGOTPASSWORD,
    public: true,
    authority: authority.ForgotPassword,
    component: ForgotPassword,
    childMenu: [],
    accPageSelect: false,
  },
  {
    id: "route-07",
    label: "VerifyEmail",
    path: routes.VERIFYEMAIL,
    public: true,
    authority: authority.VerifyEmail,
    component: VerifyEmail,
    childMenu: [],
    accPageSelect: false,
  },
  {
    id: "route-08",
    label: "ResetPassword",
    path: routes.RESETPASSWORD,
    public: true,
    authority: authority.ResetPassword,
    component: ResetPassword,
    childMenu: [],
    accPageSelect: false,
  },
  {
    id: "route-09",
    label: "Redirection",
    path: routes.REDIRECTION,
    public: true,
    authority: authority.Redirection,
    component: RedirectionPage,
    childMenu: [],
    accPageSelect: false,
  },
  {
    id: "route-10",
    label: "LinkUser",
    path: `${routes.LINKUSER}`,
    public: true,
    authority: authority.LinkUser,
    component: LinkUser,
    childMenu: [],
    accPageSelect: false,
  },
  // dashboard
  {
    id: "route-11",
    label: "Settings",
    path: routes.DASHBOARD_SETTINGS,
    public: true,
    authority: authority.Settings,
    component: SettingPage,
    childMenu: [],
    accPageSelect: false,
  },
  {
    id: "route-24",
    label: "OutsourcingRecycling",
    path: routes.DASHBOARD_OUTSOURCING_RECYCLING,
    public: true,
    authority: authority.OutsourcingRecycling,
    component: OutsourcingRecyclingPage,
    childMenu: [],
    accPageSelect: false,
  },
  {
    id: "route-26",
    label: "Customer Management",
    path: routes.DASHBOARD_CUSTOMER_MANAGEMENT,
    public: true,
    authority: authority.ManagementCustomer,
    component: CustomerManagementPage,
    childMenu: [],
    accPageSelect: false,
  },
  {
    id: "route-27",
    label: "Change Password",
    path: routes.CHANGE_PASSWORD,
    public: true,
    authority: authority.ChangePassword,
    component: ChangePasswordPage,
    childMenu: [],
    accPageSelect: false,
  },
  // Warehouse2
  {
    id: "route-28-1",
    label: "Mark【Test】",
    path: routes.MARK,
    public: true,
    authority: authority.WorkOrders,
    component: Mark,
    childMenu: [],
    accPageSelect: false,
  },
  {
    id: "route-28-2",
    label: "Work Orders",
    path: routes.WORKORDERS,
    public: true,
    authority: authority.WorkOrders,
    component: WorkOrders,
    childMenu: [],
    accPageSelect: false,
  },

  // 法律條款
  {
    id: "route-61",
    label: "Legal",
    path: `${routes.LEGAL}`,
    public: true,
    authority: authority.Legal,
    component: Legal,
    childMenu: [],
  },
  {
    id: "route-62",
    label: "Service",
    path: `${routes.SERVICE}`,
    public: true,
    authority: authority.Legal,
    component: ServicePage,
    childMenu: [],
  },
  {
    id: "route-63",
    label: "Policy",
    path: `${routes.POLICY}`,
    public: true,
    authority: authority.Legal,
    component: PolicyPage,
    childMenu: [],
  },
];

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default routesPage;
