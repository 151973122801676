import React, { useState, useEffect } from 'react';

// MUI
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';

// images
import PrevSvg from '../../../images/icons/icon_left/Size=Large.svg';
import NextSvg from '../../../images/icons/icon_right/Size=Large.svg';
import SkipPrev from '../../../images/icons/icon_skipprev/Size=Large.svg';
import SkipNext from '../../../images/icons/icon_skipnext/Size=Large.svg';

// Typescript
interface EasyPaginationProps {
  page?: number;
  totalNum: number;
  pageCount: number;
  onChange: (event: React.ChangeEvent<unknown>, page: number) => void;
}

// custom icon
const FirstIcon = (): React.JSX.Element => (
  <img src={SkipPrev} alt="SkipPrev" />
);
const LastIcon = (): React.JSX.Element => <img src={SkipNext} alt="SkipNext" />;
const NextIcon = (): React.JSX.Element => <img src={NextSvg} alt="NextSvg" />;
const PrevIcon = (): React.JSX.Element => <img src={PrevSvg} alt="PrevSvg" />;

// custom components
const StyledPagination = styled(Pagination)(() => ({
  ul: { flexwrap: 'nowrap' },
}));

const EasyPagination = (props: EasyPaginationProps): React.JSX.Element => {
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const {
    page, totalNum, pageCount, onChange,
  } = props;

  const [pageNum, setPageNum] = useState<number>(1);

  useEffect(() => {
    if (pageCount === 0) {
      return;
    }
    setPageNum(Math.ceil(totalNum / pageCount));
  }, [totalNum, pageCount]);

  return (
    <StyledPagination
      page={page}
      count={pageNum}
      siblingCount={mdDown ? 0 : 1}
      boundaryCount={mdDown ? 0 : 1}
      showFirstButton
      showLastButton
      renderItem={(item) => (
        <PaginationItem
          slots={{
            first: FirstIcon,
            last: LastIcon,
            next: NextIcon,
            previous: PrevIcon,
          }}
          {...item}
        />
      )}
      sx={{
        '& .MuiPaginationItem-root.Mui-selected': {
          backgroundColor: 'primary.light',
        },
      }}
      onChange={onChange}
    />
  );
};

export default EasyPagination;
