import React, { Dispatch, SetStateAction, useState } from 'react';

/* components */
import EasyDialog from '../../../../../commons/components/modal/EasyDialog';

/* mui */
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
/* mui icon */

/* utils */
import { useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import handleCustomerManagement from '../handleCustomerManagement';
/* hooks */

/* types */
import CustomerProps, { HintProps } from '../TypescriptProps';
/* config */

interface DeleteNoticeProps {
  isButtonLoading:boolean;
  setIsLoading:Dispatch<SetStateAction<boolean>>;
  setHintModalText:Dispatch<SetStateAction<HintProps>>;
  setIsHintModalOpen:Dispatch<SetStateAction<boolean>>;
}

const DeleteNoticeArea :React.FC<DeleteNoticeProps> = ({
  isButtonLoading, setIsLoading, setHintModalText, setIsHintModalOpen,
}) => {
  /** react-intl * */
  const { formatMessage } = useIntl();
  const id2text = (id:string) => formatMessage({ id });
  const MODAL_HINT = {
    DELETE: {
      SUCCESS: {
        title: id2text('customerManagement.modal.hint.common.success.title'),
        text: id2text('customerManagement.modal.hint.delete.success.text'),
      },
      FAILED: {
        title: id2text('customerManagement.modal.hint.common.failed.title'),
        text: id2text('customerManagement.modal.hint.delete.failed.text'),
      },
    },
  };
  /** formik * */
  const {
    values,
  } = useFormikContext<CustomerProps>();
  /** modal * */
  const [isOpen, setIsOpen] = useState(false);
  const loadingAction = async () => {
    setIsOpen(false);
    await handleCustomerManagement.deleteCustomerManagement({ fusekiID: values.fusekiID }).then((res) => {
      if (res) {
        setHintModalText(MODAL_HINT.DELETE.SUCCESS);
      } else {
        setHintModalText(MODAL_HINT.DELETE.FAILED);
      }
      setIsHintModalOpen(true);
    });
  };
  return (
    <Box mt="1.5rem" padding="2rem" border="2px solid rgba(250, 250, 250, 1)" borderRadius="0.5rem" sx={{ boxShadow: '0 0 16px 0 rgba(0,0,0,0.08)' }}>
      {/* Set Active 視窗 */}
      {isOpen && (
      <EasyDialog
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        action={loadingAction}
        actionLabel={id2text('customerManagement.common.deleteCustomer')}
        secondaryAction={() => setIsOpen(false)}
        secondaryLabel={id2text('common.go.back')}
        showCloseIcon
        title={id2text('customerManagement.common.deleteCustomer')}
        dialogActionSX={{ mt: '3rem', padding: '0 1rem' }}
        dialogBodySX={{
          marginTop: '0', width: '100%', maxHeight: '84.5vh', overflowY: 'auto', padding: '1rem',
        }}
        isDangerAction
        // actionStyle={{ backgroundColor: 'rgba(211, 47, 47, 1)' }}
        secondaryStyle={{ color: 'rgba(117, 117, 117, 1)' }}
        sx={{ minWidth: '46.875vw', maxHeight: '84.5vh', p: '1rem 2rem' }}
        body={(
          <Typography>
            {id2text('customerManagement.description.deleteCustomer')}
          </Typography>
        )}
      />
      )}
      {/* 標題 */}
      <Typography variant="h5">
        {id2text('customerManagement.page.subtitle.notice')}
      </Typography>
      <Typography variant="body1" mt="0.5rem">
        {id2text('customerManagement.description.notice')}
      </Typography>
      <Box mt="1.5rem">
        <Link
          variant="link1"
          rel="noopener noreferrer"
          color="primary"
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            setIsOpen(true);
          }}
        >
          <Typography sx={{ textDecoration: 'underline', color: 'red' }}>
            {id2text('customerManagement.common.deleteCustomer')}
          </Typography>
        </Link>
      </Box>
    </Box>
  );
};

export default DeleteNoticeArea;
