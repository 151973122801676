import { createAction } from '@reduxjs/toolkit';
import type FirestoreUser from './TypescriptProps';
import { HandlerIntroProps } from './utils/fuseki/handleEasycycleSetting';

const userAct = {
  // firebase
  FIREBASE_LOGIN_USER: createAction<FirestoreUser>('FIREBASE_LOGIN_USER'),
  FIREBASE_LOGOUT_USER: createAction<null>('FIREBASE_LOGOUT_USER'),
  FIREBASE_RESET_PASSWORD: createAction<string>('FIREBASE_RESET_PASSWORD'),
  FIREBASE_CONNECT_ACCOUNT: createAction<string>('FIREBASE_CONNECT_ACCOUNT'),
  // signUp
  SET_SIGNUP_STATE: createAction<string>('SET_SIGNUP_STATE'),
  // permission denial
  PERMISSION_DENIAL: createAction<string | null>('PERMISSION_DENIAL'),
  // hdlId
  SET_FUSEKI_ID: createAction<string | null>('SET_FUSEKI_ID'),
  // groupRole
  SET_GROUP_ROLE: createAction<Array<{ group:string;role:string[]; }>>('SET_GROUP_ROLE'),
  // easycycle Settings
  SET_EASYCYCLE_SETTINGS: createAction<HandlerIntroProps>('SET_EASYCYCLE_SETTINGS'),
  // contractModal
  SET_CONTRACTID: createAction<string | null>('SET_CONTRACTID'),

};
export default userAct;
