import React, {
  useRef,
  useState,
  useEffect,
  type Dispatch,
  type SetStateAction,
} from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Html5Qrcode } from "html5-qrcode";

// MUI
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Step from "@mui/material/Step";
import Select from "@mui/material/Select";
import Stepper from "@mui/material/Stepper";
import MenuItem from "@mui/material/MenuItem";
import StepLabel from "@mui/material/StepLabel";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";

// MUI Icons
import DoneIcon from "@mui/icons-material/Done";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

// components
import EasyAlert from "../../commons/components/EasyAlert/EasyAlert";
import EasyDialog from "../../commons/components/EasyDialog/EasyDialog";
import EasyButton from "../../commons/components/EasyButton/EasyButton";
import EasyIconButton from "../../commons/components/EasyIconButton/EasyIconButton";

// configs
import { WAREHOUSE_ID, WAREHOUSE_NAME } from "../../config/config.warehouse";
import { WorkOrders1Out } from "./Platforms/fakeData";

// utils

const EasyScanDialog = ({
  pallets,
  scannedPallets,
  setIsScannning,
  setScannedPallets,
}: {
  pallets: Array<Record<string, any>>;
  scannedPallets: Array<string>;
  setIsScannning: Dispatch<SetStateAction<boolean>>;
  setScannedPallets: Dispatch<SetStateAction<Array<string>>>;
}): React.JSX.Element => {
  const readerRef = useRef<HTMLDivElement | null>(
    document.getElementById("reader") as HTMLDivElement,
  );

  const QRCodeRef = useRef<any>(null);

  const [scanningPallet, setScanningPallet] = useState<string>("");

  useEffect(() => {
    if (readerRef.current) {
      const htmlQrCode = new Html5Qrcode("reader");
      QRCodeRef.current = htmlQrCode;
    }
  }, [readerRef]);

  useEffect(() => {
    console.log("Scanned Pallets: ", scannedPallets);
    if (QRCodeRef.current) {
      // Html5Qrcode.getCameras().then((devices: any) => {
      //   // console.log("???", devices);
      //   if (devices.length) {
      //     QRCodeRef.current.start(
      //       { facingMode: "environment" },
      //       { fps: 10, qrbox: { width: 250, height: 250 } },
      //       (decodedText: string) => {
      //         // console.log("Decoded Text: ", decodedText);
      //         QRCodeRef.current.pause(true);
      //         if (!scannedPallets.includes(decodedText)) {
      //           setScannedPallets((pallets) => [...pallets, decodedText]);
      //           setScanningPallet(decodedText);
      //         } else {
      //           console.log("The pallet is scanned!");
      //         }
      //         // QRCodeRef.current.stop();
      //       },
      //       (errorMessage: any) => {
      //         setScanningPallet("");
      //       },
      //     );
      //   }
      // });
      QRCodeRef.current.start(
        { facingMode: "environment" },
        { fps: 10, qrbox: { width: 250, height: 250 } },
        (decodedText: string) => {
          // console.log("Decoded Text: ", decodedText);
          // QRCodeRef.current.pause(true);
          setScannedPallets((pallets) => [
            ...pallets,
            ...(!pallets.includes(decodedText) ? [decodedText] : []),
          ]);
          setScanningPallet(decodedText);
          // if (!scannedPallets.includes(decodedText)) {
          //   setScanningPallet(decodedText);
          // } else {
          //   console.log("The pallet is scanned!");
          // }
          // QRCodeRef.current.resume();
          // QRCodeRef.current.stop();
        },
        (errorMessage: any) => {
          // setScanningPallet("");
        },
      );
    }
  }, [QRCodeRef]);

  return (
    <EasyDialog
      isOpen
      variant="outlined"
      fullWidth
      maxWidth="md"
      disablePortal
      isOutsideClose
      isColumnActions
      isCloseOnTop={false}
      handleClose={() => {
        setIsScannning(false);
      }}
      handleConfirm={() => {
        setIsScannning(false);
      }}
      dialogContent={
        <Box sx={{ width: "100%" }}>
          <Grid container flexDirection="column" rowSpacing="1rem">
            <Grid item xs>
              <div id="reader" ref={readerRef} />
            </Grid>
            <Grid
              item
              xs
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Typography variant="body2" color="neutral.800">
                {`已掃描棧板：${scannedPallets.length}/${pallets.length}`}
              </Typography>
            </Grid>
            {scanningPallet && (
              <Grid item xs>
                <EasyAlert severity="info">
                  {`${scanningPallet}已確認`}
                </EasyAlert>
              </Grid>
            )}
          </Grid>
        </Box>
      }
      dialogActionConfirmText="關閉"
    />
  );
};

export default EasyScanDialog;
