import React, { useState } from 'react';
import {
  Link, useLocation, useNavigate, useParams,
} from 'react-router-dom';

// MUI
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// configs
import routes from '../../../config/routes';
import services from '../../../api/services';
import { changeLang } from '../../../intl18Store/languageTools';
import { supportLanguages } from '../../../locales/langs';
import EasyButton from '../EasyButton/EasyButton';

const footer = (): React.JSX.Element => {
  const { pathname } = useLocation();
  // Expand footer width
  const isExpandFooterWidth = routes.EXPAND_NAVBAR.some((route) => pathname.includes(route));

  // 語系
  const { locale } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [currentLang, setCurrentLang] = useState(supportLanguages[0]);
  // const getNextLang = (lang:string) => {
  //   console.log('lang', lang);
  //   const currentLangIndex = supportLanguages.findIndex((item) => item.value === lang);
  //   if (currentLangIndex !== -1) {
  //     console.log(supportLanguages[(currentLangIndex + 1) % supportLanguages.length].value);
  //     return supportLanguages[(currentLangIndex + 1) % supportLanguages.length];
  //   }
  //   return supportLanguages[0];
  // };
  return (
    <Box
      sx={{
        width: '100%',
        color: 'neutral.min',
        minHeight: '4rem',
        display: 'flex',
        padding: {
          xl: isExpandFooterWidth ? '17.5px 1.5rem' : '0.5rem 0', // 8px 0
          lg: isExpandFooterWidth ? '17.5px 1.5rem' : '0.5rem 0', // 8px 0
          md: '0.5rem 3rem', // 8px 48px
          sm: '1rem 1.5rem', // 16px 24px
        },
        justifyContent: 'center',
        backgroundColor: 'neutral.max',
      }}
    >
      <Grid
        sx={{
          width: {
            xl: isExpandFooterWidth ? '100%' : '1280px',
            lg: isExpandFooterWidth ? '100%' : '1136px',
            md: '100%',
            sm: '100%',
          },
        }}
        container
        alignItems="center"
      >
        <Grid
          item
          md={6}
          display="flex"
          justifyContent={{ md: 'flex-start', xs: 'center' }}
          textAlign="center"
        >
          <Typography>
            © 2023 EasyCycle Online. All rights reserved.
          </Typography>
        </Grid>
        <Grid
          item
          md={6}
          sx={{
            display: 'flex',
            justifyContent: { md: 'flex-end', xs: 'center' },
          }}
        >
          {/* <Box mr="1.5rem"> */}
          {/*  <EasyButton */}
          {/*    style={{ textDecoration: 'none' }} */}
          {/*    onClick={() => { */}
          {/*      console.log('1'); */}
          {/*      console.log(getNextLang(currentLang.value).value); */}
          {/*      console.log('2'); */}
          {/*      // changeLang({ */}
          {/*      //   location, navigate, currentLanguage: currentLang.value, nextLanguage: getNextLang(currentLang.value).value, */}
          {/*      // }); */}
          {/*      // setCurrentLang(getNextLang(currentLang.value)); */}
          {/*    }} */}
          {/*  > */}
          {/*    <Typography sx={{ textDecoration: 'underline' }} color="primary"> */}
          {/*      {currentLang.labelLong} */}
          {/*    </Typography> */}
          {/*  </EasyButton> */}
          {/* </Box> */}
          <Box mr="1.5rem">
            <Link
              style={{ textDecoration: 'none' }}
              to={`/${services.getLocale()}${routes.SERVICE}`}
            >
              <Typography sx={{ textDecoration: 'underline' }} color="primary">
                Terms of Service
              </Typography>
            </Link>
          </Box>
          <Box>
            <Link
              style={{ textDecoration: 'none' }}
              to={`/${services.getLocale()}${routes.POLICY}`}
            >
              <Typography sx={{ textDecoration: 'underline' }} color="primary">
                Privacy Policy
              </Typography>
            </Link>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default footer;
