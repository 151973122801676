import React, { useState } from 'react';

/* components */
import EasyDialog from '../../../../../commons/components/modal/EasyDialog';
import Loading from '../../../../../commons/components/loading/Loading';

/* mui */
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import format from 'date-fns/format';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import { PaletteColor, useTheme } from '@mui/material';
/* mui icon */

/* utils */
import {
  STATUS_COLOR,
} from '../utils';
import { useIntl } from 'react-intl';
/* hooks */
import { useAppSelector } from '../../../../../reduxStore/utils/reduxHooks';

/* types */
import OutsourcingRecyclingProps from '../TypescriptProps';

/* config */
import { TITLE_TEXT } from '../config';
import { ORDER_STATUS } from '../../../../../config/config.order';

interface OutsourcingRecyclingInfoModalProps {
  isOpen:boolean;
  setIsOpen:(e:boolean)=>void;
  order:OutsourcingRecyclingProps;
}

type StatusColorType = {
  backgroundColor:string;
  dotColor:PaletteColor | string;
};

const OutsourcingRecyclingInfoModal:React.FC<OutsourcingRecyclingInfoModalProps> = ({
  order, isOpen, setIsOpen,
}) => {
  //
  const theme = useTheme();
  /** react-intl */
  const { formatMessage } = useIntl();
  const id2text = (id:string) => formatMessage({ id });
  // function
  const progressStepTime = (order:OutsourcingRecyclingProps) => {
    /* 透過時間判斷訂單狀態 */
    if ((order?.rejectedTime || order?.canceledTime)) {
      return 1;
    }
    if (order?.completedTime) {
      return 3;
    }
    if (order?.recyclingTime) {
      return 2;
    }
    return 0;
  };
  const progressStatus = (order:OutsourcingRecyclingProps, statusNumber:number) => {
    /* 透過時間判斷訂單狀態 */
    if ((order?.rejectedTime || order?.canceledTime) && statusNumber === 1) {
      return id2text('outsourceRecycle.chip.canceled.text');
    }
    if ((order?.completedTime) && statusNumber === 3) {
      return id2text('outsourceRecycle.chip.completed.text');
    }
    if ((order?.recyclingTime) && statusNumber === 2) {
      return id2text('outsourceRecycle.chip.recycling.text');
    }

    return id2text('outsourceRecycle.chip.arrived.text');
  };
  const getChipTheme = (backgroundColor:string) => {
    switch (backgroundColor) {
      case STATUS_COLOR.ORANGE:
        return {
          backgroundColor: theme.palette.tertiary['50'],
          dotColor: theme.palette.tertiary.main,
        };
      case STATUS_COLOR.GREY:
        return {
          backgroundColor: theme.palette.neutral['50'],
          dotColor: theme.palette.neutral['400'],
        };
      case STATUS_COLOR.GREEN:
        return {
          backgroundColor: theme.palette.secondary['50'],
          dotColor: theme.palette.secondary.main,
        };
      default: return {
        backgroundColor: theme.palette.primary['50'],
        dotColor: theme.palette.primary.main,
      };
    }
  };

  const statusChipColor = (status:string, callback:(backgroundColor:string)=>StatusColorType): StatusColorType => {
    /* 灰色 */
    if (status?.includes(id2text('outsourceRecycle.chip.canceled.text'))) {
      return callback(STATUS_COLOR.GREY);
    }
    /* 綠色 */
    if (
      status?.includes(id2text('outsourceRecycle.chip.recycling.text')) || status?.includes(id2text('outsourceRecycle.chip.arrived.text'))
    ) {
      return callback(STATUS_COLOR.GREEN);
    }
    /* 藍色 */
    /* Unpaid Completed */
    return callback(STATUS_COLOR.BLUE);
  };
  //
  console.log('order', order);
  const user = useAppSelector((state) => state.userDataStore);
  //
  const [isLoading, setIsLoading] = useState(false);
  /* data array */
  const recyclingInfoArray = [
    {
      id: TITLE_TEXT.IS_CONTROLLED_TITLE,
      label: id2text('outsourceRecycle.isControlled.label'),
      value: order?.isControlled === '1' ? id2text('outsourceRecycle.isControlled.true') : id2text('outsourceRecycle.isControlled.false'),
    },
    {
      id: TITLE_TEXT.IS_ASSOCIATED_TITLE,
      label: id2text('outsourceRecycle.isAssociated.label'),
      value: order?.isAssociated === '1' ? id2text('outsourceRecycle.isAssociated.true') : id2text('outsourceRecycle.isAssociated.false'),
    },
    {
      id: TITLE_TEXT.TRIPLET_NUMBER,
      label: order?.tripletNumber ? id2text('outsourceRecycle.tripletNumber.label') : '',
      value: order?.tripletNumber,
    },
    {
      id: TITLE_TEXT.CUSTOMER_NUMBER_TITLE,
      label: order?.customizedNumber ? id2text('outsourceRecycle.customizedNumber.label') : '',
      value: order?.customizedNumber,
    },
    {
      id: TITLE_TEXT.TRIPLET_NUMBER,
      label: order?.onBoardDocumentNumber ? id2text('outsourceRecycle.onBoardDocumentNumber.label') : '',
      value: order?.onBoardDocumentNumber,
    },
    {
      id: TITLE_TEXT.RECYCLING_CODE_TITLE,
      label: id2text('outsourceRecycle.recyclingCode.label'),
      value: order?.recyclingCode,
    },
    {
      id: TITLE_TEXT.GROSS_WEIGHT_TITLE,
      label: id2text('outsourceRecycle.grossWeight.label'),
      value: order?.grossWeight ? `${order?.grossWeight} t` : '0 t',
    },
    {
      id: TITLE_TEXT.ARRIVED_DATE,
      label: id2text('outsourceRecycle.arrivedDate.label'),
      value: order?.bookingTime ? format(parseInt(order?.bookingTime, 10), 'dd MMM, yyyy HH:mm') : '',
    },
    {
      id: TITLE_TEXT.STATUS_TITLE,
      label: id2text('outsourceRecycle.status.label'),
      value: <Chip
        label={(
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              variant="body2"
              sx={{
                color: statusChipColor(progressStatus(order, progressStepTime(order)), getChipTheme).dotColor as string,
                marginRight: '4px',
              }}
            >
              •
            </Typography>
            <Typography variant="caption" color="neutral.max">
              {progressStatus(order, progressStepTime(order))}
            </Typography>
          </Box>
          )}
        sx={{ backgroundColor: `${statusChipColor(progressStatus(order, progressStepTime(order)), getChipTheme).backgroundColor}` }}
      />,
    },
  ];

  const customerInfoArray = [
    {
      id: TITLE_TEXT.TAX_NUMBER_TITLE,
      label: id2text('common.tax.number'),
      value: order.taxNumber,
    },
    {
      id: TITLE_TEXT.COMPANY_NAME_TITLE,
      label: id2text('outsourceRecycle.company.name.label'),
      value: order.ordererCompany,
    },
    {
      id: TITLE_TEXT.ORDERER_NAME_TITLE,
      label: id2text('outsourceRecycle.orderer.name.label'),
      value: order.ordererName,
    },
    {
      id: TITLE_TEXT.ORDERER_TEL_TITLE,
      label: id2text('outsourceRecycle.orderer.tel.label'),
      value: order.ordererTel,
    },
    {
      id: TITLE_TEXT.ORDERER_MAIL_TITLE,
      label: id2text('outsourceRecycle.orderer.mail.label'),
      value: order.ordererMail,
    },
  ];

  const netWeightInfoArray = [
    {
      id: TITLE_TEXT.NET_WEIGHT_TITLE,
      label: id2text('outsourceRecycle.netWeight.label'),
      value: order.netWeight ? `${order?.netWeight} t` : '0 t',
    },
    {
      id: '包材重量',
      label: '包材重量',
      value: order.netWeight && order.grossWeight ? `${(parseFloat(order?.grossWeight) - parseFloat(order?.netWeight)).toFixed(2)} t` : '0 t',
    },
    {
      id: TITLE_TEXT.NET_WEIGHT_AL_FRAME_TITLE,
      label: id2text('outsourceRecycle.netWeight.alFrame.label'),
      value: order.weightAlum ? `${order?.weightAlum} t` : '0 t',
    },
    {
      id: TITLE_TEXT.NET_WEIGHT_GLASS_TITLE,
      label: id2text('outsourceRecycle.netWeight.glass.label'),
      value: order.weightGlass ? `${order?.weightGlass} t` : '0 t',
    },
    {
      id: TITLE_TEXT.NET_WEIGHT_CELL,
      label: id2text('outsourceRecycle.netWeight.cell.label'),
      value: order.weightCell ? `${order?.weightCell} t` : '0 t',
    },
    {
      id: TITLE_TEXT.NET_WEIGHT_JB_TITLE,
      label: id2text('outsourceRecycle.netWeight.JB.label'),
      value: order.weightJB ? `${order?.weightJB} t` : '0 t',
    },
    {
      id: TITLE_TEXT.NET_WEIGHT_EVA,
      label: id2text('outsourceRecycle.netWeight.eva.label'),
      value: order.weightEva ? `${order?.weightEva} t` : '0 t',
    },
    {
      id: TITLE_TEXT.NET_WEIGHT_PVDF,
      label: id2text('outsourceRecycle.netWeight.pvdf.label'),
      value: order.weightPvdf ? `${order?.weightPvdf} t` : '0 t',
    },
  ];

  const receptionistInfoArray = [
    {
      id: TITLE_TEXT.RECEPTIONIST,
      label: id2text('outsourceRecycle.receptionist.label'),
      value: order.receptionist,
    },
  ];
  // useEffect(() => {
  //   setSelectedEquipment({} as EquipmentProps);
  // }, [isAssignOpen]);

  return (
    <EasyDialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      showCloseIcon
      title={id2text('outsourceRecycle.info.title')}
      dialogActionSX={{ mt: '3rem', padding: '0 1rem' }}
      dialogBodySX={{
        marginTop: '0', width: '100%', maxHeight: '84.5vh', overflowY: 'auto', padding: '1rem',
      }}
      sx={{ minWidth: '46.875vw', maxHeight: '84.5vh' }}
      body={(
        <Box width="100%">
          {isLoading ? <Loading /> : (
            <>
              {/* Recycling Information */}
              <Box width="100%" mt="1rem">
                <Typography variant="h6" fontWeight="700">
                  {id2text('outsourceRecycle.subtitle1')}
                </Typography>
                <Box width="100%" mt="1.5rem">
                  {recyclingInfoArray.map((item) => (
                    item.value && (
                      <Grid key={item.id} container width="100%" mt="0.75rem" justifyContent="space-between" alignItems="center">
                        <Grid item container justifyContent="start" xs={6}>
                          <Typography variant="body1">
                            {item.label}
                          </Typography>
                        </Grid>
                        <Grid item container justifyContent="start" xs={6}>
                          {typeof item.value === 'string' ? (
                            <Typography variant="body1">
                              {item.value}
                            </Typography>
                          )
                            :
                            item.value}
                        </Grid>
                      </Grid>
                    )
                  ))}
                </Box>
              </Box>
              {/* Customer Information */}
              <Box width="100%" mt="1.5rem">
                <Typography variant="h6" fontWeight="700">
                  {id2text('outsourceRecycle.subtitle2')}
                </Typography>
                <Box width="100%" mt="1.5rem">
                  {customerInfoArray.map((item) => (
                    <Grid key={item.id} container width="100%" mt="0.75rem" justifyContent="space-between" alignItems="center">
                      <Grid item container justifyContent="start" xs={6} alignItems="center">
                        <Typography variant="body1">
                          {item.label}
                        </Typography>
                      </Grid>
                      <Grid item container justifyContent="start" xs={6} alignItems="center">
                        {typeof item.value === 'string' ? (
                          <Typography variant="body1">
                            {item.value}
                          </Typography>
                        )
                          :
                          item.value}
                      </Grid>
                    </Grid>
                  ))}
                </Box>
              </Box>
              {/* Net Weight Info */}
              { order.orderStatus === ORDER_STATUS.COMPLETED && (
              <Box width="100%" mt="1.5rem">
                <Typography variant="h6" fontWeight="700">
                  {id2text('outsourceRecycle.subtitle.net.weight.title')}
                </Typography>
                <Box width="100%" mt="1.5rem">
                  {netWeightInfoArray.map((item) => (
                    <Grid key={item.id} container width="100%" mt="0.75rem" justifyContent="space-between" alignItems="center">
                      <Grid item container justifyContent="start" xs={6} alignItems="center">
                        <Typography variant="body1">
                          {item.label}
                        </Typography>
                      </Grid>
                      <Grid item container justifyContent="start" xs={6} alignItems="center">
                        <Typography variant="body1">
                          {item.value}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
                </Box>
              </Box>
              )}
              {/* Receptionist Info */}
              <Box width="100%" mt="1.5rem">
                <Typography variant="h6" fontWeight="700">
                  {id2text('outsourceRecycle.receptionist.label')}
                </Typography>
                <Box width="100%" mt="1.5rem">
                  {receptionistInfoArray.map((item) => (
                    <Grid key={item.id} container width="100%" mt="0.75rem" justifyContent="space-between" alignItems="center">
                      <Grid item container justifyContent="start" xs={6} alignItems="center">
                        <Typography variant="body1">
                          {item.label}
                        </Typography>
                      </Grid>
                      <Grid item container justifyContent="start" xs={6} alignItems="center">
                        <Typography variant="body1">
                          {item.value}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
                </Box>
              </Box>
            </>
          )}
        </Box>
            )}
    />
  );
};

export default OutsourcingRecyclingInfoModal;
