/* utils */
import axios from 'axios';
import { isEqual } from 'lodash-es';
/* type */
import type CustomerProps from './TypescriptProps';
import FirestoreUser from '../../TypescriptProps';
/* config */
import services from '../../../../api/services';
import { ClassType, Graphs } from '../../../../config/config.fuseki';

interface KeyValue {
  srcId:string,
  key:string;
  value:string;
  [key: string]: string | number | boolean | undefined;
}

const getCustomerManagementDetail = async ({ uid, filterText }:{ uid?:string, filterText?:string }) => {
  if (!uid) return false;
  const detailResponse = await axios.get(
    `${services.getCustomerDetail(uid, filterText ?? '')}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  const { data } = detailResponse.data;
  const result :CustomerProps[] = data.reduce((resultItem:Record<string, any>, item:KeyValue) => {
    const { srcId, key, value } = item;
    const newResultItem = { ...resultItem };
    if (!newResultItem[srcId]) {
      newResultItem[srcId] = { fusekiID: srcId };
    }
    newResultItem[srcId][key] = value;
    return newResultItem;
  }, {});
  return Object.values(result).slice().reverse();
};

interface PostCustomerManagementProps {
  user?:FirestoreUser;
  customer:Omit<CustomerProps, 'fusekiID'>;
}
const postCustomerManagement = async ({ user, customer }:PostCustomerManagementProps) => {
  if (!user?.fusekiID) return false;
  const customerEntry = {
    classType: ClassType.HDL,
    graph: Graphs.rhandler,
    srcId: user.fusekiID,
    value: {
      hasCustomer: [{
        classType: ClassType.CMR,
        srcId: '',
        value: {
          companyName: customer?.companyName ?? '',
          taxNumber: customer?.taxNumber ?? '',
          contactName: customer?.contactName ?? '',
          contactMail: customer?.contactMail ?? '',
          contactTel: customer?.contactTel ?? '',
        },
      }],
    },
  };
  const customerResult = await axios.post(services.postGeneric, {
    entry: {
      ...customerEntry,
    },
  }, {
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data === 'OK').catch(() => false);

  return customerResult;
};

interface PutCustomerManagementProps {
  oldData:CustomerProps;
  updateData:CustomerProps;
}
const putCustomerManagement = async ({ oldData, updateData }:PutCustomerManagementProps) => {
  const customerEntry = {
    classType: ClassType.CMR,
    graph: Graphs.rhandler,
    srcId: oldData.fusekiID,
    value: {
      companyName: oldData?.companyName ?? '',
      taxNumber: oldData?.taxNumber ?? '',
      contactName: oldData?.contactName ?? '',
      contactMail: oldData?.contactMail ?? '',
      contactTel: oldData?.contactTel ?? '',
    },
  };
  const customerDst = {
    classType: ClassType.CMR,
    graph: Graphs.rhandler,
    srcId: oldData.fusekiID,
    value: {
      companyName: updateData?.companyName ?? '',
      taxNumber: updateData?.taxNumber ?? '',
      contactName: updateData?.contactName ?? '',
      contactMail: updateData?.contactMail ?? '',
      contactTel: updateData?.contactTel ?? '',
    },
  };
  const isEqualCustomer = isEqual(customerEntry, customerDst);
  let customerResult;
  if (isEqualCustomer) {
    customerResult = true;
  } else {
    customerResult = await axios.put(services.putGeneric, {
      entrySrc: {
        ...customerEntry,
      },
      entryDst: {
        ...customerDst,
      },
    }, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((res) => res.data.data === 'OK').catch(() => false);
  }
  return customerResult;
};

interface DeleteCustomerManagementProps {
  fusekiID:string;
}
const deleteCustomerManagement = async ({ fusekiID }:DeleteCustomerManagementProps) => {
  if (!fusekiID) return false;
  const customerEntry = {
    classType: ClassType.CMR,
    srcId: fusekiID,
    graph: Graphs.rhandler,
    value: {
      // companyName: customer?.companyName ?? '',
      // taxNumber: customer?.taxNumber ?? '',
      // contactName: customer?.contactName ?? '',
      // contactMail: customer?.contactMail ?? '',
      // contactTel: customer?.contactTel ?? '',
    },
  };
  const customerResult = await axios.delete(services.postGeneric, {
    data: {
      entry: {
        ...customerEntry,
      },
    },
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data === 'OK').catch(() => false);

  return customerResult;
};

const handleCustomerManagement = {
  getCustomerManagementDetail, postCustomerManagement, putCustomerManagement, deleteCustomerManagement,
};

export default handleCustomerManagement;
