import React from 'react';

/* components */
import EasyTextField from '../../../../../commons/components/EasyTextField/EasyTextField';
import MuiGooglePlaceAutocomplete from '../../../../../commons/components/MuiGooglePlaceAutocomplete/MuiGooglePlaceAutocomplete';
/* mui */
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
/* utils */
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
/* config */
import { AddressProps } from '../config';
/* types */
import FirestoreUser from '../../../TypescriptProps';

interface InformationAreaProps {
  setAddress:React.Dispatch<React.SetStateAction<AddressProps>>;
  isButtonLoading:boolean;
}
const InformationArea:React.FC<InformationAreaProps> = ({ setAddress, isButtonLoading }) => {
  const {
    values, handleChange, handleBlur, errors, touched,
  } = useFormikContext<FirestoreUser>();
    /** react intl */
  const { formatMessage } = useIntl();
  const id2text = (id:string) => formatMessage({ id });
  //
  const companyInfoArr = [
    { label: id2text('setting.page.companyName.label'), name: 'companyName' },
    { label: id2text('setting.page.companyTel.label'), name: 'companyTel' },
    { label: id2text('setting.page.companyMail.label'), name: 'companyMail' },
    { label: id2text('setting.page.licenseNumber.label'), name: 'licenseNumber' },
    { label: id2text('common.tax.number'), name: 'taxNumber' },
  ];
  const addressArr = [
    { label: id2text('setting.page.postalCode.label'), name: 'postalCode' },
    { label: id2text('setting.page.country.label'), name: 'country' },
    { label: id2text('setting.page.state.label'), name: 'state' },
    { label: id2text('setting.page.city.label'), name: 'city' },
    { label: id2text('setting.page.address1.label'), name: 'address1' },
    { label: id2text('setting.page.address2.label'), name: 'address2' },
  ];
  const mainContactArr = [
    { label: id2text('setting.page.contactName.label'), name: 'contactName' },
    { label: id2text('setting.page.contactMail.label'), name: 'contactMail' },
    { label: id2text('setting.page.contactTel.label'), name: 'contactTel' },
  ];
  return (
    <Box>
      {/* Company Info */}
      <Grid mt="1.5rem">
        <Typography variant="subtitle1" display="flex">
          {id2text('setting.page.infoArea.company.title')}
          <Typography color="red" variant="caption">&nbsp;*</Typography>
        </Typography>
        <Box mt="1rem" width="100%">
          <Box sx={{ backgroundColor: 'rgba(250, 250, 250, 1)', border: 'rgba(250, 250, 250, 1) 2px solid', borderRadius: '0.5rem' }}>
            {companyInfoArr.map((item, index) => (
              <Grid key={item.name} container mt={index === 0 ? '0rem' : '1rem'} justifyContent="space-between" alignItems="center">
                <Grid item xs={12}>
                  <EasyTextField
                    disabled={isButtonLoading}
                    label={item.label}
                    name={item.name}
                    type="text"
                    value={values[item.name] as string}
                    onChange={handleChange}
                    fullWidth
                    InputProps={{
                      style: { height: '3.1875rem', borderRadius: '0.5rem' },
                    }}
                    onBlur={handleBlur(item.name)}
                    error={Boolean(touched[item.name] && errors[item.name])}
                    helperText={touched[item.name] ? errors[item.name] : ''}
                  />
                </Grid>
              </Grid>
            ))}
          </Box>
        </Box>
      </Grid>
      {/* Address */}
      <Grid mt="2.5rem">
        <Typography variant="subtitle1" display="flex">
          {id2text('setting.page.infoArea.address.title')}
          <Typography color="red" variant="caption">&nbsp;*</Typography>
        </Typography>
        <MuiGooglePlaceAutocomplete boxSx={{ marginTop: '1rem' }} setAddress={setAddress} />
        <Box mt="1rem" width="100%">
          <Box padding="2rem 2.5rem" sx={{ backgroundColor: 'rgba(250, 250, 250, 1)', border: 'rgba(250, 250, 250, 1) 2px solid', borderRadius: '0.5rem' }}>
            {addressArr.map((item, index) => (
              // values[item.name] && (
              <Grid key={item.name} container mt={index === 0 ? '0rem' : '1rem'} justifyContent="space-between" alignItems="center">
                <Grid item xs={12}>
                  <EasyTextField
                    disabled={isButtonLoading}
                    label={item.label}
                    name={item.name}
                    type="text"
                    value={values[item.name] as string}
                    onChange={handleChange}
                    fullWidth
                    InputProps={{
                      style: { height: '3.1875rem', borderRadius: '0.5rem' },
                      readOnly: true,
                    }}
                    onBlur={handleBlur(item.name)}
                    error={Boolean(touched[item.name] && errors[item.name])}
                    helperText={touched[item.name] ? errors[item.name] : ''}
                  />
                </Grid>
              </Grid>
              // )
            ))}
          </Box>
        </Box>
      </Grid>
      {/* Main Contact */}
      <Grid mt="2.5rem">
        <Typography variant="subtitle1" display="flex">
          {id2text('setting.page.infoArea.mainContact.title')}
          <Typography color="red" variant="caption">&nbsp;*</Typography>
        </Typography>
        <Box mt="1rem" width="100%">
          <Box sx={{ backgroundColor: 'rgba(250, 250, 250, 1)', border: 'rgba(250, 250, 250, 1) 2px solid', borderRadius: '0.5rem' }}>
            {mainContactArr.map((item, index) => (
              <Grid key={item.name} container mt={index === 0 ? '0rem' : '1rem'} justifyContent="space-between" alignItems="center">
                <Grid item xs={12}>
                  <EasyTextField
                    disabled={isButtonLoading}
                    label={item.label}
                    name={item.name}
                    type="text"
                    value={values[item.name] as string}
                    onChange={handleChange}
                    fullWidth
                    InputProps={{
                      style: { height: '3.1875rem', borderRadius: '0.5rem' },
                    }}
                    onBlur={handleBlur(item.name)}
                    error={Boolean(touched[item.name] && errors[item.name])}
                    helperText={touched[item.name] ? errors[item.name] : ''}
                  />
                </Grid>
              </Grid>
            ))}
          </Box>
        </Box>
      </Grid>
    </Box>
  );
};

export default InformationArea;
