import React from 'react';
//
import Box from '@mui/material/Box';
import { CircularProgress } from '@mui/material';

interface EasyCircleProgProp {
  size?: number;
}

const EasyCircleProgress: React.FC<EasyCircleProgProp> = ({
  size,
}): React.JSX.Element => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    sx={{ color: 'neutral.400' }}
  >
    <CircularProgress size={size ?? 24} color="inherit" />
  </Box>
);

export default EasyCircleProgress;
