import React from "react";
// react-router-dom
import { Outlet, useLocation } from "react-router-dom";
// mui
import { CssBaseline } from "@mui/material";
// routes config
import routes from "../../../config/routes";
// components
import Navbar from "../../../commons/components/navbar/Navbar";
import Footer from "../../../commons/components/footer/Footer";
import RootLayout from "./RootLayout";
import WebTimeout from "../../../commons/components/Timeout/WebTimeout";
import EasySnackbar from "../../../commons/components/EasySnackbar/EasySnackbar";
//
import services from "../../../api/services";

function LayoutWrapper() {
  const location = useLocation();
  // console.log('LayoutWrapper', location.pathname);
  const layoutPathname = [
    routes.SIGNIN,
    routes.SIGNUP,
    routes.VERIFYACCOUNT,
    routes.RESETPASSWORD,
    routes.LINKUSER,
    routes.VERIFYEMAIL,
    routes.FORGOTPASSWORD,
    routes.REDIRECTION,
    routes.POLICY,
    routes.SERVICE,
    // dashboard
    routes.DASHBOARD_SETTINGS,
    routes.DASHBOARD_CUSTOMER_MANAGEMENT,
    routes.DASHBOARD_OUTSOURCING_RECYCLING,
    // warehouse
    routes.MARK,
    routes.WORKORDERS,
  ];

  // 有 Header, Footer
  if (
    layoutPathname.includes(
      location.pathname.replace(`/${services.getLocale()}`, ""),
    ) ||
    layoutPathname.find(
      (name) => location.pathname.toLowerCase().indexOf(name) >= 0,
    )
  ) {
    return (
      <>
        <header>
          <Navbar />
        </header>
        <CssBaseline />
        <div style={{ flex: 1 }}>
          <RootLayout>
            <Outlet />
          </RootLayout>
        </div>
        <footer>
          <Footer />
        </footer>
        <WebTimeout />
        <EasySnackbar />
      </>
    );
  }

  return <Outlet />;
}
export default LayoutWrapper;
