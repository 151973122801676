import React, {
  useState,
  useEffect,
  type Dispatch,
  type SetStateAction,
} from "react";
import { format } from "date-fns";
import znTW from "date-fns/locale/zh-TW"; // zh-TW

// MUI
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Step from "@mui/material/Step";
import Select from "@mui/material/Select";
import Stepper from "@mui/material/Stepper";
import MenuItem from "@mui/material/MenuItem";
import StepLabel from "@mui/material/StepLabel";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";

// MUI Icons
import DoneIcon from "@mui/icons-material/Done";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

// components
import EasyScanDialog from "./EasyScanDialog";
import EasyButton from "../../commons/components/EasyButton/EasyButton";
import EasyIconButton from "../../commons/components/EasyIconButton/EasyIconButton";

// configs
import { WAREHOUSE_ID, WAREHOUSE_NAME } from "../../config/config.warehouse";
import { WorkOrders1Out } from "./Platforms/fakeData";

// utils
import { statusChipColor } from "./utils";

const WAREHOUSE_LIST = ["INVENTORY", "RAWMATERIAL", "SEMIFINISH"];
const WORK_ORDER_STATUS = [
  { label: "全部", value: 0 },
  { label: "入倉待處理", value: 1 },
  { label: "出倉待處理", value: 2 },
  { label: "預處理中", value: 3 },
];

const WorkOrder = ({
  plateformKey,
  selectedWorkOrder,
  setSelectedWorkOrder,
}: {
  plateformKey: string;
  selectedWorkOrder: Record<string, any>;
  setSelectedWorkOrder: Dispatch<SetStateAction<Record<string, any> | null>>;
}): React.JSX.Element => {
  const theme = useTheme();
  const [scannedPallets, setScannedPallets] = useState<Array<string> | []>([]);
  const [isScanning, setIsScanning] = useState<boolean>(false);

  console.log("??????", scannedPallets);

  const slpQuantityyInfo = selectedWorkOrder.pallets.reduce(
    (
      acc: Record<string, number>,
      {
        slpQuantity,
        brokenSlpQuantity,
      }: {
        slpQuantity: number;
        brokenSlpQuantity: number;
      },
    ) => {
      acc.totalSlpQuantity += slpQuantity;
      acc.totalbrokenSlpQuantity += brokenSlpQuantity;
      return acc;
    },
    { totalSlpQuantity: 0, totalbrokenSlpQuantity: 0 },
  );

  // configs
  const WorkOrderStepState = [
    { key: "step1", label: "待處理" },
    ...(plateformKey === "RAWMATERIAL"
      ? [{ key: "step1-1", label: "預處理中" }]
      : []),
    { key: "step2", label: "待入倉" },
  ];

  return (
    <Box sx={{ width: "100%" }} pt="8px">
      <Grid container flexDirection="column" rowSpacing="8px">
        <Grid item xs>
          <Box
            sx={{ width: "fit-content", whiteSpace: "nowrap" }}
            onClick={() => {
              setSelectedWorkOrder(null);
            }}
          >
            <EasyIconButton
              disabled
              style={{ color: theme.palette.neutral[600] }}
            >
              <ArrowLeftIcon />
            </EasyIconButton>
            <Typography
              variant="buttonText1"
              component="span"
              color="neutal.600"
            >
              返回列表
            </Typography>
          </Box>
        </Grid>
        <Grid item xs>
          <Box
            sx={{
              width: "100%",
              padding: "0.5rem 1.5rem 1rem 1.5rem",
              border: "1px solid",
              borderColor: "neutral.200",
              borderRadius: "8px",
            }}
          >
            <Grid container flexDirection="column" rowSpacing="8px">
              <Grid item xs>
                <Typography variant="subtitle1" color="neutral.max">
                  {`生產指令單${selectedWorkOrder.workOrderID}`}
                </Typography>
              </Grid>
              <Grid item xs>
                <Chip
                  label={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        variant="body2"
                        sx={{
                          color: statusChipColor({}, theme).dotColor,
                          marginRight: "4px",
                          fontSize: "16px",
                        }}
                      >
                        •
                      </Typography>
                      <Typography variant="caption" color="neutral.max">
                        {selectedWorkOrder?.isExited === 0
                          ? "待出倉"
                          : selectedWorkOrder?.isEntered === 0
                            ? "待入倉"
                            : "預處理中"}
                      </Typography>
                    </Box>
                  }
                  sx={{
                    backgroundColor: `${
                      statusChipColor({}, theme).backgroundColor
                    }`,
                  }}
                />
              </Grid>
              <Grid item xs>
                <Grid container>
                  <Grid item xs>
                    <Typography variant="body2" color="neutral.800">
                      {`訂單編號：${selectedWorkOrder.OrderID}`}
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography variant="body2" color="neutral.800">
                      {`站點：${WAREHOUSE_NAME[plateformKey as keyof typeof WAREHOUSE_NAME]}`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs>
                <Grid container>
                  <Grid item xs>
                    <Typography variant="body2" color="neutral.800">
                      {`棧板數量：${selectedWorkOrder.pallets.length}`}
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography variant="body2" color="neutral.800">
                      {`完整太陽能板片數：${slpQuantityyInfo.totalSlpQuantity - slpQuantityyInfo.totalbrokenSlpQuantity}`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs>
                <Grid container>
                  <Grid item xs>
                    <Typography variant="body2" color="neutral.800">
                      {`破片數量：${slpQuantityyInfo.totalbrokenSlpQuantity}`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs>
          <Box
            sx={{
              width: "100%",
              padding: "0 1.5rem 1rem 1.5rem",
              border: "1px solid",
              borderColor: "neutral.200",
              borderRadius: "8px",
            }}
          >
            <Grid container flexDirection="column" rowSpacing="1rem">
              <Grid item xs>
                <Stepper
                  activeStep={0}
                  alternativeLabel
                  sx={{
                    // change not active step text
                    ".MuiStepIcon-text": {
                      fill: theme.palette.neutral.min,
                    },
                    // change not active step icon background
                    ".MuiSvgIcon-root:not(.Mui-active):not(.Mui-completed)": {
                      color: theme.palette.neutral["300"],
                    },
                    // change active step text
                    ".Mui-active .MuiStepIcon-text": {
                      fill: theme.palette.neutral.min,
                    },
                    ".Mui-error": {
                      fill: theme.palette.error.main,
                    },
                  }}
                >
                  {WorkOrderStepState.map((state, index) => (
                    <Step key={state.key} completed={index === 0}>
                      <StepLabel>
                        <Box>
                          <Typography variant="subtitle2">
                            {state.label}
                          </Typography>
                          <Typography variant="caption">
                            {index === 0 &&
                              format(
                                parseInt(selectedWorkOrder?.createdTime, 10),
                                "PPP HH:mm:ss",
                                { locale: znTW },
                              )}
                          </Typography>
                        </Box>
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Grid>
              <Grid item xs>
                <Grid container flexDirection="column">
                  <Grid item xs>
                    <Typography variant="subtitle2" color="neutral.800">
                      指示內容
                    </Typography>
                  </Grid>
                  <Grid item xs pt="8px">
                    <Typography variant="body2" color="neutral.800">
                      {selectedWorkOrder.isExited === 0
                        ? selectedWorkOrder.exitTaskDesc
                        : selectedWorkOrder.entryTaskDesc}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs>
          <Box
            sx={{
              width: "100%",
              padding: "0.5rem 1.5rem 1rem 1.5rem",
              border: "1px solid",
              borderColor: "neutral.200",
              borderRadius: "8px",
            }}
          >
            <Grid container flexDirection="column" rowSpacing="8px">
              <Grid item xs>
                <Grid container>
                  <Grid item xs={6}>
                    <Grid container flexDirection="column">
                      <Grid item xs>
                        <Typography variant="subtitle1" color="neutral.max">
                          棧板列表
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography variant="body2" color="neutral.800">
                          {`已掃描棧板：${scannedPallets.length}/${selectedWorkOrder.pallets.length}`}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs
                    sx={{ display: "flex", justifyContent: "space-evenly" }}
                  >
                    <EasyButton
                      sx={{ width: "130px", height: "51px" }}
                      variant="secondary"
                      startIcon={<QrCodeScannerIcon />}
                      onClick={() => {
                        setIsScanning(true);
                      }}
                    >
                      掃描
                    </EasyButton>
                    <EasyButton
                      variant="secondary"
                      sx={{ width: "130px", height: "51px" }}
                      startIcon={<DoneIcon />}
                      disabled={
                        scannedPallets.length !==
                        selectedWorkOrder.pallets.length
                      }
                    >
                      {plateformKey === "INVENTORY"
                        ? "出倉"
                        : selectedWorkOrder.isExited === 0
                          ? "出倉"
                          : plateformKey === "RAWMATERIAL" &&
                              selectedWorkOrder.isPreEntered === 0
                            ? "預處理"
                            : "入倉"}
                    </EasyButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs>
                <Grid container flexDirection="column" rowSpacing="8px">
                  {selectedWorkOrder.pallets.map(
                    ({
                      palletID,
                      slpQuantity,
                      brokenSlpQuantity,
                    }: {
                      palletID: string;
                      slpQuantity: number;
                      brokenSlpQuantity: number;
                    }) => (
                      <Grid item xs key={palletID}>
                        <Box
                          sx={{
                            width: "100%",
                            border: "1px solid",
                            borderColor: "neutral.200",
                            borderRadius: "8px",
                            padding: "1rem 1.5rem",
                          }}
                        >
                          <Grid container>
                            <Grid item xs>
                              <Grid container flexDirection="column">
                                <Grid item xs>
                                  <Typography
                                    variant="subtitle1"
                                    color="neutral.max"
                                  >
                                    {palletID}
                                  </Typography>
                                </Grid>
                                <Grid item xs>
                                  <Typography
                                    variant="body2"
                                    color="neutral.800"
                                  >
                                    {`完整太陽能板片數：${slpQuantity - brokenSlpQuantity}`}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={2}>
                              2
                            </Grid>
                            <Grid item xs={1}>
                              <EasyIconButton
                                disabled
                                style={{ color: theme.palette.neutral[700] }}
                              >
                                <ArrowRightIcon />
                              </EasyIconButton>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    ),
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      {isScanning && (
        <EasyScanDialog
          pallets={selectedWorkOrder.pallets.map(
            ({ palletID }: { palletID: string }) => palletID,
          )}
          scannedPallets={scannedPallets}
          setIsScannning={setIsScanning}
          setScannedPallets={setScannedPallets}
        />
      )}
    </Box>
  );
};

export default WorkOrder;
