import React from 'react';
import Avatar, { type AvatarProps } from '@mui/material/Avatar';
import { AvatarGroup, Badge, styled } from '@mui/material';

interface EasyAvatarProps extends AvatarProps {
  type?: 'group' | 'badge'; // group 是拿來當容器的
  children?: React.ReactNode;
  groupCount?: number;
  badgeColor?: string;
  badgeBackgroundColor?: string;
  size?: 'large' | 'medium' | 'small';
}

const sizes = {
  large: { width: '2.5rem', height: '2.5rem' },
  medium: { width: '2rem', height: '2rem' },
  small: { width: '1.5rem', height: '1.5rem' },
};
const EasyAvatar: React.FC<EasyAvatarProps> = ({
  type,
  children,
  groupCount,
  size = 'small',
  badgeColor,
  badgeBackgroundColor,
  ...props
}): React.JSX.Element => {
  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: `${badgeColor}`,
      color: `${badgeBackgroundColor}`,
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));

  if (type === 'group') {
    return (
      <AvatarGroup max={groupCount} {...props}>
        {children}
      </AvatarGroup>
    );
  }

  if (type === 'badge') {
    return (
      <StyledBadge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        variant="dot"
      >
        <Avatar sx={{ ...sizes[size] }} {...props} />
      </StyledBadge>
    );
  }

  return <Avatar sx={{ ...sizes[size] }} {...props} />;
};

export default EasyAvatar;
