import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox, { type CheckboxProps } from '@mui/material/Checkbox';
import React, { ChangeEvent } from 'react';
import { SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

interface EasyCheckboxProps extends Omit<CheckboxProps, 'title' | 'size'> {
  title?: React.ReactNode;
  size?: 'large' | 'medium' | undefined;
  isChecked: boolean;
  onChange?: ((e: ChangeEvent<HTMLInputElement>) => void) | (() => void);
  formControlSX?:SxProps<Theme> | undefined;
  labelSx?:SxProps<Theme> | undefined;
}

// 有帶title 就有文字
// 預設size : small
const EasyCheckbox: React.FC<EasyCheckboxProps> = ({
  title,
  size,
  isChecked,
  onChange,
  formControlSX,
  sx,
  labelSx,
  ...props
}): React.JSX.Element => {
  if (size === 'large') {
    return (
      <FormControlLabel
        control={(
          <Checkbox
            checked={isChecked}
            onChange={onChange}
            {...props}
            sx={{
              '& .MuiSvgIcon-root': {
                fontSize: 28,
              },
              ...sx,
            }}
          />
        )}
        label={<Typography sx={{ ...labelSx }}>{title}</Typography>}
        sx={formControlSX}
      />
    );
  }
  if (size === 'medium') {
    return (
      <FormControlLabel
        control={(
          <Checkbox
            checked={isChecked}
            onChange={onChange}
            {...props}
            sx={{
              '& .MuiSvgIcon-root': {
                fontSize: 25,
              },
              ...sx,
            }}
          />
        )}
        label={<Typography sx={{ ...labelSx }}>{title}</Typography>}
        sx={formControlSX}
      />
    );
  }

  return (
    <FormControlLabel
      control={(
        <Checkbox
          checked={isChecked}
          onChange={onChange}
          {...props}
          sx={{
            '& .MuiSvgIcon-root': {
              fontSize: 22,
            },
            ...sx,
          }}
        />
      )}
      label={<Typography sx={{ ...labelSx }}>{title}</Typography>}
      sx={formControlSX}
    />
  );
};

export default EasyCheckbox;
