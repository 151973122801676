import React, { useState } from 'react';

/* components */
import EasyDialog from '../../../../../commons/components/modal/EasyDialog';
/* mui */
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
/* utils */
import handleOutsourcingRecyclingData from '../handleOutsourcingRecyclingData';
import { useIntl } from 'react-intl';
/* types */
import OutsourcingRecyclingProps from '../TypescriptProps';

const Loading = () => (
  <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="auto">
    <CircularProgress size="5rem" />
  </Box>
);
interface OutsourcingRecyclingCancelModalProps {
  isOpen:boolean;
  setIsOpen:(e:boolean)=>void;
  outsourcingData:OutsourcingRecyclingProps;
  callback:(e:boolean)=>void;
}
const OutsourcingRecyclingCancelModal = ({
  isOpen, setIsOpen, outsourcingData, callback,
}:OutsourcingRecyclingCancelModalProps) => {
  /** react-intl */
  const { formatMessage } = useIntl();
  const id2text = (id:string) => formatMessage({ id });
  const [isLoading, setIsLoading] = useState(false);
  const handleCancel = async () => {
    setIsLoading(true);
    const cancelResult = await handleOutsourcingRecyclingData.cancelOutsourcing({ outsourcingData }).then((res) => res).catch(() => false);
    callback(cancelResult);
    setIsOpen(false);
  };
  return (
    <EasyDialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      showCloseIcon
      title={id2text('outsourceRecycle.modal.cancel.title')}
      action={isLoading ? undefined : handleCancel}
      actionLabel={id2text('outsourceRecycle.modal.cancel.action.text')}
      secondaryAction={isLoading ? undefined : () => setIsOpen(false)}
      secondaryLabel={id2text('outsourceRecycle.modal.cancel.secondary.text')}
      body={isLoading ? <Loading /> : (
        <Typography>
          {id2text('outsourceRecycle.modal.cancel.text')}
        </Typography>
      )}
    />
  );
};

export default OutsourcingRecyclingCancelModal;
