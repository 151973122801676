import React, {
  ChangeEvent,
  Dispatch, SetStateAction, useEffect, useRef, useState,
} from 'react';
/* components */
import OutsourcingRecyclingInfoModal from './subComponents/OutsourcingRecyclingInfoModal';
import EasyButton from '../../../../commons/components/EasyButton/EasyButton';
import EasyEditIcon, { IconColor } from '../../../../commons/components/EasyIcons/EasyEditIcon';
import OrderForm from './subComponents/OrderForm';
import EasyCancelIcon from '../../../../commons/components/EasyIcons/EasyCancelIcon';
import EasyCircleProgress from '../../../../commons/components/EasyLoading/EasyCircleProgress';
import SelectTabs from '../../../../commons/components/SelectTabs/SelectTabs';
import OutsourcingRecyclingCancelModal from './subComponents/OutsourcingRecyclingCancelModal';
import HintModal from './subComponents/HintModal';
import EasyTextField from '../../../../commons/components/EasyTextField/EasyTextField';
import EasyPlusIcon from '../../../../commons/components/EasyIcons/EasyPlusIcon';
import EasyPagination from '../../../../commons/components/EasyPagination/EasyPagination';
import Loading from '../../../../commons/components/loading/Loading';
import EasyIconButton from '../../../../commons/components/EasyIconButton/EasyIconButton';
/* config */
import { ORDER_STATUS } from '../../../../config/config.order';
import {
  pageMode, selectPageCounts, TITLE_TEXT,
} from './config';
/* type */
import OutsourcingRecyclingProps, { HintProps, OutsourcingListProps } from './TypescriptProps';

/* utils */
import format from 'date-fns/format';
import handleOutsourcingRecyclingData from './handleOutsourcingRecyclingData';
import { useIntl } from 'react-intl';
import {
  STATUS_COLOR,
} from './utils';
/* mui */
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import { PaletteColor, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

/* mui icon */
import DownloadIcon from '@mui/icons-material/Download';
import InfoIcon from '@mui/icons-material/Info';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { KeyboardArrowRight } from '@mui/icons-material';
/* hooks */
import { useAppSelector } from '../../../../reduxStore/utils/reduxHooks';

interface EasyTableBodyProps {
  currentPage:number;
  outsourceRecycles: OutsourcingRecyclingProps[];
  getOutsourcingRecyclingOrders:()=>void;
  setMode:Dispatch<SetStateAction<string>>;
  setSelectedOrder:Dispatch<SetStateAction<OutsourcingRecyclingProps>>
  isTableLoading:boolean;
  mode:string;
}

type StatusColorType = {
  backgroundColor:string;
  dotColor:PaletteColor | string;
};

// configs
// const PAGE_COUNT = 10;
interface EasyTableHeadProps {
  setSortConfig:Dispatch<SetStateAction<{ orderBy:string;isDesc:boolean; }>>;
}
interface TableHeadCellProps {
  id: string;
  align: 'left' | 'justify' | 'center' | 'right' | 'inherit' | undefined;
  disablePadding: false;
  label: string;
  isDesc?: boolean;
  setIsDesc?: Dispatch<SetStateAction<boolean>>;
  orderBy?: string;
}
const EasyTableHead:React.FC<EasyTableHeadProps> = ({ setSortConfig }): React.JSX.Element => {
  const { formatMessage } = useIntl();
  const id2text = (id:string) => formatMessage({ id });
  // Table IsDesc Control
  const [isDeadlineDesc, setIsDeadlineDesc] = useState(false);
  // const [isArrivedDateDesc, setIsArrivedDateDesc] = useState(false);
  // const [isOrderStatusDesc, setIsOrderStatusDesc] = useState(false);

  //
  const TableHeadCells:TableHeadCellProps[] = [
    {
      id: 'OrdererCompanyName',
      align: 'left' as const,
      disablePadding: false,
      label: id2text('outsourceRecycle.th.customer'),
    },
    {
      id: 'OrderNumber',
      align: 'left' as const,
      disablePadding: false,
      label: id2text('outsourceRecycle.th.orderNumber'),
    },
    {
      id: 'ArrivalDate',
      align: 'left' as const,
      disablePadding: false,
      label: id2text('outsourceRecycle.th.arrivalDate'),
      // isDesc: isArrivedDateDesc,
      // setIsDesc: setIsArrivedDateDesc,
      // orderBy: 'bookingTime',
    },
    {
      id: 'Deadline',
      align: 'justify' as const,
      disablePadding: false,
      label: id2text('outsourceRecycle.th.deadline'),
      isDesc: isDeadlineDesc,
      setIsDesc: setIsDeadlineDesc,
      orderBy: 'bookingTime',
    },
    {
      id: 'OrderStatus',
      align: 'left' as const,
      disablePadding: false,
      label: id2text('outsourceRecycle.th.orderStatus'),
      // isDesc: isOrderStatusDesc,
      // setIsDesc: setIsOrderStatusDesc,
      // orderBy: 'orderStatus',
    },
    {
      id: 'RecyclingRate',
      align: 'left' as const,
      disablePadding: false,
      label: id2text('outsourceRecycle.th.recyclingRate'),
    },
    {
      id: 'Action',
      align: 'left' as const,
      disablePadding: false,
      label: id2text('outsourceRecycle.th.action'),
    },
    {
      id: 'Info',
      align: 'center' as const,
      disablePadding: false,
      label: id2text('outsourceRecycle.th.info'),
    },
  ];
  return (
    <TableHead>
      <TableRow
        sx={{
          '& th': {
            borderBottom: 'unset',
            backgroundColor: 'neutral.50',
          },
        }}
      >
        {TableHeadCells.map((head) => (
          <TableCell
            key={head.id}
            align={head.align}
            padding={head.disablePadding ? 'none' : 'normal'}
          >
            <Box display="flex" alignItems="center" justifyContent={head.setIsDesc != null && head.isDesc != null ? 'space-between' : head.align} width="100%">
              <Typography variant="subtitle2" color="neutral.600">
                {head.label}
              </Typography>
              {head.setIsDesc != null && head.isDesc != null && (
              <EasyIconButton onClick={() => {
                TableHeadCells.forEach((item) => {
                  if (item.isDesc != null && item.setIsDesc != null) {
                    item.setIsDesc(false);
                  }
                });
                if (head.setIsDesc != null) {
                  head.setIsDesc(!head.isDesc);
                }
                if (head.orderBy && head.isDesc != null) {
                  setSortConfig({ orderBy: head.orderBy, isDesc: head.isDesc });
                }
              }}
              >
                {head.isDesc ? <ArrowDropDownIcon /> : <ArrowDropUpIcon /> }
              </EasyIconButton>
              )}
            </Box>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

interface EasyTableRowProps {
  row: OutsourcingRecyclingProps;
  getOutsourcingRecyclingOrders:()=>void;
  setMode:Dispatch<SetStateAction<string>>;
  setSelectedOrder:Dispatch<SetStateAction<OutsourcingRecyclingProps>>;
}
const EasyTableRow:React.FC<EasyTableRowProps> = ({
  row, getOutsourcingRecyclingOrders, setMode, setSelectedOrder,
}): React.JSX.Element => {
  const theme = useTheme();
  /** react-intl */
  const { formatMessage } = useIntl();
  const id2text = (id:string) => formatMessage({ id });
  const HINT_MODAL_TEXT = {
    error: {
      title: id2text('outsourceRecycle.modal.cancel.hint.error.title'),
      text: id2text('outsourceRecycle.modal.cancel.hint.error.text'),
    },
    success: {
      title: id2text('outsourceRecycle.modal.cancel.hint.success.title'),
      text: id2text('outsourceRecycle.modal.cancel.hint.success.text'),
    },
  };
  // function
  const progressStepTime = (order:OutsourcingRecyclingProps) => {
    /* 透過時間判斷訂單狀態 */
    if ((order?.rejectedTime || order?.canceledTime)) {
      return 1;
    }
    if (order?.completedTime) {
      return 3;
    }
    if (order?.recyclingTime) {
      return 2;
    }
    return 0;
  };
  const progressStatus = (order:OutsourcingRecyclingProps, statusNumber:number) => {
    /* 透過時間判斷訂單狀態 */
    if ((order?.rejectedTime || order?.canceledTime) && statusNumber === 1) {
      return id2text('outsourceRecycle.chip.canceled.text');
    }
    if ((order?.completedTime) && statusNumber === 3) {
      return id2text('outsourceRecycle.chip.completed.text');
    }
    if ((order?.recyclingTime) && statusNumber === 2) {
      return id2text('outsourceRecycle.chip.recycling.text');
    }

    return id2text('outsourceRecycle.chip.arrived.text');
  };
  const getChipTheme = (backgroundColor:string) => {
    switch (backgroundColor) {
      case STATUS_COLOR.ORANGE:
        return {
          backgroundColor: theme.palette.tertiary['50'],
          dotColor: theme.palette.tertiary.main,
        };
      case STATUS_COLOR.GREY:
        return {
          backgroundColor: theme.palette.neutral['50'],
          dotColor: theme.palette.neutral['400'],
        };
      case STATUS_COLOR.GREEN:
        return {
          backgroundColor: theme.palette.secondary['50'],
          dotColor: theme.palette.secondary.main,
        };
      default: return {
        backgroundColor: theme.palette.primary['50'],
        dotColor: theme.palette.primary.main,
      };
    }
  };

  const statusChipColor = (status:string, callback:(backgroundColor:string)=>StatusColorType): StatusColorType => {
    /* 灰色 */
    if (status?.includes(id2text('outsourceRecycle.chip.canceled.text'))) {
      return callback(STATUS_COLOR.GREY);
    }
    /* 綠色 */
    if (
      status?.includes(id2text('outsourceRecycle.chip.recycling.text')) || status?.includes(id2text('outsourceRecycle.chip.arrived.text'))
    ) {
      return callback(STATUS_COLOR.GREEN);
    }
    /* 藍色 */
    /* Unpaid Completed */
    return callback(STATUS_COLOR.BLUE);
  };
  /* 選擇的Order */
  const [isOrderInfoOpen, setIsOrderInfoOpen] = useState<boolean>(false);
  const [isOrderInfoLoading, setIsOrderInfoLoading] = useState<boolean>(true);
  /* */
  const isEditDisabled = !!((row?.canceledTime || row?.rejectedTime));
  /* 取消視窗 */
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  /* 提示視窗 */
  const [isHintModalOpen, setIsHintModalOpen] = useState(false);
  const [modalText, setModalText] = useState<HintProps>(HINT_MODAL_TEXT.success);

  const getOrderNumber = (row:OutsourcingRecyclingProps) => {
    if (row?.onBoardDocumentNumber) {
      return { label: 'onBoardDocumentNumber', value: row.onBoardDocumentNumber };
    }
    if (row?.tripletNumber) {
      return { label: 'tripletNumber', value: row.tripletNumber };
    }
    return { label: 'customizedNumber', value: row.customizedNumber };
  };
  useEffect(() => {
    if (!isOrderInfoOpen) {
      setSelectedOrder({} as OutsourcingRecyclingProps);
      setIsOrderInfoLoading(true);
    }
  }, [isOrderInfoOpen]);

  const bookingTimeToDeadline = (bookingTime:string) => {
    const after30daysDate = new Date(parseInt(bookingTime, 10));
    return after30daysDate.setDate(after30daysDate.getDate() + 30);
  };
  return (
    <>
      {/* Table */}
      <TableRow>
        {/* fusekiID 暫存 之後改成companyName */}
        <TableCell align="left">
          <Typography variant="body2" color="neutral.max">
            {row?.ordererCompany ?? ''}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Grid container>
            {/* tripletNumber / customized Number / onboard document Number */}
            <Grid item xs={8}>
              <Typography variant="body2" color="neutral.max" sx={{ wordWrap: 'break-word' }}>
                {getOrderNumber(row).value}
              </Typography>
            </Grid>
            {/* Copy OrderID */}
            <Grid item xs={4}>
              { getOrderNumber(row).value && (
              <EasyIconButton
                size="small"
                onClick={async () => {
                  if (getOrderNumber(row).label) {
                    await navigator.clipboard.writeText(
                      row[getOrderNumber(row).label as keyof OutsourcingRecyclingProps] as string,
                    );
                  }
                }}
              >
                <ContentCopyIcon />
              </EasyIconButton>
              )}
            </Grid>
          </Grid>
        </TableCell>
        {/* Arrival Date */}
        <TableCell align="left">
          <Typography variant="body2" color="neutral.max">
            {row?.bookingTime ? format(parseInt(row?.bookingTime, 10), 'yyyy/MM/dd HH:mm') : ''}
          </Typography>
        </TableCell>
        {/* Deadline */}
        <TableCell align="left">
          <Typography variant="body2" color="neutral.max">
            {row?.bookingTime ? format(bookingTimeToDeadline(row?.bookingTime), 'yyyy/MM/dd HH:mm') : ''}
          </Typography>
        </TableCell>
        {/* Order Status */}
        <TableCell align="left">
          <Chip
            label={(
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  variant="body2"
                  sx={{
                    color: statusChipColor(progressStatus(row, progressStepTime(row)), getChipTheme).dotColor as string,
                    marginRight: '4px',
                  }}
                >
                  •
                </Typography>
                <Typography variant="caption" color="neutral.max">
                  {progressStatus(row, progressStepTime(row))}
                </Typography>
              </Box>
              )}
            sx={{ backgroundColor: `${statusChipColor(progressStatus(row, progressStepTime(row)), getChipTheme).backgroundColor}` }}
          />
        </TableCell>
        {/* RecyclingRate */}
        <TableCell align="left">
          <Typography variant="body2" color="neutral.max">
            {row.recoveryRate ? `${row.recoveryRate} %` : ''}
          </Typography>
        </TableCell>
        {/* Action */}
        <TableCell align="left">
          <Box display="flex" justifyContent="left" alignItems="center">
            {!row?.canceledTime && (
            <EasyIconButton
              disabled={isEditDisabled}
              size="medium"
              onClick={() => {
                setMode(pageMode.EDIT);
                setSelectedOrder({ ...row });
              }}
            >
              <EasyEditIcon
                size="sm"
                color={(isEditDisabled ? 'grey' : 'primary') as IconColor}
              />
            </EasyIconButton>
            )}
            {row?.completedTime && row?.orderStatus === ORDER_STATUS.COMPLETED && !row?.canceledTime && (
            <EasyIconButton
              disabled={!row?.certificationUrl}
              size="medium"
              onClick={() => { window.open(row.certificationUrl, 'noopener noreferer'); }}
            >
              <DownloadIcon sx={{ color: row?.certificationUrl ? 'primary' : 'grey' }} />
            </EasyIconButton>
            )}
            { !row?.completedTime && row?.orderStatus === ORDER_STATUS.ARRIVED && !row.canceledTime && (
            <EasyIconButton
              disabled={isEditDisabled}
              size="medium"
              onClick={() => {
                setIsCancelModalOpen(true);
              }}
            >
              <EasyCancelIcon
                size="lg"
              />
            </EasyIconButton>
            ) }
          </Box>
        </TableCell>
        {/* Info */}
        <TableCell align="center">
          <EasyIconButton
            size="medium"
            onClick={async () => {
              setIsOrderInfoOpen(true);
            }}
          >
            <InfoIcon color="primary" />
          </EasyIconButton>
        </TableCell>
      </TableRow>
      {/* info視窗 */}
      { isOrderInfoOpen && (
      <OutsourcingRecyclingInfoModal
        isOpen={isOrderInfoOpen}
        setIsOpen={setIsOrderInfoOpen}
                    // selectedEquipment={selectedEquipment}
        order={row}
                    // handleAssignChange={handleAssignChange}
                    // action={() => setIsAssignOpen(false)}
                    // equipmentList={equipmentList}
      />
      )}
      {/* cancel 視窗 */}
      <OutsourcingRecyclingCancelModal
        isOpen={isCancelModalOpen}
        setIsOpen={setIsCancelModalOpen}
        outsourcingData={row}
        callback={(e:boolean) => {
          if (e) {
            setModalText(HINT_MODAL_TEXT.success);
          } else {
            setModalText(HINT_MODAL_TEXT.error);
          }
          setIsHintModalOpen(true);
        }}
      />
      {/* 提示視窗  */}
      <HintModal isOpen={isHintModalOpen} setIsOpen={setIsHintModalOpen} modalText={modalText} callback={getOutsourcingRecyclingOrders} />
    </>
  );
};

const EasyTableBody: React.FC<EasyTableBodyProps> = ({
  outsourceRecycles, getOutsourcingRecyclingOrders, currentPage, setMode, setSelectedOrder, mode, isTableLoading,
}): React.JSX.Element => {
  /** react-intl */
  const { formatMessage } = useIntl();
  const id2text = (id:string) => formatMessage({ id });
  const handleTable = (isTableLoading:boolean) => {
    if (isTableLoading) {
      return (
        <TableRow>
          <TableCell colSpan={11}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <EasyCircleProgress />
            </Box>
          </TableCell>
        </TableRow>
      );
    }
    return (
      outsourceRecycles.map((outsourceRecycle) => (
        <EasyTableRow
          row={outsourceRecycle}
          getOutsourcingRecyclingOrders={getOutsourcingRecyclingOrders}
          setMode={setMode}
          setSelectedOrder={setSelectedOrder}
          key={outsourceRecycle.fusekiID}
        />
      ))
    );
  };
  return (
    <TableBody>
      {outsourceRecycles.length ? (
        handleTable(isTableLoading)
      ) : (
        <TableRow>
          <TableCell colSpan={11}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {id2text('outsourceRecycle.pageTitle.null')}
            </Box>
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );
};
const OutsourcingRecyclingPage = (): React.JSX.Element => {
  /** react-intl */
  const { formatMessage } = useIntl();
  const id2text = (id: string) => formatMessage({ id });
  /* mui */
  const theme = useTheme();
  /* page */
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState(10);
  const [pageQueryCount, setPagQueryCount] = useState(pageCount);

  /* status */
  type FilterStatusProps = {
    id: string;
    label: string;
    value: string;
  };
  const FILTER_STATUS_LIST = [
    {
      id: 'All', value: 'All', label: id2text('outsourceRecycle.status.label.all'),
    },
    {
      id: 'Arrived', value: ORDER_STATUS.ARRIVED, label: id2text('outsourceRecycle.chip.arrived.text'),
    },
    {
      id: 'Recycling', value: ORDER_STATUS.RECYCLING, label: id2text('outsourceRecycle.chip.recycling.text'),
    },
    {
      id: 'Completed', value: ORDER_STATUS.COMPLETED, label: id2text('outsourceRecycle.chip.completed.text'),
    },
    {
      id: 'Canceled', value: ORDER_STATUS.CANCELED, label: id2text('outsourceRecycle.chip.canceled.text'),
    },
  ];

  const [filterStatus, setFilterStatus] = useState<FilterStatusProps>(FILTER_STATUS_LIST[0]);
  /* filter text */
  const [filterText, setFilterText] = useState<string>('');
  const [queryText, setQueryText] = useState<string>('');
  /* orderBy */
  const [sortConfig, setSortConfig] = useState<{ orderBy: string; isDesc: boolean }>({
    orderBy: 'bookingTime',
    isDesc: true,
  });
  /* 訂單內容 */
  const [outsourceRecycles, setOutsourceRecycles] = useState<OutsourcingRecyclingProps[]>([]);
  const [selectedOrder, setSelectedOrder] = useState<OutsourcingRecyclingProps>({} as OutsourcingRecyclingProps);
  const [isLoading, setIsLoading] = useState(true);
  const [osrIds, setOsrIds] = useState<OutsourcingListProps[]>([]);
  /* table loading */
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [firstLoading, setFirstLoading] = useState(true);
  // react redux
  const user = useAppSelector((state) => state.userDataStore);
  /* page mode */
  const [mode, setMode] = useState(pageMode.LIST);

  /* tabs */
  const tabsArr = [
    { label: `${id2text('outsourceRecycle.tab.all')} (${osrIds.length})`, value: 'All' },
    {
      label: `${id2text('outsourceRecycle.tab.controlledOrder')} (${osrIds.filter((item) => item.isControlled === '1').length})`,
      value: TITLE_TEXT.IS_CONTROLLED_TRUE,
    },
    {
      label: `${id2text('outsourceRecycle.tab.uncontrolledOrder')} (${osrIds.filter((item) => item.isControlled === '0').length})`,
      value: TITLE_TEXT.IS_CONTROLLED_FALSE,
    },
  ];
  const [selectedType, setSelectedType] = useState(tabsArr[0].value);
  const handleTabsChanged = (type: string) => {
    setIsTableLoading(true);
    setCurrentPage(1);
    // setSelectedType(type);
    if (type === selectedType) {
      setIsTableLoading(false);
    } else {
      setSelectedType(type);
    }
  };

  /*  page select */
  const getPageArray = () => {
    const pageArray = [];
    let osrIdsNumber = osrIds.filter((item) => {
      if (selectedType === 'All') {
        return item;
      }
      if (selectedType === TITLE_TEXT.IS_CONTROLLED_TRUE) {
        return item.isControlled === '1';
      }
      return item.isControlled === '0';
    }).length ?? 0;
    if (osrIdsNumber !== 0) {
      osrIdsNumber = Math.ceil(osrIdsNumber / pageCount);
    }
    for (let i = 1; i <= osrIdsNumber; i += 1) {
      pageArray.push(i);
    }
    return pageArray;
  };

  /** function * */
  const getOutsourcingRecyclingOrders = async () => {
    try {
      setIsTableLoading(true);
      await handleOutsourcingRecyclingData.getOutsourcingList({
        uid: user?.firebaseID, filterText: queryText, filterStatus: filterStatus.value, sortConfig,
      }).then(async (res) => {
        if (res) {
          setOsrIds(res);
          await handleOutsourcingRecyclingData.getOutsourcingDetail({
            osrIds: res.filter((item) => {
              if (selectedType === tabsArr[0].value) {
                return item;
              }
              if (selectedType === tabsArr[1].value) {
                return item.isControlled === '1';
              }
              return item.isControlled === '0';
            }).map((item) => item.osrId).slice((currentPage - 1) * pageCount, pageCount * currentPage),
            uid: user?.firebaseID ?? '',
          }).then((res) => {
            setOutsourceRecycles(res);
          });
        }
      }).then(() => {
        setIsTableLoading(false);
      });
    } catch (err) {
      console.log('getContractData error', err);
    }
  };

  useEffect(() => {
    if (firstLoading) {
      getOutsourcingRecyclingOrders().then(() => {
        setIsLoading(false);
        setFirstLoading(false);
      });
    } else if (mode === pageMode.LIST) {
      getOutsourcingRecyclingOrders();
    }
  }, [currentPage, mode, selectedType, pageQueryCount, queryText, filterStatus, sortConfig]);

  const timerPageIdRef = useRef<NodeJS.Timeout | null>(null);
  const timerFilterTextIdRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (timerPageIdRef.current !== null) {
      clearTimeout(timerPageIdRef.current);
    }
    timerPageIdRef.current = setTimeout(() => {
      setCurrentPage(1);
      if (!pageCount || pageCount < 1) return;
      setPagQueryCount(pageCount);
    }, 1000);
  }, [pageCount]);

  useEffect(() => {
    if (timerFilterTextIdRef.current !== null) {
      clearTimeout(timerFilterTextIdRef.current);
    }
    timerFilterTextIdRef.current = setTimeout(() => {
      setQueryText(filterText);
      setCurrentPage(1);
    }, 1000);
  }, [filterText, filterStatus]);

  useEffect(() => {
    console.log(queryText);
  }, [queryText]);

  return (
    <>
      {/* 主頁面 */}
      <Box
        p="1rem 1.5rem 4rem 1.5rem"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          width: '100%',
        }}
      >
        {/* 列表模式 */}
        {mode === pageMode.LIST && (
        <Paper sx={{ width: '100%' }} elevation={0}>
          <Toolbar
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            style={{ padding: '0 0 1rem 0' }}
          >
            <Grid item container justifyContent="space-between" xs={12}>
              <Typography variant="h5">{id2text('outsourceRecycle.pageTitle')}</Typography>
              <EasyButton
                disabled={isLoading}
                startIcon={<EasyPlusIcon />}
                size="medium"
                variant="contained"
                onClick={() => setMode(pageMode.ADD)}
              >
                <Typography variant="buttonText1">{id2text('outsourceRecycle.page.button.add')}</Typography>
              </EasyButton>
              {/* Tabs */}
              <Box width="100%" mt="1rem">
                <SelectTabs handleTabsChanged={handleTabsChanged} tabsArr={tabsArr} defaultValue={selectedType} />
              </Box>
              <Box width="100%" mt="1rem">
                <Grid container>
                  <Grid item xs={3}>
                    {/* Selected Filter Condition */}
                    <FormControl fullWidth>
                      <InputLabel id="Group">Status</InputLabel>
                      <Select
                        label="Group"
                        labelId="Group"
                        fullWidth
                        value={filterStatus.value}
                        sx={{
                          height: '3.1875rem',
                          borderRadius: '0.5rem',
                        }}
                          // onChange={handleChange}
                        onChange={(e:SelectChangeEvent) => {
                          const existingItem = FILTER_STATUS_LIST.find((item) => item.value === e.target.value);
                          if (existingItem) {
                            setFilterStatus(existingItem);
                          }
                        }}
                      >
                        {FILTER_STATUS_LIST.map((item) => (
                          <MenuItem
                            key={`${item.id}`}
                            value={item.value}
                            sx={{ width: '100%' }}
                          >
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={9} pl="1rem">
                    {/* SearchBar */}
                    <EasyTextField
                      fullWidth
                      type="text-search"
                      placeholder={id2text('outsourceRecycle.status.placeholder.search')}
                      inputProps={{ style: { marginLeft: '0.25rem' } }}
                      value={filterText}
                      onChange={(e:ChangeEvent<HTMLInputElement>) => {
                        setFilterText(e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Toolbar>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <TableContainer sx={{ maxHeight: '100vh' }}>
                <Table
                  sx={{
                    minWidth: 750,
                    border: '1px solid',
                    borderRadius: '8px',
                    borderColor: 'neutral.200',
                    '& .MuiTableRow-root:hover': {
                      backgroundColor: theme.palette.neutral['100'],
                    },
                  }}
                  size="medium"
                  stickyHeader
                >
                  <EasyTableHead setSortConfig={setSortConfig} />
                  <EasyTableBody
                    setMode={setMode}
                    mode={mode}
                    currentPage={currentPage}
                    outsourceRecycles={outsourceRecycles}
                    getOutsourcingRecyclingOrders={getOutsourcingRecyclingOrders}
                    setSelectedOrder={setSelectedOrder}
                    isTableLoading={isTableLoading}
                  />
                </Table>
              </TableContainer>
              {/* 下方分頁 */}
              <Box
                mt="1rem"
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                {/* 一頁幾筆資料 */}
                <Box
                  width="100%"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                  }}
                >
                  <Select
                    value={`${pageCount}`}
                    onChange={(e:SelectChangeEvent) => {
                      const pageNumber = parseInt(e.target.value, 10);
                      if (Number.isNaN(pageNumber)) {
                        setPageCount(0);
                      } else {
                        setPageCount(pageNumber);
                      }
                    }}
                  >
                    {selectPageCounts.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                  <Typography variant="body1" ml="1rem">
                    {id2text('common.records.per.page')}
                  </Typography>
                </Box>
                {/* 分頁 */}
                <Box
                  width="100%"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <EasyPagination
                    totalNum={osrIds.filter((item, index) => {
                      if (selectedType === 'All') {
                        return item;
                      }
                      if (selectedType === TITLE_TEXT.IS_CONTROLLED_TRUE) {
                        return item.isControlled === '1';
                      }
                      return item.isControlled === '0';
                    }).length ?? 0}
                    pageCount={pageQueryCount}
                    onChange={(_event, page) => {
                      setCurrentPage(page);
                    }}
                    page={currentPage}
                  />
                </Box>
                {/* 去哪一頁 */}
                <Box
                  width="100%"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'end',

                  }}
                >
                  <Typography variant="body1" ml="1rem">
                    {id2text('common.go.to.page')}
                  </Typography>
                  <Select
                    value={`${currentPage}`}
                    onChange={(e:SelectChangeEvent) => {
                      const pageNumber = parseInt(e.target.value, 10);
                      if (Number.isNaN(pageNumber)) {
                        setCurrentPage(0);
                      } else {
                        setCurrentPage(pageNumber);
                      }
                    }}
                    sx={{
                      marginLeft: '1rem',
                    }}
                  >
                    {getPageArray().map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </Box>
            </>
          )}
        </Paper>
        )}
        {/* 建立/編輯模式 傳入mode */}
        {(mode === pageMode.EDIT || mode === pageMode.ADD) && (
        <Paper sx={{ width: '100%' }} elevation={0}>
          <OrderForm setMode={setMode} selectedOrder={selectedOrder} mode={mode} />
        </Paper>
        )}
      </Box>
    </>
  );
};

export default OutsourcingRecyclingPage;
