import { createSlice } from '@reduxjs/toolkit';
import CustomerProps from './TypescriptProps';
import customerAction from './customerAction';

// eslint-disable-next-line import/prefer-default-export
export const selectedCustomerReducer = createSlice({
  name: 'selectedCustomerReducer',
  initialState: null as CustomerProps | null,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(customerAction.SET_CUSTOMER_MANAGEMENT, (state, action) => action.payload);
  },
});
