const WorkOrders1Out = [
  {
    OrderID: "GL950596011200521",
    workOrderID: "GL950596011200521IR001",
    entryWarehouse: "清點區 Inventory Area",
    entryTaskDesc: `
    1. 清點太陽能板(每個棧板20片)
    2. 建立麥頭
    3. 粘貼麥頭
    4. 出倉`,

    palletQuantity: 20,
    creator: "Hello",
    createdTime: "1708497963943",

    exitWarehouse: "原料倉 Raw Material",
    exitTaskDesc: `1. 清點後出倉`,

    // status step
    isExited: 0,
    isPreEntered: 0,
    isEntered: 0,

    pallets: [
      {
        palletID: "GL95059601120052-112345678-20240219001",
        slpQuantity: 20,
        brokenSlpQuantity: 1,
      },
      {
        palletID: "GL95059601120052-112345678-20240219002",
        slpQuantity: 20,
        brokenSlpQuantity: 2,
      },
      {
        palletID: "GL95059601120052-112345678-20240219003",
        slpQuantity: 20,
        brokenSlpQuantity: 3,
      },
      {
        palletID: "GL95059601120052-112345678-20240219004",
        slpQuantity: 20,
        brokenSlpQuantity: 4,
      },
      {
        palletID: "GL95059601120052-112345678-20240219005",
        slpQuantity: 20,
        brokenSlpQuantity: 5,
      },
    ],
  },
];

const WorkOrders2In = [
  {
    OrderID: "GL950596011200521",
    workOrderID: "GL950596011200521IR001",
    entryWarehouse: "清點區 Inventory Area",
    entryTaskDesc: `
  1. 清點太陽能板(每個棧板20片)
  2. 建立麥頭
  3. 粘貼麥頭
  4. 出倉`,

    palletQuantity: 20,
    creator: "Hello",
    createdTime: "1708497963943",

    exitWarehouse: "原料倉 Raw Material",
    exitTaskDesc: `入倉 Enter warehouse`,

    pallets: [
      {
        palletID: "GL95059601120052-112345678-20240219001",
        slpQuantity: 20,
        brokenSlpQuantity: 0,
      },
      {
        palletID: "GL95059601120052-112345678-20240219002",
        slpQuantity: 20,
        brokenSlpQuantity: 0,
      },
      {
        palletID: "GL95059601120052-112345678-20240219003",
        slpQuantity: 20,
        brokenSlpQuantity: 0,
      },
      {
        palletID: "GL95059601120052-112345678-20240219004",
        slpQuantity: 20,
        brokenSlpQuantity: 0,
      },
      {
        palletID: "GL95059601120052-112345678-20240219005",
        slpQuantity: 20,
        brokenSlpQuantity: 0,
      },
    ],
  },
];

// eslint-disable-next-line import/prefer-default-export
export { WorkOrders1Out, WorkOrders2In };
