// MUI
import { type Theme } from '@mui/material/styles';

// actions

// configs

// Typescript

const statusChipColor = (
  status: Record<string, boolean>,
  theme: Theme,
): {
  backgroundColor: string;
  dotColor: string;
} => ({
  // backgroundColor: theme.palette.primary["50"],
  // dotColor: theme.palette.primary.main,
  backgroundColor: theme.palette.tertiary['50'],
  dotColor: theme.palette.tertiary.main,
});

// eslint-disable-next-line import/prefer-default-export
export { statusChipColor };
