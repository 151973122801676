const INPUT_LIMIT = {
  taxNumber: 8,
  tripletNumber: 16,
  customizedNumber: 17,
  onBoardDocumentNumber: 18,
  ordererTel: 10,
};
const pageMode = {
  ADD: 'add',
  EDIT: 'edit',
  LIST: 'list',
};
const ORDER_STATUS = {
  CREATED: 'Created',
  CANCELED: 'Canceled',
  CONFIRMED: 'Confirmed',
  REJECTED: 'Canceled',
  ARRIVED: 'Arrived',
  RECYCLING: 'Recycling',
  COMPLETED: 'Completed',
};
const SELECT_STATUS_LIST = [
  ORDER_STATUS.ARRIVED, ORDER_STATUS.RECYCLING, ORDER_STATUS.COMPLETED,
];

const selectPageCounts = [10, 20, 50, 100];

const SLP_STATUS = {
  UNRECYCLED: 'Unrecycled',
  RECYCLING: 'Recycling',
  COMPLETED: 'Completed',
  UNPROCESSED: 'Unprocessed',
};

const RECYCLING_CODE_LIST = {
  TYPE1: 'D2528',
  TYPE2: 'E0217/E0218',
  TYPE3: '黑帳',
};
const RECYCLING_CODE_ARR = [RECYCLING_CODE_LIST.TYPE1, RECYCLING_CODE_LIST.TYPE2, RECYCLING_CODE_LIST.TYPE3];
/* info modal text */
const TITLE_TEXT = {
  TITLE: 'Information',
  SUBTITLE1: 'Recycling Information',
  SUBTITLE2: 'Customer Information',
  SUBTITLE_NET_WEIGHT_INFO: 'Net Weight of Recycled Materials',
  // SUBTITLE4: 'Receptionist',
  FUSEKI_ID: 'Order ID',
  /* Recycling Information */
  IS_CONTROLLED_TITLE: 'Order Source',
  IS_CONTROLLED_TRUE: 'Controlled Order',
  IS_CONTROLLED_FALSE: 'Uncontrolled Order',
  IS_ASSOCIATED_TITLE: 'From the Solar Energy Association',
  IS_ASSOCIATED_TRUE: 'Yes',
  IS_ASSOCIATED_FALSE: 'No',
  RECYCLING_CODE_TITLE: 'Waste and Recycling Resource Code',
  CUSTOMER_NUMBER_TITLE: 'Customized Number',
  ON_BOARD_DOCUMENT_NUMBER_TITLE: 'On-board Document Number',
  // EPA_TRACKING_NUMBER: 'EPA Tracking Number',
  TRIPLET_NUMBER: 'Triplet Number',
  TAX_NUMBER_TITLE: 'Tax Number',
  // DISCHARGE_APP_NUMBER: 'Discharge Application Number',
  QUANTITY_TITLE: 'Quantity of Solar Panels',
  NET_WEIGHT_TITLE: 'Net Weight',
  GROSS_WEIGHT_TITLE: 'Gross Weight',

  ARRIVED_DATE: 'Solar Panel Arrived Date',
  STATUS_TITLE: 'Status',
  /* Customer Information */
  COMPANY_NAME_TITLE: 'Company Name',
  ORDERER_NAME_TITLE: 'Name',
  ORDERER_MAIL_TITLE: 'Email',
  ORDERER_TEL_TITLE: 'Phone',
  /* Barcode List */
  // BARCODE_ALL: 'All',
  // BARCODE_HINT: '2 of which cannot be processed.',
  /* Receptionist */
  RECEPTIONIST: 'Receptionist',
  /* Net Weight Info */
  // NET_WEIGHT_HINT: 'Please enter the total net weight of unprocessable solar panel recycling material to generate recycling history.',
  NET_WEIGHT_AL_FRAME_TITLE: 'AL Frame',
  NET_WEIGHT_JB_TITLE: 'JB',
  NET_WEIGHT_GLASS_TITLE: 'Glass',
  NET_WEIGHT_CELL: 'Cell',
  NET_WEIGHT_EVA: 'EVA',
  NET_WEIGHT_PVDF: 'PVDF',
  /* order Form 中文 */
  // EPA_TRACKING_NUMBER_HINT: '環保署單號',
  TRIPLET_NUMBER_HINT: '三聯單編號',
  // DISCHARGE_APP_NUMBER_HINT: '案場排出申請編號',
  CANNOT_RECYCLED: 'Cannot be recycled by the equipment.',
};

const pageTitle = {
  [pageMode.LIST]: 'Outsourcing Recycling',
  [pageMode.EDIT]: 'Edit Order',
  [pageMode.ADD]: 'Add New Order',
};

const CHECK_PARAMS_TYPE = {
  TRIPLET_NUMBER: 'triplet number',
  CUSTOMIZED_NUMBER: 'customized number',
  ON_BOARD_DOCUMENT_NUMBER: 'onboard document number',
};

export {
  pageTitle, pageMode,
  TITLE_TEXT, SELECT_STATUS_LIST,
  SLP_STATUS,
  RECYCLING_CODE_ARR, RECYCLING_CODE_LIST, CHECK_PARAMS_TYPE,
  INPUT_LIMIT,
  selectPageCounts,
};
