const signUpPage = {
    /* sign up */
    "signUp.page.title": "建立您的帳戶",
    "signUp.page.helperTexts.mail": "電子信箱無效",
    /* 輸入欄位提示 */
    "signUp.page.helperTexts.password": "密碼長度必須至少為 8 個字符，並包含大寫字母、小寫字母和數字。",
    "signUp.page.helperTexts.confirmPassword": "密碼和確認密碼不符合",
    "signUp.page.helperTexts.empty": "這裡不允許有空格",
    /* 輸入欄位 */
    "signUp.page.contactName.label": "聯絡人姓名",
    "signUp.page.contactMail.label": "電子信箱",
    "signUp.page.password.label": "密碼",
    "signUp.page.confirmPassword.label": "確認密碼",
    "signUp.page.policy.text1": "建立帳戶即表示您同意我們的 ",
    "signUp.page.policy.text2": " 服務條款",
    "signUp.page.policy.text3": "和",
    "signUp.page.policy.text4": "隱私政策",
    "signUp.page.button.signUp": "註冊",
    "signUp.page.hasAccount.text": "已經使用過EasyCycle了？",
    "signUp.page.signIn.text": "登入",
    /* 註冊提示視窗 */
    "signUp.modal.exist.title": "使用者名稱已經被註冊",
    "signUp.modal.exist.text": "您提供的電子信箱已被使用。請前往登入頁面繼續使用您的帳戶。",
    "signUp.modal.exist.action": "繼續登入",
    "signUp.modal.exist.secondary.action": "我知道了",
    "signUp.modal.systemError.title": "系統錯誤",
    "signUp.modal.systemError.text": "系統錯誤。請稍後再試。",
}

export default signUpPage
