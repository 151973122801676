import React from 'react';
// components
import EasyDialog from '../../../commons/components/modal/EasyDialog';
// mui
import Typography from '@mui/material/Typography';
// hooks
import { useNavigate } from 'react-router-dom';
// config
import routes from '../../../config/routes/index';
import services from '../../../api/services';
import { useIntl } from 'react-intl';
import { string } from 'yup';

interface VerifyPopUpProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  modalObj: {
    label: string;
    title: string;
    text: string;
  };
}
const VerifyPopUp: React.FC<VerifyPopUpProps> = ({
  setIsOpen,
  isOpen,
  modalObj,
}) => {
  const navigation = useNavigate();
  /** react intl */
  const { formatMessage } = useIntl();
  const id2text = (id:string) => formatMessage({ id });
  //
  const handleGoToLogin = (): void => {
    setIsOpen(false);
    navigation(`/${services.getLocale()}${routes.SIGNIN}`);
  };

  const handleIKnow = (): void => {
    setIsOpen(false);
  };

  return (
    <EasyDialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      showCloseIcon
      title={modalObj.title}
      action={handleGoToLogin}
      actionLabel={id2text('signUp.modal.exist.action')}
      secondaryAction={handleIKnow}
      secondaryLabel={id2text('signUp.modal.exist.secondary.action')}
      body={<Typography>{modalObj.text}</Typography>}
    />
  );
};

export default VerifyPopUp;
