import React from 'react';
// mui
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
// component
import EasyTabs from '../EasyTabs/EasyTabs';

const SelectTabs = ({
  handleTabsChanged,
  tabsArr,
  defaultValue,
}: {
  handleTabsChanged: (e:string) => void;
  tabsArr: Array<{ label: string; value: string }>;
  defaultValue:string;
}): React.JSX.Element => (
  <Box
    sx={{
      borderBottom: '1px solid black',
      borderColor: 'divider',
      width: '100%',
    }}
    display="flex"
    justifyContent="start"
  >
    <EasyTabs defaultValue={defaultValue}>
      {tabsArr.map((item, index) => (
        <Tab
          key={`${index.toString()}`}
          value={item.value}
          label={item.label}
          sx={{ textTransform: 'capitalize' }}
          onClick={() => {
            handleTabsChanged(item.value);
          }}
        />
      ))}
    </EasyTabs>
  </Box>
);

export default SelectTabs;
