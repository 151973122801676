import axios from 'axios';
import services from '../../../api/services';

interface EmailData {
  email: string;
  type: 'resetPassword' | 'emailVerify';
}

const handleSendEmailForUser = async (content: EmailData): Promise<boolean> => {
  const response = await axios.post(services.postUserMail(), {
    ...content,
  }, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response.data;
};

export default handleSendEmailForUser;
