import React, { useEffect, useState } from 'react';
//

// material ui
import Box from '@mui/material/Box';
import Snackbar, { type SnackbarOrigin } from '@mui/material/Snackbar';
import MuiAlert, { type AlertProps } from '@mui/material/Alert';
import { type SnackbarProps } from '@mui/material/Snackbar/Snackbar';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../reduxStore/utils/reduxHooks';
import mainAct from '../../../reduxStore/mainReducer/mainAction';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';
import EasyAlert from '../EasyAlert/EasyAlert';

//
const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

Alert.displayName = 'Alert';

const EasySnackbar: React.FC = (): React.JSX.Element => {
  const dispatch = useAppDispatch();
  const snackbarInfo = useAppSelector((state) => state.snackbarStore);
  const {
    open, severity, message, autoHideDuration,
  } = snackbarInfo;
  //
  const [snackOrigin] = React.useState<SnackbarOrigin>({
    vertical: 'bottom',
    horizontal: 'left',
  });
  const { vertical, horizontal } = snackOrigin;
  const [snackOpen, setSnackOpen] = useState<boolean>(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  //
  const snackProps = (): SnackbarProps => {
    const prop: SnackbarProps = {};

    if (autoHideDuration) {
      prop.autoHideDuration = autoHideDuration;
    }
    return prop;
  };

  useEffect(() => {
    if (open !== null) {
      setSnackOpen(open);
    }
  }, [open]);

  const onSnackClose = (): void => {
    setSnackOpen(false);
    dispatch(
      mainAct.SET_SNACKBAR_INFO({
        open: false,
        message: '',
        severity: 'info',
      }),
    );
  };

  //
  return (
    <Snackbar
      {...snackProps()}
      open={snackOpen}
      onClose={onSnackClose}
      anchorOrigin={{ vertical, horizontal }}
    >
      <Box>
        <EasyAlert severity={severity ?? 'info'} sx={{ maxWidth: isMobile ? '100%' : '50%' }}>
          {message}
        </EasyAlert>
      </Box>
    </Snackbar>
  );
};

export default EasySnackbar;
