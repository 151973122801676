import React from 'react';
import Box from '@mui/material/Box';

const DialogContainer = ({
  children,
}: {
  children: React.ReactElement;
}): React.JSX.Element => (
  <Box
    sx={{
      zIndex: 999999,
      position: 'fixed',
      transform: 'translate(-50%, -50%)',
      top: '50%',
      left: '50%',
    }}
  >
    {children}
  </Box>
);

export default DialogContainer;
