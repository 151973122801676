// 登入驗證
// type
import {
  type User, updateProfile, createUserWithEmailAndPassword,
} from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { type AppDispatch } from '../../../reduxStore/rootReducer';
import { NavigateFunction } from 'react-router/dist/lib/hooks';
// utils
import userAct from '../userAction';
import handleSendEmailForUser from './handleSendEmailForUser';
import handleFusekiHandlerData from './fuseki/handleFusekiHandlerData';
import firestorePath from '../../../api/firebase/cloudFirestore/path';
import { getUser } from '../../../api/firebase/cloudFirestore';
// config
import routes from '../../../config/routes/index';
import { auth, db } from '../../../config/config-firebase';
import FirestoreUser from '../TypescriptProps';
import services from '../../../api/services';
import groupInfo, { roleInfo } from '../../../App-groupInfo';

let user: User | FirestoreUser | null;

interface SetSignUpDateProps {
  uid: string;
  email: string;
  name: string;
  providerId: string;
  emailVerified?: boolean;
}
// firestore 註冊 寫入資料
async function setSignUpData(
  data:SetSignUpDateProps,
): Promise<void> {
  const hasEvent = {
    hasGroupEvent: [
      {
        classType: 'GroupEvent',
        srcId: '',
        value: {
          hasGroup: [groupInfo.Handler.value],
          hasGroupRole: [roleInfo.Editor.value],
        },
      },
    ],
    hasContractEvent: [
      {
        classType: 'ContractEvent',
        srcId: '',
        value: {
          hasContract: `${process.env.REACT_APP_TERMS_OF_SERVICE_ID}`,
          signStatus: '1',
        },
      },
    ],
    hasHandlerIntro: [{
      classType: 'HandlerIntro',
      graph: 'rHandler',
      srcId: '',
      value: {
        provideRecyclingPassport: '0',
      },
    }],
  };
  const fusekiValue = {
    firebaseID: data.uid,
    imageName: '',
    companyName: '',
    companyTel: '',
    /* 地址 */
    postalCode: '',
    country: '',
    state: '',
    city: '',
    address1: '',
    address2: '',
    lat: '',
    long: '',
    /* 營業登記 */
    taxNumber: '',
    licenseNumber: '',
    /* 聯絡資訊 */
    contactName: data.name,
    contactMail: data.email,
    contactTel: '',
  };
  const firebaseValue = {
    firebaseID: data.uid,
    emailVerified: data.emailVerified ?? false,
    group: groupInfo.Handler.id,
    isAnonymous: false,
    providerID: data.providerId,
    /* 聯絡資訊 */
    contactName: data.name,
    contactMail: data.email,
  };
  const timeValue = {
    creationTime: new Date().toString(),
    creationTimeStamp: Date.now().toString(),
    lastSignInTime: new Date().toString(),
    lastSignInTimeStamp: Date.now().toString(),
  };
  // 寫入資料
  await setDoc(doc(db, firestorePath.USERS, data.uid), { ...firebaseValue, ...timeValue }).catch(() => {
  });
  await handleFusekiHandlerData.createData({ classType: 'Handler', value: { ...fusekiValue, ...hasEvent } });
}

const UpdateUserName = async (userNameValue: string): Promise<void> => {
  if (auth.currentUser) {
    await updateProfile(auth.currentUser, {
      displayName: userNameValue,
    });
  }
};
interface SignUpProps {
  email: string
  password: string
  name: string
  navigation: NavigateFunction,
  dispatch:AppDispatch,
}

export const SignUp = async (
  {
    email, password, name, navigation, dispatch,
  }: SignUpProps,
): Promise<void> => {
  /* 先設置signUp state */
  dispatch(userAct.SET_SIGNUP_STATE('signUp'));
  //
  await new Promise((resolve, reject) => {
    createUserWithEmailAndPassword(auth, email, password)
      .then(async () => {
        user = auth.currentUser;
        await UpdateUserName(name);
        await handleSendEmailForUser({
          email: user?.email as string,
          type: 'emailVerify',
        });
      })
      .then(async () => {
        user = auth.currentUser;
        // firestore DB新增用戶
        if (typeof user?.uid !== 'string') return;
        const signUpData: SetSignUpDateProps = {
          uid: `${user?.uid}`,
          email,
          name,
          emailVerified: false,
          providerId: 'password',
        };
        await setSignUpData(signUpData).then(async () => {
          if (!user?.uid) return;
          const userData = (await handleFusekiHandlerData.readData({ firebaseID: user?.uid as string }));
          if (!userData) return;
          // console.log('setSignUpData', userData);
          await getUser(user?.uid as string);
          dispatch(userAct.FIREBASE_LOGIN_USER(userData.data));
          localStorage.setItem('isLogin', 'true');
          if (!userData.groupRole) return;
          // console.log('setSignUpData role', userData.groupRole);
          dispatch(userAct.SET_GROUP_ROLE(userData.groupRole));
          dispatch(userAct.SET_SIGNUP_STATE(''));
          navigation(`/${services.getLocale()}${routes.VERIFYACCOUNT}`);
        });
      })
      .catch((err) => {
        reject(err.code);
      });
  });
};

interface SignUpWithGoogleProps {
  email: string
  name: string
  navigation: NavigateFunction,
  dispatch:AppDispatch,
}
export const SignUpWithGoogle = async (
  {
    email,
    name,
    navigation,
    dispatch,
  }:SignUpWithGoogleProps,
): Promise<void> => {
  user = auth.currentUser;
  /* 先設置signUp state */
  dispatch(userAct.SET_SIGNUP_STATE('signUp'));
  // 建立firestore資料
  if (typeof user?.uid !== 'string') return;
  const signUpGoogleData: SetSignUpDateProps = {
    uid: `${user?.uid}`,
    email,
    name,
    emailVerified: true,
    providerId: 'google.com',
  };
  await setSignUpData(signUpGoogleData).then(async () => {
    const userData = (await handleFusekiHandlerData.readData({ firebaseID: user?.uid as string }));
    // console.log('setSignUpData', userData);
    if (!userData) return;
    dispatch(userAct.FIREBASE_LOGIN_USER(userData.data));
    if (!userData.groupRole) return;
    // console.log('setSignUpData role', userData.groupRole);
    dispatch(userAct.SET_GROUP_ROLE(userData.groupRole));
    dispatch(userAct.SET_SIGNUP_STATE(''));
    auth.currentUser?.reload();
  });

  // 跳轉至註冊成功畫面
  navigation(`/${services.getLocale()}${routes.VERIFYACCOUNT}`);
};
