import React from 'react';
import { useFormikContext } from 'formik';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import EasyTextField from '../../../../../commons/components/EasyTextField/EasyTextField';
import { useIntl } from 'react-intl';
import type CustomerProps from '../TypescriptProps';
import { INPUT_LIMIT } from '../config';

const CompanyInformation = ({ mode, isButtonLoading }:{ mode:string; isButtonLoading:boolean; }) => {
  const {
    values, handleChange, handleBlur, errors, touched,
  } = useFormikContext<CustomerProps>();
  const { formatMessage } = useIntl();
  const id2text = (id:string) => formatMessage({ id });

  const areaTitle = id2text('customerManagement.page.subtitle.companyInformation');
  const companyInfoArray = [
    {
      name: 'companyName',
      title: id2text('customerManagement.label.companyName'),
      label: id2text('customerManagement.label.companyName'),
      isRequired: true,
      inputLimit: '',
      onChange: '',
    },
    {
      name: 'taxNumber',
      title: id2text('common.tax.number'),
      label: id2text('common.tax.number'),
      isRequired: true,
      inputLimit: {
        maxLength: INPUT_LIMIT.taxNumber,
      },
      onChange: '',
    },
  ];
  return (
    <Box mt="1rem" padding="2rem" border="2px solid rgba(250, 250, 250, 1)" borderRadius="0.5rem" sx={{ boxShadow: '0 0 16px 0 rgba(0,0,0,0.08)' }}>
      {/* 標題 */}
      <Typography variant="h5">
        {areaTitle}
      </Typography>
      {/* contact Name */}
      {companyInfoArray.map((item) => (
        <Grid key={item.name} container mt="1.5rem" justifyContent="space-between" alignItems="center">
          <Grid item xs={4}>
            <Typography variant="body1">
              {item.title}
              {item.isRequired && <Typography color="red" variant="caption">&nbsp;*</Typography>}
            </Typography>
          </Grid>
          {(typeof item.inputLimit === 'object') ? (
            <Grid item xs={8}>
              <EasyTextField
                key={item.name}
                name={item.name}
                disabled={isButtonLoading}
                type="inputLimit"
                label={item.label}
                value={values[item.name as keyof CustomerProps]}
                onChange={(typeof item.onChange === 'function') ? item.onChange : handleChange}
                fullWidth
                inputProps={item.inputLimit}
                onBlur={handleBlur(item.name)}
                error={Boolean(touched[item.name as keyof CustomerProps] && errors[item.name as keyof CustomerProps])}
                helperText={touched[item.name as keyof CustomerProps] ? errors[item.name as keyof CustomerProps] : ''}
              />
            </Grid>
          ) : (
            <Grid item xs={8}>
              <EasyTextField
                key={item.name}
                name={item.name}
                disabled={isButtonLoading}
                type="text"
                label={item.label}
                value={values[item.name as keyof CustomerProps]}
                onChange={(typeof item.onChange === 'function') ? item.onChange : handleChange}
                fullWidth
                InputProps={{
                  style: { height: '3.1875rem', borderRadius: '0.5rem' },
                }}
                onBlur={handleBlur(item.name)}
                error={Boolean(touched[item.name as keyof CustomerProps] && errors[item.name as keyof CustomerProps])}
                helperText={touched[item.name as keyof CustomerProps] ? errors[item.name as keyof CustomerProps] : ''}
              />
            </Grid>
          )}
        </Grid>
      ))}
    </Box>
  );
};

export default CompanyInformation;
