const forgotPasswordPage = {
    /* forgot password */
    "forgotPassword.page.title":"Forgot Password",
    "forgotPassword.page.helperText.email":"Email is not valid",
    "forgotPassword.page.step1.label":"Please enter your email address, we will send you an email to reset your password.",
    "forgotPassword.page.step1.button.text":"Send",
    /* 輸入欄位 提示 */
    "forgotPassword.page.hint.good":"Verification successful.",
    "forgotPassword.page.hint.bad":"The verification code is invalid or incorrect. Please retry.",
    "forgotPassword.page.verifyCode.label1":"Enter verification code.",
    "forgotPassword.page.verifyCode.label2":" Don't receive the email?",
    "forgotPassword.page.verifyCode.sendingMail1":"Sending email...",
    "forgotPassword.page.verifyCode.sendingMail2":"Resend verification code ({resendTime}s)",
    "forgotPassword.page.verifyCode.resend.text":"Resend verification code",
    /* 輸入驗證碼 提示視窗 */
    "forgotPassword.page.modal.error.title":"Error",
    "forgotPassword.page.modal.error.text":"Email sending error, please try again later.",

    /* 輸入email後 提示視窗 */
    "forgotPassword.modal.exist.title":"Not yet registered",
    "forgotPassword.modal.exist.action":"Proceed to registration",
    "forgotPassword.modal.exist.secondary.action":"Maybe later",
    "forgotPassword.modal.exist.text":"We couldn't find an account matching the email address you entered. Would you like to proceed to registration?",
}

export default forgotPasswordPage
