import React, { Dispatch, SetStateAction } from 'react';
import Typography from '@mui/material/Typography';
import EasyDialog from './EasyDialog';

export type HintProps = {
  title:string;
  text:string;
  [key:string]:string;
};
interface HintModalProps {
  isOpen:boolean;
  setIsOpen:(e:boolean)=>void;
  modalText:HintProps;
  callback?:()=>void;
}
const HintModal = ({
  isOpen, setIsOpen, modalText, callback,
}:HintModalProps) => {
  const a = 123;
  return (
    <EasyDialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      handleClose={() => {
        if (callback) {
          callback();
        }
        setIsOpen(false);
      }}
      showCloseIcon
      title={modalText.title}
      body={(
        <Typography variant="body1" my="6%">
          {modalText.text}
        </Typography>
            )}
      sx={{
        minHeight: {
          xl: '0vh',
          lg: '0vh',
          md: '0vh',
          sm: '0vh',
          xs: '0vh',
        },
      }}
    />
  );
};

export default HintModal;
