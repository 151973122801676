import services from '../../../../api/services';
import axios from 'axios';
import FirestoreUser from '../../TypescriptProps';
import OutsourcingRecyclingProps, {
  OutsourcingListProps,
  PSLPDetailprops, PSLPListprops,
} from './TypescriptProps';
import { ClassType, Graphs } from '../../../../config/config.fuseki';
import {
  CHECK_PARAMS_TYPE,
  SLP_STATUS,
} from './config';
import { isEqual } from 'lodash-es';
import { ORDER_STATUS } from '../../../../config/config.order';

interface KeyValue {
  srcId:string,
  key:string;
  value:string;
  [key: string]: string | number | boolean | undefined;
}

const FILTER_STATUS_LIST = [
  {
    id: 'All', value: 'All',
  },
  {
    id: 'Arrived', value: ORDER_STATUS.ARRIVED,
  },
  {
    id: 'Recycling', value: ORDER_STATUS.RECYCLING,
  },
  {
    id: 'Completed', value: ORDER_STATUS.COMPLETED,
  },
  {
    id: 'Canceled', value: ORDER_STATUS.CANCELED,
  },
];

interface GetOutsourcingListProps {
  uid?:string;
  filterText?:string;
  filterStatus:string;
  sortConfig:{ orderBy:string, isDesc:boolean };
}
const sortByProperty = <Keys, Property extends keyof Keys> (data:Keys[], property:Property, isDesc:boolean) => data.slice().sort((a, b) => {
  const valueA = a[property];
  const valueB = b[property];

  /** string排序 * */
  if (typeof valueA === 'string' && typeof valueB === 'string') {
    return isDesc ? valueB.localeCompare(valueA) : valueA.localeCompare(valueB);
  }
  /** 數字排序 * */
  return isDesc ? Number(valueB) - Number(valueA) : Number(valueA) - Number(valueB);
});

const getOutsourcingList = async ({
  uid, filterText, filterStatus, sortConfig,
}:GetOutsourcingListProps) => {
  if (!uid) return false;
  const detailResponse = await axios.get(
    `${services.getOutsourceRecycleList(uid, filterText ?? '')}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  const { data } = detailResponse.data;
  const newData :OutsourcingListProps[] = data;
  const result :OutsourcingListProps[] = newData.slice().reverse();
  const orderResult = sortByProperty(result, sortConfig.orderBy as keyof OutsourcingListProps, sortConfig.isDesc);
  if (filterStatus === FILTER_STATUS_LIST[0].value) {
    return orderResult;
  }
  return orderResult.filter((item) => item.orderStatus === filterStatus);
};

const getOutsourcingDetail = async ({ osrIds, uid }:{ osrIds:string | string[], uid:string }) => {
  /** 避免帶空值時撈出全部資料 * */
  let queryIds;
  if (osrIds.length === 0) {
    queryIds = 'null';
  } else {
    queryIds = osrIds;
  }
  const detailResponse = await axios.get(
    `${services.getOutsourceRecycleDetail({ uid, osrIds: queryIds })}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  const { data } = detailResponse.data;
  const result :OutsourcingRecyclingProps[] = data.reduce((resultItem:Record<string, any>, item:KeyValue) => {
    const { srcId, key, value } = item;
    const newResultItem = { ...resultItem };
    if (!newResultItem[srcId]) {
      newResultItem[srcId] = { fusekiID: srcId };
    }
    if (key === 'bcID') {
      if (!newResultItem[srcId][key]) {
        newResultItem[srcId][key] = { bcID: key };
      }
      newResultItem[srcId][key][value] = { bcID: value };
      return newResultItem;
    }
    newResultItem[srcId][key] = value;
    return newResultItem;
  }, {});
  console.log('getOutsourcingDetail', Object.values(result));
  return Object.values(result);
};
/* BarcodeInformation List */
const getPslpInformationList = async ({ osrId, uid }:{ osrId:string;uid:string }) => {
  if (!osrId) return false;
  if (!uid) return false;
  const detailResponse = await axios.get(
    `${services.getPslpList({ osrId, uid })}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  const { data } = detailResponse.data;
  console.log('getPslpInformationList data', data);

  const result :PSLPListprops[] = data.map((item:{ pslpId:string; }) => item.pslpId);
  console.log('getPslpInformationList', result);
  return result;
};
/* BarcodeInformation Detail */
const getPslpInformationDetail = async ({ slpIds, uid }:{ slpIds:string | string[];uid:string }) => {
  const detailResponse = await axios.get(
    `${services.getPslpDetail({ uid, slpIds })}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  const { data } = detailResponse.data;
  const result :PSLPDetailprops[] = data.reduce((resultItem:Record<string, any>, item:KeyValue) => {
    const { srcId, key, value } = item;
    const newResultItem = { ...resultItem };
    if (!newResultItem[srcId]) {
      newResultItem[srcId] = { pslpId: srcId };
    }
    newResultItem[srcId][key] = value;
    return newResultItem;
  }, {});
  return Object.values(result);
};

/* 獲取 OutsourceRecycleFusekiID 參數:createdTime、tripletNumber */
const getOutsourceRecycleFusekiID = async ({ createdTime, recyclingCode }:{ createdTime?:number, recyclingCode?:string }) => {
  if (!createdTime || !recyclingCode) return false;
  const detailResponse = await axios.get(
    `${services.getOutsourceRecycleID(createdTime, recyclingCode)}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  const { data } = detailResponse.data as { data:Array<{ osrId:string }> };
  console.log('getOutsourceRecycleFusekiID', data);
  return data[0].osrId;
};

/* 獲取 orderID */
const getOrderFusekiID = async ({ createdTime, bookingTime }:{ createdTime:number, bookingTime:number }) => {
  if (!createdTime || !bookingTime) return false;
  const detailResponse = await axios.get(
    `${services.getOutsourceRecycleOrderID(createdTime, bookingTime)}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  const { data } = detailResponse.data as { data:Array<{ ordId:string }> };
  console.log('getOrderFusekiID', data);
  return data[0].ordId;
};

interface PostOutsourcingProps {
  user:FirestoreUser;
  outsourcingData:OutsourcingRecyclingProps
}

const postOutsourcing = async ({ user, outsourcingData }:PostOutsourcingProps) => {
  /** 統一建立時間 * */
  const createdTime = +new Date();
  /** 第一支API : 寫入handler hasOutsourceRecycle * */
  const outsourcingEntry = {
    classType: ClassType.HDL,
    graph: Graphs.rhandler,
    srcId: user.fusekiID,
    value: {
      hasOutsourceRecycle: [{
        classType: ClassType.OSR,
        graph: Graphs.rhandler,
        srcId: '',
        value: {
          tripletNumber: outsourcingData.tripletNumber,
          customizedNumber: outsourcingData.customizedNumber,
          onBoardDocumentNumber: outsourcingData.onBoardDocumentNumber,
          isAssociated: outsourcingData.isAssociated,
          isControlled: outsourcingData.isControlled,
          recyclingCode: outsourcingData.recyclingCode,
          receptionist: outsourcingData.receptionist,
          ordererCompany: outsourcingData.ordererCompany,
          taxNumber: outsourcingData.taxNumber,
          grossWeight: outsourcingData.grossWeight,
          /* 唯一識別 */
          createdTime,
        },
      }],
    },
  };
  const outsourcingResult = await axios.post(services.postGeneric, {
    entry: {
      ...outsourcingEntry,
    },
  }, {
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data === 'OK').catch(() => false);

  /* 這邊打API去獲取 OutsourceRecycle fusekiID */
  const outsourceRecycleFusekiID = await getOutsourceRecycleFusekiID({ createdTime, recyclingCode: outsourcingData.recyclingCode });
  if (!outsourceRecycleFusekiID) return false;
  /** 第二支API : 寫入OutsourceRecycle 的 hasOrder * */
  const orderEntry = {
    classType: ClassType.OSR,
    graph: Graphs.rhandler,
    srcId: outsourceRecycleFusekiID,
    value: {
      hasOrder: [{
        classType: ClassType.ORD,
        graph: Graphs.rhandler,
        srcId: '',
        value: {
          /* 下單者資訊 */
          ordererName: outsourcingData.ordererName,
          ordererMail: outsourcingData.ordererMail,
          ordererTel: outsourcingData.ordererTel,
          /* 訂單相關 */
          orderStatus: ORDER_STATUS.ARRIVED,
          bookingTime: parseInt(outsourcingData.bookingTime, 10),
          /* handler資訊 */
          contactName: user.contactName,
          contactMail: user.contactMail,
          contactTel: user.contactTel,
          /* 唯一識別 */
          createdTime,
        },
      }],
    },
  };

  const orderResult = await axios.post(services.postGeneric, {
    entry: {
      ...orderEntry,
    },
  }, {
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data === 'OK').catch(() => false);

  return outsourcingResult && orderResult;
};

interface PutOutsourcingProps {
  user:FirestoreUser;
  oldData:OutsourcingRecyclingProps;
  updateData:OutsourcingRecyclingProps;
}
const putOutsourcing = async ({ user, oldData, updateData }:PutOutsourcingProps) => {
  /** 第一支API : 修改 outsource * */
  const outsourcingEntry = {
    classType: ClassType.OSR,
    graph: Graphs.rhandler,
    srcId: oldData.fusekiID,
    value: {
      tripletNumber: oldData.tripletNumber,
      customizedNumber: oldData.customizedNumber,
      onBoardDocumentNumber: oldData.onBoardDocumentNumber,
      isAssociated: oldData.isAssociated,
      isControlled: oldData.isControlled,
      recyclingCode: oldData.recyclingCode,
      receptionist: oldData.receptionist,
      ordererCompany: oldData.ordererCompany,
      grossWeight: oldData.grossWeight,
      taxNumber: oldData.taxNumber,
    },
  };
  const outsourcingDst = {
    classType: ClassType.OSR,
    graph: Graphs.rhandler,
    srcId: oldData.fusekiID,
    value: {
      tripletNumber: updateData.tripletNumber,
      customizedNumber: updateData.customizedNumber,
      onBoardDocumentNumber: updateData.onBoardDocumentNumber,
      isAssociated: updateData.isAssociated,
      isControlled: updateData.isControlled,
      recyclingCode: updateData.recyclingCode,
      receptionist: updateData.receptionist,
      ordererCompany: updateData.ordererCompany,
      grossWeight: updateData.grossWeight,
      taxNumber: updateData.taxNumber,
    },
  };
  const isEqualOutsource = isEqual(outsourcingEntry, outsourcingDst);
  let outsourcingResult;
  if (isEqualOutsource) {
    outsourcingResult = true;
  } else {
    outsourcingResult = await axios.put(services.putGeneric, {
      entrySrc: {
        ...outsourcingEntry,
      },
      entryDst: {
        ...outsourcingDst,
      },
    }, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((res) => res.data.data === 'OK').catch(() => false);
  }

  /** 第二支API : 寫入OutsourceRecycle 的 hasOrder * */
  const orderEntry = {
    classType: ClassType.ORD,
    graph: Graphs.rhandler,
    srcId: oldData.hasOrder,
    value: {
      /* 下單者資訊 */
      ordererName: oldData.ordererName,
      ordererMail: oldData.ordererMail,
      ordererTel: oldData.ordererTel,
      /* 訂單相關 */
      // orderStatus: oldData.orderStatus,
      bookingTime: parseInt(oldData.bookingTime, 10),
      /* handler資訊 */
      contactName: user.contactName,
      contactMail: user.contactMail,
      contactTel: user.contactTel,
    },
  };
  const orderDst = {
    classType: ClassType.ORD,
    graph: Graphs.rhandler,
    srcId: oldData.hasOrder,
    value: {
      /* 下單者資訊 */
      ordererName: updateData.ordererName,
      ordererMail: updateData.ordererMail,
      ordererTel: updateData.ordererTel,
      /* 訂單相關 */
      // orderStatus: updateData.orderStatus,
      bookingTime: parseInt(updateData.bookingTime, 10),
      /* handler資訊 */
      contactName: user.contactName,
      contactMail: user.contactMail,
      contactTel: user.contactTel,
    },
  };
  let orderResult;
  const isEqualOrder = isEqual(orderEntry, orderDst);
  if (isEqualOrder) {
    orderResult = true;
  } else {
    orderResult = await axios.put(services.putGeneric, {
      entrySrc: {
        ...orderEntry,
      },
      entryDst: {
        ...orderDst,
      },
    }, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((res) => res.data.data === 'OK').catch(() => false);
  }

  return outsourcingResult && orderResult;
};

interface CancelOutsourcingProps {
  outsourcingData:OutsourcingRecyclingProps;
}
const cancelOutsourcing = async ({ outsourcingData }:CancelOutsourcingProps) => {
  /** 第一支API : 修改 outsource * */
  const outsourcingEntry = {
    classType: ClassType.OSR,
    graph: Graphs.rhandler,
    srcId: outsourcingData.fusekiID,
    value: {
      tripletNumber: outsourcingData.tripletNumber,
      customizedNumber: outsourcingData.customizedNumber,
      onBoardDocumentNumber: outsourcingData.onBoardDocumentNumber,
    },
  };
  const outsourcingDst = {
    classType: ClassType.OSR,
    graph: Graphs.rhandler,
    srcId: outsourcingData.fusekiID,
    value: {
      tripletNumber: '',
      customizedNumber: '',
      onBoardDocumentNumber: '',
    },
  };
  const isEqualOutsource = isEqual(outsourcingEntry, outsourcingDst);
  let outsourcingResult;
  if (isEqualOutsource) {
    outsourcingResult = true;
  } else {
    outsourcingResult = await axios.put(services.putGeneric, {
      entrySrc: {
        ...outsourcingEntry,
      },
      entryDst: {
        ...outsourcingDst,
      },
    }, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((res) => res.data.data === 'OK').catch(() => false);
  }
  /** 第二支API : 寫入OutsourceRecycle 的 hasOrder * */
  const orderEntry = {
    classType: ClassType.ORD,
    graph: Graphs.rhandler,
    srcId: outsourcingData.hasOrder,
    value: {
      orderStatus: outsourcingData.orderStatus,
    },
  };
  const orderDst = {
    classType: ClassType.ORD,
    graph: Graphs.rhandler,
    srcId: outsourcingData.hasOrder,
    value: {
      /* 訂單相關 */
      orderStatus: ORDER_STATUS.CANCELED,
      canceledTime: +new Date(),
    },
  };
  let orderResult;
  const isEqualOrder = isEqual(orderEntry, orderDst);
  if (isEqualOrder) {
    orderResult = true;
  } else {
    orderResult = await axios.put(services.putGeneric, {
      entrySrc: {
        ...orderEntry,
      },
      entryDst: {
        ...orderDst,
      },
    }, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((res) => res.data.data === 'OK').catch(() => false);
  }

  return outsourcingResult && orderResult;
};

const checkParamsExist = async (params:string, type:string) => {
  let checkAPI;
  if (type === CHECK_PARAMS_TYPE.TRIPLET_NUMBER) {
    checkAPI = services.checkTripletNumber(params);
  }
  if (type === CHECK_PARAMS_TYPE.CUSTOMIZED_NUMBER) {
    checkAPI = services.checkCustomizedNumber(params);
  }
  if (type === CHECK_PARAMS_TYPE.ON_BOARD_DOCUMENT_NUMBER) {
    checkAPI = services.checkOnboardDocumentNumber(params);
  }
  if (checkAPI) {
    const result = await axios.get(checkAPI, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const { data }:{ data:[] } = result.data;
    return data.length > 0;
  }
  return false;
};

const handleOutsourcingRecyclingData = {
  getOutsourcingList,
  getOutsourcingDetail,
  postOutsourcing,
  getPslpInformationList,
  getPslpInformationDetail,
  putOutsourcing,
  cancelOutsourcing,
  checkParamsExist,
};
export default handleOutsourcingRecyclingData;
