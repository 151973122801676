import axios from 'axios';
import services from '../../../../api/services';
import { ContractManagementProps } from '../../../../types/contractManagement/TypescriptProps';

interface KeyValue {
  srcId:string,
  key:string;
  value:string;
  [key: string]: string | number | boolean | undefined;
}
const getPolicy = async ({ cttIds }:{ cttIds:string }) => {
  const detailResponse = await axios.get(
    `${services.getPolicy(cttIds)}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  const { data } = detailResponse.data;
  // console.log(data);
  const result:ContractManagementProps[] = Object.values(
    data.reduce((resultItem:Record<string, any>, item:KeyValue) => {
      const { srcId, key, value } = item;
      const newResultItem = { ...resultItem };
      if (!resultItem[srcId]) {
        newResultItem[srcId] = { contractId: srcId };
      }
      newResultItem[srcId][key] = value;
      return newResultItem;
    }, {}),
  );
    // console.log('contractResult', result);
  return result;
};

export default getPolicy;
