// 使用者沒動作 30 分鐘即強制登出
import { AlertColor } from '@mui/material/Alert/Alert';

export const TIMEOUT_TIME = 30 * 60 * 1000; // ms
// export const TIMEOUT_TIME = 16 * 1000; // ms

// 使用者沒動作 27 分鐘時, 跳出提示
export const TIMEOUT_ALERT_TIME = (30 - 3) * 60 * 1000; // ms
// export const TIMEOUT_ALERT_TIME = 10 * 1000;

// alert 跳出後, 多久會自動關閉
export const AUTO_HIDE_DURATION_TIME = 10 * 1000; // ms
// export const AUTO_HIDE_DURATION_TIME = 3 * 1000; // ms

export const timeoutAlertMsg = {
  en: 'The system will log you out in 3 minutes for security reasons. To stay logged in, please take any action. Thank you for your cooperation!',
  zh: '注意：系統將在3分鐘內強制登出您的帳戶以保護您的安全。如果您希望繼續使用本網站，請執行任何操作以保持登入狀態。感謝您的理解與合作！',
};

export const ERROR_SEVERITY: Record<string, AlertColor> = {
  success: 'success',
  info: 'info',
  warning: 'warning',
  error: 'error',
};

export default TIMEOUT_TIME;
