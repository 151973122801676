import React from "react";
import { FormattedMessage } from "react-intl";

// MUI
import Stack from "@mui/material/Stack";
import Dialog, { type DialogProps } from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import { useTheme, type SxProps } from "@mui/material";
import { type Theme } from "@mui/material/styles";

// MUI Icons
import ClearIcon from "@mui/icons-material/Clear";

// components
import EasyButton from "../EasyButton/EasyButton";
import EasyIconButton from "../EasyIconButton/EasyIconButton";

// Typescript
interface EasyDialogProps extends Omit<DialogProps, "open"> {
  sx?: SxProps<Theme> | undefined;
  variant?: "text" | "contained" | "outlined" | "secondary";
  isOpen: boolean;
  isCanceled?: boolean;
  isCloseOnTop?: boolean;
  isShowActions?: boolean;
  isColumnActions?: boolean;
  isOutsideClose?: boolean;
  scrollType?: "paper" | "body" | undefined;
  buttonSize?: "small" | "medium" | "large";
  handleClose?: () => void;
  handleConfirm?: () => void;
  dialogTitle?: string | React.ReactNode;
  dialogContent?: string | React.ReactNode;
  dialogTitleSX?: SxProps<Theme> | undefined;
  dialogActionSX?: SxProps<Theme> | undefined;
  dialogContentSX?: SxProps<Theme> | undefined;
  dialogTitleProps?: {
    component: string;
  };
  dialogContentTextSX?: SxProps<Theme> | undefined;
  dialogActionDisabled?: boolean;
  dialogActionCancelText?: string | React.JSX.Element;
  dialogActionConfirmText?: string | React.JSX.Element;
}

const EasyDialog: React.FC<EasyDialogProps> = ({
  sx,
  variant = "contained",
  isOpen = false,
  isCanceled = false,
  isCloseOnTop = true,
  isShowActions = true,
  isOutsideClose = false,
  isColumnActions = false,
  scrollType = "paper",
  buttonSize = "medium",
  handleClose,
  handleConfirm,
  dialogTitle,
  dialogContent,
  dialogTitleSX,
  dialogActionSX,
  dialogContentSX,
  dialogTitleProps,
  dialogContentTextSX,
  dialogActionDisabled,
  dialogActionCancelText = <FormattedMessage id="common.cancel" />,
  dialogActionConfirmText = <FormattedMessage id="common.comfirm" />,
  ...props
}): React.JSX.Element => {
  const theme = useTheme();
  return (
    <Dialog
      open={isOpen}
      scroll={scrollType}
      onClose={() => {
        if (isOutsideClose) {
          if (typeof handleClose === "function") {
            handleClose();
          }
        }
      }}
      sx={{
        ".MuiPaper-root": {
          padding: "1rem 2rem 1rem 2rem",
        },
        ...sx,
      }}
      {...props}
    >
      {isCloseOnTop && (
        <Stack flexDirection="row" justifyContent="flex-end">
          <EasyIconButton
            style={{ color: theme.palette.neutral.max }}
            size={buttonSize}
            onClick={() => {
              if (typeof handleClose === "function") {
                handleClose();
              }
            }}
          >
            <ClearIcon />
          </EasyIconButton>
        </Stack>
      )}
      {dialogTitle && (
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            ...dialogTitleSX,
          }}
          component="h5"
          {...dialogTitleProps}
        >
          {dialogTitle}
        </DialogTitle>
      )}
      <DialogContent sx={{ ...dialogContentSX }}>
        <DialogContentText sx={{ ...dialogContentTextSX }} component="div">
          {dialogContent}
        </DialogContentText>
      </DialogContent>
      {isShowActions &&
        (isColumnActions ? (
          <DialogActions sx={{ display: "flex", flexDirection: "column" }}>
            <EasyButton
              fullWidth
              variant={variant}
              size={buttonSize}
              onClick={() => {
                if (typeof handleConfirm === "function") {
                  handleConfirm();
                }
              }}
              disabled={dialogActionDisabled}
            >
              {dialogActionConfirmText}
            </EasyButton>
            {isCanceled && (
              <EasyButton
                style={{ margin: 0 }}
                variant={variant}
                fullWidth
                size={buttonSize}
                onClick={() => {
                  if (typeof handleClose === "function") {
                    handleClose();
                  }
                }}
                disabled={dialogActionDisabled}
              >
                {dialogActionCancelText}
              </EasyButton>
            )}
          </DialogActions>
        ) : (
          <DialogActions sx={{ ...dialogActionSX }}>
            {isCanceled && (
              <EasyButton
                size={buttonSize}
                variant={variant}
                onClick={() => {
                  if (typeof handleClose === "function") {
                    handleClose();
                  }
                }}
                disabled={dialogActionDisabled}
              >
                {dialogActionCancelText}
              </EasyButton>
            )}
            <EasyButton
              size={buttonSize}
              variant={variant}
              onClick={() => {
                if (typeof handleConfirm === "function") {
                  handleConfirm();
                }
              }}
              disabled={dialogActionDisabled}
            >
              {dialogActionConfirmText}
            </EasyButton>
          </DialogActions>
        ))}
    </Dialog>
  );
};

export default EasyDialog;
