import React, { useState } from 'react';
// utils
import handleSendEmailForUser from '../../../utils/handleSendEmailForUser';
// mui
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
// components
import EasyDialog from '../../../../../commons/components/modal/EasyDialog';
import SettingVerifyingCode from './SettingVerifyingCode';

// hooks
import useScrollToTop from '../../../../../hooks/useScrollToTop';
// config
import { auth } from '../../../../../config/config-firebase';
// image
import excellenceIcon from '../../../../../images/signUp/excellence.png';
import { useIntl } from 'react-intl';
import { Timeout } from 'react-number-format/types/types';

interface SettingVerifyEmailModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}
const SettingVerifyEmailModal :React.FC<SettingVerifyEmailModalProps> = ({
  isOpen, setIsOpen,
}):React.JSX.Element => {
  /** react intl */
  const { formatMessage } = useIntl();
  const id2text = (id:string) => formatMessage({ id });
  //
  // 進入頁面往最上滾動
  useScrollToTop();
  // 獲取使用者信箱，重發mail用
  const user = auth.currentUser;
  // 重新寄送計時器
  const [isSendMailButtonDisabled, setIsSendMailButtonDisabled] = useState(false);
  const [resendTime, setResendTime] = useState(60);
  // 是否正在寄送 email
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  //
  const [intervalHdl, setIntervalHdl] = useState<NodeJS.Timeout | undefined>();
  // 驗證是否成功
  const [isVerifySuccess, setIsVerifySuccess] = useState(false);

  const handleResendButton = async (): Promise<void> => {
    if (user?.emailVerified === true) {
      setIsVerifySuccess(true);
      return;
    }
    setIsSendingEmail(true);
    // disable button before send email
    setIsSendMailButtonDisabled(true);
    const data = await handleSendEmailForUser({
      email: user?.email as string,
      type: 'emailVerify',
    });
    if (data) {
      setIsSendingEmail(false);
      // await sendAuthVerificationEmail(user);
      // setIsSendMailButtonDisabled(true);

      if (intervalHdl) {
        clearInterval(intervalHdl as Timeout);
      }
      const interval = setInterval(() => {
        setResendTime((prevSecond) => {
          const newSecond = prevSecond - 1;

          // newSecond 可能為負數, newSecond <=0 時, 則重新計時
          if (newSecond <= 0) {
            clearInterval(interval);
            setIsSendMailButtonDisabled(false);
            return 60;
            // setResendTime(60);
          }
          return newSecond;
        });
      }, 1000);
      setIntervalHdl(interval);
    } else {
      setIsSendingEmail(false);
      setIsSendMailButtonDisabled(false);
      // alert('發送信件錯誤，請稍後再試');
    }
  };

  return (
    <EasyDialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      showCloseIcon
      type="settingPageUse"
      body={
      (
        <Grid>
          {isVerifySuccess ? (
            <Grid>
              <Grid container justifyContent="center">
                <Typography fontSize="700" variant="h5">
                  {id2text('setting.modal.email.verify.success.title')}
                </Typography>
              </Grid>
              <Grid mt="1rem" container justifyContent="center">
                <img
                  width={120}
                  height={120}
                  src={excellenceIcon}
                  alt="Congratulations"
                />
              </Grid>
              <Grid mt="1rem">
                <Typography>
                  {id2text('setting.modal.email.verify.success.text')}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <SettingVerifyingCode
              isSendMailButtonDisabled={isSendMailButtonDisabled}
              isSendingEmail={isSendingEmail}
              userEmail={user?.email}
              handleResendButton={handleResendButton}
              resendTime={resendTime}
              setIsVerifySuccess={setIsVerifySuccess}
            />
          )}
        </Grid>
      )
    }
    />
  );
};

export default SettingVerifyEmailModal;
