import { NavigateFunction } from 'react-router/dist/lib/hooks';

import { Location } from 'react-router';
import { supportLanguages } from '../locales/langs';

//
declare let navigator: Navigator;

/* [檢查-語系]本系統是否支援該語系 並回傳語系 */
export const checkLanguageSupport = (language?:string) => {
  const isSupport = supportLanguages.some(({ value }) => value === language);
  return isSupport ? language : supportLanguages[0].value;
};

/* [檢查-路徑]本系統是否支援該語系 並回傳語系 */
export const checkLanguageWithPaths = (pathname:string) => {
  const currentPathnames = pathname.split('/'); // 將網址分割
  let language = supportLanguages[0].value; // 預設第一個語系
  // 逐一檢查語系
  for (let i = 0; i < supportLanguages.length; i += 1) {
    if (currentPathnames.includes(supportLanguages[i].value)) {
      language = supportLanguages[i].value;
      break;
    }
  }
  return language;
};

/* 變更語系 */
export const changeLang = ({
  location, navigate, currentLanguage, nextLanguage,
// eslint-disable-next-line max-len
}:{ location:Location; navigate: NavigateFunction;currentLanguage?: string;nextLanguage?:string }) => {
  const { pathname, search } = location;

  /* 檢查網址中是否有語系存在 */
  if (currentLanguage && pathname.startsWith(`/${currentLanguage}`)) {
    /* 存在 => 取代原有語系 */
    navigate({
      pathname: pathname.replace(`/${currentLanguage}`, `/${checkLanguageSupport(nextLanguage)}`),
      search,
    });
  } else {
    /* 不存在 => 推入瀏覽器預設語系 */
    // eslint-disable-next-line max-len
    const browserLanguage = (navigator.languages ? navigator.languages[0] : navigator.language).toLowerCase();
    navigate({ pathname: `/${checkLanguageSupport(browserLanguage)}` });
  }
};

export const getLangLabel = (currentLanguage: string):string | null => {
  const find = supportLanguages.find((t) => t.value === currentLanguage);
  return find?.labelLong || null;
};
