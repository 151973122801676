import { createSlice } from '@reduxjs/toolkit';
import { ContractManagementProps } from '../../../../types/contractManagement/TypescriptProps';
import contractManagementAction from './contractManagementAction';

// eslint-disable-next-line import/prefer-default-export
export const selectedContractManagementReducer = createSlice({
  name: 'selectedContractManagementReducer',
  initialState: null as ContractManagementProps | null,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(contractManagementAction.SET_CONTRACT_MANAGEMENT, (state, action) => action.payload);
  },
});

export const selectedContractContentReducer = createSlice({
  name: 'selectedContractContentReducer',
  initialState: null as string | null,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(contractManagementAction.SET_CONTRACT_CONTENT, (state, action) => action.payload);
  },
});
