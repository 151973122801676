const editProfilePage = {
    /* edit profile */
    "editProfile.pageTitle":"Edit Profile",
    "editProfile.page.button.save":"Save",
    "editProfile.page.button.edit":"Edit",
    "editProfile.page.button.cancel":"Cancel",

    /* 輸入框錯誤提示 */
    "editProfile.page.helperTexts.name":"Name is not valid. Text length limit {USERNAME_LENGTH_LIMIT}. The illegal characters are: {ILLEGAL_CH}",
    "editProfile.page.helperTexts.mail":"Email is not valid",
    "editProfile.page.helperTexts.empty":"No whitespace allowed here",
    "editProfile.page.helperTexts.country":"\"Country / region\", \"state / province / city\" are mandatory selections. Please fill in the input fields above.",
    "editProfile.page.helperTexts.unitPrice":"Please confirm the country and enter the correct currency amount.",
    /* 小標題 */
    "editProfile.title.introLang": "Multilingual Introduction Page Setup",
    "editProfile.title.companyName": "Company Name",
    "editProfile.title.address": "Address",
    "editProfile.title.introduction": "Introduction",
    "editProfile.title.pictures": "Corporate Environment Pictures",
    "editProfile.title.upload": "upload media",
    "editProfile.title.regulations": "Regulations",
    "editProfile.title.solarPanelPrice": "Solar panel recycling price",
    "editProfile.title.recyclingHistory": "Do you offer recycling history services?",
    "editProfile.title.payment": "Payment",
    "editProfile.title.isFreeCancelOrder": "Free cancellation and full refund.",
    "editProfile.title.operatingHours": "Operating Hours",
    /* 介紹語言 */
    "editProfile.introLang.label": "Language",
    "editProfile.introLang.hint": "This section allows you to provide introduction content for different language versions. Please select a language and fill in the corresponding introduction information.",
    /* 地址 */
    "editProfile.address.placeholder": "Please enter the full address.",
    "editProfile.address.postalCode.label": "Postal Code",
    "editProfile.address.country.label": "Country / region",
    "editProfile.address.state.label": "State / province / city",
    "editProfile.address.city.label": "City / district",
    "editProfile.address.address1.label": "Address1",
    "editProfile.address.address2.label": "Address2",
    /* 上傳區 */
    "editProfile.uploadOr": "or",
    "editProfile.uploadButton.text": "Add picture",
    "editProfile.uploadRules.rule1":"Each image file must not exceed 1MB in size.",
    "editProfile.uploadRules.rule2":"The recommended aspect ratio for images is 16:9,and any exceeding this range will be cropped.",
    "editProfile.uploadRules.rule3":"Please use images in JPEG or PNG format.",
    "editProfile.uploadRules.rule4":"You can upload a maximum of 8 images at once.",
    /* 上傳圖片視窗 */
    "editProfile.modal.upload.error.title":"Error",
    "editProfile.modal.upload.error.uploadSizeExceeded":"Image upload maximum limit is 1MB.",
    "editProfile.modal.upload.error.uploadCountExceeded":"Maximum of 8 images allowed for upload.",
    "editProfile.modal.upload.error.uploadFormatError":"The image upload format should be: png, jpeg.",
    "editProfile.modal.upload.error.uploadAspectError":"Please upload images in 16:9 aspect ratio.",
    /* 更新資料提示視窗 */
    "editProfile.modal.update.error.title":"Error",
    "editProfile.modal.update.error.text":"There are errors in the fields that need to be corrected",
    "editProfile.modal.update.success.title":"Success",
    "editProfile.modal.update.success.text":"Successfully updated data",
    /* 營業日相關 */
    "editProfile.operatingHours.dayOff": "Today is a day off",
    "editProfile.operatingHours.to.label": "TO",
    "editProfile.operatingHours.button.add.text": "Add",
    "editProfile.operatingHours.button.add.specialHoliday.text": "Add Special Holiday",
    "editProfile.operatingHours.sun":"Sunday",
    "editProfile.operatingHours.mon":"Monday",
    "editProfile.operatingHours.tue":"TuesDay",
    "editProfile.operatingHours.wed":"Wednesday",
    "editProfile.operatingHours.thu":"Thursday",
    "editProfile.operatingHours.fri":"Friday",
    "editProfile.operatingHours.sat":"Saturday",
    /* 規範相關 */
    "editProfile.regulations.placeholder": "Enter text",
    /* 太陽能板單價 */
    "editProfile.unitPrice.label": "per piece",
    /* 是否提供回收履歷 */
    "editProfile.yesProvideRecycle.history": "Yes",
    "editProfile.noProvideRecycle.history": "No, recycling history services are not currently available.",


    /* V2 */
    "editProfile.page.description":"Complete the profile below to provide solar panel recycling services on EasyCycle Online.",
    "editProfile.unit.vehicle":"per vehicle",
    "editProfile.unit.distance.mile":"per mile",
    "editProfile.unit.day":"per day",
    "editProfile.label.orders":"Orders",
    "editProfile.label.pieces":"Pieces",
    "editProfile.label.ton":"Tons",
    "editProfile.label.price":"Price",
    "editProfile.label.setting":"Setting",

    "editProfile.modal.title.confirm":"Confirm Profile",
    "editProfile.modal.content.confirm":"After confirmation, we will review your profile as soon as possible. If you have any questions, please send details to service@easycycle.online.",
    "editProfile.modal.action.confirm":"Confirm",
    "editProfile.modal.secondaryAction.confirm":"Cancel",

    "editProfile.modal.title.openBusiness":"Open Business",
    "editProfile.modal.content.openBusiness":"After reactivate the plan,  you will be able to accept orders on EasyCycle Online.",
    "editProfile.modal.action.openBusiness":"Confirm",
    "editProfile.modal.secondaryAction.openBusiness":"Cancel",

    "editProfile.modal.hint.error.title":"Error Updating Data",
    "editProfile.modal.hint.error.text":"Sorry, there was an issue updating the data. Please try again later.",
    "editProfile.modal.hint.success.title":"Update Successful",
    "editProfile.modal.hint.success.text":"Data updated successfully",

    /* V2 Factory Address */
    "editProfile.title.factoryAddress" :"Factory Address",
    "editProfile.factoryAddress.modal.title" :"Edit Factory Address",
    "editProfile.factoryAddress.modal.action" :"Save",
    "editProfile.factoryAddress.modal.secondaryAction" :"Cancel",

    /* V2 Service Scope */
    "editProfile.title.serviceScope" :"Service Scope",
    "editProfile.label.serviceScope.hint":"Click to select county or city",
    "editProfile.label.serviceScope.scope":"Your service country: ",
    "editProfile.label.serviceScope.selected":"Selected: ",
    "editProfile.label.scopeLimit":"Please enter the factory address first and obtain the service country or region.",
    "editProfile.modal.title.serviceScope":"Add Service Scope",
    /* V2 Shipping */
    "editProfile.title.shipping" :"Shipping",
    "editProfile.tooltip.shippingFee.format" :"( BaseFee +  Miles * 2 * Distance Fee ) * ( Quantity(Weight) / Load Capacity )",
    "editProfile.label.baseFee" :"Base Fee",
    "editProfile.label.distanceFee" :"Distance Fee",
    "editProfile.label.loadCapacity":"Load Capacity",
    /* V2 Recycling Passport */
    "editProfile.title.recyclingPassport" :"Recycling Passport",
    "editProfile.description.recyclingPassport":"Recycling passport needs to be generated through equipment. If you want to provide recycling history, please rent the equipment through Rental Equipment.",
    "editProfile.checkbox.provideRecyclingPassport":"I want to provide recycling passport.",
    "editProfile.link.underStanding":"Understanding Recycling Passport",
    "editProfile.modal.title.recyclingPassportInfo":"What is Recycling Passport?",
    "editProfile.modal.content.recyclingPassportInfo1":"The Recycling Passport is a comprehensive dossier capturing every image and detail from the initiation of your order.",
    "editProfile.modal.content.recyclingPassportInfo2":"Our commitment is to provide you with accurate and insightful information, enabling a thorough examination of the carbon footprint associated with the recycling process.",
    "editProfile.imgAlt.recyclingPassportSample":"Recycling Passport",

    /* V2 Reservation ActiveNotice */
    "editProfile.title.reservationNotice": "Reservation Notice",
    "editProfile.modal.title.reservationNotice.preview": "Preview display reservation notice",
    /* V2 Business Hours */
    "editProfile.title.businessHours": "Business Hours",
    "editProfile.title.regularBusinessHours":"Regular Business Hours",
    "editProfile.title.specialBusinessHours":"Special Business Hours",
    "editProfile.td.null":"No Special Business Hours",
    "editProfile.th.date":"Date",
    "editProfile.th.time":"Time",
    "editProfile.th.openOrDayOff":"Open / Day Off",
    "editProfile.th.edit":"Edit",
    "editProfile.th.delete":"Delete",
    "editProfile.label.dayOff":"Day Off",
    "editProfile.label.dayOpen":"Open",
    "editProfile.button.addSpecialBusiness":"Add Special Business Hours",
    "editProfile.modal.title.add":"Add Special Business Hours",
    "editProfile.modal.title.edit":"Edit Special Business Hours",
    "editProfile.modal.title.delete":"Delete Special Business Hours",
    "editProfile.modal.label.dayOff":"Special Business Hour Setting",
    "editProfile.modal.label.date":"Date",
    "editProfile.modal.label.time":"Time",
    "editProfile.modal.action.add":"Save",
    "editProfile.modal.action.edit":"Save",
    "editProfile.modal.action.delete":"Delete",
    "editProfile.modal.secondaryAction.add":"Cancel",
    "editProfile.modal.secondaryAction.edit":"Cancel",
    "editProfile.modal.secondaryAction.delete":"Go Back",


    /* V2 Solar Panel Load Quantity */
    "editProfile.title.loadQuantity": "Solar Panel Load Quantity",
    "editProfile.label.stocking.quantity":"Factory Stocking Quantity",
    "editProfile.label.stocking.weight":"Factory Stocking Weight",
    "editProfile.label.dailyOrderCapacity":"Number of Orders Per Day",
    "editProfile.radioBox.unlimited":"Unlimited",
    "editProfile.radioBox.limited":"Limited",
    /* V2 Active Notice */
    "editProfile.title.notice": "Notice",
    "editProfile.description.notice.active":"If you need to edit profile, please close the business before editing. Please note that after closing the merchant, you will no longer be able to accept orders on the EasyCycle Online. However, if the user is already in the reservation process, user can still place an order with you.",
    "editProfile.description.notice.inActive":"The business is currently closed. If you want to reopen your business, please turn on \"Open Business\".",
    "editProfile.label.notice.switch":"Open Business",
    "editProfile.link.notice.close":"Close Business",
    "editProfile.modal.action.closeBusiness":"Close Business",
    "editProfile.modal.secondaryAction.closeBusiness":"Go Back",
    "editProfile.modal.title.closeBusiness":"Close Business",
    "editProfile.modal.content.closeBusiness":"Closing your business means you will no longer be able to accept orders on EasyCycle Online.\nAfter closing, you can still reopen the business.",

    "editProfile.normal.dayOff.error": "Unable to change business day.The selected date has reservations, preventing the modification of the specified business day.",
    "editProfile.special.dayOff.error": "Failed to add a day off.\nThe selected date has reservations, preventing the creation of the specified day off."
}

export default editProfilePage
