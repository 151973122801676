const outsourcingRecyclingPage = {
    /* outsourcing recycling */
    "outsourceRecycle.pageTitle": "外部來源訂單",
    "outsourceRecycle.pageTitle.null": "目前暫無外部來源訂單",
    "outsourceRecycle.page.button.add": "新增",
    "outsourceRecycle.page.button.save": "儲存",
    "outsourceRecycle.page.button.cancel": "取消",
    "outsourceRecycle.pageTitle.add": "新增訂單",
    "outsourceRecycle.pageTitle.edit": "編輯訂單",
    /* table head */
    "outsourceRecycle.th.customer": "客戶",
    "outsourceRecycle.th.orderNumber": "訂單編號",
    "outsourceRecycle.th.arrivalDate": "送達日期",
    "outsourceRecycle.th.deadline": "回收期限",
    "outsourceRecycle.th.orderStatus": "狀態",
    "outsourceRecycle.th.recyclingRate": "回收率",
    "outsourceRecycle.th.action": "動作",
    "outsourceRecycle.th.info": "詳細資訊",
    /* tabs */
    "outsourceRecycle.tab.all": "全部",
    "outsourceRecycle.tab.controlledOrder": "列管訂單",
    "outsourceRecycle.tab.uncontrolledOrder": "非列管訂單",
    /* page Count */
    "outsourceRecycle.pageCount.text":"records per page",
    /* 取消訂單視窗 */
    "outsourceRecycle.modal.cancel.title": "取消訂單",
    "outsourceRecycle.modal.cancel.text": "確認要取消訂單嗎？取消訂單後，您將無法編輯內容。",
    "outsourceRecycle.modal.cancel.action.text": "取消訂單",
    "outsourceRecycle.modal.cancel.secondary.text": "返回",
    /* 取消後提示視窗 */
    "outsourceRecycle.modal.cancel.hint.error.title": "錯誤",
    "outsourceRecycle.modal.cancel.hint.error.text": "請調整錯誤內容",
    "outsourceRecycle.modal.cancel.hint.success.title": "成功",
    "outsourceRecycle.modal.cancel.hint.success.text": "資料更新成功",
    /* 新增訂單後提示視窗 */
    "outsourceRecycle.modal.hint.add.success.title": "資料創建成功",
    "outsourceRecycle.modal.hint.add.success.text": "外部來源訂單創建成功",
    "outsourceRecycle.modal.hint.add.failed.title": "資料創建失敗",
    "outsourceRecycle.modal.hint.add.failed.text": "外部來源訂單創建失敗",
    /* 編輯訂單後提示視窗 */
    "outsourceRecycle.modal.hint.edit.success.title": "資料編輯成功",
    "outsourceRecycle.modal.hint.edit.success.text": "外部來源訂單編輯成功",
    "outsourceRecycle.modal.hint.edit.failed.title": "資料編輯失敗",
    "outsourceRecycle.modal.hint.edit.failed.text": "外部來源訂單編輯失敗",
    /* 訂單資訊 */
    "outsourceRecycle.info.title": "詳細資訊",
    "outsourceRecycle.subtitle1": "回收資訊",
    "outsourceRecycle.subtitle2": "客戶資訊",
    "outsourceRecycle.subtitle.net.weight.title": "回收物料淨重",
    "outsourceRecycle.isControlled.label": "訂單來源",
    "outsourceRecycle.isControlled.true": "列管訂單",
    "outsourceRecycle.isControlled.false": "非列管訂單",
    "outsourceRecycle.isAssociated.label": "是否來自太陽能協會",
    "outsourceRecycle.isAssociated.true": "是",
    "outsourceRecycle.isAssociated.false": "否",
    "outsourceRecycle.recyclingCode.label": "廢棄物及再利用資源代碼",
    "outsourceRecycle.customizedNumber.label": "自訂編號",
    "outsourceRecycle.onBoardDocumentNumber.label": "隨車文件編號",
    "outsourceRecycle.tripletNumber.label": "三聯單編號",
    "outsourceRecycle.quantity.label": "太陽能板數量",
    "outsourceRecycle.netWeight.label": "淨重",
    "outsourceRecycle.grossWeight.label": "毛重",
    "outsourceRecycle.arrivedDate.label": "太陽能板送達日期",
    "outsourceRecycle.arrivedDate.date": "日期",
    "outsourceRecycle.arrivedDate.time": "時間",
    "outsourceRecycle.status.label": "狀態",
    "outsourceRecycle.company.name.label": "公司名稱",
    "outsourceRecycle.orderer.name.label": "姓名",
    "outsourceRecycle.orderer.mail.label": "電子信箱",
    "outsourceRecycle.orderer.tel.label": "手機號碼",
    "outsourceRecycle.receptionist.title": "承辦人",
    "outsourceRecycle.receptionist.label": "承辦人",
    "outsourceRecycle.netWeight.alFrame.label": "鋁框",
    "outsourceRecycle.netWeight.JB.label": "接線盒",
    "outsourceRecycle.netWeight.glass.label": "玻璃",
    "outsourceRecycle.netWeight.cell.label": "電池",
    "outsourceRecycle.netWeight.eva.label": "EVA膠合層",
    "outsourceRecycle.netWeight.pvdf.label": "背板塑膠",
    /* 訂單輸入欄位 錯誤提示 */
    "outsourceRecycle.form.helperTexts.empty": "不可留空。",
    "outsourceRecycle.form.helperTexts.exist": "編號已存在。",
    "outsourceRecycle.form.helperTexts.format": "無效的編號格式。",
    "outsourceRecycle.form.helperTexts.ordererMail": "無效的電子信箱。",
    "outsourceRecycle.form.helperTexts.ordererTel.tw":"請輸入有效的電話號碼。格式可以包括：02xxxxxxxx（市話）、09xxxxxxxx（手機）或+886xxxxxxxxx",
    "outsourceRecycle.form.helperTexts.taxNumber.length":"統一編號必須至少 {taxNumberLimit} 個字符",
    "outsourceRecycle.form.helperTexts.customizedNumber.length":"自訂編號必須至少 {customizedNumberLimit} 個字符",
    "outsourceRecycle.form.helperTexts.tripletNumber.length":"三聯單編號必須至少 {tripletNumberLimit} 個字符",
    /* table 狀態顯示文字 */
    "outsourceRecycle.chip.canceled.text": "已取消",
    "outsourceRecycle.chip.arrived.text": "已送達",
    "outsourceRecycle.chip.recycling.text": "回收中",
    "outsourceRecycle.chip.completed.text": "回收完成",
    /* 搜尋 */
    "outsourceRecycle.status.label.all":"全部",
    "outsourceRecycle.status.placeholder.search":"輸入客戶名稱或是訂單編號",

}

export default outsourcingRecyclingPage
