const verifyEmailPage = {
    /* verify email */
    "verifyEmail.page.signUpSuccess.title": "恭喜！",
    "verifyEmail.page.signUpSuccess.text": "謝謝您註冊！根據EasyCycle政策，需要進行身份驗證才能使用所有服務。",
    "verifyEmail.page.signUpSuccess.hint.text1": "10秒後自動跳轉。",
    "verifyEmail.page.signUpSuccess.hint.text2": "{redirectTime} s",
    "verifyEmail.page.signUpSuccess.button.text": "立即前往",
    "verifyEmail.page.verifyCode.title": "驗證您的電子信箱",
    "verifyEmail.page.verifyCode.label1": "輸入驗證碼。",
    "verifyEmail.page.verifyCode.label2": "沒有收到電子郵件？",
    "verifyEmail.page.verifyCode.sendingMail1": "正在發送電子郵件...",
    "verifyEmail.page.verifyCode.sendingMail2": "重新發送驗證碼 ({resendTime}s)",
    "verifyEmail.page.verifyCode.resend.text": "重新發送驗證碼",
    "verifyEmail.page.verifyCode.hint.good": "驗證成功。",
    "verifyEmail.page.verifyCode.hint.bad": "驗證碼無效或不正確。請重新嘗試。",
    "verifyEmail.page.verifyCode.hint.netWorkError": "系統錯誤。請稍後再試。",
    /* 提示視窗 */
    "verifyEmail.modal.error.title": "錯誤",
    "verifyEmail.modal.error.text": "電子郵件發送錯誤，請稍後再試。"
}

export default verifyEmailPage
