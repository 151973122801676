import React, { useState, useEffect } from 'react';
/* component */
import EasyButton from '../../../../../commons/components/EasyButton/EasyButton';
import Loading from '../../../../../commons/components/loading/Loading';
import ContactInformation from './ContactInformation';
import CompanyInformation from './CompanyInformation';
import DeleteNoticeArea from './DeleteNoticeArea';
import HintModal from '../../../../../commons/components/modal/HintModal';
/* config */
import {
  INPUT_LIMIT,
  pageMode,
} from '../config';
import { auth } from '../../../../../config/config-firebase';
import customerAction from '../customerAction';

/* type */
import CustomerProps, { HintProps } from '../TypescriptProps';

/* mui */
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import CircularProgress from '@mui/material/CircularProgress';
/* utils */
import { Formik } from 'formik';
import {
  object, string,
} from 'yup';

import { useIntl } from 'react-intl';
import handleCustomerManagement from '../handleCustomerManagement';

/* hooks */
import { useAppDispatch, useAppSelector } from '../../../../../reduxStore/utils/reduxHooks';

interface CustomerFormProps {
  selectedCustomer:CustomerProps;
  mode:string;
  setMode:(e:string)=>void;
  getData:()=>void;
}
const CustomerForm = ({
  setMode, selectedCustomer, mode, getData,
}:CustomerFormProps) => {
  /** react-intl */
  const { formatMessage } = useIntl();
  const id2text = (id:string) => formatMessage({ id });
  //
  const taxNumberLimit = INPUT_LIMIT.taxNumber;
  // formik config
  const helperTexts = {
    empty: id2text('outsourceRecycle.form.helperTexts.empty'),
    taxNumber: formatMessage({ id: 'outsourceRecycle.form.helperTexts.taxNumber.length' }, { taxNumberLimit }),
    contactTel: id2text('outsourceRecycle.form.helperTexts.ordererTel.tw'),
  };
  const validationSchema = object({
    /* company Info */
    companyName: string().required(helperTexts.empty),
    taxNumber: string().required(helperTexts.empty).test('tax Number', helperTexts.taxNumber, (value) => value.length === INPUT_LIMIT.taxNumber),
    contactTel: string().test('Contact Tel', helperTexts.contactTel, (value) => {
      const regExp = /^(?:(?:\+?886)?(\d{9})|(0[2-8]\d{7,8})|(09\d{2}\d{3}\d{3}))$/;
      if (value) {
        return regExp.test(value);
      }
      return true;
    }),
  });
  //
  const MODAL_HINT = {
    ADD: {
      SUCCESS: {
        title: id2text('customerManagement.modal.hint.common.success.title'),
        text: id2text('customerManagement.modal.hint.add.success.text'),
      },
      FAILED: {
        title: id2text('customerManagement.modal.hint.common.failed.title'),
        text: id2text('customerManagement.modal.hint.add.failed.text'),
      },
    },
    EDIT: {
      SUCCESS: {
        title: id2text('customerManagement.modal.hint.common.success.title'),
        text: id2text('customerManagement.modal.hint.edit.success.text'),
      },
      FAILED: {
        title: id2text('customerManagement.modal.hint.common.failed.title'),
        text: id2text('customerManagement.modal.hint.edit.failed.text'),
      },
    },
  };

  const [isLoading, setIsLoading] = useState(false);
  const [customer, setCustomer] = useState<CustomerProps>({} as CustomerProps);
  const isEditMode = mode === pageMode.EDIT;
  /** 輸入欄位 * */

  /** function * */

  /** 按鈕 開關* */
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  /** hint modal * */
  const [isOpen, setIsOpen] = useState(false);
  const [modalText, setModalText] = useState<HintProps>(isEditMode ? MODAL_HINT.EDIT.SUCCESS : MODAL_HINT.ADD.SUCCESS);
  /* redux */
  const userData = useAppSelector((state) => state.userDataStore);
  /* 用redux把值備份一份 */
  const customerData = useAppSelector((state) => state.selectedCustomerStore);
  const dispatch = useAppDispatch();
  /* 根據mode變化 */
  useEffect(() => {
    switch (mode) {
      case pageMode.ADD:
        setCustomer({} as CustomerProps);
        dispatch(customerAction.SET_CUSTOMER_MANAGEMENT({} as CustomerProps));
        break;
      case pageMode.EDIT:
        setCustomer(selectedCustomer);
        dispatch(customerAction.SET_CUSTOMER_MANAGEMENT(selectedCustomer));
        break;
      default: break;
    }
  }, [mode]);
  /* 載入時使用 */
  return (
    <>
      <HintModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        modalText={modalText}
        callback={async () => {
          await getData();
          setMode(pageMode.LIST);
          setIsLoading(false);
        }}
      />
      <Formik
        initialValues={{
          fusekiID: customer?.fusekiID ?? '',
          /* company Info */
          companyName: customer?.companyName ?? '',
          taxNumber: customer?.taxNumber ?? '',
          /* contact Info */
          contactName: customer?.contactName ?? '',
          contactMail: customer?.contactMail ?? '',
          contactTel: customer?.contactTel ?? '',
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setErrors }) => {
          setIsButtonLoading(true);
          if (mode === pageMode.ADD) {
            if (userData) {
              try {
                await handleCustomerManagement.postCustomerManagement({
                  user: userData,
                  customer: {
                    contactTel: values.contactTel,
                    contactMail: values.contactMail,
                    contactName: values.contactName,
                    companyName: values.companyName,
                    taxNumber: values.taxNumber,
                  },
                }).then((res) => {
                  if (res) {
                    setIsButtonLoading(false);
                    setModalText(MODAL_HINT.ADD.SUCCESS);
                    setIsOpen(true);
                  } else {
                    setModalText(MODAL_HINT.ADD.FAILED);
                    setIsOpen(true);
                    setIsButtonLoading(false);
                  }
                });
              } catch (err) {
                console.log(err);
                setModalText(MODAL_HINT.ADD.FAILED);
                setIsOpen(true);
                setIsButtonLoading(false);
              }
            }
          }
          if (mode === pageMode.EDIT) {
            try {
              await handleCustomerManagement.putCustomerManagement({
                updateData: {
                  fusekiID: values.fusekiID,
                  contactTel: values.contactTel,
                  contactMail: values.contactMail,
                  contactName: values.contactName,
                  companyName: values.companyName,
                  taxNumber: values.taxNumber,
                },
                oldData: {
                  ...customerData as CustomerProps,
                },
              }).then((res) => {
                setIsButtonLoading(false);
                setModalText(MODAL_HINT.EDIT.SUCCESS);
                setIsOpen(true);
              });
            } catch (err) {
              console.log(err);
              setModalText(MODAL_HINT.EDIT.FAILED);
              setIsOpen(true);
              setIsButtonLoading(false);
            }
          }
        }}
        enableReinitialize
      >
        {({
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              p="0"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                minHeight: '86vh',
                width: '100%',
              }}
            >
              <Paper sx={{ width: '100%' }} elevation={0}>
                <Toolbar
                  sx={{ display: 'flex', justifyContent: 'space-between' }}
                  style={{ padding: '0 0 2rem 0' }}
                >
                  <Typography variant="h5">{mode === pageMode.EDIT ? id2text('customerManagement.pageTitle.edit') : id2text('customerManagement.pageTitle.add')}</Typography>
                </Toolbar>
                {isLoading ? (
                  <Loading />
                ) : (
                  <>
                    <CompanyInformation mode={mode} isButtonLoading={isButtonLoading} />
                    <ContactInformation mode={mode} isButtonLoading={isButtonLoading} />
                    {mode === pageMode.EDIT && (
                    <DeleteNoticeArea
                      setIsLoading={setIsLoading}
                      isButtonLoading={isButtonLoading}
                      setHintModalText={setModalText}
                      setIsHintModalOpen={setIsOpen}
                    />
                    )}
                  </>
                )}
              </Paper>
              {!isLoading && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="end"
                  width="100%"
                  mt="1.5rem"
                >
                  <Box display="flex" width="22%">
                    <Box width="48%">
                      <EasyButton
                        variant="secondary"
                        // style={{
                        //   backgroundColor: '#EEFBFB',
                        //   color: 'rgba(89, 217, 217, 1)',
                        // }}
                        size="medium"
                        fullWidth
                        disabled={!auth.currentUser?.emailVerified || isButtonLoading}
                        onClick={() => {
                          setMode(pageMode.LIST);
                        }}
                      >
                        <Typography>{id2text('common.cancel')}</Typography>
                      </EasyButton>
                    </Box>
                    <Box ml="1rem" width="48%">
                      <EasyButton
                        variant="contained"
                        size="medium"
                        fullWidth
                        disabled={!auth.currentUser?.emailVerified || isButtonLoading}
                        onClick={handleSubmit}
                      >
                        <Typography>
                          {isButtonLoading ? (
                            <CircularProgress size={16} color="inherit" />
                          ) : (
                            id2text('common.save')
                          )}
                        </Typography>
                      </EasyButton>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

export default CustomerForm;
