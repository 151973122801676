const settingPage = {
    /* setting */
    "setting.tabs.account":"Account",
    "setting.tabs.notifications":"Notifications",
    /* 在setting頁面 email驗證視窗 */
    "setting.modal.hint.email.verify.title": "驗證您的電子郵件",
    "setting.modal.hint.email.verify.action": "是",
    "setting.modal.hint.email.verify.secondary.action": "否",
    "setting.modal.hint.email.verify.text": " 如果您的驗證碼未過期，請直接輸入驗證碼。如果您輸入的驗證碼顯示已過期，請按 \"重新傳送驗證碼\" 以重新接收驗證碼。",
    "setting.modal.email.verify.success.title": "恭喜！",
    "setting.modal.email.verify.success.text": "謝謝您註冊！由於EasyCycle政策，需要身份驗證才能使用全套服務！",
    "setting.modal.email.verify.hint.good": "驗證成功",
    "setting.modal.email.verify.hint.bad": "驗證碼無效或不正確。請重新嘗試。",
    "setting.modal.email.verify.hint.networkError": "系統錯誤。請稍後再試。",
    "setting.modal.verifyCode.label1": "輸入驗證碼",
    "setting.modal.verifyCode.label2": " 沒有收到電子郵件？",
    "setting.modal.email.verify.hint.sendingMail1": "正在發送電子郵件...",
    "setting.modal.email.verify.hint.sendingMail2": "重新發送驗證碼 ({resendTime}s)",
    "setting.modal.email.verify.resend.text": "重新發送驗證碼",
    /* 刪除用戶視窗 */
    "setting.modal.deleteAccount.action": "是",
    "setting.modal.deleteAccount.secondary.action": "否",
    "setting.modal.deleteAccount.title.default": "刪除您的帳戶",
    "setting.modal.deleteAccount.title.success": "成功",
    "setting.modal.deleteAccount.title.error": "錯誤",
    "setting.modal.deleteAccount.text.default": "您真的要刪除您的帳號嗎？ 按[是]將不可逆轉，您將無法撤銷。",
    "setting.modal.deleteAccount.text.success": "您的帳戶已被刪除。若要撤銷對我們網站的訪問,請自行刪除cookie。謝謝。",
    "setting.modal.deleteAccount.text.error": "帳戶刪除失敗，請稍後重試。",
    /* 資料修改 確認視窗 */
    "setting.modal.confirm.title": "確認",
    "setting.modal.confirm.action": "儲存",
    "setting.modal.confirm.secondary.action": "取消",
    "setting.modal.confirm.text1": "此操作將更改您的個人資訊。",
    "setting.modal.confirm.text2": "您確定要繼續修改嗎？",
    /* 資料修改 修改後提示視窗 */
    "setting.modal.update.error.title": "錯誤",
    "setting.modal.update.error.text": "欄位輸入錯誤，請修正。",
    "setting.modal.update.success.title": "成功",
    "setting.modal.update.success.text": "資料更新成功",
    /* user card */
    "setting.userCard.chip.verified": "驗證",
    "setting.userCard.chip.unVerified": "未驗證",
    "setting.userCard.button.deleteAccount": "刪除帳戶",
    "setting.userCard.button.verifyAccount": "驗證帳戶",
    /* 上傳身分證明 提示視窗 */
    "setting.upload.modal.hint.uploadError": "上傳失敗。 請稍後再嘗試。",
    "setting.upload.modal.hint.loadError": "無法取得上傳的資料。請稍後重試。",
    "setting.upload.modal.hint.filesFormatError": "請上傳指定格式的檔案： \"*.pdf、*.png、*.jpeg\" 。",
    /* 上傳身分證明文件區 */
    "setting.upload.area.title":"Drop files to upload",
    "setting.upload.area.hint":"Supported formats: PDF, JPG, PNG. File size must be less than 2MB.",
    "setting.upload.area.hint.emailVerified": "請先驗證您的電子信箱，才能上傳文件。",
    "setting.upload.button.cancel": "取消",
    "setting.upload.button.upload": "上傳文件",
    "setting.upload.button.remove": "刪除文件",
    "setting.upload.button.view": "查看",

    "setting.page.title": "設定",
    "setting.page.subTitle": "您的個人資訊以及您對各種EasyCycle服務的偏好。",
    "setting.page.emailVerifiedHint": "*溫馨提醒您盡快完成帳戶和實名驗證。 謝謝您的合作！",
    "setting.page.infoArea.company.title": "公司資訊",
    "setting.page.infoArea.address.title": "地址",
    "setting.page.infoArea.mainContact.title": "主要聯絡人",
    "setting.page.helperTexts.name": "名稱無效。文字長度限制為 {USERNAME_LENGTH_LIMIT}. 非法字符包括： {ILLEGAL_CH}",
    "setting.page.helperTexts.mail": "電子信箱無效",
    "setting.page.helperTexts.empty": "這裡不允許有空格",
    "setting.page.helperTexts.phone": "電話無效",
    "setting.page.helperTexts.tax": "統一編號無效",
    "setting.page.helperTexts.license": "執照編號無效",
    "setting.page.companyName.label": "公司名稱",
    "setting.page.companyTel.label": "電話號碼",
    "setting.page.companyMail.label":"公司電子信箱",
    "setting.page.licenseNumber.label": "執照編號",
    "setting.page.postalCode.label": "郵遞區號",
    "setting.page.country.label": "國家/地區",
    "setting.page.state.label": "州/省/市",
    "setting.page.city.label": "市/區",
    "setting.page.address1.label": "地址一",
    "setting.page.address2.label": "地址二",
    "setting.page.contactName.label": "姓名",
    "setting.page.contactMail.label": "電子信箱",
    "setting.page.contactTel.label": "電話",
    "setting.page.button.save": "儲存",
    "setting.page.button.cancel": "取消",
    "setting.page.label.password":"Password",
    "setting.page.button.changePassword":"Change Password",
    /* company document */
    "setting.company.document.title":"Company Document",
    "setting.company.document.description":"Please upload company documents, the documents are only used for identity verification and not for other purposes.",
    /* upload card */
    "setting.upload.button":"Upload",
    "setting.upload.card.title":"Change Registration Form",
    "setting.upload.card.status.approved":"Approved",
    "setting.upload.card.status.pending":"Pending",
    "setting.upload.area.mode.title":"Upload Change Registration Form",
    "setting.upload.area.back":"Back",
}

export default settingPage
