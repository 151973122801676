// eslint-disable-next-line import/prefer-default-export
const ORDER_CREATED = 'created';
const ORDER_CONFIRMED = 'confirmed';
const ORDER_CANCEL = 'cancel';
const ORDER_DECLINED = 'declined';
const ORDER_ARRIVED = 'arrived';
const ORDER_RECYCLING = 'recycling';
const ORDER_STATUS = {
  CREATED: 'Created',
  CANCELED: 'Canceled',
  CONFIRMED: 'Confirmed',
  REJECTED: 'Canceled',
  DECLINED: 'Declined',
  ARRIVED: 'Arrived',
  RECYCLING: 'Recycling',
  COMPLETED: 'Completed',
};

export {
  ORDER_CREATED,
  ORDER_CONFIRMED,
  ORDER_CANCEL,
  ORDER_DECLINED,
  ORDER_ARRIVED,
  ORDER_RECYCLING,
  ORDER_STATUS,
};
