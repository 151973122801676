const DateEventType = 'DateEvent';

const PersonType = 'Personal';

// instanceRandomID 為需要建立 random ID 的 class 列表
const instanceRandomID = [{ prefix: 'PER', eventType: PersonType }];

const Graphs = {
  rhandler: 'rHandler',
  rUpstream: 'rUpstream',
  coupon: 'coupon',
  order: 'order',
  rentalEquip: 'rentalEquip',
  serviceProvider: 'serviceProvider',
  equipment: 'equipment',
  outsourceRecycle: 'outsourceRecycle',
  recyclingPlan: 'recyclingPlan',
  contract: 'contract',
  customer: 'customer',
};

const ClassType = {
  URL: 'URLevent',
  CET: 'CouponEvent',
  GRE: 'GroupEvent',
  ADM: 'Admin',
  COM: 'Company',
  INV: 'Invoice',
  JBC: 'JB',
  LOC: 'Location',
  ORD: 'Order',
  PAY: 'Payment',
  PER: PersonType,
  RSL: 'RSLP',
  RCP: 'Receipt',
  RTR: 'RecycleTrace',
  RCC: 'Recycler',
  ROL: 'Role',
  USE: 'User',
  HDL: 'Handler',
  SVP: 'ServiceProvider',
  UPS: 'Upstream',
  CUP: 'Coupon',
  PSE: 'PSLPEvent',
  PSP: 'PSLP',
  URT: 'UnitRecycleTrace',
  TRT: 'TotalRecycleTrace',
  DAT: DateEventType,
  GRP: 'Group',
  GRR: 'GroupRole',
  HLI: 'HandlerIntro',
  SRC: 'SlpRecycleCost',
  EQU: 'Equipment',
  MTR: 'MaintenanceRecords',
  REQ: 'RentalEquipment',
  CTT: 'Contract',
  CTE: 'ContractEvent',
  EHE: 'EquipHistoryEvent',
  OSR: 'OutsourceRecycle',
  RPN: 'RecyclingPlan',
  RPE: 'RecyclingPrice',
  PIF: 'PlanInformation',
  CMR: 'Customer',
};

const eventPrefix = [
  { prefix: 'URL', eventType: ClassType.URL },
  { prefix: 'CET', eventType: ClassType.CET },
  { prefix: 'GRE', eventType: ClassType.GRE },
  { prefix: 'CTE', eventType: ClassType.CTE },
];

const classPrefix = [
  { prefix: 'ADM', eventType: ClassType.ADM },
  { prefix: 'COM', eventType: ClassType.COM },
  { prefix: 'INV', eventType: ClassType.INV },
  { prefix: 'JBC', eventType: ClassType.JBC },
  { prefix: 'LOC', eventType: ClassType.LOC },
  { prefix: 'ORD', eventType: ClassType.ORD },
  { prefix: 'PAY', eventType: ClassType.PAY },
  { prefix: 'PER', eventType: ClassType.PER },
  { prefix: 'RSL', eventType: ClassType.RSL },
  { prefix: 'RCP', eventType: ClassType.RCP },
  { prefix: 'RTR', eventType: ClassType.RTR },
  { prefix: 'RCC', eventType: ClassType.RCC },
  { prefix: 'ROL', eventType: ClassType.ROL },
  { prefix: 'USE', eventType: ClassType.USE },
  { prefix: 'HDL', eventType: ClassType.HDL },
  { prefix: 'UPS', eventType: ClassType.UPS },
  { prefix: 'CUP', eventType: ClassType.CUP },
  { prefix: 'PSE', eventType: ClassType.PSE },
  { prefix: 'PSP', eventType: ClassType.PSP },
  { prefix: 'URT', eventType: ClassType.URT },
  { prefix: 'TRT', eventType: ClassType.TRT },
  { prefix: 'DAT', eventType: DateEventType },
  { prefix: 'GRP', eventType: ClassType.GRP },
  { prefix: 'GRR', eventType: ClassType.GRR },
  { prefix: 'HLI', eventType: ClassType.HLI },
  { prefix: 'SRC', eventType: ClassType.SRC },
  { prefix: 'EQU', eventType: ClassType.EQU },
  { prefix: 'MTR', eventType: ClassType.MTR },
  { prefix: 'REQ', eventType: ClassType.REQ },
  { prefix: 'CTT', eventType: ClassType.CTT },
  { prefix: 'RPN', eventType: ClassType.RPN },
  { prefix: 'RPE', eventType: ClassType.RPE },
  { prefix: 'PIF', eventType: ClassType.PIF },
  { prefix: 'CMR', eventType: ClassType.CMR },

].concat(eventPrefix);

const RentalEquipPrefix = classPrefix.find((c) => c.eventType === ClassType.REQ)?.prefix;
const OrderPrefix = classPrefix.find((c) => c.eventType === ClassType.ORD)?.prefix;
const EquipPrefix = classPrefix.find((c) => c.eventType === ClassType.EQU)?.prefix;

export {
  DateEventType,
  PersonType,
  instanceRandomID,
  eventPrefix,
  classPrefix,
  ClassType,
  Graphs,
  RentalEquipPrefix,
  OrderPrefix,
  EquipPrefix,
};
