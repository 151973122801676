import React from 'react';
// MUI
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
//
import hamburgerIcon from '../../../images/icon_hamburger/Vector@3x.png';
//
type Anchor = 'top' | 'left' | 'bottom' | 'right';

interface Props {
  menuTop: React.ReactNode;
  menuBottom: React.ReactNode;
}

interface IDrawerState {
  top: boolean;
  left: boolean;
  bottom: boolean;
  right: boolean;
}

/**
 * menuTop 及 menuBottom 可由 props 帶入
 */
const MenuDrop: React.FC<Props> = ({
  menuTop,
  menuBottom,
}): React.JSX.Element => {
  const [state, setState] = React.useState<IDrawerState>({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown'
        && ((event as React.KeyboardEvent).key === 'Tab'
          || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor: Anchor): React.JSX.Element => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      {menuTop}
      {menuTop && menuBottom && <Divider />}
      {menuBottom}
    </Box>
  );

  return (
    <div style={{ display: 'inline-block' }}>
      {(['right'] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <IconButton onClick={toggleDrawer(anchor, true)}>
            <img
              src={hamburgerIcon}
              width={22}
              height={18}
              alt="menu drop"

            />
          </IconButton>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
};

export default MenuDrop;
