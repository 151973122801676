import React, { ChangeEvent } from 'react';

/* components */
import EasyTextField from '../../../../../commons/components/EasyTextField/EasyTextField';
/* mui */
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
/* utils */
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
/* types */
import OutsourcingRecyclingProps from '../TypescriptProps';
/* config */
import {
  INPUT_LIMIT,
  RECYCLING_CODE_ARR, RECYCLING_CODE_LIST, TITLE_TEXT,
} from '../config';
import { ORDER_STATUS } from '../../../../../config/config.order';

const RecyclingInformation = ({ mode, isButtonLoading }:{ mode:string ;isButtonLoading:boolean; }) => {
  const {
    values, handleChange, setFieldValue, handleBlur, errors, touched,
  } = useFormikContext<OutsourcingRecyclingProps>();
    // const formatToday = format(+new Date(), 'yyyy-MM-dd');
  const isCompletedOrRecycling = values?.orderStatus === ORDER_STATUS.COMPLETED || values?.orderStatus === ORDER_STATUS.RECYCLING;
  /** react-intl */
  const { formatMessage } = useIntl();
  const id2text = (id:string) => formatMessage({ id });
  return (
    <Box padding="2rem" border="2px solid rgba(250, 250, 250, 1)" borderRadius="0.5rem" sx={{ boxShadow: '0 0 16px 0 rgba(0,0,0,0.08)' }}>
      {/* 標題 */}
      <Typography variant="h5">
        {id2text('outsourceRecycle.subtitle1')}
      </Typography>
      {/* 是否為列管 */}
      <Grid container mt="1.5rem" justifyContent="space-between" alignItems="center">
        <Grid item xs={4} container justifyContent="flex-start">
          <Typography variant="body1" display="flex" alignItems="center">
            {id2text('outsourceRecycle.isControlled.label')}
            <Typography color="red" variant="caption">&nbsp;*</Typography>
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <FormControl disabled={isButtonLoading || isCompletedOrRecycling}>
            <RadioGroup
              aria-labelledby="isControlled"
              name="isControlled"
              value={values.isControlled}
              onChange={(_event, value) => {
                /* 讓黑單選項清除 */
                if (values.isControlled === '0' || values.isAssociated === '0') {
                  if (values.recyclingCode === RECYCLING_CODE_LIST.TYPE3) {
                    setFieldValue('recyclingCode', '');
                  }
                }
                /* 選擇列管非列管同時清除數據 */
                if (values.isControlled === '1') {
                  setFieldValue('tripletNumber', '');
                } else {
                  setFieldValue('customizedNumber', '');
                  setFieldValue('onBoardDocumentNumber', '');
                }
                setFieldValue('isControlled', value);
              }}
              row
            >
              <FormControlLabel value="1" control={<Radio />} label={id2text('outsourceRecycle.isControlled.true')} />
              <FormControlLabel value="0" control={<Radio />} label={id2text('outsourceRecycle.isControlled.false')} />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      {/* 是否為太陽能協會補助 */}
      <Grid container mt="1.5rem" justifyContent="space-between" alignItems="center">
        <Grid item xs={4} container justifyContent="flex-start">
          <Typography variant="body1" display="flex" alignItems="center">
            {id2text('outsourceRecycle.isAssociated.label')}
            <Typography color="red" variant="caption">&nbsp;*</Typography>
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <FormControl disabled={isButtonLoading || isCompletedOrRecycling}>
            <RadioGroup
              aria-labelledby="isAssociated"
              name="isAssociated"
              value={values.isAssociated}
              onChange={(_event, value) => {
                if (values.isControlled === '0' || values.isAssociated === '0') {
                  if (values.recyclingCode === RECYCLING_CODE_LIST.TYPE3) {
                    setFieldValue('recyclingCode', '');
                  }
                }
                setFieldValue('isAssociated', value);
              }}
              row
            >
              <FormControlLabel value="1" control={<Radio />} label={id2text('outsourceRecycle.isAssociated.true')} />
              <FormControlLabel value="0" control={<Radio />} label={id2text('outsourceRecycle.isAssociated.false')} />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      {/* 三聯單編號 列管有 非列管則無 */}
      {values.isControlled === '1' ? (
        <Grid container mt="1.5rem" justifyContent="space-between" alignItems="center">
          <Grid item xs={4} container justifyContent="flex-start">
            <Typography variant="body1" display="flex" alignItems="center">
              {id2text('outsourceRecycle.tripletNumber.label')}
              <Typography color="red" variant="caption">&nbsp;*</Typography>
            </Typography>
            <Box ml="0.5rem" display="flex" alignItems="center">
              <Tooltip title={id2text('outsourceRecycle.tripletNumber.label')}>
                <InfoIcon sx={{ fontSize: '1.5rem' }} style={{ color: 'black' }} />
              </Tooltip>
            </Box>
          </Grid>
          <Grid item xs={8}>
            <EasyTextField
              key="tripletNumber"
              name="tripletNumber"
              onChange={handleChange}
              type="inputLimit"
              label={id2text('outsourceRecycle.tripletNumber.label')}
              value={values.tripletNumber}
              fullWidth
              inputProps={{
                maxLength: INPUT_LIMIT.tripletNumber,
              }}
              onBlur={handleBlur('tripletNumber')}
              error={Boolean(touched.tripletNumber && errors.tripletNumber)}
              helperText={touched.tripletNumber ? errors.tripletNumber : ''}
              disabled={isButtonLoading || isCompletedOrRecycling}
            />
          </Grid>
        </Grid>
      )
        : (
          <>
            {/* 客製化編號 */}
            <Grid container mt="1.5rem" justifyContent="space-between" alignItems="center">
              <Grid item xs={4} container justifyContent="flex-start">
                <Typography variant="body1" display="flex" alignItems="center">
                  {id2text('outsourceRecycle.customizedNumber.label')}
                  <Typography color="red" variant="caption">&nbsp;*</Typography>
                </Typography>
                <Box ml="0.5rem" display="flex" alignItems="center">
                  <Tooltip title={id2text('outsourceRecycle.customizedNumber.label')}>
                    <InfoIcon sx={{ fontSize: '1.5rem' }} style={{ color: 'black' }} />
                  </Tooltip>
                </Box>
              </Grid>
              <Grid item xs={8}>
                <EasyTextField
                  key="customizedNumber"
                  name="customizedNumber"
                  onChange={handleChange}
                  type="inputLimit"
                  label={id2text('outsourceRecycle.customizedNumber.label')}
                  value={values.customizedNumber}
                  fullWidth
                  inputProps={{
                    maxLength: INPUT_LIMIT.customizedNumber,
                  }}
                  onBlur={handleBlur('customizedNumber')}
                  error={Boolean(touched.customizedNumber && errors.customizedNumber)}
                  helperText={touched.customizedNumber ? errors.customizedNumber : ''}
                  disabled={isButtonLoading || isCompletedOrRecycling}
                />
              </Grid>
            </Grid>
            {/* 隨車文件編號 */}
            <Grid container mt="1.5rem" justifyContent="space-between" alignItems="center">
              <Grid item xs={4} container justifyContent="flex-start">
                <Typography variant="body1" display="flex" alignItems="center">
                  {id2text('outsourceRecycle.onBoardDocumentNumber.label')}
                </Typography>
                <Box ml="0.5rem" display="flex" alignItems="center">
                  <Tooltip title={id2text('outsourceRecycle.onBoardDocumentNumber.label')}>
                    <InfoIcon sx={{ fontSize: '1.5rem' }} style={{ color: 'black' }} />
                  </Tooltip>
                </Box>
              </Grid>
              <Grid item xs={8}>
                <EasyTextField
                  key="onBoardDocumentNumber"
                  name="onBoardDocumentNumber"
                  onChange={handleChange}
                  type="inputLimit"
                  label={id2text('outsourceRecycle.onBoardDocumentNumber.label')}
                  value={values.onBoardDocumentNumber}
                  fullWidth
                  inputProps={{
                    maxLength: INPUT_LIMIT.onBoardDocumentNumber,
                  }}
                  onBlur={handleBlur('onBoardDocumentNumber')}
                  error={Boolean(touched.onBoardDocumentNumber && errors.onBoardDocumentNumber)}
                  helperText={touched.onBoardDocumentNumber ? errors.onBoardDocumentNumber : ''}
                  disabled={isButtonLoading}
                />
              </Grid>
            </Grid>
          </>
        )}
      {/* 廢棄物代碼 */}
      <Grid container mt="1.5rem" justifyContent="space-between" alignItems="center">
        <Grid item xs={4} container justifyContent="flex-start">
          <Typography variant="body1" display="flex" alignItems="center">
            {id2text('outsourceRecycle.recyclingCode.label')}
            <Typography color="red" variant="caption">&nbsp;*</Typography>
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <FormControl fullWidth>
            <InputLabel id={TITLE_TEXT.RECYCLING_CODE_TITLE}>{id2text('outsourceRecycle.recyclingCode.label')}</InputLabel>
            <Select
              name="recyclingCode"
              fullWidth
              disabled={isButtonLoading || isCompletedOrRecycling}
              value={values.recyclingCode}
              labelId={TITLE_TEXT.RECYCLING_CODE_TITLE}
              label={id2text('outsourceRecycle.recyclingCode.label')}
              onBlur={handleBlur('recyclingCode')}
              error={Boolean(touched.recyclingCode && errors.recyclingCode)}
              sx={{
                height: '3.1875rem',
                borderRadius: '0.5rem',
              }}
              onChange={(e:SelectChangeEvent) => {
                const value = e?.target.value;
                setFieldValue(
                  'recyclingCode',
                  value,
                );
              }}
            >
              {RECYCLING_CODE_ARR
                .filter((item) => (values.isControlled === '0' && values.isAssociated === '0' ? item : item !== RECYCLING_CODE_LIST.TYPE3))
                .map((item) => (
                  <MenuItem
                    key={`${item}_recyclingCode`}
                    value={item}
                    sx={{ width: '100%' }}
                  >
                    {item}
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText style={{ color: '#D32F2F' }}>
              { touched.recyclingCode ? errors.recyclingCode : ''}
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      {/* 毛重 */}
      <Grid container mt="1.5rem" justifyContent="space-between" alignItems="center">
        <Grid item xs={4}>
          <Typography variant="body1">
            {id2text('outsourceRecycle.grossWeight.label')}
            <Typography color="red" variant="caption">&nbsp;*</Typography>
          </Typography>
        </Grid>
        <Grid item container xs={8} justifyContent="flex-start" alignItems="center">
          <EasyTextField
            key="grossWeight"
            name="grossWeight"
            type="number"
            label={id2text('outsourceRecycle.grossWeight.label')}
            onChange={(e:ChangeEvent<HTMLInputElement>) => {
              const { value } = e.target;
              const regex = /^\d+(\.\d{0,2})?$/;
              if (regex.test(value)) {
                setFieldValue('grossWeight', value);
              }
            }}
            value={values.grossWeight}
            sx={{
              width: '20%',
            }}
            InputProps={{
              style: { height: '3.1875rem', borderRadius: '0.5rem' },
            }}
            onBlur={handleBlur('grossWeight')}
            error={Boolean(touched.grossWeight && errors.grossWeight)}
            helperText={touched.grossWeight ? errors.grossWeight : ''}
            disabled={isButtonLoading || isCompletedOrRecycling}
          />
          <Typography ml="1rem" variant="body1">t</Typography>
        </Grid>
      </Grid>
      {/* 太陽能板抵達時間 */}
      <Grid container mt="1.5rem" justifyContent="space-between" alignItems="center">
        <Grid item xs={4}>
          <Typography variant="body1">
            {id2text('outsourceRecycle.arrivedDate.label')}
            <Typography color="red" variant="caption">&nbsp;*</Typography>
          </Typography>
        </Grid>
        <Grid container justifyContent="space-between" item xs={8} alignItems="center">
          <EasyTextField
            key="arrivedDate"
            name="arrivedDate"
            type="date"
            label={id2text('outsourceRecycle.arrivedDate.date')}
            value={values.arrivedDate}
            disabled={isButtonLoading || isCompletedOrRecycling}
            onBlur={handleBlur('arrivedDate')}
            error={Boolean(touched.arrivedDate && errors.arrivedDate)}
            helperText={touched.arrivedDate ? errors.arrivedDate : ''}
                        // onChange={handleChange}
            onChange={(e:React.ChangeEvent<HTMLInputElement>) => {
              const value = e?.target.value;
              setFieldValue(
                'arrivedDate',
                value,
              );
            }}
            sx={{ width: '47%' }}
          />
          <EasyTextField
            key="arrivedTime"
            name="arrivedTime"
            type="time"
            label={id2text('outsourceRecycle.arrivedDate.time')}
            value={values.arrivedTime}
            onChange={handleChange}
            onBlur={handleBlur('arrivedTime')}
            error={Boolean(touched.arrivedTime && errors.arrivedTime)}
            helperText={touched.arrivedTime ? errors.arrivedTime : ''}
            sx={{ width: '47%' }}
            InputProps={{
              style: { height: '3.1875rem', borderRadius: '0.5rem' },
            }}
            disabled={isButtonLoading || isCompletedOrRecycling}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default RecyclingInformation;
