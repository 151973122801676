const signInPage = {
    /* sign in */
    "signIn.page.title": "歡迎回來！",
    "signIn.page.email.label": "電子信箱",
    "signIn.page.password.label": "密碼",
    "signIn.page.stay.label": "保持登入狀態",
    "signIn.page.forgotPassword.label": "忘記密碼？",
    "signIn.page.button.signIn": "登入",
    "signIn.page.hasAccount.label": "沒有帳戶？",
    "signIn.page.goToSignUp.label": "立即註冊",
    "signIn.page.helperTexts.mail": "電子信箱無效",
    "signIn.page.helperTexts.password": "密碼長度必須至少為8個字符，並包含大寫字母、小寫字母和數字。",
    "signIn.page.helperTexts.mailOrPassword": "請檢查您的電子信箱或密碼是否正確。",
    /* 再次登入時 仍未驗證信箱 提示視窗 */
    "signIn.modal.hint.verifyEmail.title": "驗證您的帳戶",
    "signIn.modal.hint.action": "前往設定",
    "signIn.modal.hint.secondary.action": "稍後前往",
    "signIn.modal.hint.verifyEmail.text": "請檢查您的電子信箱以驗證您的帳戶並使用EasyCycle的所有服務。 如果您沒有收到驗證郵件，請前往「設定」重新傳送驗證郵件。",
}

export default signInPage
