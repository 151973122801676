import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../reduxStore/utils/reduxHooks';
//
import Container from '../../commons/components/navbar/Container';
import Grid from '@mui/material/Grid';
//
import DeleteAccountModal from './subComponent/DeleteAccountModal';
import EasyButton from '../../commons/components/EasyButton/EasyButton';
// scss
import './Home.scss';
import homeAct from './homeAction';
import { useNavigate } from 'react-router-dom';
import SignOut from '../Users/utils/signOut';
import routes from '../../config/routes';
import services from '../../api/services';
import Loading from '../../commons/components/loading/Loading';

// FIXME: 2023.08 for TW handler => set true
const PERSIST_LOADING = true;
// LOCAL TESTING => set false
// const PERSIST_LOADING = false;

function Home() {
  const dispatch = useAppDispatch();
  const { pageName } = useAppSelector((state) => state.home);
  const user = useAppSelector((state) => state.userDataStore);
  const isLogin = localStorage.getItem('isLogin');
  const navigation = useNavigate();

  // 開啟刪除帳號視窗
  const [isOpen, setIsOpen] = useState(false);
  const handleDeleteAccountModal = (): void => {
    setIsOpen(true);
  };

  useEffect(() => {
    dispatch({
      type: homeAct.SET_PAGENAME,
      payload: 'HomePage',
    });
  }, []);

  const [checkUserState, setCheckUserState] = useState(false);

  useEffect(() => {
    // FIXME: 2023.08 for TW handler
    const timeoutId = setTimeout(() => {
      setCheckUserState(true);
    }, 2000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    // FIXME: 2023.08 for TW handler
    if (checkUserState) {
      if (user) {
        navigation(`/${services.getLocale()}${routes.SIGNIN_SUCCESS_REDIRECT}`);
      } else {
        navigation(`/${services.getLocale()}${routes.SIGNIN}`);
      }
    }
  }, [checkUserState, user]);

  if (PERSIST_LOADING) {
    return (<Loading />);
  }

  // FIXME: 下面是 TESTING
  return (
    <div className="Home">
      {/* 刪除帳號視窗 */}
      {isOpen && <DeleteAccountModal isOpen={isOpen} setIsOpen={setIsOpen} />}
      <Container>
        <h3>
          {pageName}
        </h3>
        <Grid container spacing={2} justifyContent="start">
          <Grid item>
            <EasyButton
              variant="contained"
              onClick={() => {
                navigation(`/${services.getLocale()}${routes.SIGNIN}`);
              }}
            >
              signIn
            </EasyButton>
          </Grid>
          <Grid item>
            <EasyButton variant="contained" onClick={() => { navigation(`/${services.getLocale()}${routes.DASHBOARD_SETTINGS}`); }}>
              userDashboard
            </EasyButton>
          </Grid>
          <Grid item>
            <EasyButton variant="contained" onClick={handleDeleteAccountModal}>
              deleteAccount
            </EasyButton>
          </Grid>
          <Grid item>
            <EasyButton variant="contained" onClick={() => { navigation(`/${services.getLocale()}/Console`); }}>
              console
            </EasyButton>
          </Grid>
          <Grid item>
            <EasyButton
              variant="contained"
              onClick={async () => {
                await SignOut(navigation, dispatch);
              }}
            >
              sign Out
            </EasyButton>
          </Grid>
        </Grid>
      </Container>

      {isLogin
        ? (
          <div>
            <br />
            <h4>Firebase UserInfo</h4>
            <pre>
              {JSON.stringify(user, null, 2)}
            </pre>
          </div>
        )
        : null}
    </div>
  );
}

export default Home;
