/** roleType * */
type BaseRole = {
  reader: string,
  editor: string,
  super?:string
};
// /* 可由此繼續擴展 */
// type AdminRole = BaseRole & {
//   super?:string
// };
/** groupType * */
type AdminGroup = {
  id:string;
  role: BaseRole;
  value:string;
};
type DeveloperGroup = {
  id:string;
  role: BaseRole;
  value:string;
};
type TSGCmanagerGroup = {
  id:string;
  role: BaseRole;
  value:string;
};
type ServiceProviderGroup = {
  id:string;
  role: BaseRole;
  value:string;
};
type HandlerGroup = {
  id:string;
  role: BaseRole;
  value:string;
};
type TUVGroup = {
  id:string;
  role: BaseRole;
  value:string;
};
type TwHandlerGroup = {
  id:string;
  role: BaseRole;
  value:string;
};
type AnonymousGroup = {
  id:string;
  role: BaseRole;
};

export interface GroupInfoProps {
  Admin: AdminGroup;
  Developer: DeveloperGroup;
  TUV: TUVGroup;
  ServiceProvider: ServiceProviderGroup;
  TSGCmanager: TSGCmanagerGroup;
  Handler: HandlerGroup;
  TwHandler:TwHandlerGroup;
  Anonymous : AnonymousGroup;
}

/**
 GRP3 Admin (admin)
 GRP4 Developer (developer)
 GRP5 TUV (tuv)
 GRP6 ServiceProvider (serviceprovider)
 GRP7 TSGCmanager (tsgcmanager)
 GRP8 Handler (handler)
 GRP8 TwHandler (twhandler)
 * */

/**
 GRR1 super
 GRR2 editor
 GRR3 reader
 * */
const groupInfo :GroupInfoProps = {
  Admin: {
    id: 'admin',
    role: { super: 'super', reader: 'reader', editor: 'editor' },
    value: 'GRP3',
  },
  Developer: {
    id: 'developer',
    role: { super: 'super', reader: 'reader', editor: 'editor' },
    value: 'GRP4',
  },
  TUV: {
    id: 'tuv',
    role: { super: 'super', reader: 'reader', editor: 'editor' },
    value: 'GRP5',
  },
  ServiceProvider: {
    id: 'serviceprovider',
    role: { super: 'super', reader: 'reader', editor: 'editor' },
    value: 'GRP6',
  },
  TSGCmanager: {
    id: 'tsgcmanager',
    role: { super: 'super', reader: 'reader', editor: 'editor' },
    value: 'GRP7',
  },
  Handler: {
    id: 'handler',
    role: { super: 'super', reader: 'reader', editor: 'editor' },
    value: 'GRP8',
  },
  TwHandler: {
    id: 'twhandler',
    role: { super: 'super', reader: 'reader', editor: 'editor' },
    value: 'GRP9',
  },
  Anonymous: {
    id: 'anonymous',
    role: { reader: 'reader', editor: 'editor' },
  },
};

export const roleInfo = {
  Super: {
    id: 'super',
    value: 'GRR1',
  },
  Editor: {
    id: 'edit',
    value: 'GRR2',
  },
  Reader: {
    id: 'reader',
    value: 'GRR3',
  },
};

export default groupInfo;
