import React from 'react';
import { Tabs, type TabsProps } from '@mui/material';

interface EasyTabsProps extends TabsProps {
  defaultValue:string;
  children: React.ReactNode;
}

const EasyTabs: React.FC<EasyTabsProps> = ({
  children,
  defaultValue,
  sx,
  ...props
}): React.JSX.Element => {
  const [value, setValue] = React.useState(defaultValue);

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: string,
  ): void => {
    setValue(newValue);
  };

  return (
    <Tabs
      value={value}
      TabIndicatorProps={{ sx: { height: '4px' } }}
      onChange={handleChange}
      centered
      sx={{ ...sx }}
      {...props}
    >
      {children}
    </Tabs>
  );
};

export default EasyTabs;
