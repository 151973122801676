import React, { Suspense } from 'react';

// react components
import {
  Route, Routes, Navigate,
} from 'react-router-dom';
//
import Intl18Provider from '../../intl18Store/Intl18Provider';
// route config
import routesPage from '../../App-route';

// components
import LayoutWrapper from './subComponents/LayoutWrapper';
// import NotFoundPage from '../../pages/NotFound/NotFoundPage';
// hooks

import { useAppSelector } from '../../reduxStore/utils/reduxHooks';

import groupInfo from '../../App-groupInfo';
import Loading from '../../commons/components/loading/Loading';
import ErrorPage from '../../pages/Error/ErrorPage';

// utils
// configs

const AllInOneProvider = ({ children }:{ children:React.ReactNode }) => (
  <Intl18Provider>

    {children}

  </Intl18Provider>
);

// r.authority.includes(userData?.role ?? role.anonymous)
function RouteManager() {
  const groupRole = useAppSelector((state) => state.userGroupRoleStore);
  const group = groupRole?.map((item) => item.group);
  return (
    <Routes>
      {
                routesPage && routesPage
                  .filter((r) => r.public)
                  .filter((r) => group?.some((item) => r.authority.includes(item) ?? groupInfo.Anonymous.id)).map((route) => {
                    if (group?.some((item) => route.authority.includes(item))) {
                      return (
                        <Route
                          key={route.id}
                          element={(
                            <AllInOneProvider>
                              <Suspense fallback={<Loading />}>
                                <LayoutWrapper />
                              </Suspense>
                            </AllInOneProvider>
                            )}
                          errorElement={<ErrorPage />}
                        >
                          <Route
                            key={route.id}
                            path={`/:locale?${route.path}`}
                            element={
                              <Suspense fallback={<Loading />}><route.component /></Suspense>
}
                          />
                        </Route>
                      );
                    }
                    return (
                      <Route key={route.id} path="*" element={<Navigate to="/:locale?/NotFound" />} />
                    );
                  })
            }
    </Routes>
  );
}

export default RouteManager;
