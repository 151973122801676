const userTitleContainerStyle = {
  /* mt */
  mt40px: {
    xl: '10.8527%',
    lg: '10.8527%',
    md: '12.1936%',
    sm: '12.8213%',
    xs: '12.8213%',
  },
  mt24px: {
    xl: '6.523%',
    lg: '6.523%',
    md: '7.3215%',
    sm: '7.6928%',
    xs: '7.6928%',
  },
  mt16px: {
    xl: '4.3432%',
    lg: '4.3432%',
    md: '4.881%',
    sm: '5.1285%',
    xs: '5.1285%',
  },
};

export default userTitleContainerStyle;
