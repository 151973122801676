import axios from 'axios';
//
// const baseUrl = process.env.REACT_APP_EASY_API_NODE;
const langPath = process.env.REACT_APP_EASY_API_LANG;

const allDomain: { [key: string]: string | undefined } = {
  baseUrl: process.env.REACT_APP_EASY_API_NODE,
  tsgcURL: process.env.REACT_APP_TSGC_API_NODE,
};

const { baseUrl, tsgcURL } = allDomain;

const url = `${baseUrl}/${langPath}`;
// const testUrl = 'http://localhost:3030';
// 預設語系
const defaultLocale = 'en';
// 當前語系
let locale = defaultLocale;

// hide console.log in production mode
// eslint-disable-next-line no-console
if (process.env.NODE_ENV === 'production' || !(process.env.REACT_APP_DEBUG === 'true')) console.log = () => {};

const services = {
  setLocale(lang: string) {
    locale = lang;
  },
  getDefaultLocale(): string {
    return defaultLocale;
  },
  getLocale(): string {
    return locale;
  },
  // baseUrl
  getBaseUrl: `${url}`,
  // health
  getHealth: `${url}/health/1.0?limit=-1&offset=0`,

  // CRUD
  getGeneric: `${url}/generic/2.0`,
  postGeneric: `${url}/generic/2.0`,
  putGeneric: `${url}/generic/2.0`,
  delGeneric: `${url}/generic/2.0`,
  //
  postHandler: `${url}/generic/2.0`,
  putHandler: `${url}/generic/2.0`,
  delHandler: `${url}/generic/2.0`,
  delPersonAdmin: `${url}/deletePerson/2.0`,
  getHandler: (uid: string) => `${url}/dash/handler/info/1.1?limit=-1&offset=0&uid=${uid}`,
  // handlerIntro
  getHandlerIntro: (uid: string) => `${url}/dash/handlerIntro/1.0?limit=-1&offset=0&uid=${uid}`,
  getHandlerIntroImages: (uid: string) => `${url}/dash/handlerIntro/1.0?limit=-1&offset=0&uid=${uid}`,
  // putImagesToFileServer
  putHandlerIntroImages:
      `${process.env.REACT_APP_FILE_SERVER_URL}/upload/image/batch/easycycle/handler`,
  /* user */
  postChangePassword: () => `${baseUrl}/changePassword`,
  postCheckEmailExist: () => `${baseUrl}/checkEmailExist`,
  postLinkGoogleProvider: () => `${baseUrl}/linkGoogleProvider`,
  postUpdateEmailVerified: () => `${baseUrl}/updateEmailVerified`,
  postMail: () => `${baseUrl}/mail`,
  postUserMail: () => `${baseUrl}/sendEmailForUser`,
  postDeleteAccount: () => `${baseUrl}/deleteAccount`,
  postCreateAccount: () => `${baseUrl}/createAccount`,
  /* Handler Policy */
  getPolicy: (contractList: string | string[]) => `${url}/handler/policy/detail/1.0?limit=-1&offset=0&ids=${contractList}`,
  /* Handler Outsource Recycle */
  getOutsourceRecycleList: (uid: string, text:string) => `${url}/handler/outsourceRecycle/list/1.0?limit=-1&offset=0&uid=${uid}&text=${text}`,
  getOutsourceRecycleDetail: ({
    uid,
    osrIds,
  }: {
    uid: string;
    osrIds: string[] | string;
  }) => `${url}/handler/outsourceRecycle/detail/1.0?limit=-1&offset=0&ids=${osrIds}&uid=${uid}`,
  /* Handler Search Outsource Recycle ID */
  getOutsourceRecycleID: (createdTime: number, recyclingCode: string) => `${url}/handler/search/outsourceRecycle/outsourceRecycleID/1.0?limit=-1&offset=0&createdTime=${createdTime}&recyclingCode=${recyclingCode}`,
  getOutsourceRecycleOrderID: (createdTime: number, bookingTime: number) => `${url}/handler/search/outsourceRecycle/orderID/1.0?limit=-1&offset=0&createdTime=${createdTime}&bookingTime=${bookingTime}`,
  getOutsourceRecyclePslpEventID: (createdTime: number, slpQuantity: string) => `${url}/handler/search/outsourceRecycle/pslpEventID/1.0?limit=-1&offset=0&createdTime=${createdTime}&slpQuantity=${slpQuantity}`,
  /* Handler Customer Management */
  getCustomerDetail: (uid: string, text:string) => `${url}/handler/customer/detail/1.0?limit=-1&offset=0&uid=${uid}&text=${text}`,
  /* pslp */
  getPslpList: ({ osrId, uid }: { osrId: string; uid: string }) => `${url}/handler/outsourceRecycle/pslp/list/1.0?limit=-1&offset=0&osrId=${osrId}&uid=${uid}`,
  getPslpDetail: ({
    slpIds,
    uid,
  }: {
    slpIds: string | string[];
    uid: string;
  }) => `${url}/handler/outsourceRecycle/pslp/detail/1.0?limit=-1&offset=0&ids=${slpIds}&uid=${uid}`,
  checkOnboardDocumentNumber: (onBoardDocumentNumber: string) => `${url}/handler/exist/onBoardDocumentNumber/1.0?limit=-1&offset=0&onBoardDocumentNumber=${onBoardDocumentNumber}`,
  checkTripletNumber: (tripletNumber: string) => `${url}/handler/exist/tripletNumber/1.0?limit=-1&offset=0&tripletNumber=${tripletNumber}`,
  checkCustomizedNumber: (customizedNumber: string) => `${url}/handler/exist/customizedNumber/1.0?limit=-1&offset=0&customizedNumber=${customizedNumber}`,
};

export default services;
