import { configureStore } from '@reduxjs/toolkit';

// reducers
import homeReducer from '../pages/Home/homeReducer';
import {
  connectUserReducer,
  contractIdReducer,
  easycycleSettingsReducer,
  permissionDenialReducer,
  resetPasswordReducer, userFusekiIDReducer,
  userGroupRoleReducer,
  userReducer,
  userSignUpStateReducer,
} from '../pages/Users/userReducer';
import { timeoutReducer, timeoutAlertReducer, snackbarReducer } from './mainReducer/mainReducer';
import {
  selectedContractContentReducer,
  selectedContractManagementReducer,
} from '../commons/components/modal/contractManagementModal/selectedContractManagementReducer';
import { selectedCustomerReducer } from '../pages/Users/UserDashboard/CustomerManagement/customerReducer';
import { selectedOutsourceReducer } from '../pages/Users/UserDashboard/OutsourcingRecycling/outsourceRecycleReducer';

// 將要用的reducer都放入下方object
const rootReducer = configureStore({
  reducer: {
    home: homeReducer,
    /* user */
    userDataStore: userReducer.reducer,
    resetPasswordDataStore: resetPasswordReducer.reducer,
    connectUserDataStore: connectUserReducer.reducer,
    permissionDataStore: permissionDenialReducer.reducer,
    userSignUpStateStore: userSignUpStateReducer.reducer,
    userGroupRoleStore: userGroupRoleReducer.reducer,
    // hdlId
    userFusekiIDStore: userFusekiIDReducer.reducer,
    // edit profile
    easycycleSettingsStore: easycycleSettingsReducer.reducer,
    // contract
    contractIdStore: contractIdReducer.reducer,
    // contract management
    selectedContractStore: selectedContractManagementReducer.reducer,
    selectedContractContentStore: selectedContractContentReducer.reducer,
    //
    timeoutStore: timeoutReducer.reducer,
    timeoutAlertStore: timeoutAlertReducer.reducer,
    snackbarStore: snackbarReducer.reducer,
    // outsourcing slpList
    selectedOutsourceStore: selectedOutsourceReducer.reducer,
    // customer
    selectedCustomerStore: selectedCustomerReducer.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export default rootReducer;

/* 以下為 @type redux 定義 */

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer.getState>; // .getState 獲取 reducer裡面的屬性
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof rootReducer.dispatch;
