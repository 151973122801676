import React, { useState } from 'react';

// react-router-dom
import { useNavigate, useLocation } from 'react-router-dom';
import services from '../../../api/services';

// MUI
import {
  styled,
  useTheme,
  type Theme,
  type CSSObject,
} from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import { type DrawerProps } from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Tooltip from '@mui/material/Tooltip';
import ListItem from '@mui/material/ListItem/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton/ListItemButton';

// MUI Icons
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

// Typescript
interface EasyDrawerProps extends DrawerProps {
  ListItems: Array<{
    id: string;
    label: string | React.JSX.Element;
    path: string;
    icon: React.ReactElement | null;
    isShow: boolean;
  }>;
}

// configs
const drawerWidth = 300;

// components
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')<{
  theme: Theme;
  'data-open': boolean;
}>(({ theme, 'data-open': open }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: open ? 'flex-end' : 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': {
      ...openedMixin(theme),
      position: 'absolute',
      borderRight: 'unset',
      padding: '0 0.5rem',
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': {
      ...closedMixin(theme),
      position: 'absolute',
      borderRight: 'unset',
      padding: '0 0.5rem',
    },
  }),
}));

const EasyDrawer: React.FC<EasyDrawerProps> = ({
  ListItems,
}): React.JSX.Element => {
  const navigation = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const [open, setOpen] = useState(true);

  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader
        theme={theme}
        data-open={open}
        sx={{ cursor: 'pointer' }}
        onClick={() => {
          setOpen(!open);
        }}
      >
        {open ? (
          <>
            <Typography>Handler</Typography>
            <ArrowLeftIcon />
          </>
        ) : (
          <ArrowRightIcon />
        )}
      </DrawerHeader>
      <List>
        {ListItems.filter((item) => item.isShow).map((item) => (
          <ListItem
            key={`EasyDrawer_${item.id}`}
            disablePadding
            sx={{
              display: 'block',
              borderRadius: '0.5rem', // 8px
              ...(location.pathname.includes(item.path) && {
                backgroundColor: 'primary.50',
              }),
            }}
          >
            <Tooltip title={open ? '' : item.label} placement="right">
              <ListItemButton
                sx={{
                  width: '100%',
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                  '&:hover': { backgroundColor: 'transparent' },
                }}
                onClick={() => {
                  navigation(`/${services.getLocale()}${item.path}`);
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item.label}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </Tooltip>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default EasyDrawer;
