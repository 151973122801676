import {
  getDoc,
  doc,
  updateDoc,
  deleteDoc,
  setDoc,
  collection,
  onSnapshot,
  orderBy,
  query,
} from 'firebase/firestore';
import firestorePath from './path';
import { db } from '../../../config/config-firebase';
import { isEmpty } from '../../../commons/utils';
import { SheetDataProp } from './TypescriptProps';

const DisableMS = '0';

/* 寫入資料 */
export const setData = async (
  collectionName: string,
  documentName: string,
  data: any,
): Promise<void> => {
  await setDoc(doc(db, collectionName, documentName), data);
};

/* 獲取user資料 */
export const getUser = async (uid: string): Promise<any> => {
  if (uid == null) {
    return 'user is not exist';
  }
  const snapshot = await getDoc(doc(db, firestorePath.USERS, uid));
  const data = snapshot.data();
  if (data == null) {
    return 'user data is not exist';
  }
  return data;
};

/* 更新登入時間 */
export const updateUserLastLoginTime = async (uid: string): Promise<void> => {
  // 未來30天的timeStamp
  const futureDate = new Date();
  futureDate.setDate(futureDate.getDate() + 30);
  // futureDate.setSeconds(futureDate.getSeconds() + 10);
  const futureTimestamp = futureDate.getTime().toString();
  await updateDoc(doc(db, firestorePath.USERS, uid), {
    lastSignInTime: new Date().toString(),
    lastSignInTimeStamp: Date.now().toString(),
  });
  localStorage.setItem('exp', futureTimestamp);
};
/* 獲取資料 */
export const getData = async (
  collectionName: string,
  documentName: string,
): Promise<any | undefined> => {
  try {
    const docRef = doc(db, collectionName, documentName);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      console.log('Firestore document not exists');
      // console.log("No such document!");
      return;
    }
    // eslint-disable-next-line consistent-return
    return docSnap.data();
  } catch (error) {
    console.log('【Firesotre Get Data Error】: ', error);
  }
};
/* 刪除資料 */
export const delData = async (
  collectionName: string,
  documentName: string,
): Promise<void> => {
  await deleteDoc(doc(db, collectionName, documentName));
};

/* 更新資料 */
export const updateData = async (
  collectionName: string,
  documentName: string,
  data: any,
): Promise<void> => {
  await updateDoc(doc(db, collectionName, documentName), data);
};

export const getMainSubject = async () => {
  const fbDataLayer = {
    collectionOne: 'setting',
    document: 'dataset',
    collectionTwo: 'mainSubject',
  };
  const { collectionOne, document, collectionTwo } = fbDataLayer;
  const q = query(
    collection(db, collectionOne, document, collectionTwo),
    orderBy('seq', 'asc'),
  );

  return new Promise((resolve) => {
    onSnapshot(q, (snapshot) => {
      const sheets: { id: string; enable?: string }[] = [];
      snapshot.forEach((tmpDoc) => {
        sheets.push({ id: tmpDoc.id, ...tmpDoc.data() });
      });
      const enabledSheets = sheets.filter((sh) => sh.enable !== DisableMS);
      return !isEmpty(enabledSheets) ? resolve(enabledSheets) : resolve([]);
      // : resolve({ error: "doc or collection doesn't exist" });
    });
  });
};

/* 編輯資料時只更新name mail */
export const updateFirestoreUserData = async ({
  firebaseID,
  contactName,
  contactMail,
}: {
  firebaseID: string;
  contactName: string;
  contactMail: string;
}): Promise<void> => {
  await updateDoc(doc(db, firestorePath.USERS, firebaseID), {
    contactName,
    contactMail,
  });
};

export const getSingleLayerCollection = (collectionName: string) => {
  const q = query(collection(db, collectionName));
  return new Promise((resolve) => {
    onSnapshot(q, (querySnapshot) => {
      const sheets: { id: string }[] = [];
      querySnapshot.forEach((tmpDoc) => sheets.push({ id: tmpDoc.id, ...tmpDoc.data() }));
      return resolve(sheets);
    });
  });
};

export const getSheets = (): Promise<Array<Partial<SheetDataProp>> | []> => {
  const fbDataLayer = {
    collectionOne: 'setting',
    document: 'dataset',
    collectionTwo: 'sheet',
  };
  const { collectionOne, document, collectionTwo } = fbDataLayer;
  const q = query(
    collection(db, collectionOne, document, collectionTwo),
    orderBy('order', 'asc'),
  );

  return new Promise((resolve) => {
    onSnapshot(q, (querySnapshot) => {
      const sheets: { id: string }[] = [];
      querySnapshot.forEach((tmpDoc) => sheets.push({ id: tmpDoc.id, ...tmpDoc.data() }));
      return !isEmpty(sheets) ? resolve(sheets) : resolve([]);
    });
  });
};
