import React, { Dispatch, SetStateAction } from 'react';
// component
import EasyButton from '../../../../../commons/components/EasyButton/EasyButton';
import EasyAvatar from '../../../../../commons/components/EasyAvatar/EasyAvatar';
// mui
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Chip, useTheme } from '@mui/material';
// config
import { auth } from '../../../../../config/config-firebase';
import { useIntl } from 'react-intl';
import { pageMode, STATUS_COLOR, UPLOAD_CARD_STATUS } from '../config';
/* images */
import CheckMarkIcon from '../../../../../images/setting/CheckMarkIcon.svg';
import PendingIcon from '../../../../../images/setting/PendingIcon.svg';

interface UploadCardProps {
  mode:string;
  setMode: Dispatch<SetStateAction<string>>;
  uploadCardStatus:string;
}

const UploadCard :React.FC<UploadCardProps> = ({
  mode, setMode, uploadCardStatus,
}):React.JSX.Element => {
  /** react intl */
  const { formatMessage } = useIntl();
  const id2text = (id:string) => formatMessage({ id });
  //
  const theme = useTheme();
  const getChipTheme = (status:string) => {
    switch (status) {
      case UPLOAD_CARD_STATUS.PENDING:
        return {
          backgroundColor: theme.palette.tertiary['50'],
          dotColor: theme.palette.tertiary.main,
          icon: <img alt={id2text('setting.upload.card.status.pending')} src={PendingIcon} />,
          text: id2text('setting.upload.card.status.pending'),
        };
      // case STATUS_COLOR.GREY:
      //   return {
      //     backgroundColor: theme.palette.neutral['50'],
      //     dotColor: theme.palette.neutral['400'],
      //   };
      // case STATUS_COLOR.GREEN:
      //   return {
      //     backgroundColor: theme.palette.secondary['50'],
      //     dotColor: theme.palette.secondary.main,
      //   };
      default: return {
        backgroundColor: theme.palette.primary['50'],
        dotColor: theme.palette.primary.main,
        icon: <img alt={id2text('setting.upload.card.status.approved')} src={CheckMarkIcon} />,
        text: id2text('setting.upload.card.status.approved'),
      };
    }
  };
  return (
    <Grid
      mt="1rem"
      sx={{
        borderRadius: '0.5rem',
        border: 'solid #DEF7F7 2px',
        backgroundColor: 'linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6) ,linear-gradient(0deg, #DEF7F7, #DEF7F7)',
      }}
    >
      <Grid padding="1.5rem">
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" flexDirection="column" justifyContent="space-between">
            <Typography variant="h6">
              {id2text('setting.upload.card.title')}
            </Typography>
            <Box mt="0.5rem">
              <Chip
                size="small"
                icon={getChipTheme(uploadCardStatus).icon}
                label={(
                // <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="caption" color="neutral.max" display="flex" justifyContent="center" alignItems="center">
                    {getChipTheme(uploadCardStatus).text}
                  </Typography>
                // </Box>
                )}
                sx={{ backgroundColor: `${getChipTheme(uploadCardStatus).backgroundColor}` }}
              />
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <EasyButton
              size="large"
              variant="contained"
              onClick={() => {
                setMode(pageMode.UPLOAD);
                window.scrollTo(0, 0);
              }}
            >
              <Typography variant="buttonText1">
                {id2text('setting.upload.button')}
              </Typography>
            </EasyButton>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default UploadCard;
