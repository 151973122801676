const rtName = {
  HOME: "/",
  NOTFOUND: "/NotFound",
  SIGNIN: "/signIn",
  SIGNUP: "/signUp",
  VERIFYACCOUNT: "/signUp/verifyAccount",
  FORGOTPASSWORD: "/forgotPassword",
  USERSTATECHANGE: "/userStateChange",
  MEMBERSTATECHANGE: "/memberStateChange",
  VERIFYEMAIL: "/memberStateChange/verifyEmail",
  RESETPASSWORD: "/memberStateChange/resetPassword",
  CHANGE_PASSWORD: "/memberStateChange/changePassword",
  LINKUSER: "/linkUser",
  // USERDASHBOARD: '/userDashboard',
  REDIRECTION: "/redirection",
  // legal and sub routes
  LEGAL: "/legal/*", // for index and children routes
  SERVICE: "/legal/service",
  POLICY: "/legal/policy",
  CONSOLE: "/console/*", // for index and children routes
  // Payment
  PAYMENT: "/payment",
  // console and sub routes
  SIGNUP_SUCCESS_REDIRECT: "/redirection", // 註冊成功後導向
  SIGNIN_SUCCESS_REDIRECT: "/redirection", // 登入成功後導向
  // dashboard
  DASHBOARD_SETTINGS: "/userDashboard/Settings",
  DASHBOARD_OUTSOURCING_RECYCLING: "/userDashboard/outsourcingRecycling",
  DASHBOARD_CUSTOMER_MANAGEMENT: "/userDashboard/management/customer",
  // EasyCycle
  EASYCYCLE: "https://www.easycycle.online",
  // warehouse
  MARK: "/mark",
  WORKORDERS: "/workOrders",
};

const routes = {
  ...rtName,
  HIDDENBUTTONSPATHS: [
    rtName.SIGNIN,
    rtName.SIGNUP,
    rtName.VERIFYACCOUNT,
    rtName.FORGOTPASSWORD,
    rtName.MEMBERSTATECHANGE,
    rtName.VERIFYEMAIL,
    rtName.RESETPASSWORD,
    rtName.LINKUSER,
    rtName.REDIRECTION,
    rtName.CHANGE_PASSWORD,
  ],
  HIDDEN_SIGNUP_BTN: [
    rtName.HOME,
    rtName.LEGAL,
    rtName.SERVICE,
    rtName.POLICY,
  ] as string[],
  HIDDEN_LOGIN_BTN: [] as string[],
  EXPAND_NAVBAR: [
    rtName.DASHBOARD_SETTINGS,
    rtName.DASHBOARD_CUSTOMER_MANAGEMENT,
    rtName.DASHBOARD_OUTSOURCING_RECYCLING,
    rtName.MARK,
  ] as string[],
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default routes;
