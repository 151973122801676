import React from 'react';

// Images
import PlusIcon from '../../../images/icons/icon_plus/plus.svg';

// configs
enum IconColor {
  grey = 'grey',
  black = 'black',
  primary = 'primary',
}
enum IconSize {
  xl = 32,
  lg = 28,
  md = 24,
  sm = 20,
  xs = 16,
}

const imageSrc: { [key in IconColor]: string } = {
  [IconColor.grey]: PlusIcon,
  [IconColor.black]: PlusIcon,
  [IconColor.primary]: PlusIcon,
};

// Typescript
type ValidSize = keyof typeof IconSize;

interface EasyPlusIconProps {
  color?: IconColor;
  size?: ValidSize;
  width?: number;
  height?: number;
  style?: React.CSSProperties;
}

const EasyPlusIcon: React.FC<EasyPlusIconProps> = ({
  color,
  size = 'md',
  width,
  height,
  ...props
}): React.JSX.Element => (
  <img
    src={imageSrc[color ?? IconColor.primary]}
    width={width ?? IconSize[size]}
    height={height ?? IconSize[size]}
    alt="Icon"
    {...props}
  />
);

export default EasyPlusIcon;
