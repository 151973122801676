import type React from 'react';
import {
  useRef,
  useState,
  useEffect,
  type MouseEvent,
  type RefObject,
} from 'react';

interface UseCheckClickOutsideProps {
  ref: RefObject<HTMLInputElement> | null;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const useCheckClickOutside = (
  initialIsOpen = false,
): UseCheckClickOutsideProps => {
  const [isOpen, setIsOpen] = useState<boolean>(initialIsOpen);
  const ref = useRef<HTMLInputElement>(null);
  const handleClickOutside = (event: MouseEvent<HTMLButtonElement>): void => {
    if (ref?.current?.contains(event.target as Node) === false) setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside as () => void, true);
    return () => {
      document.removeEventListener(
        'click',
        handleClickOutside as () => void,
        true,
      );
    };
  }, []);
  return { ref, isOpen, setIsOpen };
};

export default useCheckClickOutside;
