const verifyEmailPage = {
    /* verify email */
    "verifyEmail.page.signUpSuccess.title":"Congratulations!",
    "verifyEmail.page.signUpSuccess.text":"Thank you for signing up! Due to EasyCycle policy, identity verification is required to use full service.",
    "verifyEmail.page.signUpSuccess.hint.text1":"It will automatically jump after 10 seconds.",
    "verifyEmail.page.signUpSuccess.hint.text2":"{redirectTime} s",
    "verifyEmail.page.signUpSuccess.button.text":"Go Now",
    "verifyEmail.page.verifyCode.title":"Verify your Email",
    "verifyEmail.page.verifyCode.label1":"Enter verification code.",
    "verifyEmail.page.verifyCode.label2":"Didn't receive the email?",
    "verifyEmail.page.verifyCode.sendingMail1":"Sending email...",
    "verifyEmail.page.verifyCode.sendingMail2":"Resend verification code ({resendTime}s)",
    "verifyEmail.page.verifyCode.resend.text":"Resend verification code",
    "verifyEmail.page.verifyCode.hint.good":"Verification successful.",
    "verifyEmail.page.verifyCode.hint.bad":"The verification code is invalid or incorrect. Please retry.",
    "verifyEmail.page.verifyCode.hint.netWorkError":"The system encountered an error. Please try again later.",
    /* 提示視窗 */
    "verifyEmail.modal.error.title":"Error",
    "verifyEmail.modal.error.text":"Email sending error, please try again later.",
}

export default verifyEmailPage
