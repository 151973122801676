'use client';

import React, { useEffect, useState } from 'react';

// utils
import handleUpdateEmailVerified from '../../../utils/handleUpdateEmailVerified';
import { delData, getData } from '../../../../../api/firebase/cloudFirestore';
// mui
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

// components
import OtpInput from '../../../../../commons/components/otpInput/OtpInput';
// hooks
import useScrollToTop from '../../../../../hooks/useScrollToTop';
// config
import firestorePath from '../../../../../api/firebase/cloudFirestore/path';
import { auth } from '../../../../../config/config-firebase';
import { useIntl } from 'react-intl';

interface VerifyingProps {
  isSendMailButtonDisabled: boolean;
  handleResendButton: () => void;
  resendTime: number;
  userEmail: string | null | undefined;
  setIsVerifySuccess: (e: boolean) => void;
  isSendingEmail: boolean;
}
const SettingVerifyingCode: React.FC<VerifyingProps> = ({
  isSendMailButtonDisabled,
  handleResendButton,
  resendTime,
  userEmail,
  setIsVerifySuccess,
  isSendingEmail,
}): React.JSX.Element => {
  /** react intl */
  const { formatMessage } = useIntl();
  const id2text = (id:string) => formatMessage({ id });
  //
  // 進入頁面往最上滾動
  useScrollToTop();
  // 驗證碼
  const [otp, setOtp] = useState('');
  const handleOtpChange = (value: string): void => {
    setOtp(value);
  };
  // 驗證碼是否正確
  const [isValidateVerifyCode, setIsValidateVerifyCode] = useState(true);
  // otp驗證觸發時disabled
  const [isOtpDisabled, setIsOtpDisabled] = useState(false);

  // 驗證碼輸入後提示文字
  interface HintProps {
    hintText: string;
    hintColor: string;
  }
  const hintText = {
    good: id2text('setting.modal.email.verify.hint.good'),
    bad: id2text('setting.modal.email.verify.hint.bad'),
    netWorkError: id2text('setting.modal.email.verify.hint.networkError'),
  };
  // 驗證碼輸入後提示文字
  const [hint, setHint] = useState<HintProps | null>(null);

  // 驗證碼正確
  const document = firestorePath.USER_EMAIL_VERIFY_CODE;
  const verifyEmailCode = async (): Promise<void> => {
    const data = await getData(document, userEmail as string);
    if (data?.verifyCode === otp) {
      const currentTimestamp = Math.floor(Date.now());
      const firestoreTimestamp = data?.createdAt;
      const timeDifference = (currentTimestamp - parseInt(firestoreTimestamp, 10)) / 1000;
      if (timeDifference > 300) {
        setIsValidateVerifyCode(false);
        setHint({ hintText: hintText.bad, hintColor: 'red' });
        setIsOtpDisabled(false);
        return;
      }
      setHint({ hintText: hintText.good, hintColor: 'green' });

      // 驗證成功刪除驗證碼資料
      const res = await handleUpdateEmailVerified(userEmail as string);
      if (res) {
        setHint({ hintText: hintText.good, hintColor: 'green' });
        await delData(document, userEmail as string);
        /* 驗證成功後auth reload */
        await auth.currentUser?.reload();
        setTimeout(() => {
          setIsVerifySuccess(true);
        }, 500);
      } else {
        setOtp('');
        setIsOtpDisabled(false);
        setIsValidateVerifyCode(false);
        setHint({ hintText: hintText.netWorkError, hintColor: 'red' });
      }
    } else {
      setOtp('');
      setIsOtpDisabled(false);
      setIsValidateVerifyCode(false);
      setHint({ hintText: hintText.bad, hintColor: 'red' });
    }
  };

  // 驗證碼滿4碼自動發送
  useEffect(() => {
    if (otp.length !== 0) {
      // remove hint after typing
      setHint(null);
      if (otp.length === 4) {
        setIsOtpDisabled(true);
        verifyEmailCode();
      }
    }
  }, [otp]);

  // 暫時寫死style
  const pageStyle = {
    box_width: {
      xl: '23rem',
      lg: '23rem',
      md: '20.5rem',
      sm: '19.5rem',
      xs: '19.5rem',
    },
    box_height: {
      xl: '18rem',
      lg: '18rem',
      md: '18rem',
      sm: '18rem',
      xs: '18rem',
    },
    title_mt: {
      xl: '4rem',
      lg: '4rem',
      md: '4rem',
      sm: '4rem',
      xs: '4rem',
    },
    subtitle_mt: {
      xl: '2.5rem',
      lg: '2.5rem',
      md: '2.5rem',
      sm: '2.5rem',
      xs: '2.5rem',
    },
    otp_mt: {
      xl: '1rem',
      lg: '1rem',
      md: '1rem',
      sm: '1rem',
      xs: '1rem',
    },
    hint_mt: {
      xl: '1rem',
      lg: '1rem',
      md: '1rem',
      sm: '1rem',
      xs: '1rem',
    },
    resendArea_mt: {
      xl: '1rem',
      lg: '1rem',
      md: '1rem',
      sm: '1rem',
      xs: '1rem',
    },
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Box width={pageStyle.box_width} height={pageStyle.box_height}>
        <Box display="flex" justifyContent="center">
          <Typography fontSize="700" variant="h5">{id2text('setting.modal.hint.email.verify.title')}</Typography>
        </Box>
        <Box mt={pageStyle.subtitle_mt}>
          <Typography>{id2text('setting.modal.verifyCode.label1')}</Typography>
        </Box>
        <Box mt={pageStyle.otp_mt} display="flex" justifyContent="start">
          <OtpInput
            handleOtpChange={handleOtpChange}
            isValidateVerifyCode={isValidateVerifyCode}
            setIsValidateVerifyCode={setIsValidateVerifyCode}
            isOtpDisabled={isOtpDisabled}
          />
        </Box>
        <Box mt={pageStyle.hint_mt} display="flex" justifyContent="start">
          <Typography variant="subtitle2" sx={{ color: hint?.hintColor }}>
            {hint?.hintText}
          </Typography>
        </Box>
        <Box
          mt={pageStyle.resendArea_mt}
          display="flex"
          justifyContent="start"
          flexDirection={{
            xl: 'row',
            lg: 'row',
            md: 'column',
            sm: 'column',
            xs: 'column',
          }}
        >
          <Typography variant="subtitle2">
            {id2text('setting.modal.verifyCode.label2')}
          </Typography>
          {isSendMailButtonDisabled ? (
            <Typography
              variant="subtitle2"
              color={isSendingEmail ? 'primary' : 'grey'}
              sx={{
                marginLeft: {
                  xl: '0.5rem',
                  lg: '0.5rem',
                  md: '0',
                  sm: '0',
                  xs: '0',
                },
              }}
            >
              {isSendingEmail
                ? id2text('setting.modal.email.verify.hint.sendingMail1')
                : formatMessage({ id: 'setting.modal.email.verify.hint.sendingMail2' }, { resendTime })}
            </Typography>
          ) : (
            <button
              type="button"
              style={{
                border: 0,
                backgroundColor: 'white',
                cursor: 'pointer',
              }}
              onClick={() => {
                handleResendButton();
                // remove hint after resending verify code
                setHint(null);
              }}
              disabled={isSendMailButtonDisabled}
            >
              <Typography
                variant="subtitle2"
                color="primary"
                display="flex"
                justifyContent="start"
                sx={{
                  marginLeft: {
                    xl: '0.5rem',
                    lg: '0.5rem',
                    md: '0',
                    sm: '0',
                    xs: '0',
                  },
                }}
              >
                {id2text('setting.modal.email.verify.resend.text')}
              </Typography>
            </button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SettingVerifyingCode;
