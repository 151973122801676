import React from 'react';
import { Container, type ContainerProps } from '@mui/material';

interface EasyContainerProps extends ContainerProps {
  children: React.ReactNode;
}

const EasyContainer: React.FC<EasyContainerProps> = ({
  sx,
  maxWidth = false,
  children,
  disableGutters = true,
  ...props
}): React.JSX.Element => (
  <Container
    sx={{
      minHeight: '100vh',
      ...(!maxWidth && {
        maxWidth: {
          xl: '1280px',
          lg: '1136px',
        },
      }),
      padding: {
        xl: '4rem 0',
        lg: '4rem 0',
        md: '4rem 48px',
        sm: '4rem 48px',
        xs: '4rem 24px',
      },
      background: 'white',
      ...sx,
    }}
    maxWidth={maxWidth}
    disableGutters={disableGutters}
    {...props}
  >
    {children}
  </Container>
);

export default EasyContainer;
