import React from 'react';

// MUI
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';

//
import { auth } from '../../../config/config-firebase';
// configs
import routes from '../../../config/routes';

// components
import UserMenu from './UserMenu';
import EasyButton from '../EasyButton/EasyButton';
import MenuDrop from './MenuDrop';
import {
  useAppSelector,
  useAppDispatch,
} from '../../../reduxStore/utils/reduxHooks';

//
import services from '../../../api/services';

// css
import './Navbar.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';

// img
// import EasyCycleLogo from '../../../images/navbarIcon/logo.png';
import EasyLogoDesk from '../../../images/navbarIcon/desktop/Logo@3x.png';
import EasyLogoMob from '../../../images/navbarIcon/Mobile/Logo.svg';

//
import { getLangLabel } from '../../../intl18Store/languageTools';
import SignOut from '../../../pages/Users/utils/signOut';
import { useMediaQuery, useTheme } from '@mui/material';

//
const MenuListItemText = (text: string): React.JSX.Element => (
  <ListItemText primary={text} />
);

// typing
type HandleClick = (event: React.MouseEvent<HTMLElement>) => void;

function Navbar(): React.JSX.Element {
  const navigation = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const isConsolePage = location.pathname.toLowerCase().indexOf('console') >= 0;
  const userData = useAppSelector((state) => state.userDataStore);
  const dispatch = useAppDispatch();
  //
  // const userEmail = userData?.email;

  const theme = useTheme();
  // const breakPoint = useBreakPoint();
  //
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleSignIn = (): void => {
    navigation(`/${services.getLocale()}${routes.SIGNIN}`);
  };
  const handleSignUp = (): void => {
    navigation(`/${services.getLocale()}${routes.SIGNUP}`);
  };
  /* 加入語系的 navbar 按鈕隱藏 */
  const localeHiddenButtonPaths = routes.HIDDENBUTTONSPATHS.map(
    (r) => `/${services.getLocale()}${r}`,
  );

  /// /////////=======================
  // language btn
  const langBtn = (fullWidth = false): React.JSX.Element => (
    <EasyButton
      size="large"
      sx={{
        color: 'neutral.max',
        marginRight: '0.5rem',
        textTransform: 'capitalize',
        width: 'auto',
      }}
      fullWidth={fullWidth}
      disableRipple
    >
      <Typography>{getLangLabel(services.getLocale())}</Typography>
    </EasyButton>
  );

  // user avatar
  const userBtn = (): React.JSX.Element => (
    <Box
      sx={{
        marginLeft: {
          xl: '0.5rem',
          lg: '0.5rem',
          md: '0.5rem',
          sm: '-1.5rem',
          xs: '-1.5rem',
        },
      }}
    >
      <UserMenu isLogin={userData?.groupRole != null} />
    </Box>
  );

  // login btn
  const logInBtn = (fullWidth = false): React.JSX.Element => (
    <EasyButton
      size="large"
      variant="contained"
      onClick={handleSignIn}
      fullWidth={fullWidth}
    >
      Log in
    </EasyButton>
  );

  // signup btn
  const signUpBtn = (fullWidth = false): React.JSX.Element => (
    <EasyButton
      size="large"
      onClick={handleSignUp}
      sx={{
        color: 'neutral.max',
        marginRight: '0.5rem',
      }}
      fullWidth={fullWidth}
    >
      Sign up
    </EasyButton>
  );

  // Expand Navbar width
  const isExpandNavbarWidth = routes.EXPAND_NAVBAR.some((route) => pathname.includes(route));

  // 控制(顯示/隱藏)的變數
  const isUserLogin = auth.currentUser != null || userData != null;
  const showBtnThisPage = pathname && !localeHiddenButtonPaths.includes(pathname);
  // user avatar
  const showUser = showBtnThisPage && isUserLogin;
  const showUserDesk = !isMobile && showUser;
  // language btn
  const showLang = false; // TODO: 等語系建置
  const showLangDesk = !isMobile && showLang;
  // signup btn
  const showSignUp = showBtnThisPage
    && !isUserLogin
    && !routes.HIDDEN_SIGNUP_BTN.map(
      (r) => `/${services.getLocale()}${r}`,
    ).includes(pathname);
  const showSignUpDesk = !isMobile && showSignUp;
  // login btn
  const showLogIn = showBtnThisPage && !isUserLogin;
  const showLogInDesk = !isMobile && showLogIn;
  //
  const handleSignOut: HandleClick = async (): Promise<void> => {
    await SignOut(navigation, dispatch);
  };
  const handleGoToDashboard: HandleClick = (): void => {
    if (userData?.groupRole == null) {
      navigation(`/${services.getLocale()}${routes.REDIRECTION}`);
      return;
    }
    navigation(`/${services.getLocale()}${routes.DASHBOARD_SETTINGS}`);
  };

  const showDashBoardPage = process.env.REACT_APP_USER_DASHBOARD_PAGE_PUBLIC === 'true';

  // menuDrop(漢堡選單) 上方 list config
  const menuDropTop: Array<{
    key: string | number;
    component: React.JSX.Element;
    show: boolean;
    onClick: HandleClick;
  }> = [
    {
      key: 0,
      component: MenuListItemText('My order'),
      onClick: handleGoToDashboard,
      show: isUserLogin && showDashBoardPage,
    },
    {
      key: 1,
      component: MenuListItemText('Setting'),
      onClick: handleGoToDashboard,
      show: isUserLogin && showDashBoardPage,
    },
  ];
  // filter only show
  const menuDropTopShow = menuDropTop.filter((item) => item.show);

  // menuDrop(漢堡選單) 下方 list config
  const menuDropBottom = [
    {
      key: 'logInBtn',
      component: (
        <Box sx={{ paddingX: 2, paddingY: 1, width: '100%' }}>
          {logInBtn(true)}
        </Box>
      ),
      show: showLogIn,
      isBtn: true,
    },
    {
      key: 'signUpBtn',
      component: signUpBtn(true),
      show: showSignUp,
      isBtn: true,
    },
    {
      key: 2,
      component: MenuListItemText('Sign out'),
      onClick: handleSignOut,
      show: isUserLogin,
      isBtn: false,
    },
  ];
  // filter only show
  const menuDropBottomShow = menuDropBottom.filter((item) => item.show);

  // menuDrop(漢堡選單) 上方 list
  const menuDropTopEle = menuDropTopShow.length > 0 && (
    <List>
      {menuDropTopShow.map((item) => (
        <ListItem key={item.key} disablePadding>
          <ListItemButton
            onClick={(e) => {
              item.onClick(e);
            }}
          >
            {item.component}
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );

  // menuDrop(漢堡選單) 下方 list
  const menuDropBottomEle = menuDropBottomShow.length > 0 && (
    <List>
      {menuDropBottomShow.map((item) => (
        <ListItem key={item.key} disablePadding>
          {(item.isBtn && item.component) || (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            <ListItemButton
              {...(item.onClick
                ? {
                  onClick: (e) => {
                    item.onClick(e);
                  },
                }
                : {})}
            >
              {item.component}
            </ListItemButton>
          )}
        </ListItem>
      ))}
    </List>
  );

  return (
    <Box
      sx={{
        width: '100%',
        height: { lg: '4.6875rem', md: '4.6875rem', sm: '4rem' }, // 75px 75px 64px
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        padding: {
          xl: isExpandNavbarWidth ? '17.5px 1.5rem' : '0.75rem 0', // 24px 0 / 12px 0
          lg: isExpandNavbarWidth ? '17.5px 1.5rem' : '0.75rem 0', // 24px 0 / 12px 0
          md: '0.75rem 3rem', // 12px 48px
          sm: '0.75rem 1.5rem', // 12px 24px
        },
      }}
    >
      <Grid
        container
        width={{
          xl: isExpandNavbarWidth ? '100%' : '1280px',
          lg: isExpandNavbarWidth ? '100%' : '1136px',
          md: '100%',
          sm: '100%',
        }}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid
          item
          xs={3}
          sm={6}
          md={6}
          lg={3}
          onClick={() => {
            // navigation(`/${services.getLocale()}${routes.HOME}`);

            // temporarily redirect to EasyCycle
            window.location.href = routes.EASYCYCLE;
          }}
          alignSelf="center"
        >
          {!isConsolePage && (
            <Box display="flex" alignItems="center" sx={{ cursor: 'pointer' }}>
              <img
                alt="Logo"
                width={isMobile ? 43 : 252}
                height={isMobile ? 40 : 40}
                src={isMobile ? EasyLogoMob : EasyLogoDesk}
                className=".logo"
              />
            </Box>
          )}
        </Grid>
        {isMobile && (menuDropTopEle || menuDropBottomEle) && (
          <Grid item xs={1} sm={2} md={6} lg={9}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <MenuDrop
                menuTop={menuDropTopEle}
                menuBottom={menuDropBottomEle}
              />
            </Box>
          </Grid>
        )}
        {!isMobile && (
          <Grid
            item
            md={6}
            xs={4}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
          >
            {showLangDesk && langBtn()}
            {showUserDesk && userBtn()}
            {showSignUpDesk && signUpBtn()}
            {showLogInDesk && logInBtn()}
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default Navbar;
