const linkUserPage = {
    /* link user */
    "linkUser.page.beforeLink.title": "連結您的帳戶",
    "linkUser.page.beforeLink.text": "已有一個帳號與此電子信箱關聯。我們可以將您的Google帳戶連接到此帳戶。",
    "linkUser.page.beforeLink.button.connect": "連接",
    "linkUser.page.beforeLink.button.cancel": "取消",
    "linkUser.page.afterLink.title": "連結成功！",
    "linkUser.page.afterLink.text": "您的帳戶已成功連結。請重新登入。",
    "linkUser.page.afterLink.button.text": "回到登入",
    /* 連結帳戶 錯誤提示視窗 */
    "linkUser.modal.systemError.title": "系統錯誤",
    "linkUser.modal.systemError.text": "系統錯誤。請稍後再試。",
}

export default linkUserPage
