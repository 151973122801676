import React, {
  useEffect, useRef, useState,
} from 'react';
import EasyDialog from '../EasyDialog';
import { Box } from '@mui/material';
import format from 'date-fns/format';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { useAppSelector } from '../../../../reduxStore/utils/reduxHooks';
import EasyMarkdownReader from '../../EasyMarkdownReader/EasyMarkdownReader';
import { ContractManagementProps } from '../../../../types/contractManagement/TypescriptProps';

interface ContractModalProps {
  isOpen:boolean;
  setIsOpen:(e:boolean)=>void;
  isLoading:boolean;
  setIsLoading:(e:boolean)=>void;
  action?:()=>void;
  secondaryAction?:() => void | ((e: boolean) => void) | Promise<void> ;
  type:'edit' | 'read';
}
const actionLabel = 'Agree';
const secondaryActionLabel = 'Decline';

const Loading = () => (
  <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="50vh">
    <CircularProgress size="10rem" />
  </Box>
);

const ContractModal:React.FC<ContractModalProps> = ({
  isOpen, setIsOpen, isLoading, setIsLoading, action, type, secondaryAction,
}) => {
  const contractData = useAppSelector((state) => state.selectedContractStore);
  const [selectedContract, setSelectedContract] = useState<ContractManagementProps | null>(null);
  const [isButtonDisable, setIsButtonDisable] = useState(true);
  const contentRef = useRef<HTMLDivElement>(null);

  // 添加滾輪事件監聽器
  useEffect(() => {
    const handleScroll = () => {
      const box = contentRef.current;
      if (box) {
        const { scrollTop, clientHeight, scrollHeight } = box;
        if (scrollHeight > clientHeight) {
          const isNearBottom = scrollHeight - scrollTop - clientHeight <= 10;
          setIsButtonDisable(!isNearBottom);
        } else {
          setIsButtonDisable(false); // 内容沒超過視窗高度，啟用按鈕
        }
      }
    };
    contentRef.current?.addEventListener('scroll', handleScroll);
    // 在組件卸載時移除事件監聽器
    return () => {
      contentRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, [contentRef.current, isLoading]);

  /* 塞contract 資料 */
  useEffect(() => {
    if (contractData) {
      setSelectedContract(contractData);
      setIsLoading(false);
    }
  }, [contractData]);

  return (
    <EasyDialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      showCloseIcon={type !== 'edit'}
      dialogBodySX={{ marginTop: '0', width: '100%' }}
      type="contract"
      sx={{
        minHeight: {
          xl: '75vh',
          lg: '75vh',
          md: '75vh',
          sm: '75vh',
          xs: '75vh',
        },
        minWidth: {
          xl: '40vw',
          lg: '40vw',
          md: '40vw',
          sm: '90vw',
          xs: '90vw',
        },
      }}
      body={isLoading ? <Loading /> : (
        <Box width="100%">
          {/* 標題 */}
          <Typography variant="h5" width="100%" display="flex" justifyContent="center">
            {selectedContract?.contractName}
          </Typography>
          {/* 時間 */}
          <Typography color="grey.600" mt="0.5rem" variant="body1" width="100%" display="flex" justifyContent="center">
            {typeof selectedContract?.releaseTime === 'string' ? format(parseInt(selectedContract?.releaseTime, 10), 'MMMM d, yyyy') : ''}
          </Typography>
          <Box textAlign="start" mt="1.5rem" sx={{ maxHeight: '40vh', overflowY: 'auto' }} ref={contentRef}>
            <EasyMarkdownReader>
              {selectedContract?.contractContent}
            </EasyMarkdownReader>
          </Box>
        </Box>
      )}
      dialogActionSX={{ display: 'flex', justifyContent: 'space-between', marginTop: '1.5rem' }}
      actionLabel={actionLabel}
      secondaryLabel={secondaryActionLabel}
      action={type === 'edit' && !isLoading ? action : undefined}
      secondaryAction={type === 'edit' && !isLoading ? secondaryAction : undefined}
      buttonDisabled={isButtonDisable}
    />
  );
};

export default ContractModal;
