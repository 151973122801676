const editProfilePage = {
    /* edit profile */
    "editProfile.pageTitle":"編輯企業檔案",
    "editProfile.page.button.save":"儲存",
    "editProfile.page.button.edit":"編輯",
    "editProfile.page.button.cancel":"取消",

    /* 輸入框錯誤提示 */
    "editProfile.page.helperTexts.name":"無效的名稱，字數最多{USERNAME_LENGTH_LIMIT}字，不可輸入{ILLEGAL_CH}",
    "editProfile.page.helperTexts.mail":"無效的電子信箱",
    "editProfile.page.helperTexts.empty":"不可留空",
    "editProfile.page.helperTexts.country":"\"國家 / 地區\", \"州 / 省 / 市\" 為必填，請輸入內容。",
    "editProfile.page.helperTexts.unitPrice":"請確認國家或地區並輸入正確的貨幣金額。",
    /* 小標題 */
    "editProfile.title.introLang": "多語言簡介設定",
    "editProfile.title.companyName": "公司名稱",
    "editProfile.title.address": "地址",
    "editProfile.title.introduction": "簡介",
    "editProfile.title.pictures": "企業形象圖片",
    "editProfile.title.upload": "上傳檔案",
    "editProfile.title.regulations": "太陽能板包裝載運回收規範",
    "editProfile.title.solarPanelPrice": "太陽能板回收價格",
    "editProfile.title.recyclingHistory": "是否提供回收履歷服務？",
    "editProfile.title.payment": "付款",
    "editProfile.title.isFreeCancelOrder": "免費取消且全額退款",
    "editProfile.title.operatingHours": "營業時間",
    /* 介紹語言 */
    "editProfile.introLang.label": "語言",
    "editProfile.introLang.hint": "此區提供您填寫不同語言的簡介內容，請選擇欲填寫的語言並填寫。",
    /* 地址 */
    "editProfile.address.placeholder": "請輸入完整地址。",
    "editProfile.address.postalCode.label": "郵遞區號",
    "editProfile.address.country.label": "國家/地區",
    "editProfile.address.state.label": "州/省/市",
    "editProfile.address.city.label": "市/區",
    "editProfile.address.address1.label": "地址一",
    "editProfile.address.address2.label": "地址二",
    /* 上傳區 */
    "editProfile.uploadOr": "或",
    "editProfile.uploadButton.text": "新增圖片",
    "editProfile.uploadRules.rule1":"大小不可超過1MB。",
    "editProfile.uploadRules.rule2":"長寬比建議為16:9，超出範圍將自動裁切。",
    "editProfile.uploadRules.rule3":"格式僅限JPEG或PNG。",
    "editProfile.uploadRules.rule4":"一次最多可上傳8張圖片",
    /* 上傳圖片視窗 */
    "editProfile.modal.upload.error.title":"錯誤",
    "editProfile.modal.upload.error.uploadSizeExceeded":"圖片大小不可超過1MB。",
    "editProfile.modal.upload.error.uploadCountExceeded":"一次最多可上傳8張圖片。",
    "editProfile.modal.upload.error.uploadFormatError":"格式僅限JPEG或PNG。",
    "editProfile.modal.upload.error.uploadAspectError":"請上傳長寬比為16:9的圖片",
    /* 更新資料提示視窗 */
    "editProfile.modal.update.error.title":"錯誤",
    "editProfile.modal.update.error.text":"請調整錯誤內容",
    "editProfile.modal.update.success.title":"成功",
    "editProfile.modal.update.success.text":"資料更新成功",
    /* 營業日相關 */
    "editProfile.operatingHours.dayOff": "本日公休",
    "editProfile.operatingHours.to.label": "至",
    "editProfile.operatingHours.button.add.text": "新增",
    "editProfile.operatingHours.button.add.specialHoliday.text": "新增特殊營業時間",
    "editProfile.operatingHours.sun": "星期日",
    "editProfile.operatingHours.mon": "星期一",
    "editProfile.operatingHours.tue": "星期二",
    "editProfile.operatingHours.wed": "星期三",
    "editProfile.operatingHours.thu": "星期四",
    "editProfile.operatingHours.fri": "星期五",
    "editProfile.operatingHours.sat": "星期六",
    /* 規範相關 */
    "editProfile.regulations.placeholder": "輸入內容",
    /* 太陽能板單價 */
    "editProfile.unitPrice.label": "每片",
    /* 是否提供回收履歷 */
    "editProfile.yesProvideRecycle.history": "是",
    "editProfile.noProvideRecycle.history": "否，目前暫無提供回收履歷",


    /* V2 */
    "editProfile.page.description": "完成填寫下方資料可在EasyCycle Online上提供太陽能板回收預約服務。",
    "editProfile.unit.vehicle": "每輛",
    "editProfile.unit.distance.mile": "每英里",
    "editProfile.unit.day": "每日",
    "editProfile.label.orders": "訂單",
    "editProfile.label.pieces": "片",
    "editProfile.label.ton": "噸",
    "editProfile.label.price": "價格",
    "editProfile.label.setting": "設定",

    "editProfile.modal.title.confirm": "確認企業檔案",
    "editProfile.modal.content.confirm": "確認後，我們將儘快審核您的企業檔案。如果有任何問題，歡迎寄信至service@easycycle.online。",
    "editProfile.modal.action.confirm": "確認",
    "editProfile.modal.secondaryAction.confirm": "取消",

    "editProfile.modal.title.openBusiness": "開啟回收服務",
    "editProfile.modal.content.openBusiness": "重新開啟回收服務後，您將可以在EasyCycle Online接受回收預約。",
    "editProfile.modal.action.openBusiness": "確認",
    "editProfile.modal.secondaryAction.openBusiness": "取消",

    "editProfile.modal.hint.error.title": "資料更新錯誤",
    "editProfile.modal.hint.error.text": "抱歉，更新資料時出現問題。請稍後再試。",
    "editProfile.modal.hint.success.title": "更新成功",
    "editProfile.modal.hint.success.text": "資訊更新成功",

    /* V2 Factory Address */
    "editProfile.title.factoryAddress": "工廠地址",
    "editProfile.factoryAddress.modal.title": "編輯工廠地址",
    "editProfile.factoryAddress.modal.action": "儲存",
    "editProfile.factoryAddress.modal.secondaryAction": "取消",

    /* V2 Service Scope */
    "editProfile.title.serviceScope": "服務範圍",
    "editProfile.label.serviceScope.hint": "點擊選擇縣或市",
    "editProfile.label.serviceScope.scope": "您的服務國家或地區：",
    "editProfile.label.serviceScope.selected": "已選擇：",
    "editProfile.label.scopeLimit": "請先輸入工廠地址並取得服務國家或地區。",
    "editProfile.modal.title.serviceScope": "新增服務範圍",
    /* V2 Shipping */
    "editProfile.title.shipping": "運費",
    "editProfile.tooltip.shippingFee.format": "( 基本費用 + 里程數 * 2 * 里程單價 ) * ( 回收數量 / 每車負載數 )",
    "editProfile.label.baseFee": "基本費用",
    "editProfile.label.distanceFee": "距離費用",
    "editProfile.label.loadCapacity": "每車負載數",
    /* V2 Recycling Passport */
    "editProfile.title.recyclingPassport": "回收履歷",
    "editProfile.description.recyclingPassport": "回收履歷需透過PV Circulator設備產出。如果您想提供回收履歷，請透過租賃合約租賃設備。",
    "editProfile.checkbox.provideRecyclingPassport": "我要提供回收履歷。",
    "editProfile.link.underStanding": "了解回收履歷",
    "editProfile.modal.title.recyclingPassportInfo": "什麼是回收履歷?",
    "editProfile.modal.content.recyclingPassportInfo1": "回收履歷完整記錄了從您下訂單開始到太陽能板回收結束的每張圖片和詳細資訊。",
    "editProfile.modal.content.recyclingPassportInfo2": "我們的承諾是為您提供準確且具有洞察力的訊息，以便您詳細檢查與回收過程相關的碳足跡。",
    "editProfile.imgAlt.recyclingPassportSample": "回收履歷",

    /* V2 Reservation ActiveNotice */
    "editProfile.title.reservationNotice": "預約通知",
    "editProfile.modal.title.reservationNotice.preview": "預覽預約通知呈現",
    /* V2 Business Hours */
    "editProfile.title.businessHours": "營業時間",
    "editProfile.title.regularBusinessHours": "一般營業時間",
    "editProfile.title.specialBusinessHours": "特殊營業時間",
    "editProfile.td.null": "目前暫無特殊營業時間",
    "editProfile.th.date": "日期",
    "editProfile.th.time": "時間",
    "editProfile.th.openOrDayOff": "營業日/公休日",
    "editProfile.th.edit": "編輯",
    "editProfile.th.delete": "刪除",
    "editProfile.label.dayOff": "公休日",
    "editProfile.label.dayOpen": "營業日",
    "editProfile.button.addSpecialBusiness": "新增特殊營業時間",
    "editProfile.modal.title.add": "新增特殊營業時間",
    "editProfile.modal.title.edit": "編輯特殊營業時間",
    "editProfile.modal.title.delete": "刪除特殊營業時間",
    "editProfile.modal.label.dayOff": "特殊營業時間設定",
    "editProfile.modal.label.date": "日期",
    "editProfile.modal.label.time": "時間",
    "editProfile.modal.action.add": "儲存",
    "editProfile.modal.action.edit": "儲存",
    "editProfile.modal.action.delete": "刪除",
    "editProfile.modal.secondaryAction.add": "取消",
    "editProfile.modal.secondaryAction.edit": "取消",
    "editProfile.modal.secondaryAction.delete": "返回",


    /* V2 Solar Panel Load Quantity */
    "editProfile.title.loadQuantity": "太陽能板負載數量",
    "editProfile.label.stocking.quantity": "工廠可存放數量",
    "editProfile.label.stocking.weight": "工廠可存放重量",
    "editProfile.label.dailyOrderCapacity": "每日接單量",
    "editProfile.radioBox.unlimited": "不限制",
    "editProfile.radioBox.limited": "限制",
    /* V2 Active Notice */
    "editProfile.title.notice": "注意事項",
    "editProfile.description.notice.active": "如果您需要編輯企業檔案，請先關閉回收服務。請注意，關閉回收服務後將無法在EasyCycle Online接受回收預約。然而，如果用戶已經在預訂過程中，仍然可以向您預約回收服務。",
    "editProfile.description.notice.inActive": "回收服務目前已關閉。如果您想重新開啟回收服務，請打開 \"開啟回收服務\".",
    "editProfile.label.notice.switch": "開啟回收服務",
    "editProfile.link.notice.close": "關閉回收服務",
    "editProfile.modal.action.closeBusiness": "關閉回收服務",
    "editProfile.modal.secondaryAction.closeBusiness": "返回",
    "editProfile.modal.title.closeBusiness": "關閉回收服務",
    "editProfile.modal.content.closeBusiness": "關閉回收服務將導致您無法在EasyCycle Online上接受回收預約。\n關閉服務後，您仍可以重新開啟回收服務。",

    "editProfile.normal.dayOff.error": "Unable to change business day.The selected date has reservations, preventing the modification of the specified business day.",
    "editProfile.special.dayOff.error": "Failed to add a day off.\nThe selected date has reservations, preventing the creation of the specified day off."
}

export default editProfilePage
