const linkUserPage = {
    /* link user */
    "linkUser.page.beforeLink.title":"Connect Your Account",
    "linkUser.page.beforeLink.text":"There's an existing account associated with this email. We can connect your Google account to this account.",
    "linkUser.page.beforeLink.button.connect":"Connect",
    "linkUser.page.beforeLink.button.cancel":"Cancel",
    "linkUser.page.afterLink.title":"Connect successful!",
    "linkUser.page.afterLink.text":"Your account has been successfully linked. Please log in again.",
    "linkUser.page.afterLink.button.text":"Back to Login",
    /* 連結帳戶 錯誤提示視窗 */
    "linkUser.modal.systemError.title":"System Error",
    "linkUser.modal.systemError.text":"The system encountered an error. Please try again later.",
}

export default linkUserPage
