const signUpPage = {
    /* sign up */
    "signUp.page.title":"Create Your Account",
    "signUp.page.helperTexts.mail":"Email is not valid",
    /* 輸入欄位提示 */
    "signUp.page.helperTexts.password":"Your password must be at least 8 characters long and include uppercase letters, lowercase letters, and numbers.",
    "signUp.page.helperTexts.confirmPassword":"Password and confirm password do not match",
    "signUp.page.helperTexts.empty":"No whitespace allowed here",
    /* 輸入欄位 */
    "signUp.page.contactName.label":"Contact Name",
    "signUp.page.contactMail.label":"Email",
    "signUp.page.password.label":"Password",
    "signUp.page.confirmPassword.label":"Confirm Password",
    "signUp.page.policy.text1":"By creating an account, you agree to our ",
    "signUp.page.policy.text2":" Terms of Service",
    "signUp.page.policy.text3":"and",
    "signUp.page.policy.text4":"Privacy Policy.",
    "signUp.page.button.signUp":"Sign Up",
    "signUp.page.hasAccount.text":"Already on EasyCycle ?",
    "signUp.page.signIn.text":"Sign in",
    /* 註冊提示視窗 */
    "signUp.modal.exist.title":"Account already exists",
    "signUp.modal.exist.text":"The email address you provided is already in use. Please proceed to the login page to continue using your account.",
    "signUp.modal.exist.action":"Proceed to log in",
    "signUp.modal.exist.secondary.action":"I got it.",
    "signUp.modal.systemError.title":"System Error",
    "signUp.modal.systemError.text":"The system encountered an error. Please try again later.",
}

export default signUpPage
