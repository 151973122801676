import React from 'react';
import LoadingItem from './LoadingItem';

interface LoadingProps {
  title?:string;
  heightAuto?:boolean;
}
const Loading :React.FC<LoadingProps> = ({ title = 'Loading...', heightAuto }): React.JSX.Element => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: heightAuto ? 'auto' : '550px',
    }}
  >
    <div>
      <LoadingItem />
      <p style={{ textAlign: 'center' }}>{title}</p>
    </div>
  </div>
);

export default Loading;
