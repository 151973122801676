import React, { useEffect, useState } from 'react';

/* mui */
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
/* components */
import Loading from '../../../../commons/components/loading/Loading';
import UserCard from './subComponent/UserCard';
import DeleteAccountModal from '../../../Home/subComponent/DeleteAccountModal';
import SettingVerifyEmailModal from './subComponent/SettingVerifyEmailModal';
import ConfirmModal from './subComponent/ConfirmModal';
import EasyButton from '../../../../commons/components/EasyButton/EasyButton';
import CircularProgress from '@mui/material/CircularProgress';
import UploadDocumentArea from './subComponent/UploadDocumentArea';
import EasyDialog from '../../../../commons/components/modal/EasyDialog';
import InformationArea from './subComponent/InformationArea';
/* config */
import FirestoreUser from '../../TypescriptProps';
import {
  AddressProps, MAIN_TYPE, pageMode, UPLOAD_CARD_STATUS,
} from './config';
import { auth } from '../../../../config/config-firebase';
import userAct from '../../userAction';
/* utils */
import { useAppDispatch, useAppSelector } from '../../../../reduxStore/utils/reduxHooks';
import handleFusekiHandlerData from '../../utils/fuseki/handleFusekiHandlerData';
import { Formik, useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import { object, string } from 'yup';
/* images */
import UserAvatar from '../../../../images/navbarIcon/UserAvatar.png';
import UploadCard from './subComponent/UploadCard';
import SelectTabs from '../../../../commons/components/SelectTabs/SelectTabs';
import EditUserCard from './subComponent/EditUserCard';

const SettingPage = () => {
  /** react intl */
  const { formatMessage } = useIntl();
  const id2text = (id:string) => formatMessage({ id });
  //
  const regex = {
    mail: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    phone: /^\+?\d+$/,
    tax: /^\d+$/,
    license: /^\d+$/,
  };
  const validationSchema = object({
    companyName: string().required(id2text('setting.page.helperTexts.empty')),
    companyTel: string().required(id2text('setting.page.helperTexts.phone')).matches(regex.phone, id2text('setting.page.helperTexts.phone')),
    postalCode: string().required(id2text('setting.page.helperTexts.empty')),
    country: string().required(id2text('setting.page.helperTexts.empty')),
    state: string().required(id2text('setting.page.helperTexts.empty')),
    // city: string().required(helperTexts.empty),
    // address1: string().required(helperTexts.empty),
    // address2: string().required(helperTexts.empty),
    companyMail: string().required(id2text('setting.page.helperTexts.mail')).matches(regex.mail, id2text('setting.page.helperTexts.mail')),
    taxNumber: string().required(id2text('setting.page.helperTexts.empty')).matches(regex.tax, id2text('setting.page.helperTexts.tax')),
    licenseNumber: string().required(id2text('setting.page.helperTexts.empty')).matches(regex.license, id2text('setting.page.helperTexts.license')),
    contactName: string().required(id2text('setting.page.helperTexts.empty')),
    contactMail: string().required(id2text('setting.page.helperTexts.mail')).matches(regex.mail, id2text('setting.page.helperTexts.mail')),
    contactTel: string().required(id2text('setting.page.helperTexts.phone')).matches(regex.phone, id2text('setting.page.helperTexts.phone')),
  });
  //
  const [isLoading, setIsLoading] = useState(true);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  /* pageMode */
  const [mode, setMode] = useState(pageMode.SETTING);
  /* tabs */
  const tabsArr = [
    { label: `${id2text('setting.tabs.account')}`, value: MAIN_TYPE.ACCOUNT },
    {
      label: `${id2text('setting.tabs.notifications')} `,
      value: MAIN_TYPE.NOTIFICATIONS,
    },
  ];
  const [selectedType, setSelectedType] = useState(tabsArr[0].value);
  const handleTabsChanged = (type: string) => {
    if (type !== selectedType) {
      setSelectedType(type);
    }
  };
  /* upload card status */
  const [uploadCardStatus, setUploadCardStatus] = useState(UPLOAD_CARD_STATUS.PENDING);
  /* 使用者資料載入 */
  const userData = useAppSelector((state) => state.userDataStore);
  const dispatch = useAppDispatch();
  /* 使用者資料編輯輸入框 */
  const [user, setUser] = useState<FirestoreUser>({} as FirestoreUser);
  /* 設定頁驗證email modal */
  const [IsOpenEmailModal, setIsOpenEmailModal] = useState(false);
  /* 設定頁驗證email開啟modal */
  const handleOpenEmailModal = () => {
    setIsOpenEmailModal(true);
  };
  /* 編輯確定視窗 */
  const [isOpen, setIsOpen] = useState(false);
  /* 修改成功或是失敗 提示視窗 */
  const [isHintModalOpen, setIsHintModalOpen] = useState(false);
  /* 提示視窗文字 */
  const modalTextObj = {
    error: {
      title: id2text('setting.modal.update.error.title'),
      text: id2text('setting.modal.update.error.text'),
    },
    success: { title: id2text('setting.modal.update.success.title'), text: id2text('setting.modal.update.success.text') },
  };
  const [modalText, setModalText] = useState(modalTextObj.success);
  /* 開啟刪除帳號視窗 */
  const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] = useState(false);
  const showDelAccount = process.env.REACT_APP_DEL_ACCOUNT_FEA_PUBLIC === 'true';
  const handleDeleteAccountModal = (): void => {
    if (!showDelAccount) return;
    setIsDeleteAccountModalOpen(true);
  };
    // 地址由父組件傳入並且將值給formik
  const [address, setAddress] = useState<AddressProps>({} as AddressProps);
  const getUserData = async () => {
    if (auth?.currentUser?.uid) {
      await handleFusekiHandlerData.readData({ firebaseID: auth?.currentUser.uid }).then((res) => {
        if (res.data) {
          setUser(res.data);
          setAddress(
            {
              postalCode: res.data?.postalCode,
              country: res.data?.country,
              state: res.data?.state,
              city: res.data?.city,
              address1: res.data?.address1,
              address2: res.data?.address2,
              lat: res.data?.lat,
              long: res.data?.long,
            },
          );
          dispatch(userAct.FIREBASE_LOGIN_USER(res.data));
        }
      }).then(() => {
        setIsLoading(false);
      });
    }
  };

  /* 獲取userData */
  useEffect(() => {
    getUserData();
  }, []);

  // const [localUser,setLocalUser] = useState<FirestoreUser>({} as FirestoreUser);
  return (
    <>
      {/* 主頁面 */}
      <Formik
        initialValues={{
          companyName: user?.companyName ?? '',
          companyTel: user?.companyTel ?? '',
          postalCode: user?.postalCode ?? '',
          country: user?.country ?? '',
          state: user?.state ?? '',
          city: user?.city ?? '',
          address1: user?.address1 ?? '',
          address2: user?.address2 ?? '',
          taxNumber: user?.taxNumber ?? '',
          licenseNumber: user?.licenseNumber ?? '',
          contactName: user?.contactName ?? '',
          contactMail: user?.contactMail ?? '',
          contactTel: user?.contactTel ?? '',
          companyMail: user?.companyMail ?? '',
          lat: user?.lat ?? '',
          long: user?.long ?? '',
        }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          // console.log('values', values);
          if (userData && values) {
            setIsButtonLoading(true);
            try {
              await handleFusekiHandlerData.updateData({ firebaseID: userData.firebaseID, value: { ...values, ...address } }).then(async () => {
                await getUserData();
                setIsButtonLoading(false);
                setModalText(modalTextObj.success);
                setIsHintModalOpen(true);
              });
            } catch (err) {
              console.log(err);
            }
          }
        }}
        enableReinitialize
      >
        {({
          handleSubmit, errors, values, setTouched, validateForm,
        }) => {
          useEffect(() => {
            setUser((prev) => ({
              ...prev,
              companyName: values.companyName,
              companyMail: values.companyMail,
              companyTel: values.companyTel,
              taxNumber: values.taxNumber,
              licenseNumber: values.licenseNumber,
              contactName: values.contactName,
              contactMail: values.contactMail,
              contactTel: values.contactTel,
            } as FirestoreUser));
          }, [values]);
          useEffect(() => {
            setUser((prev) => ({
              ...prev,
              postalCode: address.postalCode,
              country: address.country,
              state: address.state,
              city: address.city,
              address1: address.address1,
              address2: address.address2,
            } as FirestoreUser));
          }, [address]);
          return (
            <form onSubmit={handleSubmit}>
              {/* 刪除帳號視窗 */}
              <DeleteAccountModal
                isOpen={isDeleteAccountModalOpen}
                setIsOpen={setIsDeleteAccountModalOpen}
              />
              {/* 設定頁面 email 驗證視窗 */}
              <SettingVerifyEmailModal
                isOpen={IsOpenEmailModal}
                setIsOpen={setIsOpenEmailModal}
              />
              {/* 設定頁確定修改視窗 */}
              <ConfirmModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                handleConfirm={handleSubmit}
              />
              {/* 提示modal */}
              <EasyDialog
                isOpen={isHintModalOpen}
                setIsOpen={setIsHintModalOpen}
                showCloseIcon
                title={modalText.title}
                body={(
                  <Typography variant="body1" my="6%">
                    {modalText.text}
                  </Typography>
                  )}
                sx={{
                  minHeight: '0vh',
                }}
              />
              <Box
                // p="1rem 1.5rem 4rem 1.5rem"
                width="100%"
                p={{
                  xl: '4rem 5rem 4rem 5rem',
                  lg: '4rem 5rem 4rem 5rem',
                  md: '1rem 1.5rem 4rem 1.5rem',
                  sx: '1rem 1.5rem 4rem 1.5rem',
                  xs: '1rem 1.5rem 4rem 1.5rem',
                }}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  minHeight: '86vh',
                }}
              >
                <Paper sx={{ width: '100%' }} elevation={0}>
                  <Toolbar
                    sx={{ display: 'flex', justifyContent: 'flex-start' }}
                    style={{ padding: '0 0 1.5rem 0' }}
                  >
                    <Typography variant="h5">{id2text('setting.page.title')}</Typography>
                  </Toolbar>
                  {isLoading ? (
                    <Loading />
                  ) : (
                    <Box>
                      {/* 副標題 */}
                      <Box>
                        <Typography variant="body1">
                          {id2text('setting.page.subTitle')}
                        </Typography>
                      </Box>
                      {/* email 未驗證提示 */}
                      {auth.currentUser?.emailVerified ? null : (
                        <Box mt="1.5rem">
                          <Typography variant="body1" color="error">
                            {id2text('setting.page.emailVerifiedHint')}
                          </Typography>
                        </Box>
                      )}
                      <Box my="1.5rem" width="100%">
                        <SelectTabs handleTabsChanged={handleTabsChanged} tabsArr={tabsArr} defaultValue={selectedType} />
                      </Box>
                      {
                            mode === pageMode.SETTING && (
                            <>
                              {/* 用戶卡片 */}
                              <UserCard
                                setMode={setMode}
                                imgSrc={UserAvatar}
                                email={auth.currentUser?.email as string}
                                handleOpenEmailModal={handleOpenEmailModal}
                                handleDeleteAccountModal={handleDeleteAccountModal}
                              />
                              {/*  上傳文件  */}
                              <Box mt="2.5rem">
                                <Typography variant="h6">
                                  {id2text('setting.company.document.title')}
                                </Typography>
                              </Box>
                              <Box mt="0.5rem">
                                <Typography variant="body1">
                                  {id2text('setting.company.document.description')}
                                </Typography>
                              </Box>
                              <UploadCard setMode={setMode} mode={mode} uploadCardStatus={uploadCardStatus} />
                              {/* <UploadDocumentArea /> */}
                              {/* form  */}
                              <InformationArea setAddress={setAddress} isButtonLoading={isButtonLoading} />
                              {/*  按鈕  */}
                              <Box width="100%" display="flex" justifyContent="end" mt="1rem">
                                <EasyButton
                                  variant="secondary"
                                  size="medium"
                                  sx={{
                                    width: '120px',
                                  }}
                                  disabled={!auth.currentUser?.emailVerified || isButtonLoading}
                                  onClick={async () => {
                                    await getUserData();
                                  }}
                                >
                                  <Typography>{id2text('setting.page.button.cancel')}</Typography>
                                </EasyButton>
                                <EasyButton
                                  variant="contained"
                                  size="medium"
                                  sx={{
                                    marginLeft: '0.5rem',
                                    width: '120px',
                                  }}
                                  disabled={!auth.currentUser?.emailVerified || isButtonLoading}
                                  onClick={async () => {
                                    // console.log('values', values);
                                    /* 去驗證檢查哪個輸入框是有errors的 */
                                    await validateForm(values).then((res) => {
                                      /* 如果有errors，去touched 顯示errors */
                                      setTouched({
                                        ...Object.keys(values).reduce((acc, fieldName) => ({ ...acc, [fieldName]: true }), {}),
                                      });
                                      /* 如果有errors，開啟提示視窗，填入錯誤提示 */
                                      if (Object.keys(res).length > 0) {
                                        setModalText(modalTextObj.error);
                                        setIsHintModalOpen(true);
                                      } else {
                                        setIsOpen(true);
                                      }
                                    });
                                  }}
                                >
                                  <Typography>
                                    {isButtonLoading ? (
                                      <CircularProgress size={16} color="inherit" />
                                    ) : (
                                      id2text('setting.page.button.save')
                                    )}
                                  </Typography>
                                </EasyButton>
                              </Box>
                            </>
                            )
                        }
                      {
                            mode === pageMode.UPLOAD && (
                            <UploadDocumentArea setMode={setMode} />
                            )
                        }
                      {
                            mode === pageMode.EDIT_USER_CARD && (
                            <EditUserCard setIsDeleteAccountModalOpen={setIsDeleteAccountModalOpen} setMode={setMode} />
                            )
                        }

                    </Box>
                  )}
                </Paper>
              </Box>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default SettingPage;
