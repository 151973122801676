//
import type React from 'react';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../reduxStore/utils/reduxHooks';
import mainAct from '../../../reduxStore/mainReducer/mainAction';
//
import {
  TIMEOUT_TIME, TIMEOUT_ALERT_TIME, timeoutAlertMsg, AUTO_HIDE_DURATION_TIME,
} from '../../../config/config.timeout';

import SignOut from '../../../pages/Users/utils/signOut';
import { useLocation, useNavigate } from 'react-router-dom';

// setTimeout 的 handler
let handlerId = -1;
let alertHandlerId = -1;

let targets: any[] = [];

const WebTimeout = (): React.JSX.Element | null => {
  //
  const navigation = useNavigate();
  const location = useLocation();
  const { pathname, search } = location;

  //
  const timeoutTs = useAppSelector((state) => state.timeoutStore);
  const timeoutAlertTs = useAppSelector((state) => state.timeoutAlertStore);
  const dispatch = useAppDispatch();
  const userData = useAppSelector((state) => state.userDataStore);
  //

  const pathNameListener = (): void => {
    dispatch({
      type: mainAct.SET_TIMEOUT_TIMESTAMP,
      payload: Date().toString(),
    });
  };

  // observer for pathname
  useEffect(() => {
    // console.log("pathname change");
    pathNameListener();
  }, [pathname, search]);

  const genericListener = (): void => {
    dispatch({
      type: mainAct.SET_TIMEOUT_TIMESTAMP,
      payload: Date().toString(),
    });
    dispatch({
      type: mainAct.SET_TIMEOUT_ALERT_TIMESTAMP,
      payload: Date().toString(),
    });
  };

  const startListen = (): void => {
    if (typeof window !== 'undefined') {
      // targets for listen
      targets = [
        {
          ele: window?.document.body,
          listenType: 'scroll',
          listener: genericListener,
        },
        {
          ele: window?.document,
          listenType: 'scroll',
          listener: genericListener,
        },
        {
          ele: window?.document.body,
          listenType: 'click',
          listener: genericListener,
        },
        {
          ele: window?.document,
          listenType: 'click',
          listener: genericListener,
        },
        {
          ele: window?.document,
          listenType: 'touchstart',
          listener: genericListener,
        },
        {
          ele: window?.document.body,
          listenType: 'touchstart',
          listener: genericListener,
        },
      ];

      targets.forEach((cur) => {
        if (typeof window !== 'undefined' && cur.ele) {
          cur.ele.addEventListener(cur.listenType, cur.listener, true);
        }
      });
    }
  };

  // observer for scroll event
  useEffect(() => {
    startListen();

    return () => {
      if (typeof window !== 'undefined') {
        targets.forEach((target) => {
          target.ele.removeEventListener(target.listenType, target.listener);
        });
      }
    };
  }, []);

  // observer for timeOutTimeStamp
  useEffect(() => {
    // 先清除舊的 timeout id
    if (handlerId) {
      clearTimeout(handlerId);
    }
    if (!userData) {
      clearTimeout(handlerId);
    }

    if (userData && typeof window !== 'undefined') {
      // console.log('[user stay log in]');
      // timeout ID: postivie number
      handlerId = window.setTimeout(async () => {
        // do something here
        // sign out
        // when user sign out, userData will be null
        await SignOut(navigation, dispatch);

        clearTimeout(handlerId);
      }, TIMEOUT_TIME);
    }
    return () => {
      if (handlerId) {
        clearTimeout(handlerId);
      }
    };
  }, [timeoutTs, userData]);

  // observer for timeOutTimeStamp
  useEffect(() => {
    // 先清除舊的 timeout id
    if (alertHandlerId) {
      clearTimeout(alertHandlerId);
    }
    if (!userData && typeof window !== 'undefined') {
      clearTimeout(alertHandlerId);
      dispatch(
        mainAct.SET_SNACKBAR_INFO({
          open: false,
          message: '',
          severity: 'info',
        }),
      );
    }

    if (userData) {
      // console.log('[timeout alert countdown]');
      // timeout ID: postivie number
      alertHandlerId = window.setTimeout(async () => {
        // do something here
        dispatch(
          mainAct.SET_SNACKBAR_INFO({
            open: true,
            // FIXME: 先用 英文版
            message: timeoutAlertMsg.en,
            severity: 'warning',
            autoHideDuration: AUTO_HIDE_DURATION_TIME,
          }),
        );
        clearTimeout(alertHandlerId);
      }, TIMEOUT_ALERT_TIME);
    }
    return () => {
      if (alertHandlerId) {
        clearTimeout(alertHandlerId);
      }
    };
  }, [timeoutAlertTs, userData]);

  return null;
};

export default WebTimeout;
