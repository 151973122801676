import { ILLEGAL_CH, USERNAME_LENGTH_LIMIT } from '../../utils/validateUserName';
import { object, ref, string } from 'yup';

const informationAreaTitle = {
  COMPANY_INFO_TITLE: 'Company Info',
  ADDRESS_TITLE: 'Address',
  MAIN_CONTACT_TITLE: 'Main Contact',
};
const helperTexts = {
  name: `Name is not valid. Text length limit ${USERNAME_LENGTH_LIMIT}. The illegal characters are: ${ILLEGAL_CH}`,
  mail: 'Email is not valid',
  empty: 'No whitespace allowed here',
  phone: 'Phone is not valid',
  tax: 'Tax number is not valid',
  license: 'License Number is not valid',
};
const regex = {
  mail: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  phone: /^\+?\d+$/,
  tax: /^\d+$/,
  license: /^\d+$/,
};
const validationSchema = object({
  companyName: string().required(helperTexts.empty),
  companyTel: string().required(helperTexts.phone).matches(regex.phone, helperTexts.phone),
  // postalCode: string().required(helperTexts.empty),
  country: string().required(helperTexts.empty),
  state: string().required(helperTexts.empty),
  // city: string().required(helperTexts.empty),
  // address1: string().required(helperTexts.empty),
  // address2: string().required(helperTexts.empty),
  taxNumber: string().required(helperTexts.empty).matches(regex.tax, helperTexts.tax),
  licenseNumber: string().required(helperTexts.empty).matches(regex.license, helperTexts.license),
  contactName: string().required(helperTexts.empty),
  contactMail: string().required(helperTexts.mail).matches(regex.mail, helperTexts.mail),
  contactTel: string().required(helperTexts.phone).matches(regex.phone, helperTexts.phone),
});

interface AddressProps {
  country?:string;
  state?:string;
  city?:string;
  // district?:string;
  address1?:string;
  address2?:string;
  lat?:string;
  long?:string;
  postalCode?:string;
  [key: string]: string | undefined;
}

const pageMode = {
  UPLOAD: 'UPLOAD',
  EDIT_USER_CARD: 'EDIT_USER_CARD',
  SETTING: 'SETTING',
  NOTIFICATIONS: 'NOTIFICATIONS',
};

const MAIN_TYPE = {
  ACCOUNT: 'ACCOUNT',
  NOTIFICATIONS: 'NOTIFICATIONS',
};

const STATUS_COLOR = {
  ORANGE: 'orange',
  GREY: 'grey',
  GREEN: 'green',
  BLUE: 'blue',
};

const UPLOAD_CARD_STATUS = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
};

const PASSWORD_PLACEHOLDER = '********';
export {
  pageMode, helperTexts, validationSchema, informationAreaTitle, type AddressProps, STATUS_COLOR, UPLOAD_CARD_STATUS, MAIN_TYPE, PASSWORD_PLACEHOLDER,
};
