import { User } from 'firebase/auth';

const isEmpty = (obj: object | [] | unknown):boolean => Object.keys(obj || {}).length === 0;

/** 將時間數字轉成YYYY-MM-DD string */
const getDate = (timeNum: number) => {
  const nowDate = timeNum ? new Date(timeNum) : new Date();
  const mm = nowDate.getMonth() + 1;
  const dd = nowDate.getDate();
  return [
    nowDate.getFullYear(),
    (mm > 9 ? '' : '0') + mm,
    (dd > 9 ? '' : '0') + dd,
  ].join('-');
};

const getTimeSince = (date: Date): string => {
  const seconds = Math
    .floor((Date.parse(new Date().toString()) - Date.parse(date.toString())) / 1000);

  let intervalType = '';
  let interval = Math.floor(seconds / 31536000);

  if (interval >= 1) {
    intervalType = 'year';
  } else {
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) {
      intervalType = 'month';
    } else {
      interval = Math.floor(seconds / 86400);
      if (interval >= 1) {
        intervalType = 'day';
      } else {
        interval = Math.floor(seconds / 3600);
        if (interval >= 1) {
          intervalType = 'hour';
        } else {
          interval = Math.floor(seconds / 60);
          if (interval >= 1) {
            intervalType = 'minute';
          } else {
            interval = seconds;
            intervalType = 'second';
          }
        }
      }
    }
  }

  if (interval > 1 || interval === 0) {
    intervalType += 's';
  }

  return `${interval} ${intervalType} age`;
};

type FormatUserProp = Pick<User, 'uid' | 'displayName' | 'email' | 'emailVerified' | 'isAnonymous' | 'providerData' | 'metadata'>;

const getFormatUser = (user: FormatUserProp) => {
  const {
    uid,
    displayName,
    email,
    emailVerified,
    isAnonymous,
    providerData,
    metadata,
  } = user;
  // const { providerId } = providerData && providerData?.[0];
  const {
    // creationTimestamp,
    // lastSignInTimestamp,
    creationTime,
    lastSignInTime,
  } = metadata;
  return {
    uid,
    displayName,
    email,
    emailVerified,
    isAnonymous,
    providerId: (providerData && providerData?.[0]?.providerId) || 'unKnown',
    // creationTimestamp,
    // lastSignInTimestamp,
    creationTime,
    lastSignInTime,
  };
};

// const isArrayEqual = (a, b) => JSON.stringify(a.sort()) === JSON.stringify(b.sort());

// check object is equal deeply.
// const isObjectEqual = (value, other) => {
//   // Get the value type
//   const type = Object.prototype.toString.call(value);
//
//   // If the two objects are not the same type, return false
//   if (type !== Object.prototype.toString.call(other)) return false;
//
//   // If items are not an object or array, return false
//   if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;
//
//   // Compare the length of the length of the two items
//   const valueLen = type === '[object Array]' ? value.length : Object.keys(value).length;
//   const otherLen = type === '[object Array]' ? other.length : Object.keys(other).length;
//   if (valueLen !== otherLen) return false;
//
//   // Compare two items
//   const compare = (item1, item2) => {
//     // Get the object type
//     const itemType = Object.prototype.toString.call(item1);
//
//     // If an object or array, compare recursively
//     if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
//       if (!isObjectEqual(item1, item2)) return false;
//     } else { // Otherwise, do a simple comparison
//       // If the two items are not the same type, return false
//       if (itemType !== Object.prototype.toString.call(item2)) return false;
//
//       // Else if it's a function, convert to a string and compare
//       // Otherwise, just compare
//       if (itemType === '[object Function]') {
//         if (item1.toString() !== item2.toString()) return false;
//       } else if (item1 !== item2) return false;
//     }
//     return true;
//   };
//
//   // Compare properties
//   if (type === '[object Array]') {
//     for (let i = 0; i < valueLen; i += 1) {
//       if (compare(value[i], other[i]) === false) return false;
//     }
//   } else {
//     // eslint-disable-next-line no-restricted-syntax
//     for (const key in value) {
//       if (Object.keys(value).indexOf(key) > -1) {
//         if (compare(value[key], other[key]) === false) return false;
//       }
//     }
//   }
//
//   // If nothing failed, return true
//   return true;
// };

// 整理 errors 成 string
// const getErrorString = (errs) => {
//   try {
//     let str = '';
//     const singleErrStr = (err) => {
//       if (err instanceof Error) {
//         const { code, message } = err;
//         return `error code:${code || ''}; message:${message};`;
//       }
//       return JSON.stringify(err);
//     };
//     if (Array.isArray(errs)) {
//       errs.forEach((err) => {
//         str += singleErrStr(err);
//       });
//     } else {
//       str += singleErrStr(errs);
//     }
//     return str;
//   } catch (e) {
//     return e.message;
//   }
// };

export {
  isEmpty,
  getDate,
  getTimeSince,
  getFormatUser,
  // isArrayEqual,
  // isObjectEqual,
  // getErrorString,
};
