// import React from 'react';

import AuthListener from './firebase/AuthListener';
// import FirestoreListener from './firebase/FirestoreListener';

// interface Props {
//   children: React.ReactNode;
// }
// { children }:Props
const DataLayer = () => {
  // handle firebase user login status
  AuthListener();

  // handle firestore listener
  // FirestoreListener();

  // return children ? (
  // // eslint-disable-next-line react/jsx-no-useless-fragment
  //   <>
  //     {children}
  //   </>
  // ) : null;
  return null;
};

export default DataLayer;
