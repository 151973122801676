import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

interface TitleContainerProps {
  title?: string;
  customTitle?: React.ReactNode;
  children: React.ReactNode;
  isSignUp?: boolean;
}
const TitleContainer :React.FC<TitleContainerProps> = ({
  title,
  customTitle,
  children,
  isSignUp,
}) => {
  /* 如果有標題 返回有標題版本 */
  if (title) {
    return (
      <Grid
        container
        sx={{
          padding: {
            xl: '5.209% 35%',
            lg: '5.209% 35%',
            md: '8.6816% 27.7%',
            sm: isSignUp ? '6.9464% 4.865%' : '18.5211% 4.865%',
            xs: isSignUp ? '6.9464% 4.865%' : '18.5211% 4.865%',
          },
        }}
      >
        {/* 標題 */}
        <Grid container justifyContent="center">
          {title
            ? (
              <Typography fontSize="700" variant="h5">
                {title}
              </Typography>
            )
            : null}
          {customTitle}
        </Grid>
        {children}
      </Grid>
    );
  }
  /* 如果沒有標題 則返回客製化版本 */
  return (
    <Grid
      container
      sx={{
        padding: {
          xl: '5.209% 35%',
          lg: '5.209% 35%',
          md: '8.6816% 27.7%',
          sm: isSignUp ? '7.2076% 4.865%' : '18.5211% 4.865%',
          xs: isSignUp ? '7.2076% 4.865%' : '18.5211% 4.865%',
        },
      }}
    >
      {/* 標題 */}
      <Grid container justifyContent="center">
        {customTitle}
      </Grid>
      {children}
    </Grid>
  );
};

export default TitleContainer;
