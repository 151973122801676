import React from 'react';

//
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
//
import Box from '@mui/material/Box';

interface Props {
  image: { name:string; url: string; };
  maxHeight?: string;
}

const IMG_MAX_H = 50;

// 用來顯示縮小圖片: for image and pdf
const ImageBlock: React.FC<Props> = ({ image, maxHeight }):React.JSX.Element => {
  // FIXME: 更嚴謹的驗證規則
  const pdfReg = /^.+\.(pdf)$/;

  const imgMaxH = maxHeight ?? IMG_MAX_H;

  if (pdfReg.test(image.name)) {
    return <PictureAsPdfOutlinedIcon sx={{ fontSize: `${imgMaxH}px` }} />;
  }
  return (
    <Box display="flex" alignItems="center">
      <img src={image.url} alt={`${image.name}`} style={{ maxHeight: `${imgMaxH}px` }} />
    </Box>
  );
};

export default ImageBlock;
