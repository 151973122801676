import React, { useEffect, useState } from 'react';
//
// eslint-disable-next-line import/no-extraneous-dependencies
import { IntlProvider } from 'react-intl';
//
import { useParams, useNavigate, useLocation } from 'react-router-dom';
//
import { changeLang } from './languageTools';
import services from '../api/services';
//
import Loading from '../commons/components/loading/Loading';
//
import languages, { supportLanguages } from '../locales/langs';

interface Props {
  children: React.ReactNode;
}

interface I18n {
  locale: string;
  messages: { [key:string]: string }
}

const Intl18Provider: React.FC<Props> = ({ children }) => {
  const [i18n, setI18n] = useState<I18n>();

  const { locale } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  //
  useEffect(() => {
    /* 檢查 語系最常不會超過9字元 => https://github.com/ladjs/i18n-locales
     * 檢查 本系統是否支援該語系 */
    if (locale && locale.length < 9 && supportLanguages.some(({ value }) => value === locale)) {
      services.setLocale(locale);
      setI18n({ locale, messages: languages[locale] });
    } else {
      /* URL沒有語系 自動將語系帶上 */
      changeLang({ location, navigate });
    }
  }, [locale]);

  return i18n?.locale ? (
    <IntlProvider messages={i18n.messages} locale={i18n.locale} defaultLocale={services.getDefaultLocale()}>
      {children}
    </IntlProvider>
  ) : <Loading />;
};

export default Intl18Provider;
