const settingPage = {
    /* setting */
    "setting.tabs.account":"Account",
    "setting.tabs.notifications":"Notifications",
    /* 在setting頁面 email驗證視窗 */
    "setting.modal.hint.email.verify.title":"Verify your email",
    "setting.modal.hint.email.verify.action":"Sure",
    "setting.modal.hint.email.verify.secondary.action":"No",
    "setting.modal.hint.email.verify.text":" If you have a verification code that has not yet expired, you can directly enter the verification code. If you enter the verification code and it appears to be expired ,please press 'Resend verification code' to receive the verification code again.",
    "setting.modal.email.verify.success.title":"Congratulations!",
    "setting.modal.email.verify.success.text":"Thank you for signing up! Due to EasyCycle policy, identity verification is required to use full service!",
    "setting.modal.email.verify.hint.good":"Verification successful.",
    "setting.modal.email.verify.hint.bad":"The verification code is invalid or incorrect. Please retry.",
    "setting.modal.email.verify.hint.networkError":"The system encountered an error. Please try again later.",
    "setting.modal.verifyCode.label1":"Enter verification code.",
    "setting.modal.verifyCode.label2":" Didn't receive the email?",
    "setting.modal.email.verify.hint.sendingMail1":"Sending email...",
    "setting.modal.email.verify.hint.sendingMail2":"Resend verification code ({resendTime}s)",
    "setting.modal.email.verify.resend.text":"Resend verification code",
    /* 刪除用戶視窗 */
    "setting.modal.deleteAccount.action":"Yes",
    "setting.modal.deleteAccount.secondary.action":"No",
    "setting.modal.deleteAccount.title.default":"Delete Your Account",
    "setting.modal.deleteAccount.title.success":"Success",
    "setting.modal.deleteAccount.title.error":"Error",
    "setting.modal.deleteAccount.text.default":"Do you really want to delete your account? Pressing [Yes] will be irreversible and you won not be able to undo it.",
    "setting.modal.deleteAccount.text.success":"Your account has been deleted. To revoke access to our website, please delete the cookies yourself. Thank you.",
    "setting.modal.deleteAccount.text.error":"Account deletion failed, please try again later.",
    /* 資料修改 確認視窗 */
    "setting.modal.confirm.title":"Confirmation",
    "setting.modal.confirm.action":"Save",
    "setting.modal.confirm.secondary.action":"Cancel",
    "setting.modal.confirm.text1":"This action will change your personal information.",
    "setting.modal.confirm.text2":"Are you sure you want to proceed with the modification?",
    /* 資料修改 修改後提示視窗 */
    "setting.modal.update.error.title":"Error",
    "setting.modal.update.error.text":"There are errors in the fields that need to be corrected",
    "setting.modal.update.success.title":"Success",
    "setting.modal.update.success.text":"Successfully updated data",
    /* user card */
    "setting.userCard.chip.verified":"Verified",
    "setting.userCard.chip.unVerified":"Unverified",
    "setting.userCard.button.deleteAccount":"Delete Account",
    "setting.userCard.button.verifyAccount":"Verify Account",
    /* 上傳身分證明 提示視窗 */
    "setting.upload.modal.hint.uploadError":"Upload failed. Please try again later.",
    "setting.upload.modal.hint.loadError":"Failed to retrieve uploaded data. Please try again later.",
    "setting.upload.modal.hint.filesFormatError":"Please upload files in the specified formats: '*.pdf, *.png, *.jpeg'.",
    /* 上傳身分證明文件區 */
    "setting.upload.area.title":"Drop files to upload",
    "setting.upload.area.hint":"Supported formats: PDF, JPG, PNG. File size must be less than 2MB.",
    "setting.upload.area.hint.emailVerified":"You need to verify your email first before you can upload your files.",
    "setting.upload.button.cancel":"Cancel",
    "setting.upload.button.upload":"Upload Files.",
    "setting.upload.button.remove":"Remove this file",
    "setting.upload.button.view":"View",

    "setting.page.title":"Settings",
    "setting.page.subTitle":"Your personal information and your preferences for various EasyCycle services.",
    "setting.page.emailVerifiedHint":"*We kindly remind you to complete your account and real-name verification at your earliest convenience. Thank you for your cooperation!",
    "setting.page.infoArea.company.title":"Company Information",
    "setting.page.infoArea.address.title":"Address",
    "setting.page.infoArea.mainContact.title":"Main Contact",
    "setting.page.helperTexts.name":"Name is not valid. Text length limit {USERNAME_LENGTH_LIMIT}. The illegal characters are: {ILLEGAL_CH}",
    "setting.page.helperTexts.mail":"Email is not valid",
    "setting.page.helperTexts.empty":"No whitespace allowed here",
    "setting.page.helperTexts.phone":"Phone is not valid",
    "setting.page.helperTexts.tax":"Tax number is not valid",
    "setting.page.helperTexts.license":"License Number is not valid",
    "setting.page.companyName.label":"Company Name",
    "setting.page.companyTel.label":"Phone Number",
    "setting.page.companyMail.label":"Company Email",
    "setting.page.licenseNumber.label":"License Number",
    "setting.page.postalCode.label":"Postal Code",
    "setting.page.country.label":"Country / region",
    "setting.page.state.label":"State / province / city",
    "setting.page.city.label":"City / district",
    "setting.page.address1.label":"Address1",
    "setting.page.address2.label":"Address2",
    "setting.page.contactName.label":"Name",
    "setting.page.contactMail.label":"Email",
    "setting.page.contactTel.label":"Phone Number",
    "setting.page.button.save":"Save",
    "setting.page.button.cancel":"Cancel",
    "setting.page.label.password":"Password",
    "setting.page.button.changePassword":"Change Password",
    /* company document */
    "setting.company.document.title":"Company Document",
    "setting.company.document.description":"Please upload company documents, the documents are only used for identity verification and not for other purposes.",
    /* upload card */
    "setting.upload.button":"Upload",
    "setting.upload.card.title":"Change Registration Form",
    "setting.upload.card.status.approved":"Approved",
    "setting.upload.card.status.pending":"Pending",
    "setting.upload.area.mode.title":"Upload Change Registration Form",
}

export default settingPage
