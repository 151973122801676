import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { type BaseTextFieldProps } from '@mui/material/TextField/TextField';
import { type InputProps as StandardInputProps } from '@mui/material/Input/Input';
import SearchIcon from '@mui/icons-material/Search';

type InputType = 'text' | 'password' | 'number' | 'time' | 'date' | 'text-search' | 'inputLimit';
interface EasyTextFieldProps extends BaseTextFieldProps {
  label?: string;
  type: InputType;
  value?: string;
  disabled?: boolean;
  error?: boolean;
  size?: 'small' | 'medium';
  required?: boolean;
  fullWidth?: boolean;
  helperText?: React.ReactNode;
  variant?: 'filled' | 'outlined' | 'standard';
  onChange?: (e: any) => void;
  InputProps?: Partial<StandardInputProps>;
  onBlur?: (e: any) => void;
}

const EasyTextField: React.FC<EasyTextFieldProps> = ({
  label,
  type = 'text',
  value,
  disabled,
  error,
  size,
  required,
  fullWidth,
  helperText,
  variant,
  onChange,
  onBlur,
  InputProps,
  sx,
  ...props
}): React.JSX.Element => {
  const [isVisible, setIsVisible] = useState(false);

  if (type === 'text') {
    return (
      <TextField
        label={label}
        type="text"
        value={value}
        disabled={disabled}
        error={error}
        size={size}
        required={required}
        fullWidth={fullWidth}
        helperText={helperText}
        variant={variant}
        onChange={onChange}
        onBlur={onBlur}
        InputProps={{
          ...InputProps,
        }}
        {...props}
        sx={{
          backgroundColor: disabled === true ? '#E6E6E6' : 'white',
          ...sx,
        }}
      />
    );
  }

  if (type === 'text-search') {
    return (
      <TextField
        label={label}
        type="text"
        value={value}
        disabled={disabled}
        error={error}
        size={size}
        required={required}
        fullWidth={fullWidth}
        helperText={helperText}
        variant={variant}
        onChange={onChange}
        onBlur={onBlur}
        InputProps={{
          style: { height: '3.1875rem', borderRadius: '0.5rem' },
          startAdornment: <SearchIcon sx={{ color: 'neutral.500', fontSize: '1.5rem' }} />,
        }}
        {...props}
        sx={{
          backgroundColor: disabled === true ? '#E6E6E6' : 'white',
          ...sx,
        }}
      />
    );
  }

  if (type === 'date') {
    return (
      <TextField
        label={label}
        type="date"
        value={value}
        disabled={disabled}
        error={error}
        size={size}
        required={required}
        fullWidth={fullWidth}
        helperText={helperText}
        variant={variant}
        onChange={onChange}
        onBlur={onBlur}
        InputProps={{
          style: { height: '3.1875rem', borderRadius: '0.5rem' },
        }}
        {...props}
        sx={{
          backgroundColor: disabled === true ? '#E6E6E6' : 'white',
          ...sx,
        }}
      />
    );
  }

  if (type === 'password') {
    return (
      <TextField
        label={label}
        type={isVisible ? 'text' : 'password'}
        value={value}
        disabled={disabled}
        error={error}
        size={size}
        required={required}
        fullWidth={fullWidth}
        helperText={helperText}
        variant={variant}
        onChange={onChange}
        onBlur={onBlur}
        {...props}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => {
                  setIsVisible(!isVisible);
                }}
                edge="end"
              >
                {isVisible ? (
                  <Visibility
                    sx={{
                      fontSize: 'small',
                      color: error === true ? '#DB371F' : '#666666',
                    }}
                  />
                ) : (
                  <VisibilityOff
                    sx={{
                      fontSize: 'small',
                      color: error === true ? '#DB371F' : '#666666',
                    }}
                  />
                )}
              </IconButton>
            </InputAdornment>
          ),
          ...InputProps,
        }}
        sx={{
          backgroundColor: disabled === true ? '#E6E6E6' : 'white',
          ...sx,
        }}
      />
    );
  }
  if (type === 'inputLimit') {
    return (
      <TextField
        label={label}
        type="text"
        value={value}
        disabled={disabled}
        error={error}
        size={size}
        required={required}
        fullWidth={fullWidth}
        helperText={helperText}
        variant={variant}
        onChange={onChange}
        onBlur={onBlur}
        InputProps={{
          style: { height: '3.1875rem', borderRadius: '0.5rem' },
        }}
        {...props}
        sx={{
          backgroundColor: disabled === true ? '#E6E6E6' : 'white',
          ...sx,
        }}
      />
    );
  }

  return (
    <TextField
      id="outlined-password-input"
      type={type}
      label={label}
      value={value}
      disabled={disabled}
      error={error}
      size={size}
      required={required}
      fullWidth={fullWidth}
      helperText={helperText}
      variant={variant}
      onChange={onChange}
      onBlur={onBlur}
      InputProps={{
        ...InputProps,
      }}
      {...props}
      sx={{
        backgroundColor: disabled === true ? '#E6E6E6' : 'white',
        ...sx,
      }}
    />
  );
};

export default EasyTextField;
