import { createSlice } from '@reduxjs/toolkit';
import OutsourcingRecyclingProps from './TypescriptProps';
import outsourceRecycleAction from './outsourceRecycleAction';

// eslint-disable-next-line import/prefer-default-export
export const selectedOutsourceReducer = createSlice({
  name: 'selectedOutsourceReducer',
  initialState: null as OutsourcingRecyclingProps | null,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(outsourceRecycleAction.SET_OUTSOURCE, (state, action) => action.payload);
  },
});
