const customerManagementPage = {
    /* common ( 出現多次 ) */
    "customerManagement.common.deleteCustomer":"Delete Customer",
    /* title */
    "customerManagement.pageTitle":"Customer Management",
    "customerManagement.pageTitle.null":"No Customers",
    "customerManagement.pageTitle.add":"Add Customer",
    "customerManagement.pageTitle.edit":"Edit Customer",
    /* subtitle */
    "customerManagement.page.subtitle.companyInformation":"Company Information",
    "customerManagement.page.subtitle.customerInformation":"Contact Information",
    "customerManagement.page.subtitle.notice":"Notice",
    /* modal common */
    "customerManagement.modal.hint.common.success.title":"Successful",
    "customerManagement.modal.hint.common.failed.title":"Failed",
    /* add modal hint */
    "customerManagement.modal.hint.add.success.text":"Create Data Successfully",
    "customerManagement.modal.hint.add.failed.text":"Failed to create Data",
    /* edit modal hint */
    "customerManagement.modal.hint.edit.success.text":"Edit Data Successfully",
    "customerManagement.modal.hint.edit.failed.text":"Failed to Edit Data",
    /* delete modal hint */
    "customerManagement.modal.hint.delete.success.text":"Delete Data Successfully",
    "customerManagement.modal.hint.delete.failed.text":"Failed to Delete Data",
    /* search */
    "customerManagement.search.placeholder":"Search",
    /* table head */
    "customerManagement.th.companyName":"Company Name",
    "customerManagement.th.name":"Name",
    "customerManagement.th.emailOrPhone":"Email / Phone",
    "customerManagement.th.action":"Action",
    "customerManagement.th.info":"Info",
    /* label */
    "customerManagement.label.companyName":"Company Name",
    "customerManagement.label.name":"Name",
    "customerManagement.label.email":"Email",
    "customerManagement.label.phone":"Phone",
    /* description */
    "customerManagement.description.notice":"Please note that when you delete a customer, all information about that customer will be removed and cannot be restored.",
    "customerManagement.description.deleteCustomer":"After deleting a customer, all information related to the changed customer will be permanently deleted.",

}

export default customerManagementPage
