import React, { useEffect, useState } from 'react';

// utils
import { decodeJwt } from 'jose';

// mui
import Grid from '@mui/material/Grid';
// firebase
import { GoogleAuthProvider, signInWithCredential } from 'firebase/auth';
import userAct from '../../../pages/Users/userAction';
// utils
import handleCheckEmailExist from '../../../pages/Users/utils/handleCheckEmailExist';
import handleFusekiHandlerData from '../../../pages/Users/utils/fuseki/handleFusekiHandlerData';
// hooks
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../reduxStore/utils/reduxHooks';
// route config
import routes from '../../../config/routes/index';
// config
import services from '../../../api/services';
import { auth } from '../../../config/config-firebase';
import groupInfo from '../../../App-groupInfo';

declare global {
  interface Window {
    google: any;
    handleCredentialResponse:(response:any)=>void;
  }
}
// 此處有新增setIsLoading 所以使用組件的同時也要有loading的元件
interface GoogleSignInButtonProps {
  setIsLoading: (e: boolean) => void;
}

function GoogleSignInButton({
  setIsLoading,
}: // setAvoidFlash,
GoogleSignInButtonProps): React.JSX.Element {
  const dispatch = useAppDispatch();
  const navigation = useNavigate();
  const [scaleSize, setScaleSize] = useState(1);
  useEffect(() => {
    const handleResize = (): void => {
      const windowWidth = window.innerWidth;
      const baseWidth = 1280;
      let baseScale = 1.005;
      if (windowWidth < 768) {
        baseScale = 3.05;
      } else if (windowWidth < 1200) {
        baseScale = 1.5;
      }

      // 計算新的 scale
      const newScaleSize = baseScale * (windowWidth / baseWidth);
      setScaleSize(newScaleSize);
    };

    // 監視螢幕大小
    window.addEventListener('resize', handleResize);

    // 初始化
    handleResize();

    // 清除監聽
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  window.handleCredentialResponse = async (response: any): Promise<void> => {
    setIsLoading(true);
    const credential = GoogleAuthProvider.credential(response.credential);
    const { email } = decodeJwt(response.credential);

    const data = await handleCheckEmailExist(email as string);
    // 第一種狀況 : google登入且api回傳true，代表此用戶找得到，但是provider不包含google，即有用過email註冊
    if (data.message === true) {
      if (typeof email === 'string') {
        dispatch(userAct.FIREBASE_CONNECT_ACCOUNT(email));
        navigation(`/${services.getLocale()}${routes.LINKUSER}`);
      }
    }
    // 第二種狀況 : 本身是google登入過的用戶，或是email用戶已經串聯google Provider
    if (data.message === 'linkUser' || data.message === 'google.com') {
      // 使用 Firebase 身份驗證
      await signInWithCredential(auth, credential)
        .then(async (userCredential) => {
          const uid = userCredential.user?.uid;
          if (typeof uid !== 'string') return;
          // 登入成功，獲取用戶資訊
          localStorage.setItem('isLogin', 'true');
          const userData = (await handleFusekiHandlerData.readData({ firebaseID: uid }));
          if (!userData) return;
          dispatch(userAct.FIREBASE_LOGIN_USER(userData.data));
          navigation(`/${services.getLocale()}${routes.REDIRECTION}`);
        })
        .catch(() => {
          // 處理錯誤
        });
    }
    // 第三種狀況 : google登入且api回傳false，代表此用戶第一次使用google登入
    if (data.message === false) {
      dispatch(userAct.SET_SIGNUP_STATE('signUp'));
      await signInWithCredential(auth, credential)
        .then(async (userCredential) => {
          const uid = userCredential.user?.uid;
          if (typeof uid !== 'string') return;
          // 登入成功，獲取用戶資訊
          localStorage.setItem('isLogin', 'true');
          /* 在這邊設置註冊狀態 */
          navigation(`/${services.getLocale()}${routes.REDIRECTION}`);
        })
        .catch(() => {
          // 處理錯誤
        });
    }
  };
  useEffect(() => {
    // 載入 Google Sign-In 相關的 JavaScript 檔案
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client?hl=en';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }, []);

  const clientKey = {
    production:
        '675013872173-dlor7ehv0ajgvjedk4p85mpo2a7m2hji.apps.googleusercontent.com',
    develop:
        '675013872173-9hcngp0rj6nn17um9dckdlttlej2qmq1.apps.googleusercontent.com',
  };
  return (
    <Grid
      id="buttonDiv"
      width="100%"
      container
      justifyContent="center"
      height="51px"
      sx={{
        transform: `scale(${scaleSize})`,
      }}
    >
      <div
        id="g_id_onload"
        data-client_id={clientKey.develop}
        data-locale="en"
        data-context="continue_with"
        data-ux_mode="popup"
        data-callback="handleCredentialResponse"
        data-auto_prompt="false"
      />
      <div
        className="g_id_signin"
        data-locale="en"
        data-type="standard"
        data-shape="rectangular"
        data-theme="outline"
        data-text="continue_with"
        data-size="large"
        data-logo_alignment="left"
        data-width="368"
      />
    </Grid>
  );
}

export default GoogleSignInButton;
