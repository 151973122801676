import axios from 'axios';
import services from '../../../api/services';

const handleUpdateEmailVerified = async (email: string): Promise<string> => {
  const response = await axios.post(services.postUpdateEmailVerified(), {
    email,
  }, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response.data;
};

export default handleUpdateEmailVerified;
