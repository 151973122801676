import {
  // useContext,
  useEffect,
} from 'react';

// firebase
import { onAuthStateChanged } from 'firebase/auth';
// commons code
import { getFormatUser } from '../../commons/utils';
import { useAppDispatch, useAppSelector } from '../../reduxStore/utils/reduxHooks';
import { auth } from '../../config/config-firebase';
// redux
import userAct from '../../pages/Users/userAction';
import signOut from '../../pages/Users/utils/signOut';
import { useNavigate, useLocation } from 'react-router-dom';
import { getUser } from '../../api/firebase/cloudFirestore';
import handleFusekiHandlerData from '../../pages/Users/utils/fuseki/handleFusekiHandlerData';
import services from '../../api/services';
import routes from '../../config/routes';
import routesPage from '../../App-route';
import groupInfo from '../../App-groupInfo';

// const ALLOW_TEST_USER_ONLY = false;

function AuthListener() {
  const dispatch = useAppDispatch();
  const navigation = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (!auth) {
      return;
    }
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userInfo = getFormatUser(user);
        const { uid } = userInfo;
        const firestoreData = uid && (await getUser(uid));
        const userData = await handleFusekiHandlerData?.readData({ firebaseID: uid });
        /* 如果是handler 就拿editProfile */
        if (userData?.groupRole.some((item) => !item.group.includes(groupInfo.ServiceProvider.id)) && userData?.groupRole.some((item) => !item.group.includes(groupInfo.Admin.id))) {
          console.log('123');
        }
        /* 如果是upstream登入，將使用者登出，不符合平台登入身分 */
        if (firestoreData.role === 'personal' || firestoreData.role === 'company') {
          dispatch(userAct.PERMISSION_DENIAL('deny'));
          await signOut(navigation, dispatch);
          window.location.reload();
          alert('Please log in to the user platform to proceed.');
          dispatch(userAct.FIREBASE_LOGOUT_USER);
          return;
        }
        if (!userData) return;
        /* 設置setting資料 */
        dispatch(userAct.FIREBASE_LOGIN_USER(userData.data));
        /* 設置fuseki id資料 */
        dispatch(userAct.SET_FUSEKI_ID(userData.data?.fusekiID ?? ''));
        if (userData.groupRole?.length === 0) return;
        if (!userData.groupRole) return;
        /* 設置group role資料 */
        dispatch(userAct.SET_GROUP_ROLE(userData?.groupRole ?? ''));
        // if (handlerIntro) {
        //   /* 設置easycycle setting資料 */
        //   dispatch(userAct.SET_EASYCYCLE_SETTINGS(handlerIntro));
        // }
        localStorage.setItem('isLogin', JSON.stringify(true));
      } else {
        /* 如果是Anonymous也可以進入的就不跳轉 */
        dispatch(userAct.FIREBASE_LOGOUT_USER);
        dispatch(userAct.SET_GROUP_ROLE([{ group: groupInfo.Anonymous.id, role: ['reader'] }]));
        const allowAnonymous = routesPage.find((item) => `/${services.getLocale()}${item.path}` === location.pathname)?.authority.includes(groupInfo.Anonymous.id);
        if (!allowAnonymous) {
          navigation(`${services.getLocale()}${routes.SIGNIN}`);
        }
        localStorage.removeItem('isLogin');
      }
    });
  }, [dispatch]);
}

export default AuthListener;
