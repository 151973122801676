import format from 'date-fns/format';

const STATUS_COLOR = {
  ORANGE: 'orange',
  GREY: 'grey',
  GREEN: 'green',
  BLUE: 'blue',
};

// const slpChipColor = (status:string, callback:(backgroundColor:string)=>StatusColorType) => {
//   /* 橘色 */
//   if (status?.includes(SLP_STATUS.UNPROCESSED)) {
//     return callback(STATUS_COLOR.ORANGE);
//   }
//   /* 灰色 */
//   if (status?.includes(SLP_STATUS.UNRECYCLED)) {
//     return callback(STATUS_COLOR.GREY);
//   }
//   /* 綠色 */
//   if (
//     status?.includes(SLP_STATUS.RECYCLING)
//   ) {
//     return callback(STATUS_COLOR.GREEN);
//   }
//   /* 藍色 */
//   /* Unpaid Completed */
//   return callback(STATUS_COLOR.BLUE);
// };

interface FormatBarcodeProps {
  bookingTime:string;
  tripletNumber:string;
  number:string;
}
const formatBarcode = ({ bookingTime, tripletNumber, number }:FormatBarcodeProps) => {
  const formatArrivedDate = format(parseInt(bookingTime, 10), 'yyyyMMdd');
  return `${tripletNumber}_${formatArrivedDate}_${number}`;
};
export {
  STATUS_COLOR, formatBarcode,
};
