import React, { useEffect, useState } from 'react';
// utils
import validatePassword from '../../utils/validatePassword';
// components
import EasyTextField from '../../../../commons/components/EasyTextField/EasyTextField';
import EasyButton from '../../../../commons/components/EasyButton/EasyButton';
import TitleContainer from '../../subComponent/TitleContainer';
import EasyDialog from '../../../../commons/components/modal/EasyDialog';
// hooks
import { useAppSelector } from '../../../../reduxStore/utils/reduxHooks';
import useScrollToTop from '../../../../hooks/useScrollToTop';
import useValidateState from '../../../../hooks/useValidateState';
import { useNavigate } from 'react-router-dom';
// mui
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { CircularProgress } from '@mui/material';
// image
import excellenceIcon from '../../../../images/signUp/excellence.png';
// config
import routes from '../../../../config/routes/index';
import services from '../../../../api/services';
import axios from 'axios';
// style
import userTitleContainerStyle from '../../userTitleContainerStyle';
import { useIntl } from 'react-intl';
import { string } from 'yup';
import { auth } from '../../../../config/config-firebase';
import { signOut } from 'firebase/auth';

const ResetPasswordPage = (): React.JSX.Element => {
  /** react-intl */
  const { formatMessage } = useIntl();
  const id2text = (id:string) => formatMessage({ id });
  // 進入頁面往最上滾動及檢查
  useScrollToTop();
  const navigation = useNavigate();
  /* 按鈕loading */
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  // 密碼
  const passwordValidation = useValidateState(
    '',
    validatePassword,
    id2text('resetPassword.page.hint.password'),
  );

  // 確認密碼規則
  const isEqualToPassword = (value: string): boolean => value === passwordValidation.value;
  // 確認密碼
  const confirmPasswordValidation = useValidateState(
    '',
    isEqualToPassword,
    id2text('resetPassword.page.hint.confirmPassword'),
  );
  /* 錯誤視窗 */
  const [isOpen, setIsOpen] = useState(false);
  const modalTextObj = {
    systemError: {
      label: 'systemError',
      title: id2text('resetPassword.page.modal.error.title'),
      text: id2text('resetPassword.page.modal.error.text'),
    },
  };
  const modalObj = modalTextObj.systemError;
  /* 修改密碼 */
  // 獲取全域email 因為沒有登入 取不到auth().email
  const userEmail = useAppSelector((state) => state.resetPasswordDataStore);
  // 密碼修改成功與否
  const [resetPasswordSuccessful, setResetPasswordSuccessful] = useState(false);
  // 修改密碼傳資料給後端
  const editPassword = async (email: string, newPassword: string): Promise<void> => {
    setButtonIsLoading(true);
    await axios.post(services.postChangePassword(), {
      email: userEmail,
      newPassword,
    }, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(() => {
        setResetPasswordSuccessful(true);
      })
      .catch(() => {
        setButtonIsLoading(false);
        /* 錯誤視窗開啟 */
        setIsOpen(true);
      });
  };
  const submitPassword = (submitEmail: string, newPsd: string): void => {
    try {
      editPassword(submitEmail, newPsd);
    } catch (err) {
      // console.log(err);
    }
  };

  // 全部都為空
  const [isAllEmpty, setIsAllEmpty] = useState(true);

  useEffect(() => {
    if (
      passwordValidation.value === ''
            || confirmPasswordValidation.value === ''
    ) {
      setIsAllEmpty(true);
      return;
    }
    setIsAllEmpty(false);
  }, [confirmPasswordValidation.value, passwordValidation.value]);

  // 如果沒有全域email 返回登入頁面
  useEffect(() => {
    if (userEmail == null) {
      navigation(`/${services.getLocale()}${routes.SIGNIN}`);
    }
  }, []);
  return (
    <>
      {/* 提示modal */}
      <EasyDialog
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        showCloseIcon
        title={modalObj.title}
        body={(
          <Typography variant="body1" my="6%">
            {modalObj.text}
          </Typography>
            )}
        sx={{
          minHeight: {
            xl: '0vh',
            lg: '0vh',
            md: '0vh',
            sm: '0vh',
            xs: '0vh',
          },
        }}
      />
      {/* 頁面 */}
      <TitleContainer customTitle={(
        <Grid container justifyContent="center">
          {!resetPasswordSuccessful ? (
            <Typography fontSize="700" variant="h5">
              {id2text('resetPassword.page.title')}
            </Typography>
          ) : (
            <Typography fontSize="700" variant="h5">
              {id2text('resetPassword.page.title.success')}
            </Typography>
          )}
        </Grid>
    )}
      >
        {!resetPasswordSuccessful ? (
          <>
            <Grid
              mt={userTitleContainerStyle.mt40px}
            >
              <Typography>
                {id2text('resetPassword.page.reset.label1')}
              </Typography>
            </Grid>
            <Grid
              mt={userTitleContainerStyle.mt40px}
              width="100%"
            >
              <EasyTextField
                label="Password"
                type="password"
                error={!passwordValidation.isValid}
                helperText={passwordValidation.helperText}
                fullWidth
                value={passwordValidation.value}
                onChange={passwordValidation.handleChange}
                onBlur={passwordValidation.handleValidate}
                InputProps={{
                  style: { height: '3.1875rem', borderRadius: '0.5rem' },
                }}
              />
            </Grid>
            <Grid
              mt={userTitleContainerStyle.mt16px}
              width="100%"
            >
              <EasyTextField
                label="Confirm Password"
                type="password"
                error={!confirmPasswordValidation.isValid}
                helperText={confirmPasswordValidation.helperText}
                fullWidth
                value={confirmPasswordValidation.value}
                onChange={confirmPasswordValidation.handleChange}
                onBlur={confirmPasswordValidation.handleValidate}
                InputProps={{
                  style: { height: '3.1875rem', borderRadius: '0.5rem' },
                }}
              />
            </Grid>
            <Grid
              width="100%"
              mt={userTitleContainerStyle.mt40px}
              container
              justifyContent="space-between"
            >
              <EasyButton
                variant="contained"
                onClick={() => {
                  navigation(`/${services.getLocale()}${routes.SIGNIN}`);
                }}
                color="primary"
                size="large"
                sx={{
                  width: '47.82%',
                }}
                style={{
                  backgroundColor: '#EEFBFB',
                  color: 'rgba(89, 217, 217, 1)',
                }}
                disabled={buttonIsLoading}
              >
                <Typography>{id2text('resetPassword.page.button.cancel')}</Typography>
              </EasyButton>
              <EasyButton
                disabled={
                  !isEqualToPassword(confirmPasswordValidation.value)
                  || !passwordValidation.isValid
                  || !confirmPasswordValidation.isValid
                  || isAllEmpty
                  || buttonIsLoading
                                }
                variant="contained"
                onClick={() => {
                  submitPassword(userEmail as string, passwordValidation.value);
                }}
                color="primary"
                size="large"
                sx={{
                  width: '47.82%',
                }}
              >
                <Typography>{buttonIsLoading ? <CircularProgress size={16} color="inherit" /> : id2text('resetPassword.page.button.reset')}</Typography>
              </EasyButton>
            </Grid>
          </>
        ) : (
          <>
            <Grid
              container
              justifyContent="center"
              mt={userTitleContainerStyle.mt40px}
            >
              <img
                width={120}
                height={120}
                src={excellenceIcon}
                alt="Congratulations"
              />
            </Grid>
            <Grid container justifyContent="center">
              <Grid>
                <Grid
                  mt={userTitleContainerStyle.mt40px}
                  container
                  justifyContent="center"
                >
                  <Typography variant="subtitle1">
                    {id2text('resetPassword.page.reset.label2')}
                  </Typography>
                </Grid>
                <Grid
                  mt={userTitleContainerStyle.mt40px}
                >
                  <EasyButton
                    fullWidth
                    variant="contained"
                    onClick={async () => {
                      if (auth.currentUser) {
                        await signOut(auth).then(() => {
                          setResetPasswordSuccessful(true);
                        });
                      } else {
                        setResetPasswordSuccessful(true);
                      }
                      navigation(`/${services.getLocale()}${routes.SIGNIN}`);
                    }}
                    color="primary"
                    size="large"
                  >
                    <Typography variant="subtitle1">{id2text('resetPassword.page.button.signIn')}</Typography>
                  </EasyButton>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </TitleContainer>
    </>
  );
};

export default ResetPasswordPage;
