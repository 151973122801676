import React from 'react';

/* components */
import EasyDialog from '../../../../../commons/components/modal/EasyDialog';

/* mui */
import Typography from '@mui/material/Typography';
/* mui icon */

/* utils */

/* hooks */

/* types */
import { HintModalProps } from '../TypescriptProps';
/* config */

const HintModal = ({
  isOpen, setIsOpen, modalText, callback,
}:HintModalProps) => {
  const a = 123;
  return (
    <EasyDialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      handleClose={() => {
        if (callback) {
          callback();
        }
        setIsOpen(false);
      }}
      showCloseIcon
      title={modalText.title}
      body={(
        <Typography variant="body1" my="6%">
          {modalText.text}
        </Typography>
            )}
      sx={{
        minHeight: {
          xl: '0vh',
          lg: '0vh',
          md: '0vh',
          sm: '0vh',
          xs: '0vh',
        },
      }}
    />
  );
};

export default HintModal;
