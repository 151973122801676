const signInPage = {
    /* sign in */
    "signIn.page.title":"Welcome Back!",
    "signIn.page.email.label":"Email",
    "signIn.page.password.label":"Password",
    "signIn.page.stay.label":"Stay Logged in",
    "signIn.page.forgotPassword.label":" Forgot Password?",
    "signIn.page.button.signIn":" Sign In",
    "signIn.page.hasAccount.label":"Don't have an account?",
    "signIn.page.goToSignUp.label":"Sign up now",
    "signIn.page.helperTexts.mail":"Email is not valid",
    "signIn.page.helperTexts.password":"Your password must be at least 8 characters long and include uppercase letters, lowercase letters, and numbers.",
    "signIn.page.helperTexts.mailOrPassword":"Please check if your email address field or password is correct.",
    /* 再次登入時 仍未驗證信箱 提示視窗 */
    "signIn.modal.hint.verifyEmail.title":"Verify Your Account",
    "signIn.modal.hint.action":"Go to Setting",
    "signIn.modal.hint.secondary.action":"OK, Later",
    "signIn.modal.hint.verifyEmail.text":"Please check your email to verify your account and use all services of EasyCycle. If you did not received the verification email, please go to “Settings” to resend the verification email.",
}

export default signInPage
