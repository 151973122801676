const forgotPasswordPage = {
    /* forgot password */
    "forgotPassword.page.title": "忘記密碼",
    "forgotPassword.page.helperText.email": "電子信箱無效",
    "forgotPassword.page.step1.label": "請輸入您的電子信箱，我們將向您發送一封電子郵件以重設您的密碼。",
    "forgotPassword.page.step1.button.text": "傳送",
    /* 輸入欄位 提示 */
    "forgotPassword.page.hint.good": "驗證成功。",
    "forgotPassword.page.hint.bad": "驗證碼無效或不正確。 請重試。",
    "forgotPassword.page.verifyCode.label1": "輸入驗證碼。",
    "forgotPassword.page.verifyCode.label2": " 沒有收到電子郵件？",
    "forgotPassword.page.verifyCode.sendingMail1": "正在發送電子郵件...",
    "forgotPassword.page.verifyCode.sendingMail2": "重新發送驗證碼 ({resendTime}s)",
    "forgotPassword.page.verifyCode.resend.text": "重新發送驗證碼",
    /* 輸入驗證碼 提示視窗 */
    "forgotPassword.page.modal.error.title": "錯誤",
    "forgotPassword.page.modal.error.text": "郵件發送錯誤，請稍後再試。",

    /* 輸入email後 提示視窗 */
    "forgotPassword.modal.exist.title": "尚未註冊",
    "forgotPassword.modal.exist.action": "繼續註冊",
    "forgotPassword.modal.exist.secondary.action": "稍後再說",
    "forgotPassword.modal.exist.text": "我們找不到與您輸入的電子信箱相符的帳戶。您想繼續註冊嗎？",
}

export default forgotPasswordPage
