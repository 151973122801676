import React from 'react';
import Grid from '@mui/material/Grid';

interface MenuItemProps {
  onClick: () => void;
  label: string | React.JSX.Element;
}

const MenuOptionItem: React.FC<MenuItemProps> = ({ onClick, label }) => (
  <Grid onClick={onClick} ml="0.5rem" px="1.5rem">
    {label}
  </Grid>
);

export default MenuOptionItem;
