import React from 'react';
import rehypeRaw from 'rehype-raw';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import './index.css';
import remarkBreaks from 'remark-breaks';
// eslint-disable-next-line react/destructuring-assignment
const LinkReader = (props: React.AnchorHTMLAttributes<HTMLAnchorElement>) => <a href={props.href} target="_blank" rel="noreferrer">{props.children}</a>;
/**
 rehypeRaw 是插件 可以轉換字串中的html標籤
 components 可以放自定義的標籤進去，例如<a>標籤想要讓他是另開分頁的模式，就可以寫一個component轉換成自定義<a>標籤

 * */
interface EasyMarkdownReaderProps {
  children:string | undefined | null;
}
const EasyMarkdownReader :React.FC<EasyMarkdownReaderProps> = ({ children }) => (
  <Markdown components={{ a: LinkReader }} rehypePlugins={[rehypeRaw, remarkGfm, remarkBreaks]}>
    {typeof children === 'string' ? children : ''}
  </Markdown>
);

export default EasyMarkdownReader;
