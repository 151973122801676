import { getApps, initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyD47-ZQuDeoOJa5k-KRwpAuXETGQsLYPjw',
  authDomain: 'easycycle-5afb7.firebaseapp.com',
  databaseURL: 'https://easycycle-5afb7-default-rtdb.firebaseio.com',
  projectId: 'easycycle-5afb7',
  storageBucket: 'easycycle-5afb7.appspot.com',
  messagingSenderId: '675013872173',
  appId: '1:675013872173:web:438e4eb1c071b3a8ac86a4',
  measurementId: 'G-SCH2ET36KP',
};

if (getApps().length === 0) {
  initializeApp(firebaseConfig);
}
const app = getApps()[0];
const db = getFirestore(app);
const auth = getAuth(app);

const storage = getStorage(app);

export {
  app, db, auth, firebaseConfig, storage,
};
