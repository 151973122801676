import React, { useState } from 'react';

/* components */
import EasyTextField from '../../../../../commons/components/EasyTextField/EasyTextField';

/* mui */
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
/* mui icon */

/* utils */
import { useFormikContext } from 'formik';
import debounce from '../../../../../utils/debounce';
import { useIntl } from 'react-intl';
import handleCustomerManagement from '../../CustomerManagement/handleCustomerManagement';
/* hooks */
import { useAppSelector } from '../../../../../reduxStore/utils/reduxHooks';

/* types */
import OutsourcingRecyclingProps from '../TypescriptProps';
import CustomerProps from '../../CustomerManagement/TypescriptProps';

/* config */
import { INPUT_LIMIT } from '../config';
import { ORDER_STATUS } from '../../../../../config/config.order';

const CustomerInformation = ({ mode, isButtonLoading }:{ mode:string; isButtonLoading:boolean; }) => {
  /** formik * */
  const {
    values, handleChange, setFieldValue, handleBlur, errors, touched,
  } = useFormikContext<OutsourcingRecyclingProps>();
  const isCompletedOrRecycling = values?.orderStatus === ORDER_STATUS.COMPLETED || values?.orderStatus === ORDER_STATUS.RECYCLING;
  /** react-intl * */
  const { formatMessage } = useIntl();
  const id2text = (id:string) => formatMessage({ id });
  /** redux * */
  const userData = useAppSelector((state) => state.userDataStore);
  /** taxNumber QueryOptions * */
  const [queryOptions, setQueryOptions] = useState<CustomerProps[]>([]);

  const debounceFetchOptions = debounce(async (e:string) => {
    if (!e) return;
    setFieldValue('taxNumber', e);
    await handleCustomerManagement.getCustomerManagementDetail({ uid: userData?.firebaseID, filterText: e }).then((res) => {
      if (res) {
        setQueryOptions(res);
        console.log(res);
      }
    });
  }, 1500);

  return (
    <Box mt="1rem" padding="2rem" border="2px solid rgba(250, 250, 250, 1)" borderRadius="0.5rem" sx={{ boxShadow: '0 0 16px 0 rgba(0,0,0,0.08)' }}>
      {/* 標題 */}
      <Typography variant="h5">
        {id2text('outsourceRecycle.subtitle2')}
      </Typography>
      {/* 聯絡人統編 */}
      <Grid container mt="1.5rem" justifyContent="space-between" alignItems="center">
        <Grid item xs={4}>
          <Typography variant="body1">
            {id2text('common.tax.number')}
            <Typography color="red" variant="caption">&nbsp;*</Typography>
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Autocomplete
            id="taxNumberSearch"
            freeSolo
            renderInput={(params) => (
              <TextField
                {...params}
                key="taxNumber"
                name="taxNumber"
                disabled={isButtonLoading || isCompletedOrRecycling}
                type="inputLimit"
                placeholder={id2text('common.tax.number')}
                label={id2text('common.tax.number')}
                value={values.taxNumber}
                // onChange={handleChange}
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  maxLength: INPUT_LIMIT.taxNumber,
                }}
                onBlur={handleBlur('taxNumber')}
                error={Boolean(touched.taxNumber && errors.taxNumber)}
                helperText={touched.taxNumber ? errors.taxNumber : ''}
              />
            )}
            getOptionLabel={(option) => {
              if (typeof option === 'string') return option;
              return option.taxNumber;
            }}
            onChange={(_, option) => {
              if (option == null) return;
              if (typeof option === 'string') {
                setFieldValue('taxNumber', option);
              } else {
                console.log('OPTION', option);
                setFieldValue('ordererCompany', option?.companyName);
                setFieldValue('taxNumber', option?.taxNumber);
                setFieldValue('ordererName', option?.contactName);
                setFieldValue('ordererMail', option?.contactMail);
                setFieldValue('ordererTel', option?.contactTel);
              }
            }}
            onInputChange={(_, value) => {
              console.log(value);
              debounceFetchOptions(value);
            }}
            renderOption={(props, option) => {
              console.log('OPTIONS', option);
              return (
                <MenuItem {...props} key={option.fusekiID}>
                  <Typography>
                    {option.taxNumber}
                  </Typography>
                </MenuItem>
              );
            }}
            disabled={isButtonLoading || isCompletedOrRecycling}
            value={values.taxNumber}
            options={queryOptions}
            onBlur={handleBlur('taxNumber')}
          />
        </Grid>
      </Grid>
      {/* 公司名稱 */}
      <Grid container mt="1.5rem" justifyContent="space-between" alignItems="center">
        <Grid item xs={4}>
          <Typography variant="body1">
            {id2text('outsourceRecycle.company.name.label')}
            <Typography color="red" variant="caption">&nbsp;*</Typography>
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <EasyTextField
            key="ordererCompany"
            name="ordererCompany"
            disabled={isButtonLoading || isCompletedOrRecycling}
            type="text"
            label={id2text('outsourceRecycle.company.name.label')}
            value={values.ordererCompany}
            onChange={handleChange}
            fullWidth
            InputProps={{
              style: { height: '3.1875rem', borderRadius: '0.5rem' },
            }}
            onBlur={handleBlur('ordererCompany')}
            error={Boolean(touched.ordererCompany && errors.ordererCompany)}
            helperText={touched.ordererCompany ? errors.ordererCompany : ''}
          />
        </Grid>
      </Grid>
      {/* 聯絡人名稱 */}
      <Grid container mt="1.5rem" justifyContent="space-between" alignItems="center">
        <Grid item xs={4}>
          <Typography variant="body1">
            {id2text('outsourceRecycle.orderer.name.label')}
            <Typography color="red" variant="caption">&nbsp;*</Typography>
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <EasyTextField
            key="ordererName"
            name="ordererName"
            disabled={isButtonLoading || isCompletedOrRecycling}
            type="text"
            label={id2text('outsourceRecycle.orderer.name.label')}
            value={values.ordererName}
            onChange={handleChange}
            fullWidth
            InputProps={{
              style: { height: '3.1875rem', borderRadius: '0.5rem' },
            }}
            onBlur={handleBlur('ordererName')}
            error={Boolean(touched.ordererName && errors.ordererName)}
            helperText={touched.ordererName ? errors.ordererName : ''}
          />
        </Grid>
      </Grid>
      {/* 聯絡人電話 */}
      <Grid container mt="1.5rem" justifyContent="space-between" alignItems="center">
        <Grid item xs={4}>
          <Typography variant="body1">
            {id2text('outsourceRecycle.orderer.tel.label')}
            <Typography color="red" variant="caption">&nbsp;*</Typography>
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <EasyTextField
            key="ordererTel"
            name="ordererTel"
            disabled={isButtonLoading || isCompletedOrRecycling}
            type="text"
            label={id2text('outsourceRecycle.orderer.tel.label')}
            value={values.ordererTel}
            onChange={handleChange}
            fullWidth
            InputProps={{
              style: { height: '3.1875rem', borderRadius: '0.5rem' },
            }}
            onBlur={handleBlur('ordererTel')}
            error={Boolean(touched.ordererTel && errors.ordererTel)}
            helperText={touched.ordererTel ? errors.ordererTel : ''}
          />
        </Grid>
      </Grid>
      {/* 聯絡人mail */}
      <Grid container mt="1.5rem" justifyContent="space-between" alignItems="center">
        <Grid item xs={4}>
          <Typography variant="body1">
            {id2text('outsourceRecycle.orderer.mail.label')}
            <Typography color="red" variant="caption">&nbsp;*</Typography>
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <EasyTextField
            key="ordererMail"
            name="ordererMail"
            disabled={isButtonLoading || isCompletedOrRecycling}
            type="text"
            placeholder="Email"
            label={id2text('outsourceRecycle.orderer.mail.label')}
            value={values.ordererMail}
            onChange={handleChange}
            fullWidth
            InputProps={{
              style: { height: '3.1875rem', borderRadius: '0.5rem' },
            }}
            onBlur={handleBlur('ordererMail')}
            error={Boolean(touched.ordererMail && errors.ordererMail)}
            helperText={touched.ordererMail ? errors.ordererMail : ''}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CustomerInformation;
