import React, { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
/* components */
import EasyButton from '../../../../../commons/components/EasyButton/EasyButton';
import EasyIconButton from '../../../../../commons/components/EasyIconButton/EasyIconButton';
import EasyBackIcon from '../../../../../commons/components/EasyIcons/EasyBackIcon';
/* mui */
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Badge } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';

/* utils */
import { useAppDispatch } from '../../../../../reduxStore/utils/reduxHooks';

/* config */
import { auth } from '../../../../../config/config-firebase';
import { useIntl } from 'react-intl';
import {
  pageMode, PASSWORD_PLACEHOLDER,
} from '../config';
import userAct from '../../../userAction';
import services from '../../../../../api/services';
import routes from '../../../../../config/routes';
/* images */
import UserAvatar from '../../../../../images/navbarIcon/UserAvatar.png';
import EditImg from '../../../../../images/icons/icon_edit/Edit_dark_grey.svg';

interface EditUserCardProps {
  setMode: Dispatch<SetStateAction<string>>;
  setIsDeleteAccountModalOpen: Dispatch<SetStateAction<boolean>>;
}

const EditUserCard :React.FC<EditUserCardProps> = ({
  setMode, setIsDeleteAccountModalOpen,
}):React.JSX.Element => {
  /** react intl */
  const { formatMessage } = useIntl();
  const id2text = (id:string) => formatMessage({ id });
  //
  const showDelAccount = process.env.REACT_APP_DEL_ACCOUNT_FEA_PUBLIC === 'true';
  const dispatch = useAppDispatch();
  const navigation = useNavigate();
  // 是否沒有含password
  const isPasswordProvider = auth.currentUser?.providerData.some((item) => item.providerId === 'password');

  return (
    <Box>
      {/* Back */}
      <Box width="100%" display="flex" alignItems="center" mt="1.5rem">
        <EasyIconButton onClick={() => setMode(pageMode.SETTING)}>
          <EasyBackIcon />
        </EasyIconButton>
        <Typography variant="buttonText1">
          {id2text('common.back')}
        </Typography>
      </Box>
      {/* edit userIcon */}
      <Box
        mt="1.5rem"
        onClick={() => {
          console.log('auth?.currentUser?.providerData', auth?.currentUser?.providerData);
        }}
      >
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={(
            <Avatar
              alt={id2text('common.edit')}
              src={EditImg}
              sx={{
                backgroundColor: 'neutral.min',
                width: '1.5rem',
                height: '1.5rem',
                img: {
                  width: '12px',
                  height: '12px',
                },
              }}
            />
          )}
        >
          <Avatar
            alt="Travis Howard"
            src={UserAvatar}
            sx={{
              cursor: 'pointer',
              width: '4rem',
              height: '4rem',
            }}
          />
        </Badge>
      </Box>
      {/*  email  */}
      <Box display="flex" flexDirection="column" mt="1.5rem">
        <Typography variant="h6">
          {id2text('common.email')}
        </Typography>
        <Typography variant="body1" color="neutral.600">
          {auth.currentUser?.email}
        </Typography>
      </Box>
      {/*  password */}
      <Box display="flex" justifyContent="space-between" mt="1.5rem">
        <Box display="flex" flexDirection="column">
          <Typography variant="h6">
            {id2text('setting.page.label.password')}
          </Typography>
          <Typography variant="body1" color="neutral.600">
            {PASSWORD_PLACEHOLDER}
          </Typography>
        </Box>
        {isPasswordProvider && (
          <Box display="flex" alignItems="center">
            <EasyButton
              variant="contained"
              onClick={() => {
                if (typeof auth?.currentUser?.email === 'string') {
                  dispatch(userAct.FIREBASE_RESET_PASSWORD(auth?.currentUser?.email));
                  navigation(`/${services.getLocale()}${routes.CHANGE_PASSWORD}`);
                }
              }}
            >
              <Typography variant="buttonText1">
                {id2text('setting.page.button.changePassword')}
              </Typography>
            </EasyButton>
          </Box>
        )}
      </Box>
      {/*  delete account */}
      {showDelAccount && (
        <Box mt="1.5rem">
          <Link
            variant="link1"
            rel="noopener noreferrer"
            color="primary"
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              setIsDeleteAccountModalOpen(true);
            }}
          >
            <Typography variant="link1" sx={{ textDecoration: 'underline', color: 'red' }}>
              {id2text('setting.modal.deleteAccount.title.default')}
            </Typography>
          </Link>
        </Box>
      )}
    </Box>
  );
};

export default EditUserCard;
