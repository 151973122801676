import React, { Dispatch, SetStateAction } from 'react';
import { Chip } from '@mui/material';
// component
import EasyButton from '../../../../../commons/components/EasyButton/EasyButton';
import EasyAvatar from '../../../../../commons/components/EasyAvatar/EasyAvatar';
// mui
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// config
import { auth } from '../../../../../config/config-firebase';
import { useIntl } from 'react-intl';
import { pageMode } from '../config';

interface UserCardProps {
  imgSrc:string;
  email:string;
  handleOpenEmailModal:()=>void;
  handleDeleteAccountModal:()=>void;
  setMode:Dispatch<SetStateAction<string>>;
  // date:string;
}

const UserCard :React.FC<UserCardProps> = ({
  imgSrc, email, handleOpenEmailModal, handleDeleteAccountModal, setMode,
}):React.JSX.Element => {
  const showDelAccount = process.env.REACT_APP_DEL_ACCOUNT_FEA_PUBLIC === 'true';
  /** react intl */
  const { formatMessage } = useIntl();
  const id2text = (id:string) => formatMessage({ id });
  //
  return (
    <Grid
      sx={{
        boxShadow: '0 0 16px 0 rgba(0,0,0,0.08)',
        borderRadius: '0.5rem',
      }}
    >
      <Grid padding="1.5rem">
        <Box width="100%" display="flex" justifyContent="space-between">
          <Box width="100%" display="flex" justifyContent="start">
            <Box>
              <EasyAvatar size="large" src={imgSrc} />
            </Box>
            <Box ml="1rem" display="flex" flexDirection="column" overflow="hidden" maxWidth={{ md: '100%', sm: '100%', xs: '100%' }}>
              <Typography noWrap variant="h6" sx={{ textOverflow: 'ellipsis' }}>{email}</Typography>
              <Box display="flex" alignItems="center" mt="0.25rem" sx={{ height: 'auto', width: 'auto', fontSize: '10px' }}>
                {
                  auth.currentUser?.emailVerified != null
                    ? <Chip variant="filled" size="small" label={auth.currentUser?.emailVerified === true ? id2text('setting.userCard.chip.verified') : id2text('setting.userCard.chip.unVerified')} color={auth.currentUser?.emailVerified === true ? 'primary' : 'error'} />
                    : <Chip variant="filled" size="small" label="Loading" color="secondary" />
                }
              </Box>
            </Box>
          </Box>
          <Box width="100%" display="flex" alignItems="center" justifyContent="end">
            <EasyButton
              variant="contained"
              onClick={() => {
                setMode(pageMode.EDIT_USER_CARD);
                window.scrollTo(0, 0);
              }}
            >
              <Typography variant="buttonText1">
                {id2text('common.edit')}
              </Typography>
            </EasyButton>
          </Box>
        </Box>
        {/* {showDelAccount && ( */}
        {/*  <Box mt="1rem"> */}
        {/*    <EasyButton variant="contained" onClick={handleDeleteAccountModal}> */}
        {/*      {id2text('setting.userCard.button.deleteAccount')} */}
        {/*    </EasyButton> */}
        {/*  </Box> */}
        {/* )} */}
        {
            auth.currentUser?.emailVerified == null || auth.currentUser?.emailVerified === true
              ? null
              : (
                <Box mt="1rem">
                  <EasyButton variant="contained" size="small" style={{ backgroundColor: '#D32F2F' }} onClick={handleOpenEmailModal}>
                    <Typography>
                      {id2text('setting.userCard.button.verifyAccount')}
                    </Typography>
                  </EasyButton>
                </Box>
              )
          }
      </Grid>
    </Grid>
  );
};

export default UserCard;
