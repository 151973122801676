import React from 'react';
// react-router-dom
import { useNavigate } from 'react-router-dom';
// components
import EasyDialog from '../../../commons/components/modal/EasyDialog';
// hooks
import { useAppSelector } from '../../../reduxStore/utils/reduxHooks';
// mui
import Typography from '@mui/material/Typography';
// config
import services from '../../../api/services';
import routes from '../../../config/routes/index';
import { useIntl } from 'react-intl';
import { string } from 'yup';

interface VerifyPopUpProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}
const VerifyModal: React.FC<VerifyPopUpProps> = ({ setIsOpen, isOpen }) => {
  /** react-intl */
  const { formatMessage } = useIntl();
  const id2text = (id:string) => formatMessage({ id });
  //
  const navigation = useNavigate();
  const user = useAppSelector((state) => state.userDataStore);

  const handleGoToSetting = (): void => {
    setIsOpen(false);
    if (typeof user?.firebaseID !== 'string') return;
    navigation(`/${services.getLocale()}${routes.DASHBOARD_SETTINGS}`);
  };

  const handleOKLater = (): void => {
    setIsOpen(false);
    navigation(`/${services.getLocale()}${routes.HOME}`);
  };

  return (
    <EasyDialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      showCloseIcon
      title={id2text('signIn.modal.hint.verifyEmail.title')}
      action={handleGoToSetting}
      actionLabel={id2text('signIn.modal.hint.action')}
      secondaryAction={handleOKLater}
      secondaryLabel={id2text('signIn.modal.hint.secondary.action')}
      handleClose={() => navigation(`/${services.getLocale()}${routes.HOME}`)}
      body={(
        <Typography>
          {id2text('signIn.modal.hint.verifyEmail.text')}
        </Typography>
              )}
    />
  );
};

export default VerifyModal;
