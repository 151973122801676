import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

// MUI
import Grid from '@mui/material/Grid';

// Images
import placeholderIcon from '../../../images/navbarIcon/UserAvatar.png';

// utils
import SignOut from '../../../pages/Users/utils/signOut';

// configs
import routes from '../../../config/routes';
import authority from '../../../App-authority';
import services from '../../../api/services';

// components
import EasyAvatar from '../EasyAvatar/EasyAvatar';
import MenuOptionItem from './MenuOptionItem';

// hooks
import useCheckClickOutside from '../../../hooks/useCheckClickOutside';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../reduxStore/utils/reduxHooks';
// Typescript
interface UserMenuProps {
  isLogin: boolean;
}

const UserMenu: React.FC<UserMenuProps> = ({ isLogin }): React.JSX.Element => {
  const navigation = useNavigate();
  const dispatch = useAppDispatch();
  /** 控制側選單 需要填入哪些資訊才開放 * */
  const userGroup = useAppSelector((state) => state.userGroupRoleStore);
  const userData = useAppSelector((state) => state.userDataStore);

  // const isOpenArea = () => checkHandlerDataFull({
  //   userGroup,
  //   userData,
  //   editProfileData,
  // });
  const { ref, isOpen, setIsOpen } = useCheckClickOutside(false);

  // const userRole = userData?.role;
  const redirectionRoute = `/${services.getLocale()}${routes.REDIRECTION}`;
  // const authData = auth.currentUser;
  // const authName = authData?.displayName;
  // const authEmail = authData?.email;
  // const userName = userData?.name;
  // const userFullName = userData?.fullName;
  // const userEmail = userData?.email;
  // const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = useCallback(() => {
    setIsOpen((value) => !value);
  }, []);

  // 是否開放 user dashboard
  const showDashBoardPage =
    process.env.REACT_APP_USER_DASHBOARD_PAGE_PUBLIC === 'true';

  const handleSignOut = async (): Promise<void> => {
    setIsOpen((value) => !value);
    await SignOut(navigation, dispatch);
  };

  const handleGoToDashboard = (route: string): void => {
    setIsOpen((value) => !value);
    navigation(userGroup ? route : redirectionRoute);
  };

  // menuOption
  interface MenuOption {
    id: string;
    label: string | React.JSX.Element;
    onClick: () => void;
    show: boolean;
  }
  const menuOption: MenuOption[] = [
            {
      id: 'UserMenu_Settings',
      label: 'Settings',
      onClick: () => {
        handleGoToDashboard(
          `/${services.getLocale()}${routes.DASHBOARD_SETTINGS}`,
        );
      },
      show: showDashBoardPage,
    },
        {
      id: 'UserMenu_SignOut',
      label: 'Sign out',
      onClick: handleSignOut,
      show: true,
    },
  ];

  const menuOptionOpen = menuOption.filter((m) => m.show);

  return (
    <Grid position="relative" sx={{ zIndex: 999 }}>
      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 3,
        }}
      >
        {/* <Grid item sx={{ display: { xs: 'none', md: 'block' }, cursor: 'pointer' }}> */}
        {/*  <p>EN</p> */}
        {/* </Grid> */}
        <Grid>
          <Grid
            onClick={toggleOpen}
            sx={{
              padding: '1rem',
              '@media (min-width: 768px)': {
                padding: '1rem 0',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 3,
                borderRadius: '0.5rem',
                cursor: 'pointer',
                transition: 'background-color 0.3s ease',
              },
            }}
          >
            <Grid sx={{ display: { md: 'block' } }}>
              <EasyAvatar
                size="large"
                src={userData?.imageName ?? placeholderIcon}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {isOpen && isLogin != null && (
        <Grid
          ref={ref}
          position="absolute"
          sx={{
            width: {
              xl: '16rem',
              lg: '16rem',
              md: '16rem',
              sm: '12rem',
              xs: '12rem',
            },
            overflow: 'hidden',
            top: '4rem',
            right: '1rem',
            backgroundColor: 'white',
            borderRadius: '0.5rem',
            boxShadow: '0 0 16px 0 rgba(0,0,0,0.08)',
          }}
        >
          <Grid my="0.5rem" sx={{ cursor: 'pointer' }}>
            {menuOptionOpen.map((option) => (
              <Grid
                key={option.id}
                py="0.5rem"
                sx={{ '&:hover': { backgroundColor: 'primary.50' } }}
              >
                <MenuOptionItem onClick={option.onClick} label={option.label} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default UserMenu;
