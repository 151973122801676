import axios from 'axios';
import services from '../../../../api/services';
import { AccountUser } from '../../TypescriptProps';
import { isEqual } from 'lodash-es';
import { ClassType, Graphs } from '../../../../config/config.fuseki';

interface FusekiHandlerProps {
  /* 共用資料 */
  firebaseID: string;
  imageName:string;
  group?:string;
  role?:string;
  hasGroupEvent: object[] | string | string[];
  companyName:string;
  companyTel:string;
  /* 地址 */
  postalCode:string;
  country:string;
  state:string;
  city:string;
  address1:string;
  address2:string;
  lat:string;
  long:string;
  /* 營業登記 */
  taxNumber:string;
  licenseNumber:string;
  /* 聯絡資訊 */
  // providerID:string | string[];
  contactName:string;
  contactMail:string;
  contactTel:string;
}

type KeyValue = {
  srcId:string;
  key:string;
  value:string;
};

const createData = async (
  {
    classType,
    value,
  }:{ classType: string, value: FusekiHandlerProps },
): Promise<boolean> => {
  const response = await axios.post(services.postHandler, {
    entry: {
      srcId: '',
      graph: 'rHandler',
      classType: `${classType}`,
      value: {
        ...value,
      },
    },
  }, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response.data;
};

const readData = async ({
  firebaseID,
  // isNeedLastModified = false,
}:{ firebaseID:string }) => {
  const response = await axios.get(
    `${services.getHandler(firebaseID)}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  // interface ItemProps {
  //   key:string;
  //   value:string;
  // }
  // const handlerData = {} as AccountUser;
  // const fusekiID = { id: null };
  const responseData = response.data;
  // if (tempData.data.length > 0) {
  //   fusekiID.id = tempData.data[0].srcId;
  //   tempData.data.forEach((item:ItemProps) => {
  //     handlerData[item.key] = item.value;
  //   });
  // }
  // // console.log('tempData', tempData);
  // if (!tempData) {
  //   return null;
  // }
  // console.log(handlerData);
  const { data } = responseData;
  const result = Object.values(data.reduce((resultItem:Record<string, any>, item:KeyValue) => {
    const newResultItem = { ...resultItem };
    const { srcId, key, value } = item;
    // if (key === 'lastModified') {
    //   return newResultItem;
    // }
    if (!resultItem[srcId]) {
      newResultItem[srcId] = { fusekiID: srcId };
    }
    newResultItem[srcId][key] = value;
    // console.log(newResultItem);
    return newResultItem;
  }, {}))[0] as AccountUser;
  const filterGroupRoleData:{ srcId:string;key:string;value:string }[] = responseData.data.filter((item:KeyValue) => item.key === 'groupRole');
  const returnGroupRole = filterGroupRoleData.map((item) => ({ group: item.value.split('_')[0], role: item.value.split('_')[1].split(',') }));
  // console.log('filterGroupRoleData', filterGroupRoleData);
  console.log('groupRole', returnGroupRole);

  /** 群組角色資料格式 * */
  /* 範例: [{group:'Admin',role:['reader','editor']} , group:'Handler',role:['reader','editor']}] */

  /* 需要 lastModified */
  // if (isNeedLastModified) {
  //   return { data: result };
  // }
  /* 不需要 lastModified */
  // const { lastModified, ...newHandlerData } = result;
  /* 不需要 在data回傳groupRole */
  if (result?.groupRole) {
    const { groupRole, ...newHandlerData } = result;
    console.log('result', newHandlerData);
    // console.log(handlerData);
    return { data: newHandlerData, groupRole: returnGroupRole };
  }
  return { data: result, groupRole: returnGroupRole };
};

const updateData = async (
  {
    firebaseID,
    value,
  }:{ firebaseID:string | undefined, value: Partial<FusekiHandlerProps> },
): Promise<boolean> => {
  if (typeof firebaseID === 'string') {
    const userData = await readData({ firebaseID });
    if (!userData) return false;
    // Object.keys(value).forEach((key) => {
    //   if (!(key in userData.data)) {
    //     userData.data[key] = '';
    //   }
    // });
    /* 如果更新前後資料相同，則return */
    const { firebaseID: uid, ...oldValue } = userData.data;
    const newValue = { ...value };
    if (isEqual(newValue, oldValue)) {
      return true;
    }
    /* 如果是 fusekiID為 SVD 開頭 是serviceProvider */
    const isServiceProvider = userData.data.fusekiID.startsWith('SVD');
    await axios.put(services.putHandler, {
      entrySrc: {
        srcId: userData.data.fusekiID,
        graph: isServiceProvider ? Graphs.serviceProvider : Graphs.rhandler,
        classType: isServiceProvider ? ClassType.SVP : ClassType.HDL,
        value: {
          ...oldValue,
        },
      },
      entryDst: {
        srcId: userData.data.fusekiID,
        graph: isServiceProvider ? Graphs.serviceProvider : Graphs.rhandler,
        classType: isServiceProvider ? ClassType.SVP : ClassType.HDL,
        value: {
          ...newValue,
        },
      },
    }, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  return true;
};

const deleteData = async (
  {
    firebaseID,
  }:{ firebaseID:string | undefined, },
): Promise<boolean> => {
  if (typeof firebaseID === 'string') {
    const userData = await readData({ firebaseID });
    if (!userData) return false;
    /* 如果是 fusekiID為 SVD 開頭 是serviceProvider */
    const isServiceProvider = userData.data.fusekiID.startsWith(ClassType.SVP);
    await axios.delete(services.delPersonAdmin, {
      data: {
        entry: {
          srcId: userData.data.fusekiID,
          graph: isServiceProvider ? Graphs.serviceProvider : Graphs.rhandler,
          classType: isServiceProvider ? ClassType.SVP : ClassType.HDL,
          value: {

          },
        },
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
  return true;
};

const handleFusekiHandlerData = {
  createData,
  readData,
  updateData,
  deleteData,
};
export default handleFusekiHandlerData;
