const debounce = <T extends (...args:any[])=>Promise<void > | void >(func:T, delay:number) :((...args:Parameters<T>)=>void) => {
  let timerId:NodeJS.Timeout;
  return (...args:Parameters<T>) => {
    clearTimeout(timerId);
    timerId = setTimeout(async () => {
      await func(...args);
    }, delay);
  };
};

export default debounce;
