import React from 'react';

// Images
import EditImg from '../../../images/icons/icon_edit/Edit.svg';
import EditBlackImg from '../../../images/icons/icon_edit/Edit_black.svg';
import EditGreyImg from '../../../images/icons/icon_edit/Edit_grey.svg';

// configs
export enum IconColor {
  disabled = 'disabled',
  grey = 'grey',
  black = 'black',
  primary = 'primary',
}
enum IconSize {
  xl = 32,
  lg = 28,
  md = 24,
  sm = 20,
  xs = 16,
}

const imageSrc: { [key: string]: string } = {
  [IconColor.disabled]: EditGreyImg,
  [IconColor.grey]: EditGreyImg,
  [IconColor.black]: EditBlackImg,
  [IconColor.primary]: EditImg,
};

// TypeScript
type ValidSize = keyof typeof IconSize;

interface EasyEditProps {
  color?: IconColor;
  size?: ValidSize;
  width?: number;
  height?: number;
  style?: React.CSSProperties;
}

const EasyEditIcon: React.FC<EasyEditProps> = ({
  color,
  size = 'md',
  width,
  height,
  ...props
}): React.JSX.Element => (
  <img
    src={imageSrc[color ?? IconColor.primary]}
    width={width ?? IconSize[size]}
    height={height ?? IconSize[size]}
    alt="Icon"
    {...props}
  />
);

export default EasyEditIcon;
