import React, { useEffect, useState } from 'react';
// MUI
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

// component
import EasyContainer from '../../../commons/components/EasyContainer/EasyContainer';
import EasyMarkdownReader from '../../../commons/components/EasyMarkdownReader/EasyMarkdownReader';
import Loading from '../../../commons/components/loading/Loading';
import { ContractManagementProps } from '../../../types/contractManagement/TypescriptProps';
import handlePolicy from '../../Users/utils/fuseki/handlePolicy';
import format from 'date-fns/format';
import { useIntl } from 'react-intl';

//
const PolicyPage = (): React.JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);
  const [policyData, setPolicyData] = useState<ContractManagementProps>({
    fusekiID: '',
    contractContent: '',
    contractName: '',
    contractStatus: '',
    contractType: '',
    releaseTime: '',
    versionNumber: '',
  });
  /** react intl */
  const { formatMessage } = useIntl();
  const id2text = (id:string) => formatMessage({ id });

  useEffect(() => {
    handlePolicy({ cttIds: `${process.env.REACT_APP_PRIVACY_POLICY_ID}` as string }).then((res) => {
      setPolicyData(res[0]);
    }).then(() => {
      setIsLoading(false);
    });
  }, []);

  // TODO: 內文改成 fetch API 取得 markdown 格式, 再 render 至畫面中

  return (
    <EasyContainer>
      {isLoading ? <Loading /> :
        (
          <Box display="flex" flexDirection="column">
            <Typography variant="h4">
              {policyData?.contractName ?? ''}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: 'neutral.600',
              }}
            >
              {id2text('policy.effective.date')}
              {typeof policyData?.releaseTime === 'string' ? format(parseInt(policyData?.releaseTime, 10), 'MMMM d, yyyy') : ''}
            </Typography>
            <Box mt="1rem">
              <EasyMarkdownReader>
                {policyData?.contractContent ?? ''}
              </EasyMarkdownReader>
            </Box>
          </Box>
        )}

    </EasyContainer>
  );
};

export default PolicyPage;
