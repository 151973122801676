import { useEffect, useState } from 'react';

interface UseScrollToTop {
  isLoading: boolean;
  setIsLoading: (e: boolean) => void;
}
const useScrollToTop = (): UseScrollToTop => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return { isLoading, setIsLoading };
};
export default useScrollToTop;
