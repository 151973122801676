import React, { useState, useEffect } from 'react';
/* component */
import EasyButton from '../../../../../commons/components/EasyButton/EasyButton';
import Loading from '../../../../../commons/components/loading/Loading';
import RecyclingInformation from './RecyclingInformation';
import CustomerInformation from './CustomerInformation';
import EasyDialog from '../../../../../commons/components/modal/EasyDialog';
/* config */
import {
  CHECK_PARAMS_TYPE, INPUT_LIMIT,
  pageMode,
} from '../config';
import { auth } from '../../../../../config/config-firebase';
import outsourceRecycleAction from '../outsourceRecycleAction';
import { ORDER_STATUS } from '../../../../../config/config.order';
/* type */
import OutsourcingRecyclingProps from '../TypescriptProps';
/* mui */
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import CircularProgress from '@mui/material/CircularProgress';
/* utils */
import { Formik } from 'formik';
import {
  object, string,
} from 'yup';
import format from 'date-fns/format';
import handleOutsourcingRecyclingData from '../handleOutsourcingRecyclingData';
import { useIntl } from 'react-intl';
/* hooks */
import { useAppDispatch, useAppSelector } from '../../../../../reduxStore/utils/reduxHooks';

interface AddNewOrderProps {
  selectedOrder:OutsourcingRecyclingProps;
  mode:string;
  setMode:(e:string)=>void;

}
const OrderForm = ({
  setMode, selectedOrder, mode,
}:AddNewOrderProps) => {
  /** react-intl */
  const { formatMessage } = useIntl();
  const id2text = (id:string) => formatMessage({ id });
  //
  const taxNumberLimit = INPUT_LIMIT.taxNumber;
  const customizedNumberLimit = INPUT_LIMIT.customizedNumber;
  const tripletNumberLimit = INPUT_LIMIT.tripletNumber;

  // formik config
  const helperTexts = {
    empty: id2text('outsourceRecycle.form.helperTexts.empty'),
    exist: id2text('outsourceRecycle.form.helperTexts.exist'),
    format: id2text('outsourceRecycle.form.helperTexts.format'),
    ordererMail: id2text('outsourceRecycle.form.helperTexts.ordererMail'),
    ordererTel: id2text('outsourceRecycle.form.helperTexts.ordererTel.tw'),
    taxNumber: formatMessage({ id: 'outsourceRecycle.form.helperTexts.taxNumber.length' }, { taxNumberLimit }),
    customizedNumber: formatMessage({ id: 'outsourceRecycle.form.helperTexts.customizedNumber.length' }, { customizedNumberLimit }),
    tripletNumber: formatMessage({ id: 'outsourceRecycle.form.helperTexts.tripletNumber.length' }, { tripletNumberLimit }),
  };
  const regex = {
    ordererMail: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    ordererTel: /^(?:(?:\+?886)?(\d{9})|(0[2-8]\d{7,8})|(09\d{2}\d{3}\d{3}))$/,

  };
  const validationSchema = object({
    /* 客戶資訊 */
    ordererName: string().required(helperTexts.empty),
    ordererMail: string().required(helperTexts.empty).matches(regex.ordererMail, helperTexts.ordererMail),
    ordererTel: string().required(helperTexts.empty).matches(regex.ordererTel, helperTexts.ordererTel),
    ordererCompany: string().required(helperTexts.empty),
    /* 訂單狀態相關 */
    orderStatus: string().required(helperTexts.empty),
    /* Outsourcing Recycling相關 */
    tripletNumber: string().test('isNeedTripletNumber', helperTexts.empty, (value, context) => {
      const isControlled = context.parent.isControlled === '1';
      if (!isControlled) {
        return true;
      }
      return value != null;
    }).test('tripletNumberLength', helperTexts.tripletNumber, (value, context) => {
      const isControlled = context.parent.isControlled === '1';
      if (!isControlled) {
        return true;
      }
      return value !== undefined && value.length === INPUT_LIMIT.tripletNumber;
    }),
    customizedNumber: string().test('isNeedCustomizedNumber', helperTexts.empty, (value, context) => {
      const isControlled = context.parent.isControlled === '1';
      if (isControlled) {
        return true;
      }
      return value != null;
    }).test('customizedNumberLength', helperTexts.customizedNumber, (value, context) => {
      const isControlled = context.parent.isControlled === '1';
      if (isControlled) {
        return true;
      }
      return value !== undefined && value.length === INPUT_LIMIT.customizedNumber;
    }),
    // onBoardDocumentNumber: string().required(helperTexts.empty),
    isAssociated: string().required(helperTexts.empty),
    isControlled: string().required(helperTexts.empty),
    recyclingCode: string().required(helperTexts.empty),
    receptionist: string().required(helperTexts.empty),
    arrivedDate: string().required(helperTexts.empty),
    arrivedTime: string().required(helperTexts.empty),
    grossWeight: string().required(helperTexts.empty),
    taxNumber: string().required(helperTexts.empty).test('tax Number', helperTexts.taxNumber, (value) => value.length === INPUT_LIMIT.taxNumber),
  });
  //
  const MODAL_HINT = {
    ADD: {
      SUCCESS: {
        title: id2text('outsourceRecycle.modal.hint.add.success.title'),
        text: id2text('outsourceRecycle.modal.hint.add.success.text'),
      },
      FAILED: {
        title: id2text('outsourceRecycle.modal.hint.add.failed.title'),
        text: id2text('outsourceRecycle.modal.hint.add.failed.text'),
      },
    },
    EDIT: {
      SUCCESS: {
        title: id2text('outsourceRecycle.modal.hint.edit.success.title'),
        text: id2text('outsourceRecycle.modal.hint.edit.success.text'),
      },
      FAILED: {
        title: id2text('outsourceRecycle.modal.hint.edit.failed.title'),
        text: id2text('outsourceRecycle.modal.hint.edit.failed.text'),
      },
    },
  };

  const [isLoading, setIsLoading] = useState(false);
  const [order, setOrder] = useState<OutsourcingRecyclingProps>({} as OutsourcingRecyclingProps);
  const isEditMode = mode === pageMode.EDIT;
  const formatToday = format(+new Date(), 'yyyy-MM-dd');
  const userData = useAppSelector((state) => state.userDataStore);
  /** 輸入欄位 * */

  /** function * */
  const getCustomNumberDate = () => `${new Date().getFullYear() - 1911}${(new Date().getMonth() + 1).toString().padStart(2, '0')}${(new Date().getDate()).toString().padStart(2, '0')}`;
  const getNumberRegex = (maxNumber:number) => new RegExp(`^\\d{${maxNumber}}$`);

  /** 按鈕 開關* */
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  /** hint modal * */
  const [isOpen, setIsOpen] = useState(false);
  const [modalText, setModalText] = useState(isEditMode ? MODAL_HINT.EDIT.SUCCESS : MODAL_HINT.ADD.SUCCESS);
  /* 用redux把值備份一份 */
  const outsourceData = useAppSelector((state) => state.selectedOutsourceStore);
  const dispatch = useAppDispatch();
  /* 根據mode變化 */
  useEffect(() => {
    switch (mode) {
      case pageMode.ADD:
        setOrder({} as OutsourcingRecyclingProps);
        dispatch(outsourceRecycleAction.SET_OUTSOURCE({} as OutsourcingRecyclingProps));
        break;
      case pageMode.EDIT:
        setOrder(selectedOrder);
        dispatch(outsourceRecycleAction.SET_OUTSOURCE(selectedOrder));
        break;
      default: break;
    }
  }, [mode]);
  /* 載入時使用 */
  return (
    <>
      <EasyDialog
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleClose={() => {
          setIsOpen(false);
          setMode(pageMode.LIST);
        }}
        showCloseIcon
        title={modalText.title}
        body={(
          <Typography variant="body1" my="6%">
            {modalText.text}
          </Typography>
          )}
        sx={{
          minHeight: {
            xl: '0vh',
            lg: '0vh',
            md: '0vh',
            sm: '0vh',
            xs: '0vh',
          },
        }}
      />
      <Formik
        initialValues={{
          fusekiID: order?.fusekiID ?? '',
          /* 客戶資訊 */
          ordererName: order?.ordererName ?? '',
          ordererMail: order?.ordererMail ?? '',
          ordererTel: order?.ordererTel ?? '',
          ordererCompany: order?.ordererCompany ?? '',
          taxNumber: order?.taxNumber ?? '',
          /* 訂單狀態相關 */
          orderStatus: order?.orderStatus ?? ORDER_STATUS.ARRIVED, // 訂單狀態
          /* Outsourcing Recycling相關 */
          // EPATrackingNumber: order?.EPATrackingNumber ?? '', // environmental protection agency
          tripletNumber: order?.tripletNumber ?? '', // 三聯單編號
          customizedNumber: order?.customizedNumber ?? `${userData?.taxNumber}${getCustomNumberDate()}` ?? '', // 客製化編號
          onBoardDocumentNumber: order?.onBoardDocumentNumber ?? '', // 隨車文件
          isAssociated: order?.isAssociated ?? '1', // 0 or 1 是否被太陽能協會補助
          isControlled: order?.isControlled ?? '1', // 0 or 1 是否為列管
          recyclingCode: order?.recyclingCode ?? '', // 有三種 其中列管有兩個選項 非列管三個選項
          // dischargeApplicationNumber: order?.dischargeApplicationNumber ?? '', // 案場排出申請編號
          receptionist: order?.receptionist ?? userData?.contactName, // 建單人員
          arrivedDate: order?.bookingTime ? format(parseInt(order.bookingTime, 10), 'yyyy-MM-dd') : formatToday,
          arrivedTime: order?.bookingTime ? `${new Date(parseInt(order.bookingTime, 10)).getHours().toString().padStart(2, '0')}:${new Date(parseInt(order.bookingTime, 10)).getMinutes().toString().padStart(2, '0')}` : '00:00',
          grossWeight: order?.grossWeight ?? '',
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setErrors }) => {
          setIsButtonLoading(true);
          const bookingTime = Date.parse(`${values.arrivedDate} ${values.arrivedTime}`).toString();
          const errors = {} as { tripletNumber:string;onBoardDocumentNumber:string;customizedNumber:string };
          setErrors({});
          if (values.isControlled === '1') {
            if (!(outsourceData?.tripletNumber === values.tripletNumber)) {
              /* 檢查格式 */
              const isValidTripletNumber = /^[a-zA-Z0-9]{16}$/.test(values.tripletNumber);
              if (isValidTripletNumber) {
                /* 檢查是否存在 */
                const tripletNumberExist = await handleOutsourcingRecyclingData.checkParamsExist(values.tripletNumber, CHECK_PARAMS_TYPE.TRIPLET_NUMBER);
                if (tripletNumberExist) {
                  errors.tripletNumber = helperTexts.exist;
                }
              } else {
                errors.tripletNumber = helperTexts.format;
              }
            }
          } else {
            if (!(outsourceData?.customizedNumber === values.customizedNumber)) {
              /* 檢查格式 */
              const number17Regex = getNumberRegex(INPUT_LIMIT.customizedNumber);
              /* 先檢查是否為17碼，若不是則後面不用檢查 */
              if (number17Regex.test(values.customizedNumber)) {
                const correctTaxNumber = userData?.taxNumber === values.customizedNumber.slice(0, userData?.taxNumber.length);
                const dateString = values.customizedNumber.slice(userData?.taxNumber.length, -2);
                const year = dateString.slice(0, 3);
                const date = dateString.slice(3);
                const correctYear = (parseInt(year, 10) + 1911 <= 2099 && parseInt(year, 10) + 1911 >= new Date().getFullYear());
                const correctDate = !Number.isNaN(new Date(`${parseInt(year, 10) + 1911}/${date.slice(0, 2)}/${date.slice(2)}`).getTime());
                if (correctYear && correctDate && correctTaxNumber) {
                /* 檢查是否存在 */
                  const customizedNumberExist = await handleOutsourcingRecyclingData.checkParamsExist(values.customizedNumber, CHECK_PARAMS_TYPE.CUSTOMIZED_NUMBER);
                  if (customizedNumberExist) {
                    errors.customizedNumber = helperTexts.exist;
                  }
                } else {
                  errors.customizedNumber = helperTexts.format;
                }
              } else {
                errors.customizedNumber = helperTexts.format;
              }
            }
            if (!(outsourceData?.onBoardDocumentNumber === values.onBoardDocumentNumber)) {
              // /* 檢查格式 */
              // const number18Regex = getNumberRegex(18);
              // /* 先檢查是否為18碼，若不是則後面不用檢查 */
              // if (number18Regex.test(values.onBoardDocumentNumber)) {
              if (values.onBoardDocumentNumber) {
                const onBoardDocumentNumberExist = await handleOutsourcingRecyclingData.checkParamsExist(values.onBoardDocumentNumber, CHECK_PARAMS_TYPE.ON_BOARD_DOCUMENT_NUMBER);
                if (onBoardDocumentNumberExist) {
                  errors.onBoardDocumentNumber = helperTexts.exist;
                }
              }
              //   else {
              //     errors.onBoardDocumentNumber = helperTexts.format;
              //   }
              // }
            }
          }
          await setErrors(errors);
          if (Object.keys(errors).length > 0) {
            console.log(errors);
            setIsButtonLoading(false);
            return;
          }
          if (mode === pageMode.ADD) {
            if (userData) {
              try {
                await handleOutsourcingRecyclingData.postOutsourcing({
                  user: userData,
                  outsourcingData: {
                    ordererName: values.ordererName ?? '',
                    ordererMail: values.ordererMail ?? '',
                    ordererTel: values.ordererTel ?? '',
                    ordererCompany: values.ordererCompany ?? '',
                    taxNumber: values.taxNumber ?? '',

                    // orderStatus: values.orderStatus ?? '',
                    /* 原訂單屬性 */
                    /* Outsourcing Recycling相關 */
                    // EPATrackingNumber: values.EPATrackingNumber ?? '', // environmental protection agency
                    tripletNumber: values.tripletNumber ?? '', // 三聯單編號
                    customizedNumber: values.customizedNumber ?? '', // 客製化編號
                    onBoardDocumentNumber: values.onBoardDocumentNumber ?? '', // 隨車文件
                    isAssociated: values.isAssociated ?? '', // 0 or 1 是否被太陽能協會補助
                    isControlled: values.isControlled ?? '', // 0 or 1 是否為列管
                    recyclingCode: values.recyclingCode ?? '', // 有三種 其中列管有兩個選項 非列管三個選項
                    // dischargeApplicationNumber: values.dischargeApplicationNumber ?? '', // 案場排出申請編號
                    receptionist: values.receptionist ?? '', // 建單人員
                    bookingTime,
                    grossWeight: String(values?.grossWeight ?? ''),
                  },
                }).then((res) => {
                  setIsButtonLoading(false);
                  setModalText(MODAL_HINT.ADD.SUCCESS);
                  setIsOpen(true);
                });
              } catch (err) {
                console.log(err);
                setModalText(MODAL_HINT.ADD.FAILED);
                setIsOpen(true);
                setIsButtonLoading(false);
              }
            }
          }
          if (mode === pageMode.EDIT) {
            if (userData) {
              try {
                await handleOutsourcingRecyclingData.putOutsourcing({
                  user: userData,
                  updateData: {
                    ordererName: values.ordererName ?? '',
                    ordererMail: values.ordererMail ?? '',
                    ordererTel: values.ordererTel ?? '',
                    ordererCompany: values.ordererCompany ?? '',
                    taxNumber: values.taxNumber ?? '',
                    // orderStatus: values.orderStatus ?? '',
                    /* 原訂單屬性 */
                    /* Outsourcing Recycling相關 */
                    // EPATrackingNumber: values.EPATrackingNumber ?? '', // environmental protection agency
                    tripletNumber: values.tripletNumber ?? '', // 三聯單編號
                    customizedNumber: values.customizedNumber ?? '', // 客製化編號
                    onBoardDocumentNumber: values.onBoardDocumentNumber ?? '', // 隨車文件
                    isAssociated: values.isAssociated ?? '', // 0 or 1 是否被太陽能協會補助
                    isControlled: values.isControlled ?? '', // 0 or 1 是否為列管
                    recyclingCode: values.recyclingCode ?? '', // 有三種 其中列管有兩個選項 非列管三個選項
                    // dischargeApplicationNumber: values.dischargeApplicationNumber ?? '', // 案場排出申請編號
                    receptionist: values.receptionist ?? '', // 建單人員
                    bookingTime,
                    grossWeight: String(values?.grossWeight ?? ''),
                  },
                  oldData: {
                    ...outsourceData as OutsourcingRecyclingProps,
                  },
                }).then((res) => {
                  setIsButtonLoading(false);
                  setModalText(MODAL_HINT.EDIT.SUCCESS);
                  setIsOpen(true);
                });
              } catch (err) {
                console.log(err);
                setModalText(MODAL_HINT.EDIT.FAILED);
                setIsOpen(true);
                setIsButtonLoading(false);
              }
            }
          }
        }}
        enableReinitialize
      >
        {({
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              p="0"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                minHeight: '86vh',
                width: '100%',
              }}
            >
              <Paper sx={{ width: '100%' }} elevation={0}>
                <Toolbar
                  sx={{ display: 'flex', justifyContent: 'space-between' }}
                  style={{ padding: '0 0 2rem 0' }}
                >
                  <Typography variant="h5">{mode === 'edit' ? id2text('outsourceRecycle.pageTitle.edit') : id2text('outsourceRecycle.pageTitle.add')}</Typography>
                </Toolbar>
                {isLoading ? (
                  <Loading />
                ) : (
                  <>
                    <RecyclingInformation mode={mode} isButtonLoading={isButtonLoading} />
                    <CustomerInformation mode={mode} isButtonLoading={isButtonLoading} />
                    {/* <ReceptionistInformation mode={mode} isButtonLoading={isButtonLoading} /> */}
                  </>
                )}
              </Paper>
              {!isLoading && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="end"
                  width="100%"
                  mt="1.5rem"
                >
                  <Box display="flex" width="22%">
                    <Box width="48%">
                      <EasyButton
                        variant="secondary"
                        size="medium"
                        fullWidth
                        disabled={!auth.currentUser?.emailVerified || isButtonLoading}
                        onClick={() => {
                          setMode(pageMode.LIST);
                        }}
                      >
                        <Typography>{id2text('outsourceRecycle.page.button.cancel')}</Typography>
                      </EasyButton>
                    </Box>
                    <Box ml="1rem" width="48%">
                      <EasyButton
                        variant="contained"
                        size="medium"
                        fullWidth
                        disabled={!auth.currentUser?.emailVerified || isButtonLoading}
                        onClick={handleSubmit}
                      >
                        <Typography>
                          {isButtonLoading ? (
                            <CircularProgress size={16} color="inherit" />
                          ) : (
                            id2text('outsourceRecycle.page.button.save')
                          )}
                        </Typography>
                      </EasyButton>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

export default OrderForm;
