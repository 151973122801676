// 登入驗證
import userAct from '../userAction';
import {
  updateUserLastLoginTime,
} from '../../../api/firebase/cloudFirestore';
import { AppDispatch } from '../../../reduxStore/rootReducer';
import {
  browserLocalPersistence,
  browserSessionPersistence,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { auth } from '../../../config/config-firebase';
import handleFusekiHandlerData from './fuseki/handleFusekiHandlerData';
import { AccountUser } from '../TypescriptProps';

/* firestore */
const SignIn = async (
  emailValue: string,
  passwordValue: string,
  dispatch: AppDispatch,
  stayLoggedIn: boolean,
): Promise<void> => {
  if (stayLoggedIn) {
    // 預設一小時 要修改成30天
    await auth.setPersistence(browserLocalPersistence);
  } else {
    await auth.setPersistence(browserSessionPersistence);
  }
  await new Promise((resolve, reject) => {
    signInWithEmailAndPassword(auth, emailValue, passwordValue)
      .then(async (UserCredential) => {
        if (UserCredential.user?.uid == null) return;
        localStorage.setItem('isLogin', 'true');
        // // 登入之後 使用auth的uid查找role 找到之後去修改uid/role/lastSignTime跟lastSignInTimeStamp
        const { uid } = UserCredential.user;
        // await updateUserLastLoginTime(uid);
        const fusekiData = await handleFusekiHandlerData.readData({
          firebaseID: UserCredential?.user.uid,
        }).catch((err) => {
          console.log(err);
          throw new Error(err);
        });
        dispatch(userAct.FIREBASE_LOGIN_USER(fusekiData?.data as AccountUser));
        // resolve();
      })
      .catch((err) => {
        if (err.code === 'auth/user-not-found') {
          reject(new Error('auth/user-not-found'));
        } else if (err.code === 'auth/wrong-password') {
          reject(new Error('auth/wrong-password'));
        } else {
          reject(err.message);
        }
      });
  });
};

export default SignIn;
