import React, {
  ChangeEvent,
  Dispatch, SetStateAction, useEffect, useRef, useState,
} from 'react';
/* components */
import Loading from '../../../../commons/components/loading/Loading';
import EasyIconButton from '../../../../commons/components/EasyIconButton/EasyIconButton';
import EasyButton from '../../../../commons/components/EasyButton/EasyButton';
import EasyEditIcon, { IconColor } from '../../../../commons/components/EasyIcons/EasyEditIcon';
import EasyCircleProgress from '../../../../commons/components/EasyLoading/EasyCircleProgress';
import EasyPagination from '../../../../commons/components/EasyPagination/EasyPagination';
import EasyTextField from '../../../../commons/components/EasyTextField/EasyTextField';
import CustomerForm from './subComponents/CustomerForm';
import HintModal from '../../../../commons/components/modal/HintModal';
import CustomerInfoModal from './subComponents/CustomerInfoModal';
import EasyPlusIcon from '../../../../commons/components/EasyIcons/EasyPlusIcon';

/* config */

/* type */
import type CustomerProps from './TypescriptProps';
import { HintProps } from './TypescriptProps';
/* utils */
import { useIntl } from 'react-intl';
import handleCustomerManagement from './handleCustomerManagement';
/* mui */
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';

/* mui icon */
import InfoIcon from '@mui/icons-material/Info';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

/* config */
import {
  pageMode,
} from './config';

/* hooks */
import { useAppSelector } from '../../../../reduxStore/utils/reduxHooks';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { selectPageCounts } from '../OutsourcingRecycling/config';
import MenuItem from '@mui/material/MenuItem';

interface EasyTableBodyProps {
  currentPage:number;
  customerList: CustomerProps[];
  getCustomerList:()=>void;
  setMode:Dispatch<SetStateAction<string>>;
  setSelectedCustomer:Dispatch<SetStateAction<CustomerProps>>
  isTableLoading:boolean;
  mode:string;
  queryCount:number;
}

interface TableHeadCellProps {
  id: string;
  align: 'left' | 'justify' | 'center' | 'right' | 'inherit' | undefined;
  disablePadding: false;
  label: string;
  isDesc?: boolean;
  setIsDesc?: Dispatch<SetStateAction<boolean>>;
  orderBy?: string;
}
const EasyTableHead = (): React.JSX.Element => {
  const { formatMessage } = useIntl();
  const id2text = (id:string) => formatMessage({ id });
  //
  const TableHeadCells:TableHeadCellProps[] = [
    {
      id: 'Company Name',
      align: 'left' as const,
      disablePadding: false,
      label: id2text('customerManagement.th.companyName'),
    },
    {
      id: 'Tax Number',
      align: 'left' as const,
      disablePadding: false,
      label: id2text('common.tax.number'),
    },
    {
      id: 'Contact Name',
      align: 'left' as const,
      disablePadding: false,
      label: id2text('customerManagement.th.name'),
    },
    {
      id: 'Contact Mail / Tel',
      align: 'justify' as const,
      disablePadding: false,
      label: id2text('customerManagement.th.emailOrPhone'),
    },
    {
      id: 'Action',
      align: 'left' as const,
      disablePadding: false,
      label: id2text('customerManagement.th.action'),
    },
    {
      id: 'Info',
      align: 'center' as const,
      disablePadding: false,
      label: id2text('customerManagement.th.info'),
    },
  ];
  return (
    <TableHead>
      <TableRow
        sx={{
          '& th': {
            borderBottom: 'unset',
            backgroundColor: 'neutral.50',
          },
        }}
      >
        {TableHeadCells.map((head) => (
          <TableCell
            key={head.id}
            align={head.align}
            padding={head.disablePadding ? 'none' : 'normal'}
          >
            <Box display="flex" alignItems="center" justifyContent={head.setIsDesc != null && head.isDesc != null ? 'space-between' : head.align} width="100%">
              <Typography variant="subtitle2" color="neutral.600">
                {head.label}
              </Typography>
            </Box>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

interface EasyTableRowProps {
  row: CustomerProps;
  getCustomerList:()=>void;
  setMode:Dispatch<SetStateAction<string>>;
  setSelectedCustomer:Dispatch<SetStateAction<CustomerProps>>;
}
const EasyTableRow:React.FC<EasyTableRowProps> = ({
  row, getCustomerList, setMode, setSelectedCustomer,
}): React.JSX.Element => {
  /** react-intl */
  const { formatMessage } = useIntl();
  const id2text = (id:string) => formatMessage({ id });
  const HINT_MODAL_TEXT = {
    error: {
      title: id2text('outsourceRecycle.modal.cancel.hint.error.title'),
      text: id2text('outsourceRecycle.modal.cancel.hint.error.text'),
    },
    success: {
      title: id2text('outsourceRecycle.modal.cancel.hint.success.title'),
      text: id2text('outsourceRecycle.modal.cancel.hint.success.text'),
    },
  };
  // function
  /* 選擇的Order */
  const [isCustomerInfoOpen, setIsCustomerInfoOpen] = useState<boolean>(false);
  const [isCustomerInfoLoading, setIsCustomerInfoLoading] = useState<boolean>(true);
  /* 提示視窗 */
  const [isHintModalOpen, setIsHintModalOpen] = useState(false);
  const [modalText, setModalText] = useState<HintProps>(HINT_MODAL_TEXT.success);

  useEffect(() => {
    if (isCustomerInfoOpen) {
      try {
        // handleRentalContractData.getRentalContractDataDetail({ rtEquipIds: row.rtEquipId }).then((r) => setSelectedRentalContract(r)).then(() => {
        //   setIsRentalContractLoading(false);
        // });
      } catch (e) {
        console.log(e);
      }
    } else {
      setSelectedCustomer({} as CustomerProps);
      setIsCustomerInfoLoading(true);
    }
  }, [isCustomerInfoOpen]);

  return (
    <>
      {/* Table */}
      <TableRow>
        {/* companyName */}
        <TableCell align="left">
          <Typography variant="body2" color="neutral.max">
            {row?.companyName ?? ''}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Grid container>
            {/* Tax Number */}
            <Grid item xs={8}>
              <Typography variant="body2" color="neutral.max" sx={{ wordWrap: 'break-word' }}>
                {row?.taxNumber}
              </Typography>
            </Grid>
            {/* Copy TaxNumber */}
            <Grid item xs={4}>
              { row?.taxNumber && (
              <EasyIconButton
                size="small"
                onClick={async () => {
                  await navigator.clipboard.writeText(
                    row?.taxNumber,
                  );
                }}
              >
                <ContentCopyIcon />
              </EasyIconButton>
              )}
            </Grid>
          </Grid>
        </TableCell>
        {/* Contact Name  */}
        <TableCell align="left">
          <Typography variant="body2" color="neutral.max">
            {row?.contactName}
          </Typography>
        </TableCell>
        {/* Email / Phone */}
        <TableCell align="left">
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Typography variant="body2" color="neutral.max">
              {row?.contactMail}
            </Typography>
            <Typography variant="body2" color="neutral.max">
              {row?.contactTel}
            </Typography>
          </Box>
        </TableCell>
        {/* Action */}
        <TableCell align="left">
          <Box display="flex" justifyContent="left" alignItems="center">
            <EasyIconButton
              size="medium"
              onClick={() => {
                setMode(pageMode.EDIT);
                setSelectedCustomer({ ...row });
              }}
            >
              <EasyEditIcon
                size="sm"
                color={'primary' as IconColor}
              />
            </EasyIconButton>
          </Box>
        </TableCell>
        {/* Info */}
        <TableCell align="center">
          <EasyIconButton
            size="medium"
            onClick={async () => {
              setIsCustomerInfoOpen(true);
            }}
          >
            <InfoIcon color="primary" />
          </EasyIconButton>
        </TableCell>
      </TableRow>
      {/* info視窗 */}
      { isCustomerInfoOpen && (
      <CustomerInfoModal
        isOpen={isCustomerInfoOpen}
        setIsOpen={setIsCustomerInfoOpen}
        customer={row}
      />
      )}
      {/* 提示視窗  */}
      <HintModal isOpen={isHintModalOpen} setIsOpen={setIsHintModalOpen} modalText={modalText} callback={getCustomerList} />
    </>
  );
};

const EasyTableBody: React.FC<EasyTableBodyProps> = ({
  customerList, getCustomerList, currentPage, setMode, setSelectedCustomer, mode, isTableLoading, queryCount,
}): React.JSX.Element => {
  /** react-intl */
  const { formatMessage } = useIntl();
  const id2text = (id:string) => formatMessage({ id });
  const handleTable = (isTableLoading:boolean) => {
    if (isTableLoading) {
      return (
        <TableRow>
          <TableCell colSpan={11}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <EasyCircleProgress />
            </Box>
          </TableCell>
        </TableRow>
      );
    }
    return (
      customerList.map((customer) => (
        <EasyTableRow
          row={customer}
          getCustomerList={getCustomerList}
          setMode={setMode}
          setSelectedCustomer={setSelectedCustomer}
          key={customer.fusekiID}
        />
      )).slice((currentPage - 1) * queryCount, currentPage * queryCount)
    );
  };
  return (
    <TableBody>
      {customerList.length ? (
        handleTable(isTableLoading)
      ) : (
        <TableRow>
          <TableCell colSpan={11}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {id2text('customerManagement.pageTitle.null')}
            </Box>
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );
};
const CustomerManagementPage = (): React.JSX.Element => {
  /** react-intl */
  const { formatMessage } = useIntl();
  const id2text = (id:string) => formatMessage({ id });
  /* mui */
  const theme = useTheme();
  /* redux */
  const user = useAppSelector((state) => state.userDataStore);
  /* page */
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState(10);
  const [queryCount, setQueryCount] = useState<number>(pageCount);
  const [goPage, setGoPage] = useState(currentPage);

  /* filter text */
  const [filterText, setFilterText] = useState<string>('');
  const [queryText, setQueryText] = useState<string>('');
  /* 訂單內容 */
  const [customerList, setCustomerList] = useState<CustomerProps[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<CustomerProps>({} as CustomerProps);
  const [isLoading, setIsLoading] = useState(true);
  /* table loading */
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [firstLoading, setFirstLoading] = useState(true);
  /* page mode */
  const [mode, setMode] = useState(pageMode.LIST);

  /*  page select */
  const getPageArray = () => {
    const pageArray = [];
    const page = Math.ceil(customerList.length / pageCount);
    for (let i = 1; i <= page; i += 1) {
      pageArray.push(i);
    }
    return pageArray;
  };

  /** function * */
  const getCustomerList = async () => {
    try {
      setIsTableLoading(true);
      await handleCustomerManagement.getCustomerManagementDetail({
        uid: user?.firebaseID, filterText: queryText,
      }).then((res) => {
        if (res) {
          setCustomerList(res);
        }
      }).then(() => {
        setIsTableLoading(false);
      });
    } catch (err) {
      console.log('getContractData error', err);
    }
  };

  useEffect(() => {
    if (firstLoading) {
      getCustomerList().then(() => {
        setIsLoading(false);
        setFirstLoading(false);
      });
    } else if (mode === pageMode.LIST) {
      getCustomerList();
    }
  }, [currentPage, mode, queryText]);

  const timerPageIdRef = useRef<NodeJS.Timeout | null>(null);
  const timerFilterTextIdRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (timerPageIdRef.current !== null) {
      clearTimeout(timerPageIdRef.current);
    }
    timerPageIdRef.current = setTimeout(() => {
      setCurrentPage(1);
      if (!pageCount || pageCount < 1) return;
      setQueryCount(pageCount);
    }, 1000);
  }, [pageCount]);

  useEffect(() => {
    if (timerFilterTextIdRef.current !== null) {
      clearTimeout(timerFilterTextIdRef.current);
    }
    timerFilterTextIdRef.current = setTimeout(() => {
      setQueryText(filterText);
      setCurrentPage(1);
    }, 1000);
  }, [filterText]);

  useEffect(() => {
    console.log(queryText);
  }, [queryText]);

  return (
    <>
      {/* 主頁面 */}
      <Box
        p="1rem 1.5rem 4rem 1.5rem"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          width: '100%',
        }}
      >
        {/* 列表模式 */}
        {mode === pageMode.LIST && (
        <Paper sx={{ width: '100%' }} elevation={0}>
          <Toolbar
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            style={{ padding: '0 0 1rem 0' }}
          >
            <Grid item container justifyContent="space-between" xs={12}>
              {/*  標題 */}
              <Typography variant="h5">{id2text('customerManagement.pageTitle')}</Typography>
              <EasyButton
                disabled={isLoading}
                color="primary"
                startIcon={<EasyPlusIcon />}
                size="medium"
                variant="contained"
                // sx={{
                //   boxShadow: 'unset',
                // }}
                onClick={() => setMode(pageMode.ADD)}
              >
                <Typography variant="buttonText1">{id2text('customerManagement.pageTitle.add')}</Typography>
              </EasyButton>
              <Box width="100%" mt="1rem">
                <Grid container>
                  <Grid item xs={12}>
                    {/* SearchBar */}
                    <EasyTextField
                      fullWidth
                      type="text-search"
                      placeholder={id2text('customerManagement.search.placeholder')}
                      inputProps={{ style: { marginLeft: '0.25rem' } }}
                      value={filterText}
                      onChange={(e:ChangeEvent<HTMLInputElement>) => {
                        setFilterText(e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Toolbar>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <TableContainer sx={{ maxHeight: '100vh' }}>
                <Table
                  sx={{
                    minWidth: 750,
                    border: '1px solid',
                    borderRadius: '8px',
                    borderColor: 'neutral.200',
                    '& .MuiTableRow-root:hover': {
                      backgroundColor: theme.palette.neutral['100'],
                    },
                  }}
                  size="medium"
                  stickyHeader
                >
                  <EasyTableHead />
                  <EasyTableBody
                    setMode={setMode}
                    mode={mode}
                    currentPage={currentPage}
                    customerList={customerList}
                    getCustomerList={getCustomerList}
                    setSelectedCustomer={setSelectedCustomer}
                    isTableLoading={isTableLoading}
                    queryCount={queryCount}
                  />
                </Table>
              </TableContainer>
              {/* 下方分頁 */}
              <Box
                mt="1rem"
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                {/* 一頁幾筆資料 */}
                <Box
                  width="100%"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                  }}
                >
                  <Select
                    value={`${pageCount}`}
                    onChange={(e:SelectChangeEvent) => {
                      const pageNumber = parseInt(e.target.value, 10);
                      if (Number.isNaN(pageNumber)) {
                        setPageCount(0);
                      } else {
                        setPageCount(pageNumber);
                      }
                    }}
                  >
                    {selectPageCounts.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                  <Typography variant="body1" ml="1rem">
                    {id2text('common.records.per.page')}
                  </Typography>
                </Box>
                {/* 分頁 */}
                <Box
                  width="100%"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <EasyPagination
                    totalNum={customerList.length ?? 0}
                    pageCount={queryCount}
                    onChange={(_event, page) => {
                      setCurrentPage(page);
                    }}
                    page={currentPage}
                  />
                </Box>
                {/* 去哪一頁 */}
                <Box
                  width="100%"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'end',
                  }}
                >
                  <Typography variant="body1" ml="1rem">
                    {id2text('common.go.to.page')}
                  </Typography>
                  <Select
                    value={`${currentPage}`}
                    onChange={(e:SelectChangeEvent) => {
                      const pageNumber = parseInt(e.target.value, 10);
                      if (Number.isNaN(pageNumber)) {
                        setCurrentPage(0);
                      } else {
                        setCurrentPage(pageNumber);
                      }
                    }}
                    sx={{
                      marginLeft: '1rem',
                    }}
                  >
                    {getPageArray().map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </Box>

            </>
          )}
        </Paper>
        )}
        {/* 建立/編輯模式 傳入mode */}
        {(mode === pageMode.EDIT || mode === pageMode.ADD) && (
        <Paper sx={{ width: '100%' }} elevation={0}>
          <CustomerForm getData={getCustomerList} setMode={setMode} selectedCustomer={selectedCustomer} mode={mode} />
        </Paper>
        )}
      </Box>
    </>
  );
};

export default CustomerManagementPage;
