import React, { CSSProperties } from 'react';

// mui
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Modal, SxProps } from '@mui/material';
// component
import EasyButton from '../EasyButton/EasyButton';
import Overlay from './subComponent/Overlay';
import DialogContainer from './subComponent/DialogContainer';
import CloseButton from './subComponent/CloseButton';
// types
import { Theme } from '@mui/material/styles';

interface ModalProps {
  type?: 'normal' | 'contract' | 'settingPageUse';
  isOpen: boolean;
  setIsOpen?: (isOpen: boolean) => void;
  showCloseIcon: boolean;
  title?: string;
  action?: () => void;
  actionLabel?: string;
  secondaryAction?: () => void;
  secondaryLabel?: string;
  handleClose?: () => void;
  body: React.ReactElement;
  sx?: SxProps<Theme> | undefined;
  dialogBodySX?: SxProps<Theme> | undefined;
  dialogActionSX?: SxProps<Theme> | undefined;
  buttonDisabled?: boolean;
  secondaryButtonDisabled?: boolean;
  actionStyle?:CSSProperties ;
  secondaryStyle?:CSSProperties ;
  secondarySx?: SxProps<Theme> | undefined;
  closeIconSx?: SxProps<Theme> | undefined;
  isDangerAction?:boolean;
  errorHint?: React.ReactElement | null;
}

const EasyDialog: React.FC<ModalProps> = ({
  body,
  type,
  title,
  action,
  isOpen,
  setIsOpen,
  actionLabel,
  handleClose,
  showCloseIcon,
  secondaryLabel,
  buttonDisabled,
  secondaryAction,
  secondaryButtonDisabled = false,
  isDangerAction,
  // css
  sx,
  dialogBodySX,
  dialogActionSX,
  actionStyle,
  secondaryStyle,
  secondarySx,
  closeIconSx,
  errorHint,
}): React.JSX.Element => {
  const handleCloseDefault = (): void => {
    if (typeof handleClose === 'function') {
      handleClose();
    }
    if (setIsOpen) {
      setIsOpen(false);
    }
  };

  const ResponseProps = {
    minWidth: {
      xl: '28.75vw',
      lg: '28.75vw',
      md: '42.7vw',
      sm: '86.67vw',
      xs: '86.67vw',
    },
    maxWidth: {
      xl: '28.75vw',
      lg: '28.75vw',
      md: '42.7vw',
      sm: '86.67vw',
      xs: '86.67vw',
    },
    minHeight: {
      xl: '24.80vh',
      lg: '24.80vh',
      md: '40.23vh',
      sm: '51.5vh',
      xs: '51.5vh',
    },
  };
  if (type === 'settingPageUse') {
    return (
      <Modal open={isOpen} onClose={handleCloseDefault}>
        <Box>
          {/* <Overlay /> */}
          <DialogContainer>
            <Grid
              container
              justifyContent="center"
              sx={{
                minWidth: {
                  xl: '40vw',
                  lg: '40vw',
                  md: '60vw',
                  sm: '95vw',
                  xs: '100vw',
                },
                maxWidth: {
                  xl: '60vw',
                  lg: '60vw',
                  md: '60vw',
                  sm: '95vw',
                  xs: '100vw',
                },
              }}
              height="auto"
              bgcolor="white"
              borderRadius="0.5rem"
              pt="4.35%"
              pb="8.7%"
              px="5%"
            >
              {showCloseIcon && (
                <Box
                  display="flex"
                  justifyContent="end"
                  width="100%"
                  height="2rem"
                >
                  <CloseButton
                    handleClose={handleClose}
                    handleCloseDefault={handleCloseDefault}
                  />
                </Box>
              )}
              <Grid container flexDirection="row" justifyContent="start">
                {/* BODY */}
                {body}
              </Grid>
            </Grid>
          </DialogContainer>
        </Box>
      </Modal>
    );
  }

  if (type === 'contract') {
    return (
      <Modal open={isOpen} onClose={handleCloseDefault}>
        <Box>
          {/* <Overlay /> */}
          <DialogContainer>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="start"
              alignItems="center"
              sx={{ ...ResponseProps, ...sx }}
              height="auto"
              bgcolor="white"
              borderRadius="0.5rem"
              p={showCloseIcon ? '1rem 2rem 3rem 2rem' : '2rem 2rem 3rem 2rem'}
            >
              {showCloseIcon && (
                <Box display="flex" justifyContent="end" width="100%">
                  <CloseButton
                    handleClose={handleClose}
                    handleCloseDefault={handleCloseDefault}
                  />
                </Box>
              )}
              {/* TITLE */}
              <Box display="flex" justifyContent="center">
                <Typography fontSize="700" variant="h5">
                  {title}
                </Typography>
              </Box>
              {/* BODY */}
              <Box mt="7.145%" flex={1} sx={{ ...dialogBodySX }}>
                {body}
              </Box>
              {/* FOOTER */}
              {action ? (
                <Grid
                  container
                  justifyContent="center"
                  mt="20%"
                  sx={{ ...dialogActionSX }}
                >
                  {secondaryAction ? (
                    <EasyButton
                      size="large"
                      variant="contained"
                      onClick={secondaryAction}
                      sx={{
                        width: '47.82%',
                        color: 'primary.main',
                        boxShadow: 'unset',
                        textTransform: 'capitalize',
                      }}
                      style={{ backgroundColor: '#EEFBFB', color: '#59D9D9' }}
                      disabled={false}
                    >
                      <Typography variant="buttonText1" color="primary">
                        {secondaryLabel}
                      </Typography>
                    </EasyButton>
                  ) : null}
                  {action ? (
                    <EasyButton
                      color={isDangerAction ? 'error' : 'primary'}
                      size="large"
                      variant="contained"
                      onClick={action}
                      sx={{
                        width: '47.82%',
                        boxShadow: 'unset',
                        textTransform: 'capitalize',
                      }}
                      disabled={buttonDisabled}
                    >
                      <Typography variant="buttonText1">{actionLabel}</Typography>
                    </EasyButton>
                  ) : null}
                </Grid>
              ) : null}
            </Box>
          </DialogContainer>
        </Box>
      </Modal>
    );
  }

  return (
    <Modal open={isOpen} onClose={handleCloseDefault}>
      <Box>
        {/* <Overlay /> */}
        <DialogContainer>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="start"
            alignItems="center"
            sx={{
              p: showCloseIcon ? '1rem 2rem 3rem 2rem' : '2rem 2rem 3rem 2rem',
              ...ResponseProps,
              ...sx,
            }}
            height="auto"
            bgcolor="white"
            borderRadius="0.5rem"
          >
            {showCloseIcon && (
              <Box display="flex" justifyContent="end" width="100%" sx={{ ...closeIconSx }}>
                <CloseButton
                  handleClose={handleClose}
                  handleCloseDefault={handleCloseDefault}
                />
              </Box>
            )}
            {/* TITLE */}
            <Box display="flex" justifyContent="center">
              <Typography fontSize="700" variant="h5">
                {title}
              </Typography>
            </Box>
            {/* BODY */}
            <Box mt="7.145%" flex={1} sx={{ ...dialogBodySX }}>
              {body}
            </Box>
            {/* Error Message */}
            {errorHint && (
            <Box mt="1rem" flex={1}>
              {errorHint}
            </Box>
            )}
            {/* FOOTER */}
            {action ? (
              <Grid
                container
                justifyContent="center"
                mt="20%"
                sx={{ ...dialogActionSX }}
              >
                {action ? (
                  <EasyButton
                    fullWidth
                    color={isDangerAction ? 'error' : 'primary'}
                    size="large"
                    variant="contained"
                    onClick={action}
                    sx={{
                      boxShadow: 'unset',
                      marginBottom: '0.5rem',
                      textTransform: 'capitalize',
                    }}
                    style={{ ...actionStyle }}
                    disabled={buttonDisabled}
                  >
                    <Typography variant="buttonText1">{actionLabel}</Typography>
                  </EasyButton>
                ) : null}
                {secondaryAction ? (
                  <EasyButton
                    fullWidth
                    size="large"
                    variant="text"
                    onClick={secondaryAction}
                    sx={{
                      // boxShadow: 'unset',
                      textTransform: 'capitalize',
                      ...secondarySx,
                    }}
                    // style={{ backgroundColor: 'white', color: 'rgba(89, 217, 217, 1)', ...secondaryStyle }}
                    style={{ ...secondaryStyle }}
                    disabled={secondaryButtonDisabled}
                  >
                    <Typography variant="buttonText1">
                      {secondaryLabel}
                    </Typography>
                  </EasyButton>
                ) : null}
              </Grid>
            ) : null}
          </Box>
        </DialogContainer>
      </Box>
    </Modal>
  );
};

export default EasyDialog;
