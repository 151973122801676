import React, { useState } from 'react';

// components
import EasyDialog from '../../../commons/components/modal/EasyDialog';
// hooks
import { useAppDispatch } from '../../../reduxStore/utils/reduxHooks';
// mui
import Typography from '@mui/material/Typography';
// config
import { auth } from '../../../config/config-firebase';
import services from '../../../api/services';
import userAct from '../../Users/userAction';
// utils
import signOut from '../../Users/utils/signOut';
import handleDeleteAccount from '../../Users/utils/handleDeleteAccount';
import handleFusekiHandlerData from '../../Users/utils/fuseki/handleFusekiHandlerData';
// router
import { useNavigate } from 'react-router-dom';
// routes config
import routes from '../../../config/routes/index';
import groupInfo from '../../../App-groupInfo';
import { useIntl } from 'react-intl';

interface VerifyPopUpProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}
const DeleteAccountModal: React.FC<VerifyPopUpProps> = ({
  setIsOpen,
  isOpen,
}) => {
  /** react intl */
  const { formatMessage } = useIntl();
  const id2text = (id:string) => formatMessage({ id });
  //
  const navigation = useNavigate();
  // const user = useAppSelector((state) => state.userDataStore);
  /* 視窗文字 */
  const modalTextObj = {
    default: {
      title: id2text('setting.modal.deleteAccount.title.default'),
      text: id2text('setting.modal.deleteAccount.text.default'),
    },
    success: {
      title: id2text('setting.modal.deleteAccount.title.success'),
      text: id2text('setting.modal.deleteAccount.text.success'),
    },
    error: {
      title: id2text('setting.modal.deleteAccount.title.error'),
      text: id2text('setting.modal.deleteAccount.text.error'),
    },
  };
  const [modalText, setModalText] = useState(modalTextObj.default);
  // 是否執行刪除動作過
  const [isDeleteAction, setIsDeleteAction] = useState(false);
  const dispatch = useAppDispatch();
  const deleteAccount = async (): Promise<void> => {
    setIsOpen(false);
    // if (user?.firebaseID !== auth.currentUser?.uid) return;
    handleFusekiHandlerData.deleteData({ firebaseID: auth.currentUser?.uid as string });
    const data = await handleDeleteAccount(auth.currentUser?.uid as string);
    setIsDeleteAction(true);
    if (data.message === 'success') {
      dispatch(userAct.FIREBASE_LOGOUT_USER);
      dispatch(userAct.SET_FUSEKI_ID(null));
      // eslint-disable-next-line no-alert
      setModalText(modalTextObj.success);
      setIsOpen(true);
      setTimeout(async () => {
        await signOut(navigation, dispatch);
        navigation(`/${services.getLocale()}${routes.SIGNIN}`);
        dispatch(userAct.SET_GROUP_ROLE([{ group: groupInfo.Anonymous.id, role: [groupInfo.Anonymous.role.reader] }]));
      }, 3000);
    } else {
      setModalText(modalTextObj.error);
      setIsOpen(true);
      setTimeout(async () => {
        setIsOpen(false);
        setModalText(modalTextObj.default);
        setIsDeleteAction(false);
      }, 3000);
    }
  };

  const handleNo = (): void => {
    setIsOpen(false);
    setModalText(modalTextObj.default);
  };

  return (
  // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isDeleteAction ? (
        <EasyDialog
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          showCloseIcon={false}
          title={modalText.title}
          body={<Typography>{modalText.text}</Typography>}
          sx={{
            minHeight: {
              xl: '0vh',
              lg: '0vh',
              md: '0vh',
              sm: '0vh',
              xs: '0vh',
            },
          }}
        />
      ) : (
        <EasyDialog
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          showCloseIcon
          title={modalText.title}
          action={deleteAccount}
          actionLabel="Yes"
          secondaryAction={handleNo}
          secondaryLabel="No"
          body={<Typography>{modalText.text}</Typography>}
        />
      )}
    </>
  );
};

export default DeleteAccountModal;
