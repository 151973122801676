import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import znTW from "date-fns/locale/zh-TW"; // zh-TW

// MUI
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";

// MUI Icons
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

// components
import WorkOrder from "./WorkOrder";
import EasyIconButton from "../../commons/components/EasyIconButton/EasyIconButton";

// configs
import { WAREHOUSE_ID, WAREHOUSE_NAME } from "../../config/config.warehouse";
import { WorkOrders1Out } from "./Platforms/fakeData";

// utils
import { statusChipColor } from "./utils";

const WAREHOUSE_LIST = ["INVENTORY", "RAWMATERIAL", "SEMIFINISH"];
const WORK_ORDER_STATUS = [
  { label: "全部", value: 0 },
  { label: "入倉待處理", value: 1 },
  { label: "出倉待處理", value: 2 },
  { label: "預處理中", value: 3 },
];

// hooks

// Typescript

const WorkOrders = (): React.JSX.Element => {
  const theme = useTheme();
  const [plateformKey, setPlateformKey] = useState<string>(WAREHOUSE_LIST[0]);
  const [plateformStatus, setPLateformStatus] = useState<number>(0);
  const [selectedWorkOrder, setSelectedWorkOrder] = useState<Record<
    string,
    any
  > | null>(null);

  useEffect(() => {
    // get work orders by api
  }, [plateformKey]);

  return (
    <Box sx={{ width: "100%", padding: "0 3rem 4rem 3rem" }}>
      {selectedWorkOrder ? (
        <WorkOrder
          plateformKey={plateformKey}
          selectedWorkOrder={selectedWorkOrder}
          setSelectedWorkOrder={setSelectedWorkOrder}
        />
      ) : (
        <Grid container flexDirection="column" rowSpacing="1rem">
          <Grid item xs>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="h5">生產指令單列表</Typography>
              <Typography variant="body1">
                請選擇欲處理的生產指令單。
              </Typography>
            </Box>
          </Grid>
          <Grid item xs>
            <Grid container>
              <Grid item xs>
                <FormControl fullWidth>
                  <InputLabel>執行站點</InputLabel>
                  <Select
                    fullWidth
                    value={plateformKey}
                    label="執行站點"
                    onChange={(event) => {
                      setPlateformKey(event.target.value);
                    }}
                  >
                    {WAREHOUSE_LIST.map((key) => (
                      <MenuItem key={key} value={key}>
                        {WAREHOUSE_NAME[key as keyof typeof WAREHOUSE_NAME]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs pl="8px">
                <FormControl fullWidth>
                  <InputLabel>指令單狀態</InputLabel>
                  <Select
                    fullWidth
                    value={plateformStatus}
                    label="指令單狀態"
                    onChange={(event) => {
                      setPLateformStatus(
                        parseInt(event.target.value as string, 10),
                      );
                    }}
                  >
                    {WORK_ORDER_STATUS.map(({ label, value }) => (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs>
            <Grid container flexDirection="column">
              {WorkOrders1Out.map((workOrder: Record<string, any>) => (
                <Grid item xs key={workOrder.workOrderID}>
                  <Box
                    sx={{
                      width: "100%",
                      padding: "1rem 1.5rem",
                      border: "1px solid",
                      borderColor: "neutral.200",
                      borderRadius: "8px",
                    }}
                    onClick={() => {
                      setSelectedWorkOrder(workOrder);
                    }}
                  >
                    <Grid container>
                      <Grid item xs>
                        <Grid container>
                          <Grid item xs={6}>
                            <Grid container flexDirection="column">
                              <Grid item xs>
                                <Typography
                                  variant="subtitle1"
                                  color="neutral.max"
                                >
                                  {workOrder.workOrderID}
                                </Typography>
                              </Grid>
                              <Grid item xs>
                                <Typography>
                                  {`建立時間: ${format(
                                    parseInt(workOrder?.createdTime, 10),
                                    "PPP HH:mm:ss",
                                    { locale: znTW },
                                  )}`}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              paddingLeft: "1.5rem",
                            }}
                          >
                            <Chip
                              label={
                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      color: statusChipColor({}, theme)
                                        .dotColor,
                                      marginRight: "4px",
                                      fontSize: "16px",
                                    }}
                                  >
                                    •
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    color="neutral.max"
                                  >
                                    {workOrder?.isExited === 0
                                      ? "待處理"
                                      : workOrder?.isEntered === 0
                                        ? "待入倉"
                                        : "預處理中"}
                                  </Typography>
                                </Box>
                              }
                              sx={{
                                backgroundColor: `${
                                  statusChipColor({}, theme).backgroundColor
                                }`,
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <EasyIconButton
                          disabled
                          style={{ color: theme.palette.neutral[700] }}
                        >
                          <ArrowRightIcon />
                        </EasyIconButton>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default WorkOrders;
