import signInPage from './User/SignIn/zh-tw';
import signUpPage from './User/SignUp/zh-tw';
import verifyEmailPage from './User/VerifyEmail/zh-tw';
import linkUserPage from './User/LinkUser/zh-tw';
import forgotPasswordPage from './User/ForgotPassword/zh-tw';
import settingPage from './Setting/zh-tw';
import editProfilePage from './EditProfile/zh-tw';
import resetPasswordPage from './User/ResetPassword/zh-tw';
import policyPage from './Legal/Policy/zh-tw';
import servicePage from './Legal/Service/zh-tw';
import changePasswordPage from "./ChangePassword/zh-tw";
import outsourcingRecyclingPage from "./OutsourcingRecycling/zh-tw";
import customerManagementPage from "./CustomerManagement/zh-tw";

const messages = {
  //   helloWorld: '你好世界 {ts, date, ::yyyyMMdd}',
  // common
  'common.info': '詳細資訊',
  'common.information': '詳細資訊',
  'common.enable': '啟用',
  'common.enabled': '啟用',
  'common.disable': '停用',
  'common.disabled': '停用',
  'common.search': '搜尋',
  'common.clear': '清除',
  'common.add': '新增',
  'common.edit': '編輯',
  'common.unit': '單位',
  'common.note': '備註',
  'common.submit': '提交',
  'common.save': '儲存',
  'common.all': 'All',
  'common.recycling.passport': 'Recycling Passport',
  'common.tax.number': '統一編號',
  'common.reason': 'Reason',
  'common.other': 'Other',
  'common.notice': 'Notice',
  "common.back":"返回",
  'common.email':"Email",
  // common: button
  'common.comfirm': '確認',
  'common.cancel': '取消',
  'common.go.back': '返回',
  'common.close': '關閉',
  // error hint
  'common.Required': 'Required',
  // common: option select
  'common.yes': '是',
  'common.no': '否',
  // common pagination
  'common.records.per.page': '每頁筆數',
  'common.go.to.page': '前往頁碼',
  'common.go': '前往',

  /* ====== START of SignIn page ====== */
  ...signInPage,
  /* ====== START of SignUp page ====== */
  ...signUpPage,
  /* ====== START of VerifyEmail page ====== */
  ...verifyEmailPage,
  /* ====== START of LinkUser page ====== */
  ...linkUserPage,
  /* ====== START of ForgotPassword page ====== */
  ...forgotPasswordPage,
  /* ====== START of ResetPassword page ====== */
  ...resetPasswordPage,
  /* ====== START of Setting page ====== */
  ...settingPage,
  /* ====== START of Edit Profile page ====== */
  ...editProfilePage,
  /* ====== START of Policy page ====== */
  ...policyPage,
  /* ====== START of Service page ====== */
  ...servicePage,
  /* ====== START of ChangePassword page ====== */
  ...changePasswordPage,
  /* ====== START of Outsourcing Recycling page ====== */
  ...outsourcingRecyclingPage,
  /* ====== START of Customer Management page ====== */
  ...customerManagementPage,
};

export default messages;
