import React, { useEffect } from "react";
import QRcode from "qrcode";

// MUI
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

// MUI Icons

// components
import EasyButton from "../../../commons/components/EasyButton/EasyButton";

// configs

// Typescript

const palletIDs = [
  "GL95059601120052-112345678-20240219001",
  "GL95059601120052-112345678-20240219002",
  "GL95059601120052-112345678-20240219003",
  "GL95059601120052-112345678-20240219004",
  "GL95059601120052-112345678-20240219005",
];

const Mark = (): React.JSX.Element => {
  useEffect(() => {
    palletIDs.forEach((palletID, index) => {
      QRcode.toCanvas(document.getElementById(`canvas${index + 1}`), palletID, {
        scale: 10,
      });
    });
  }, []);

  return (
    <Box
      p="1rem 1.5rem 4rem 1.5rem"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
      }}
    >
      <Paper sx={{ width: "100%" }} elevation={0}>
        <Toolbar
          sx={{ display: "flex", justifyContent: "flex-start" }}
          style={{ padding: "0 0 1.5rem 0" }}
        >
          <Typography variant="h5">Mark</Typography>
        </Toolbar>
        <Grid container flexDirection="column">
          {palletIDs.map((palletID, index: number) => (
            <Grid item xs key={palletID}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box component="canvas" id={`canvas${index + 1}`} />
              </Box>
            </Grid>
          ))}

          <Grid item xs>
            <EasyButton variant="contained">Print</EasyButton>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default Mark;
