const outsourcingRecyclingPage = {
    /* outsourcing recycling */
    "outsourceRecycle.pageTitle":"Outsourcing Recycling",
    "outsourceRecycle.pageTitle.null":"No Outsourcing Recycling",
    "outsourceRecycle.page.button.add":"New",
    "outsourceRecycle.page.button.save":"Save",
    "outsourceRecycle.page.button.cancel":"Cancel",
    "outsourceRecycle.pageTitle.add":"Add New Order",
    "outsourceRecycle.pageTitle.edit":"Edit Order",
    /* table head */
    "outsourceRecycle.th.customer":"Customer",
    "outsourceRecycle.th.orderNumber":"Order Number",
    "outsourceRecycle.th.arrivalDate":"Arrival Date",
    "outsourceRecycle.th.deadline":"Deadline",
    "outsourceRecycle.th.orderStatus":"Status",
    "outsourceRecycle.th.recyclingRate":"Recycling Rate",
    "outsourceRecycle.th.action":"Action",
    "outsourceRecycle.th.info":"Info",
    /* tabs */
    "outsourceRecycle.tab.all":"All",
    "outsourceRecycle.tab.controlledOrder":"Controlled Order",
    "outsourceRecycle.tab.uncontrolledOrder":"Uncontrolled Order",
    /* page Count */
    "outsourceRecycle.pageCount.text":"records per page",
    /* 取消訂單視窗 */
    "outsourceRecycle.modal.cancel.title":"Cancel Order",
    "outsourceRecycle.modal.cancel.text":"Are you sure you want to cancel the order? After canceling the order, you will not be able to edit the information.",
    "outsourceRecycle.modal.cancel.action.text":"Cancel Order",
    "outsourceRecycle.modal.cancel.secondary.text":"Go Back",
    /* 取消後提示視窗 */
    "outsourceRecycle.modal.cancel.hint.error.title":"Error",
    "outsourceRecycle.modal.cancel.hint.error.text":"There are errors in the fields that need to be corrected",
    "outsourceRecycle.modal.cancel.hint.success.title":"Success",
    "outsourceRecycle.modal.cancel.hint.success.text":"Successfully updated data",
    /* 新增訂單後提示視窗 */
    "outsourceRecycle.modal.hint.add.success.title":"Create Data Successful",
    "outsourceRecycle.modal.hint.add.success.text":"Create Outsourcing Recycling Data Successfully",
    "outsourceRecycle.modal.hint.add.failed.title":"Failed to create Data",
    "outsourceRecycle.modal.hint.add.failed.text":"Failed to create Outsourcing Recycling Data",
    /* 編輯訂單後提示視窗 */
    "outsourceRecycle.modal.hint.edit.success.title":"Edit Data Successful",
    "outsourceRecycle.modal.hint.edit.success.text":"Edit Outsourcing Recycling Data Successfully",
    "outsourceRecycle.modal.hint.edit.failed.title":"Failed to Edit Data",
    "outsourceRecycle.modal.hint.edit.failed.text":"Failed to Edit Outsourcing Recycling Data",
    /* 訂單資訊 */
    "outsourceRecycle.info.title": "Information",
    "outsourceRecycle.subtitle1": "Recycling Information",
    "outsourceRecycle.subtitle2": "Customer Information",
    "outsourceRecycle.subtitle.net.weight.title":"Net Weight of Recycled Materials",
    "outsourceRecycle.isControlled.label":"Order Source",
    "outsourceRecycle.isControlled.true":"Controlled Order",
    "outsourceRecycle.isControlled.false":"Uncontrolled Order",
    "outsourceRecycle.isAssociated.label":"From the Solar Energy Association",
    "outsourceRecycle.isAssociated.true":"Yes",
    "outsourceRecycle.isAssociated.false":"No",
    "outsourceRecycle.recyclingCode.label":"Waste and Recycling Resource Code",
    "outsourceRecycle.customizedNumber.label":"Customized Number",
    "outsourceRecycle.onBoardDocumentNumber.label":"On-board Document Number",
    "outsourceRecycle.tripletNumber.label":"Triplet Number",
    "outsourceRecycle.quantity.label":"Quantity of Solar Panels",
    "outsourceRecycle.netWeight.label":"Net Weight",
    "outsourceRecycle.grossWeight.label":"Gross Weight",
    "outsourceRecycle.arrivedDate.label":"Solar Panel Arrived Date",
    "outsourceRecycle.arrivedDate.date":"Date",
    "outsourceRecycle.arrivedDate.time":"Time",
    "outsourceRecycle.status.label":"Status",
    "outsourceRecycle.company.name.label":"Company Name",
    "outsourceRecycle.orderer.name.label":"Name",
    "outsourceRecycle.orderer.mail.label":"Email",
    "outsourceRecycle.orderer.tel.label":"Phone",
    "outsourceRecycle.receptionist.title":"Receptionist",
    "outsourceRecycle.receptionist.label":"Receptionist",
    "outsourceRecycle.netWeight.alFrame.label":"AL Frame",
    "outsourceRecycle.netWeight.JB.label":"JB",
    "outsourceRecycle.netWeight.glass.label":"Glass",
    "outsourceRecycle.netWeight.cell.label":"Cell",
    "outsourceRecycle.netWeight.eva.label":"EVA",
    "outsourceRecycle.netWeight.pvdf.label":"PVDF",
    /* 訂單輸入欄位 錯誤提示 */
    "outsourceRecycle.form.helperTexts.empty":"No whitespace allowed here",
    "outsourceRecycle.form.helperTexts.exist":"The number exists",
    "outsourceRecycle.form.helperTexts.format":"Format of the number is not valid",
    "outsourceRecycle.form.helperTexts.ordererMail":"Email is not valid",
    "outsourceRecycle.form.helperTexts.ordererTel.tw":"Please enter a valid Taiwan phone number. Formats can include: 02xxxxxxxx (for fixed-line phones), 09xxxxxxxx (for mobile phones), or +886xxxxxxxxx.",
    "outsourceRecycle.form.helperTexts.taxNumber.length":"Tax Number must be at least {taxNumberLimit} characters long",
    "outsourceRecycle.form.helperTexts.customizedNumber.length":"Customized Number must be at least {customizedNumberLimit} characters long",
    "outsourceRecycle.form.helperTexts.tripletNumber.length":"Triplet Number must be at least {tripletNumberLimit} characters long",
    /* table 狀態顯示文字 */
    "outsourceRecycle.chip.canceled.text":"Canceled",
    "outsourceRecycle.chip.arrived.text":"Arrived",
    "outsourceRecycle.chip.recycling.text":"Recycling",
    "outsourceRecycle.chip.completed.text":"Completed",
    /* 搜尋 */
    "outsourceRecycle.status.label.all":"All",
    "outsourceRecycle.status.placeholder.search":"Enter Customer Name or Order Number",

}

export default outsourcingRecyclingPage
