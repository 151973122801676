import React from 'react';
import './Loading.css';

function LoadingItem() {
  return (
    <div className="loader" />
  );
}

export default LoadingItem;
