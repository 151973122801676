import React, {
  useEffect, useRef, useState,
} from 'react';
import TextField from '@mui/material/TextField';

interface OtpInputProps {
  handleOtpChange: (value: string) => void;
  isValidateVerifyCode: boolean;
  setIsValidateVerifyCode: (value: boolean) => void;
  isOtpDisabled: boolean;
}
const OtpInput: React.FC<OtpInputProps> = ({
  handleOtpChange,
  isValidateVerifyCode,
  setIsValidateVerifyCode,
  isOtpDisabled,
}) => {
  const [otp, setOtp] = useState(['', '', '', '']);
  const inputRefs: any = useRef([]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
  ): void => {
    const { value } = e.target;
    if (Number.isNaN(value)) {
      return;
    }
    const currentOtp = otp[index];

    if (value !== currentOtp) {
      setOtp((prevOtp: string[]) => {
        const newOtp = [...prevOtp];
        newOtp[index] = value;

        return newOtp;
      });
    }
    // move focus to the next input
    if (value !== '') {
      if (typeof index === 'number' && index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>, index: number): void => {
    if (e.key === 'Backspace' && otp[index] === '') {
      // move focus to the previous input on backspace
      if (index > 0) {
        const previousInput = inputRefs.current[index - 1];
        previousInput.focus();
        previousInput.setSelectionRange(0, previousInput.value.length);
      }
    } else if (e.key === 'ArrowLeft') {
      if (index > 0) {
        e.preventDefault();
        const previousInput = inputRefs.current[index - 1];
        previousInput.focus();
        previousInput.setSelectionRange(0, previousInput.value.length);
      }
    } else if (e.key === 'ArrowRight') {
      if (typeof index === 'number' && index < inputRefs.current.length - 1) {
        e.preventDefault();
        const nextInput = inputRefs.current[index + 1];
        nextInput.focus();
        nextInput.setSelectionRange(0, nextInput.value.length);
      }
    }
  };

  useEffect(() => {
    const otpValue = otp.join('').split(',')[0];
    handleOtpChange(otpValue);
  }, [otp]);

  useEffect(() => {
    if (!isValidateVerifyCode) {
      setOtp(['', '', '', '']);
      inputRefs.current[0].focus();
      setTimeout(() => {
        setIsValidateVerifyCode(true);
      }, 1000);
    }
  }, [isValidateVerifyCode]);

  const inputIndex = ['key0', 'key1', 'key2', 'key3'];
  const boxSize = {
    xl: '5rem',
    lg: '5rem',
    md: '4rem',
    sm: '4rem',
    xs: '4rem',
  };
  return (
    <div>
      {otp.map((digit, index) => (
        <TextField
          key={inputIndex[index]}
          type="text"
          disabled={isOtpDisabled}
          inputProps={{
            maxLength: 1,
            style: {
              fontSize: '2.125rem',
              color: 'black',
              textAlign: 'center',
              padding: 0,
            },
          }}
          value={digit}
          onChange={(e) => {
            handleChange(e, index);
          }}
          onKeyDown={(e) => {
            handleKeyDown(e, index);
          }}
          inputRef={(ref) => {
            inputRefs.current[index] = ref;
            return inputRefs.current[index];
          }}
          sx={{
            marginRight: `${index !== otp.length - 1 ? '16px' : '0'}`,
            '& .MuiInputBase-root': {
              borderRadius: '0.5rem',
              width: boxSize,
              height: boxSize,
            },
          }}
        />
      ))}
    </div>
  );
};

export default OtpInput;
