// React
import React from 'react';
import { getApps, initializeApp } from 'firebase/app';
// MUI
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// components
import Body from './main/Body';
// theme
import customTheme from './styles/customTheme';
//
import { BrowserRouter } from 'react-router-dom';
//
import { firebaseConfig } from './config/config-firebase';
// CSS
import './styles/globals.scss';
import IndexProvider from './reduxStore/IndexProvider';
import DataLayer from './data/DataLayer';

if (getApps().length < 0) {
  initializeApp(firebaseConfig);
}

// main
function App() {
  return (
    <BrowserRouter>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider theme={customTheme}>
          <IndexProvider>
            <>
              <DataLayer />
              <CssBaseline />
              <Body />
            </>
          </IndexProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </BrowserRouter>
  );
}

export default App;
