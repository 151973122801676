import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import userTitleContainerStyle from '../userTitleContainerStyle';
import Typography from '@mui/material/Typography';
import OtpInput from '../../../commons/components/otpInput/OtpInput';
import { string } from 'yup';
import services from '../../../api/services';
import routes from '../../../config/routes';
import LeftArrow from '../../../images/signUp/arrow-left.png';
import TitleContainer from '../subComponent/TitleContainer';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import handleCheckEmailExist from '../utils/handleCheckEmailExist';
import handleSendEmailForUser from '../utils/handleSendEmailForUser';
import { auth } from '../../../config/config-firebase';
import EasyDialog from '../../../commons/components/modal/EasyDialog';
import { delData, getData } from '../../../api/firebase/cloudFirestore';
import firestorePath from '../../../api/firebase/cloudFirestore/path';
import userAct from '../userAction';
import { useAppSelector } from '../../../reduxStore/utils/reduxHooks';
import EasyIconButton from '../../../commons/components/EasyIconButton/EasyIconButton';
import EasyBackIcon from '../../../commons/components/EasyIcons/EasyBackIcon';
import ResetPasswordPage from '../UserStateChange/ResetPassword/ResetPasswordPage';

const STEP_CONFIG = {
  VERIFY_CODE: 'VERIFY_CODE',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
};
const ChangePasswordPage = () => {
  /** react-intl */
  const { formatMessage } = useIntl();
  const id2text = (id:string) => formatMessage({ id });
  //
  const resetPasswordStore = useAppSelector((state) => state.resetPasswordDataStore);
  //
  const navigation = useNavigate();
  // 驗證碼
  const [otp, setOtp] = useState('');
  const handleOtpChange = (value: string): void => {
    setOtp(value);
  };
  /* step */
  const [step, setStep] = useState(STEP_CONFIG.VERIFY_CODE);

  /** STEP_VERIFY_CODE * */
  // 重發驗證碼信件按鈕開關
  const [isSendMailButtonDisabled, setIsSendMailButtonDisabled] = useState(false);
  // 是否正在寄送 email
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  // 重發時間倒數
  const [resendTime, setResendTime] = useState(60);
  // 驗證碼是否正確
  const [isValidateVerifyCode, setIsValidateVerifyCode] = useState(true);
  const [isOtpDisabled, setIsOtpDisabled] = useState(false);
  /* 寄信失敗視窗 */
  const [isResendMailFailed, setIsResendMailFailed] = useState(false);
  /* 寄信失敗視窗文字 */
  const modalTextObj = {
    error: {
      title: id2text('forgotPassword.page.modal.error.title'),
      text: id2text('forgotPassword.page.modal.error.text'),
    },
  };
  const modalText = modalTextObj.error;
  const hintText = {
    good: id2text('forgotPassword.page.hint.good'),
    bad: id2text('forgotPassword.page.hint.bad'),
  };
  // 驗證碼輸入後提示文字
  interface HintProps {
    hintText: string;
    hintColor: string;
  }
  // 驗證碼輸入後提示文字
  const [hint, setHint] = useState<HintProps | null>(null);

  // 驗證碼正確
  const verifyEmailCode = async (): Promise<void> => {
    if (typeof resetPasswordStore === 'string') {
      const data = await getData(
        firestorePath.RESET_PASSWORD_VERIFY_CODE,
        resetPasswordStore,
      );
      if (data?.verifyCode === otp) {
        const currentTimestamp = Math.floor(Date.now());
        const firestoreTimestamp = data?.createdAt;
        const timeDifference = (currentTimestamp - parseInt(
          firestoreTimestamp,
          10,
        )) / 1000;
        if (timeDifference > 300) {
          setIsValidateVerifyCode(false);
          setHint({ hintText: hintText.bad, hintColor: 'red' });
          setIsOtpDisabled(false);
          return;
        }
        setHint({ hintText: hintText.good, hintColor: 'green' });

        // 驗證成功刪除驗證碼資料
        await delData(firestorePath.RESET_PASSWORD_VERIFY_CODE, resetPasswordStore);
        setTimeout(() => {
          setStep(STEP_CONFIG.CHANGE_PASSWORD);
        }, 500);
      } else {
        setOtp('');
        setIsOtpDisabled(false);
        setIsValidateVerifyCode(false);
        setHint({ hintText: hintText.bad, hintColor: 'red' });
      }
    }
  };
  // 驗證碼滿4碼自動發送
  useEffect(() => {
    if (otp.length !== 0) {
      // remove hint after typing
      setHint(null);
      if (otp.length === 4) {
        setIsOtpDisabled(true);
        verifyEmailCode();
      }
    }
  }, [otp]);

  const handleResendButton = async (): Promise<void> => {
    setIsSendingEmail(true);
    setIsSendMailButtonDisabled(true);
    if (typeof auth.currentUser?.email === 'string') {
      const res = await handleSendEmailForUser({
        email: auth.currentUser?.email,
        type: 'resetPassword',
      }).catch(() => {
        setIsSendingEmail(false);
        setIsSendMailButtonDisabled(false);
        /* 寄信失敗視窗 */
        setIsResendMailFailed(true);
      });
      if (res) {
        setIsSendingEmail(false);
        const interval = setInterval(() => {
          setResendTime((prevSecond) => {
            const newSecond = prevSecond - 1;
            if (newSecond === 0) {
              clearInterval(interval);
              setIsSendMailButtonDisabled(false);
              return 60;
              // setResendTime(60);
            }
            return newSecond;
          });
        }, 1000);
      } else {
        setIsSendingEmail(false);
        setIsSendMailButtonDisabled(false);
        /* 寄信失敗視窗 */
        setIsResendMailFailed(true);
      }
    }
  };
  const pageStyle = {
    box_width: {
      xl: '23rem',
      lg: '23rem',
      md: '20.5rem',
      sm: '19.5rem',
      xs: '19.5rem',
    },
    box_height: {
      xl: '18rem',
      lg: '18rem',
      md: '18rem',
      sm: '18rem',
      xs: '18rem',
    },
    title_mt: {
      xl: '4rem',
      lg: '4rem',
      md: '4rem',
      sm: '4rem',
      xs: '4rem',
    },
    subtitle_mt: {
      xl: '2.5rem',
      lg: '2.5rem',
      md: '2.5rem',
      sm: '2.5rem',
      xs: '2.5rem',
    },
    otp_mt: {
      xl: '1rem',
      lg: '1rem',
      md: '1rem',
      sm: '1rem',
      xs: '1rem',
    },
    hint_mt: {
      xl: '1rem',
      lg: '1rem',
      md: '1rem',
      sm: '1rem',
      xs: '1rem',
    },
    resendArea_mt: {
      xl: '1rem',
      lg: '1rem',
      md: '1rem',
      sm: '1rem',
      xs: '1rem',
    },
  };

  return (
    <>
      {/* 提示modal */}
      <EasyDialog
        isOpen={isResendMailFailed}
        setIsOpen={setIsResendMailFailed}
        showCloseIcon
        title={modalText.title}
        body={(
          <Typography variant="body1" my="6%">
            {modalText.text}
          </Typography>
            )}
        sx={{
          minHeight: {
            xl: '0vh',
            lg: '0vh',
            md: '0vh',
            sm: '0vh',
            xs: '0vh',
          },
        }}
      />
      {/* 驗證碼 */}
      {step === STEP_CONFIG.VERIFY_CODE && (
      //   <TitleContainer customTitle={(
      //     <Grid container justifyContent="center" position="relative">
      //       <Box sx={{ position: 'absolute', top: '0', left: '0' }} width="auto">
      //         <EasyIconButton onClick={() => navigation(`/${services.getLocale()}${routes.DASHBOARD_SETTINGS}`)}>
      //           <EasyBackIcon />
      //         </EasyIconButton>
      //       </Box>
      //       <Typography fontSize="700" variant="h5">
      //         {id2text('changePassword.pageTitle')}
      //       </Typography>
      //     </Grid>
      //     )}
      //   >
      //     <Grid
      //       container
      //       justifyContent="start"
      //       mt={userTitleContainerStyle.mt40px}
      //       sx={{
      //         paddingLeft: {
      //           xl: '18%',
      //         },
      //         justifyContent: 'start',
      //       }}
      //     >
      //       <Typography>{id2text('forgotPassword.page.verifyCode.label1')}</Typography>
      //     </Grid>
      //     <Grid
      //       container
      //       justifyContent="start"
      //       mt={userTitleContainerStyle.mt16px}
      //       sx={{
      //         justifyContent: {
      //           xs: 'start',
      //           sm: 'start',
      //           md: 'start',
      //           lg: 'start',
      //           xl: 'center',
      //         },
      //       }}
      //     >
      //       <OtpInput
      //         handleOtpChange={handleOtpChange}
      //         isValidateVerifyCode={isValidateVerifyCode}
      //         setIsValidateVerifyCode={setIsValidateVerifyCode}
      //         isOtpDisabled={isOtpDisabled}
      //       />
      //     </Grid>
      //     <Grid
      //       container
      //       sx={{
      //         paddingLeft: {
      //           xl: '18%',
      //         },
      //         justifyContent: 'start',
      //       }}
      //     >
      //       <Typography
      //         variant="subtitle2"
      //         mt="1rem"
      //         sx={{ color: hint?.hintColor }}
      //       >
      //         {hint?.hintText}
      //       </Typography>
      //     </Grid>
      //     <Grid
      //       container
      //       mt={userTitleContainerStyle.mt16px}
      //       sx={{
      //         justifyContent: 'start',
      //         paddingLeft: {
      //           xl: '17.7%',
      //         },
      //       }}
      //     >
      //       <Typography variant="subtitle2">
      //         {id2text('forgotPassword.page.verifyCode.label2')}
      //       </Typography>
      //       {isSendMailButtonDisabled ? (
      //         <Typography variant="subtitle2" color={isSendingEmail ? 'primary' : 'grey'} pl="0.5rem">
      //           {isSendingEmail ? id2text('forgotPassword.page.verifyCode.sendingMail1') : formatMessage({ id: 'forgotPassword.page.verifyCode.sendingMail2' }, { resendTime })}
      //         </Typography>
      //       ) : (
      //         <button
      //           type="button"
      //           style={{
      //             border: 0,
      //             backgroundColor: 'white',
      //             cursor: 'pointer',
      //             marginLeft: '0.5rem',
      //           }}
      //           onClick={() => {
      //             handleResendButton();
      //             // remove hint after resending verify code
      //             setHint(null);
      //           }}
      //           disabled={isSendMailButtonDisabled}
      //         >
      //           <Typography variant="subtitle2" color="primary">
      //             {id2text('forgotPassword.page.verifyCode.resend.text')}
      //           </Typography>
      //         </button>
      //       )}
      //     </Grid>
      //   </TitleContainer>
      // )}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        mt="2.5rem"
      >
        <Box width={pageStyle.box_width} height={pageStyle.box_height}>
          <Grid container justifyContent="center" position="relative">
            <Box sx={{ position: 'absolute', top: '0', left: '0' }} width="auto">
              <EasyIconButton onClick={() => navigation(`/${services.getLocale()}${routes.DASHBOARD_SETTINGS}`)}>
                <EasyBackIcon />
              </EasyIconButton>
            </Box>
            <Typography fontSize="700" variant="h5">
              {id2text('changePassword.pageTitle')}
            </Typography>
          </Grid>
          <Box mt={pageStyle.subtitle_mt}>
            <Typography>{id2text('setting.modal.verifyCode.label1')}</Typography>
          </Box>
          <Box mt={pageStyle.otp_mt} display="flex" justifyContent="start">
            <OtpInput
              handleOtpChange={handleOtpChange}
              isValidateVerifyCode={isValidateVerifyCode}
              setIsValidateVerifyCode={setIsValidateVerifyCode}
              isOtpDisabled={isOtpDisabled}
            />
          </Box>
          <Box mt={pageStyle.hint_mt} display="flex" justifyContent="start">
            <Typography variant="subtitle2" sx={{ color: hint?.hintColor }}>
              {hint?.hintText}
            </Typography>
          </Box>
          <Box
            mt={pageStyle.resendArea_mt}
            display="flex"
            justifyContent="start"
            flexDirection={{
              xl: 'row',
              lg: 'row',
              md: 'column',
              sm: 'column',
              xs: 'column',
            }}
          >
            <Typography variant="subtitle2">
              {id2text('setting.modal.verifyCode.label2')}
            </Typography>
            {isSendMailButtonDisabled ? (
              <Typography
                variant="subtitle2"
                color={isSendingEmail ? 'primary' : 'grey'}
                sx={{
                  marginLeft: {
                    xl: '0.5rem',
                    lg: '0.5rem',
                    md: '0',
                    sm: '0',
                    xs: '0',
                  },
                }}
              >
                {isSendingEmail
                  ? id2text('setting.modal.email.verify.hint.sendingMail1')
                  : formatMessage({ id: 'setting.modal.email.verify.hint.sendingMail2' }, { resendTime })}
              </Typography>
            ) : (
              <button
                type="button"
                style={{
                  border: 0,
                  backgroundColor: 'white',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  handleResendButton();
                  // remove hint after resending verify code
                  setHint(null);
                }}
                disabled={isSendMailButtonDisabled}
              >
                <Typography
                  variant="subtitle2"
                  color="primary"
                  display="flex"
                  justifyContent="start"
                  sx={{
                    marginLeft: {
                      xl: '0.5rem',
                      lg: '0.5rem',
                      md: '0',
                      sm: '0',
                      xs: '0',
                    },
                  }}
                >
                  {id2text('setting.modal.email.verify.resend.text')}
                </Typography>
              </button>
            )}
          </Box>
        </Box>
      </Box>
      )}
      {/* 更改密碼 */}
      {step === STEP_CONFIG.CHANGE_PASSWORD && (
      <ResetPasswordPage />
      )}
    </>
  );
};

export default ChangePasswordPage;
