// TODO: read text length limit of username from config

export const USERNAME_LENGTH_LIMIT = 214;

const requirements = {
  username: {
    length: 'Name length must be less than or equal to 214 characters long',
    lowerCase: 'Name must be lowercase',
    urlSafe: 'Name may not contain non-url-safe chars',
    dot: 'Name may not start with "."',
    illegal: 'Name may not contain illegal character',
  },
  password: {},
  email: {
    length: 'Email length must be less then or equal to 254 characters long',
    valid: 'Email must be an email address',
  },
};

// const illegalCharacterRe = new RegExp(`([${["'"].join()}])`);
// const illegalCharacterRe = new RegExp(`([${['[]\\/"「」$%^@#&'].join('\\')}])`);

export const ILLEGAL_CH = '[]\\/"「」$%^@#&';

// if username is illegal throw Error
function usernameErr(text: string): Error | null {
  // if (text !== text.toLowerCase()) {
  //   return new Error(requirements.username.lowerCase);
  // }

  // if (text !== encodeURIComponent(text)) {
  //   return new Error(requirements.username.urlSafe);
  // }

  if (text.charAt(0) === '.') {
    return new Error(requirements.username.dot);
  }

  if (text.length > USERNAME_LENGTH_LIMIT) {
    return new Error(requirements.username.length);
  }

  // const illegal = text.match(illegalCharacterRe);
  // if (illegal) {
  //   return new Error(`${requirements.username.illegal} "${illegal[0]}"`);
  // }

  return null;
}

const validateUserName = (min = 1, max: number = USERNAME_LENGTH_LIMIT) => (text: string, _min: number = min, _max: number = max): boolean => {
  try {
    return text.length >= _min && text.length <= _max && !usernameErr(text);
  } catch (e) {
    return false;
  }
};

export default validateUserName;
