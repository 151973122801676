const INPUT_LIMIT = {
  taxNumber: 8,
  contactTel: 10,
};

const selectPageCounts = [10, 20, 50, 100];

const pageMode = {
  ADD: 'add',
  EDIT: 'edit',
  LIST: 'list',
};

export { pageMode, INPUT_LIMIT, selectPageCounts };
