import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';
import { useEffect } from 'react';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';
import { SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';

// This key was created specifically for the demo in mui.com.
// You need to create a new one for your application.
const GOOGLE_MAPS_API_KEY = process.env.REACT_PUBLIC_GOOGLE_MAPS_API_KEY;

// const loadScript = (src: string, position: HTMLElement | null, id: string) => {
//   if (!position) {
//     return;
//   }
//   const script = document.createElement('script');
//   script.setAttribute('async', '');
//   script.setAttribute('id', id);
//   script.src = src;
//   position.appendChild(script);
// };

const autocompleteService = { current: null };
const placeholderText = 'Please enter the full address.';
interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}

interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}

interface PlaceType {
  place_id: string;
  description: string;
  structured_formatting: StructuredFormatting;
}
interface AddressProps {
  country?: string;
  state?: string;
  city?: string;
  district?: string;
  address1?: string;
  address2?: string;
  postalCode?: string;
  location?: string;
  [key: string]: string | undefined;
}

interface AddressComponents {
  long_name: string;
  short_name: string;
  types: string | string[];
}

interface MuiGooglePlaceProps {
  name?: string;
  error?: boolean;
  onBlur?: (event: React.ChangeEvent) => void;
  helperText?: string | React.ReactNode;
  setAddress?: React.Dispatch<React.SetStateAction<AddressProps>>;
  handleOnChange?: (address: AddressProps) => void;
  buttonIsLoading?: boolean;
  setIsValidAddress?: React.Dispatch<React.SetStateAction<boolean>>;
  getDetail?:(()=>void) | ((item:any)=>any);
  boxSx?:SxProps<Theme> | undefined;
}

const MuiGooglePlace: React.FC<MuiGooglePlaceProps> = ({
  name,
  error,
  onBlur,
  helperText = '',
  setAddress,
  handleOnChange,
  buttonIsLoading,
  setIsValidAddress,
  getDetail,
  boxSx,
}) => {
  const { clearSuggestions } = usePlacesAutocomplete({
    callbackName: 'YOUR_CALLBACK_NAME',
    requestOptions: {
      /* Define search scope here */
      sessionToken: new (
        window as any
      ).google.maps.places.AutocompleteSessionToken(),
    },
    debounce: 300,
  });

  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });

  const [value, setValue] = React.useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);
  // const loaded = React.useRef(false);
  // if (typeof window !== 'undefined' && !loaded.current) {
  //   if (!ref) {
  //     loadScript(
  //       `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
  //       document.querySelector('head'),
  //       'google-maps',
  //     );
  //   }
  //   loaded.current = true;
  // }

  const fetch = React.useMemo(
    () => debounce(
      (
        request: { input: string },
        callback: (results?: readonly PlaceType[]) => void,
      ) => {
        (autocompleteService.current as any).getPlacePredictions(
          request,
          callback,
        );
      },
      400,
    ),
    [],
  );

  const parseAddress = (addressComponents: AddressComponents[]) => addressComponents.reduce(
    (result, component) => {
      const updatedResult = { ...result }; // 使用新的物件複製原始 result

      if (component.types.includes('country')) {
        updatedResult.country = component.short_name;
      } else if (component.types.includes('administrative_area_level_1')) {
        updatedResult.state = component.long_name;
      } else if (component.types.includes('administrative_area_level_2')) {
        updatedResult.city = component.long_name;
      } else if (component.types.includes('administrative_area_level_3')) {
        updatedResult.district = component.long_name;
      } else if (component.types.includes('route')) {
        updatedResult.address1 = component.long_name;
      } else if (component.types.includes('postal_code')) {
        updatedResult.postalCode = component.long_name;
      } else if (component.types.includes('street_number')) {
        updatedResult.address2 = component.long_name;
      }
      return updatedResult; // 返回更新後的結果物件
    },
    {
      country: '',
      state: '',
      city: '',
      district: '',
      address1: '',
      address2: '',
      postalCode: '',
    },
  );

  const handleSearchResult = (address: PlaceType | null) => {
    if (address == null) return;
    getGeocode({ address: address?.description }).then((res) => {
      // console.log(res[0].address_components);
      const { lat, lng } = getLatLng(res[0]);
      const item = parseAddress(res[0].address_components);
      // console.log('address.description', address.description);
      if (setAddress) {
        setAddress({
          ...item, lat: lat.toString(), long: lng.toString(), location: address.description,
        });
      }
      if (setIsValidAddress) {
        setIsValidAddress(true);
      }
      if (typeof handleOnChange === 'function') {
        handleOnChange({
          ...item,
          lat: lat.toString(),
          long: lng.toString(),
          description: address?.description,
        });
      }
      if (getDetail) {
        getDetail({
          ...item, lat: lat.toString(), long: lng.toString(), location: address.description,
        });
      }
      clearSuggestions();
      console.log(res[0]);
      console.log('lat', lat);
      console.log('lng', lng);
      // console.log(item);
      // console.log(lat, lng);
    });
  };

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          // newOptions = [...newOptions, ...results];
          newOptions = [...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Box width="100%" sx={{ ...boxSx }} mt="2rem" ref={ref}>
      <Autocomplete
        disabled={buttonIsLoading}
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        noOptionsText="No locations"
        onChange={(_, newValue: PlaceType | null) => {
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
          handleSearchResult(newValue);
        }}
        onInputChange={(_, newInputValue) => {
          setInputValue(newInputValue);
          clearSuggestions();
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={placeholderText}
            InputProps={{
              ...params.InputProps,
              // type: 'search',
              style: { height: '3.1875rem', borderRadius: '0.5rem' },
            }}
            name={name}
            onBlur={onBlur}
            error={error}
            helperText={helperText}
          />
        )}
        renderOption={(props, option) => {
          const matches =
            option.structured_formatting.main_text_matched_substrings || [];

          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match) => [match.offset, match.offset + match.length]),
          );
          return (
            <li {...props} key={option.place_id}>
              <Grid container alignItems="center">
                <Grid item sx={{ display: 'flex', width: 44 }}>
                  <LocationOnIcon sx={{ color: 'text.secondary' }} />
                </Grid>
                <Grid
                  item
                  sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}
                >
                  {parts.map((part) => (
                    <Box
                      key={part.text}
                      component="span"
                      sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                    >
                      {part.text}
                    </Box>
                  ))}
                  <Typography variant="body2" color="text.secondary">
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
        isOptionEqualToValue={(option, value) => option.place_id === value.place_id}
      />
    </Box>
  );
};

export default MuiGooglePlace;
