import React, { useState } from 'react';

/* components */
import EasyDialog from '../../../../../commons/components/modal/EasyDialog';
import Loading from '../../../../../commons/components/loading/Loading';

/* mui */
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
/* mui icon */

/* utils */
import { useIntl } from 'react-intl';

/* hooks */

/* types */
import type CustomerProps from '../TypescriptProps';

/* config */

interface CustomerInfoModalProps {
  isOpen:boolean;
  setIsOpen:(e:boolean)=>void;
  customer:CustomerProps;
}

const CustomerInfoModal:React.FC<CustomerInfoModalProps> = ({
  customer, isOpen, setIsOpen,
}) => {
  //
  /** react-intl */
  const { formatMessage } = useIntl();
  const id2text = (id:string) => formatMessage({ id });
  // function
  //
  const [isLoading, setIsLoading] = useState(false);
  /* data array */
  const companyInfoArray = [
    {
      id: 'Company Name',
      label: id2text('customerManagement.label.companyName'),
      value: customer?.companyName,
    },
    {
      id: 'Tax Number',
      label: id2text('common.tax.number'),
      value: customer?.taxNumber,
    },
  ];

  const customerInfoArray = [
    {
      id: 'Contact Name',
      label: id2text('customerManagement.label.name'),
      value: customer.contactName,
    },
    {
      id: 'Contact Mail',
      label: id2text('customerManagement.label.email'),
      value: customer.contactMail,
    },
    {
      id: 'Contact Phone',
      label: id2text('customerManagement.label.phone'),
      value: customer.contactTel,
    },
  ];

  // useEffect(() => {
  //   setSelectedEquipment({} as EquipmentProps);
  // }, [isAssignOpen]);

  return (
    <EasyDialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      showCloseIcon
      title={id2text('common.information')}
      dialogActionSX={{ mt: '3rem', padding: '0 1rem' }}
      dialogBodySX={{
        marginTop: '0', width: '100%', maxHeight: '84.5vh', overflowY: 'auto', padding: '1rem',
      }}
      sx={{ minWidth: '46.875vw', maxHeight: '84.5vh' }}
      body={(
        <Box width="100%">
          {isLoading ? <Loading /> : (
            <>
              {/* Company Information */}
              <Box width="100%" mt="1rem">
                <Typography variant="h6" fontWeight="700">
                  {id2text('customerManagement.page.subtitle.companyInformation')}
                </Typography>
                <Box width="100%" mt="1.5rem">
                  {companyInfoArray.map((item) => (
                    item.value && (
                      <Grid key={item.id} container width="100%" mt="0.75rem" justifyContent="space-between" alignItems="center">
                        <Grid item container justifyContent="start" xs={6}>
                          <Typography variant="body1">
                            {item.label}
                          </Typography>
                        </Grid>
                        <Grid item container justifyContent="start" xs={6}>
                          {typeof item.value === 'string' ? (
                            <Typography variant="body1">
                              {item.value}
                            </Typography>
                          )
                            :
                            item.value}
                        </Grid>
                      </Grid>
                    )
                  ))}
                </Box>
              </Box>
              {/* Customer Information */}
              <Box width="100%" mt="1.5rem">
                <Typography variant="h6" fontWeight="700">
                  {id2text('customerManagement.page.subtitle.customerInformation')}
                </Typography>
                <Box width="100%" mt="1.5rem">
                  {customerInfoArray.map((item) => (
                    <Grid key={item.id} container width="100%" mt="0.75rem" justifyContent="space-between" alignItems="center">
                      <Grid item container justifyContent="start" xs={6} alignItems="center">
                        <Typography variant="body1">
                          {item.label}
                        </Typography>
                      </Grid>
                      <Grid item container justifyContent="start" xs={6} alignItems="center">
                        {typeof item.value === 'string' ? (
                          <Typography variant="body1">
                            {item.value}
                          </Typography>
                        )
                          :
                          item.value}
                      </Grid>
                    </Grid>
                  ))}
                </Box>
              </Box>
            </>
          )}
        </Box>
            )}
    />
  );
};

export default CustomerInfoModal;
