const resetPasswordPage = {
    /* reset password */
    "resetPassword.page.title": "重設密碼",
    "resetPassword.page.title.success": "成功！",
    /* 輸入欄位 提示 */
    "resetPassword.page.hint.password": "密碼長度必須至少為8個字符，並包含大寫字母、小寫字母和數字。",
    "resetPassword.page.hint.confirmPassword": "密碼和確認密碼不符合",
    /*  */
    "resetPassword.page.reset.label1": " 如果完成密碼變更，您將從其他裝置登出。",
    "resetPassword.page.reset.label2": "密碼重設完成，請重新登入。",
    "resetPassword.page.button.reset": "重設",
    "resetPassword.page.button.cancel": "取消",
    "resetPassword.page.button.signIn": "登入",
    /* 重設密碼 提示視窗 */
    "resetPassword.page.modal.error.title": "系統錯誤",
    "resetPassword.page.modal.error.text": "系統錯誤。請稍後再試。",
}

export default resetPasswordPage
