import React from 'react';
// component
import EasyDialog from '../../../../../commons/components/modal/EasyDialog';
// mui
import Typography from '@mui/material/Typography';
import { useIntl } from 'react-intl';

interface ConfirmModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  handleConfirm?: () => void;
}
const ConfirmModal: React.FC<ConfirmModalProps> = ({
  setIsOpen,
  isOpen,
  handleConfirm,
}) => {
  /** react intl */
  const { formatMessage } = useIntl();
  const id2text = (id:string) => formatMessage({ id });
  //
  const Confirm = async (): Promise<void> => {
    setIsOpen(false);
    // 改變模式 把ValidationFunction 傳進來
    if (handleConfirm) {
      handleConfirm();
    }
  };

  const handleCancel = (): void => {
    setIsOpen(false);
  };

  return (
    <EasyDialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      showCloseIcon
      title={id2text('setting.modal.confirm.title')}
      action={Confirm}
      actionLabel={id2text('setting.modal.confirm.action')}
      secondaryAction={handleCancel}
      secondaryLabel={id2text('setting.modal.confirm.secondary.action')}
      handleClose={handleCancel}
      body={(
        <>
          <Typography>
            {id2text('setting.modal.confirm.text1')}
          </Typography>
          <Typography>
            {id2text('setting.modal.confirm.text2')}
          </Typography>
        </>
      )}
    />
  );
};

export default ConfirmModal;
