import type React from 'react';
import { useState } from 'react';

export interface UseValidateStateReturnProps {
  value: string;
  isValid: boolean;
  helperText: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleValidate: () => void | boolean;
  setValue: (e: string) => void;
  setIsValid: (e: boolean) => void;
  setHelperText: (e: string) => void;
  disable: boolean;
  setDisable: (e: boolean) => void;
}
/*
  使用方式 :
    第 1 個值 傳入初始值 例如 : "" 空字串
    第 2 個值 傳入檢查規則 例如 : validateEmail
    第 3 個值 傳入提示文字 (檢查有誤時顯示) 例如 : "Email is not valid"
*/

const useValidateState = (
  initialValue: string,
  validator: (value: string, type?:any) => boolean,
  validationErrorMessage: string,
): UseValidateStateReturnProps => {
  const [value, setValue] = useState(initialValue);
  const [isValid, setIsValid] = useState(true);
  const [helperText, setHelperText] = useState('');
  // 可以幫忙設置輸入框disable狀態
  const [disable, setDisable] = useState(false);
  // 數值變化
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(e.target.value);
  };
  const handleValidate = () => {
    const isValidValue = validator(value);
    setIsValid(isValidValue);
    setHelperText(isValidValue ? '' : `${validationErrorMessage}`);
    return isValidValue;
  };

  return {
    value,
    isValid,
    helperText,
    handleChange,
    handleValidate,
    setValue,
    setIsValid,
    setHelperText,
    disable,
    setDisable,
  };
};

export default useValidateState;
