import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
// mui
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

// MUI Icons
import SettingIcon from '@mui/icons-material/Settings';
import AssignmentIcon from '@mui/icons-material/Assignment';
import WarehouseIcon from '@mui/icons-material/Warehouse';

// Icons
import EasyOrderIcon from '../../../commons/components/EasyIcons/EasyOrderIcon';
import EasyRecyclingHistoryIcon from '../../../commons/components/EasyIcons/EasyRecyclingHistoryIcon';
import EasyManagementAuthenticationIcon from '../../../commons/components/EasyIcons/EasyManagementAuthenticationIcon';
import EasyEquipIcon from '../../../commons/components/EasyIcons/EasyEquipIcon';
import EasyRentalContractIcon from '../../../commons/components/EasyIcons/EasyRentalContractIcon';
import EasyRecyclingPlanIcon from '../../../commons/components/EasyIcons/EasyRecyclingPlanIcon';

// hooks
import { useLocation } from 'react-router-dom';

// route config
import routes from '../../../config/routes/index';
import services from '../../../api/services';
import authority from '../../../App-authority';
import { useAppSelector } from '../../../reduxStore/utils/reduxHooks';
import { WAREHOUSE_NAME } from '../../../config/config.warehouse';

//  components
import EasyDrawer from '../../../commons/components/EasyDrawer/EasyDrawer';
import EasyOutsourceRecyclingIcon from '../../../commons/components/EasyIcons/EasyOutsourceRecyclingIcon';

// Typescript
interface Props {
  children: React.ReactNode;
}

const RootLayout: React.FC<Props> = ({ children }): React.JSX.Element => {
  const location = useLocation();
  const locationMinHeight = {
    [`/${services.getLocale()}${routes.SIGNIN}`]: '80vh',
    [`/${services.getLocale()}${routes.SIGNUP}`]: '85vh',
    [`/${services.getLocale()}${routes.NOTFOUND}`]: '85vh',
  };
  const [minHeight, setMinHeight] = useState('80vh');
  useEffect(() => {
    const keys = Object.keys(locationMinHeight);
    const matchingKey = keys.find((key) => key === location.pathname);
    if (matchingKey) {
      setMinHeight(locationMinHeight[matchingKey]);
    }
  }, [location.pathname]);

  /** 控制側選單 需要填入哪些資訊才開放 * */
  const userGroup = useAppSelector((state) => state.userGroupRoleStore);
  console.log(userGroup);
  // const userData = useAppSelector((state) => state.userDataStore);

  // const isOpenArea = () => checkHandlerDataFull({
  //   userGroup,
  //   userData,
  //   editProfileData,
  // });
  // console.log('userGroup', userGroup);

  const ListItems = [
    {
      id: 'Setting',
      label: <FormattedMessage id="setting.page.title" />,
      path: routes.DASHBOARD_SETTINGS,
      icon: <SettingIcon sx={{ color: 'neutral.max' }} />,
      isShow: userGroup?.some(({ group }) => authority.Settings.includes(group)) as boolean,
    },
    {
      id: 'Customer Management',
      label: <FormattedMessage id="customerManagement.pageTitle" />,
      path: routes.DASHBOARD_CUSTOMER_MANAGEMENT,
      icon: <EasyOutsourceRecyclingIcon />,
      isShow: userGroup?.some(({ group }) => authority.ManagementCustomer.includes(group)) as boolean,
    },
    {
      id: 'Outsourcing Recycling',
      label: <FormattedMessage id="outsourceRecycle.pageTitle" />,
      path: routes.DASHBOARD_OUTSOURCING_RECYCLING,
      icon: <EasyOutsourceRecyclingIcon />,
      isShow: userGroup?.some(({ group }) => authority.OutsourcingRecycling.includes(group)) as boolean,
    },
    {
      id: 'Mark',
      label: 'Mark【Test】',
      path: routes.MARK,
      icon: <WarehouseIcon />,
      isShow: userGroup?.some(({ group }) => authority.WorkOrders.includes(group)) as boolean,
    },
  ];

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight,
      }}
    >
      <Grid container sx={{ flexGrow: 1 }}>
        {routes.EXPAND_NAVBAR.includes(
          location.pathname.replace(`/${services.getLocale()}`, ''),
        ) ? (
          <Grid>
            <Box
              sx={{
                minHeight: '86vh',
                position: 'relative',
              }}
            >
              <Grid container sx={{ flexWrap: 'nowrap' }}>
                <Grid item xl="auto" sx={{ width: 'auto' }}>
                  <EasyDrawer ListItems={ListItems} />
                </Grid>
                <Grid item xl>
                  {children}
                </Grid>
              </Grid>
            </Box>
          </Grid>
          ) : (
            <Grid px="2%">{children}</Grid>
          )}
      </Grid>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default RootLayout;
