// 倉別代碼
// 使用場景:產生"生產指令單號"
// e.g. GL950596011200521IR001 => IR => 來源倉:I, 目標倉:R
// e.g. GL950596011200521RS001 => RS => 來源倉:R, 目標倉:S
const WAREHOUSE_CODE = {
  INVENTORY: "I",
  RAWMATERIAL: "R",
  SEMIFINISH: "S",
  EQUIPMENT: "E",
  BYPRODUCT: "B",
};

// 倉別 ID
// 使用場景:寫入資料庫的 來源倉(WorkOrderDP/exitWarehouse),目標倉(WorkOrderDP/entryWarehouse)
const WAREHOUSE_ID = {
  INVENTORY: "INV",
  RAWMATERIAL: "RAW",
  SEMIFINISH: "SEM",
  EQUIPMENT: "EQU",
  BYPRODUCT: "BYP",
};

// 倉別名稱
// const WAREHOUSE_NAME = {
//   INVENTORY: 'Inventory Count Area', // 清點區
//   RAWMATERIAL: 'Raw Material Warehouse', // 原料倉
//   SEMIFINISH: 'Semi-finished products Warehouse', // 半成品倉
//   EQUIPMENT: 'Equipment Area', // 設備區
//   BYPRODUCT: 'By-Product Warehouse', // 副成品倉
// };

const WAREHOUSE_NAME = {
  INVENTORY: "清點區",
  RAWMATERIAL: "原料倉",
  SEMIFINISH: "半成品倉",
  EQUIPMENT: "設備區",
  BYPRODUCT: "副成品倉",
};

export { WAREHOUSE_CODE, WAREHOUSE_ID, WAREHOUSE_NAME };
