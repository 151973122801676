const firestorePath = {
  /* 開發-使用者資料 */
  USERS: process.env.REACT_APP_FS_USERS as string,
  /* 註冊email驗證碼 */
  USER_EMAIL_VERIFY_CODE: process.env.REACT_APP_FS_EMAIL_VERIFY as string,
  /* 重設密碼驗證碼 */
  RESET_PASSWORD_VERIFY_CODE: process.env
    .REACT_APP_FS_RESET_PASSWORD as string,

  // /* 開發-使用者資料 */
  // DEVELOPMENT: process.env.REACT_APP_FS_USERS,
  // /* 註冊email驗證碼 */
  // DEVELOPMENT_USER_EMAIL_VERIFY_CODE: process.env.REACT_APP_FS_EMAIL_VERIFY,
  // /* 重設密碼驗證碼 */
  // DEVELOPMENT_RESET_PASSWORD_VERIFY_CODE:
  //     process.env.REACT_APP_FS_RESET_PASSWORD,
  // /* 正式-使用者資料 */
  // PRODUCTION: 'production-users',
  /* 測試寫入 */
  WRITE: 'development-test-write',
};

export default firestorePath;
