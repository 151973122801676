import React from 'react';
import Alert from '@mui/material/Alert';
import { type AlertProps } from '@mui/material/Alert';

// MUI
import Typography, { type TypographyProps } from '@mui/material/Typography';

// icons
import ErrorIcon from '../../../images/icons/icon_alert_error/Error@1x.svg';
import InfoIcon from '../../../images/icons/icon_alert_info/Info@1x.svg';
import WarnIcon from '../../../images/icons/icon_alert_warn/Warn@1x.svg';
import SuccessIcon from '../../../images/icons/icon_alert_success/Success@1x.svg';

// TypeScript
interface EasyAlertSxProps {
  color: string;
  backgroundColor?: string;
  borderColor?: string;
}

// local configs
const ColorMappingWithVariant: {
  standard: Record<string, EasyAlertSxProps>;
  outlined: Record<string, EasyAlertSxProps>;
  filled?: Record<string, EasyAlertSxProps>;
} = {
  standard: {
    info: { color: 'primary.900', backgroundColor: 'primary.50' },
    error: { color: 'error.800', backgroundColor: 'error.50' },
    success: { color: 'success.main', backgroundColor: 'secondary.50' },
    warning: { color: 'warning.main', backgroundColor: 'tertiary.50' },
  },
  outlined: {
    info: { color: 'primary.900', borderColor: 'primary.700' },
    error: { color: 'error.800', borderColor: 'error.600' },
    success: { color: 'success.main', borderColor: 'secondary.500' },
    warning: { color: 'warning.main', borderColor: 'tertiary.500' },
  },
  filled: {},
};

// TypeScript
interface EasyAlertProps extends AlertProps {
  severity: 'info' | 'error' | 'success' | 'warning';
  variant?: 'standard' | 'outlined' | 'filled';
  children: React.ReactNode | string;
  variantOfTypography?: TypographyProps['variant'];
}

const EasyAlert: React.FC<EasyAlertProps> = ({
  sx,
  variant = 'standard',
  severity,
  children,
  variantOfTypography = 'subtitle1',
}) => (
  <Alert
    severity={severity}
    iconMapping={{
      error: <img width={15} height={15} src={ErrorIcon} alt="Error Icon" />,
      info: <img width={15} height={15} src={InfoIcon} alt="Info Icon" />,
      warning: <img width={15} height={15} src={WarnIcon} alt="Warn Icon" />,
      success: (
        <img width={15} height={15} src={SuccessIcon} alt="Success Icon" />
      ),
    }}
    variant={variant}
    sx={{
      borderRadius: '8px',
      display: 'flex',
      alignItems: 'center',
      '& .MuiAlert-message': { width: '100%' },
      ...(ColorMappingWithVariant[variant]?.[severity] ?? {}),
      ...sx,
    }}
    style={{
      padding: '6px 16px', // MUI default
    }}
  >
    <Typography variant={variantOfTypography}>{children}</Typography>
  </Alert>
);

export default EasyAlert;
