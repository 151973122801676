const resetPasswordPage = {
    /* reset password */
    "resetPassword.page.title":"Reset Password",
    "resetPassword.page.title.success":"Success!",
    /* 輸入欄位 提示 */
    "resetPassword.page.hint.password":"Your password must be at least 8 characters long and include uppercase letters, lowercase letters, and numbers.",
    "resetPassword.page.hint.confirmPassword":"Password and confirm password do not match",
    /*  */
    "resetPassword.page.reset.label1":" If you complete the password change, you will be signed out of your other devices.",
    "resetPassword.page.reset.label2":"Password reset is complete, please sign in again.",
    "resetPassword.page.button.reset":"Reset",
    "resetPassword.page.button.cancel":"Cancel",
    "resetPassword.page.button.signIn":"Sign In",
    /* 重設密碼 提示視窗 */
    "resetPassword.page.modal.error.title":"System Error",
    "resetPassword.page.modal.error.text":"The system encountered an error. Please try again later.",
}

export default resetPasswordPage
