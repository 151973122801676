import React from 'react';
import Button, { type ButtonProps } from '@mui/material/Button';

interface EasyButtonProps extends ButtonProps {
  size?: 'small' | 'medium' | 'large';
  startIcon?: React.ReactNode | null;
  endIcon?: React.ReactNode | null;
  variant?: 'contained' | 'outlined' | 'secondary' | 'text';
  disabled?: boolean;
  fullWidth?: boolean;
  onClick?: (() => void) | ((e:React.MouseEvent<HTMLButtonElement>)=>void);
  children: React.ReactNode;
  isFocus?: boolean | string;
}

const EasyButton: React.FC<EasyButtonProps> = ({
  size = 'medium',
  startIcon,
  endIcon,
  variant = 'text',
  disabled = false,
  fullWidth = false,
  onClick,
  children,
  isFocus,
  sx,
  ...props
}): React.JSX.Element => (
  <Button
    variant={variant}
    size={size}
    startIcon={startIcon ?? null}
    endIcon={endIcon ?? null}
    disabled={disabled}
    fullWidth={fullWidth}
    onClick={onClick}
    sx={{
      ...sx,
    }}
    {...(isFocus && { 'data-focus': true })}
    {...props}
  >
    {children}
  </Button>
);

export default EasyButton;
