import { createSlice } from '@reduxjs/toolkit';
import userAct from './userAction';
import type FirestoreUser from './TypescriptProps';
import groupInfo from '../../App-groupInfo';
import { HandlerIntroProps } from './utils/fuseki/handleEasycycleSetting';

export const userReducer = createSlice({
  name: 'userData',
  initialState: null as FirestoreUser | null,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      userAct.FIREBASE_LOGOUT_USER,
      (state, action) => action.payload,
    );
    builder.addCase(userAct.FIREBASE_LOGIN_USER, (state, action) => action.payload);
  },
});

export const resetPasswordReducer = createSlice({
  name: 'userEmail',
  initialState: null as string | null,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      userAct.FIREBASE_RESET_PASSWORD,
      (state, action) => action.payload,
    );
  },
});

export const connectUserReducer = createSlice({
  name: 'connectEmail',
  initialState: null as string | null,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      userAct.FIREBASE_CONNECT_ACCOUNT,
      (state, action) => action.payload,
    );
  },
});

export const permissionDenialReducer = createSlice({
  name: 'permissionDenial',
  initialState: null as string | null,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      userAct.PERMISSION_DENIAL,
      (state, action) => action.payload,
    );
  },
});

export const userSignUpStateReducer = createSlice({
  name: 'userSignUpStateReducer',
  initialState: null as string | null,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(userAct.SET_SIGNUP_STATE, (state, action) => action.payload);
  },
});

export const userGroupRoleReducer = createSlice({
  name: 'userGroupRoleReducer',
  initialState: [{ group: groupInfo.Anonymous.id, role: ['reader'] }] as Array<{ group:string;role:string[]; }> | null,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(userAct.SET_GROUP_ROLE, (state, action) => action.payload);
  },
});

export const easycycleSettingsReducer = createSlice({
  name: 'easycycleSettingsReducer',
  initialState: null as HandlerIntroProps | null,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(userAct.SET_EASYCYCLE_SETTINGS, (state, action) => action.payload);
  },
});

export const userFusekiIDReducer = createSlice({
  name: 'userFusekiIDReducer',
  initialState: null as string | null,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(userAct.SET_FUSEKI_ID, (state, action) => action.payload);
  },
});

export const contractIdReducer = createSlice({
  name: 'contractIdReducer',
  initialState: null as string | null,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(userAct.SET_CONTRACTID, (state, action) => action.payload);
  },
});
