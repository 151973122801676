import React, { useEffect, useState } from 'react';
// react-router-dom
import { Link, useNavigate } from 'react-router-dom';
// utils
import { SignUp } from '../utils/signUp';
// import getLocations from '../utils/getLocations';
// import isNotEmpty from '../utils/isNotEmpty';
// mui
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {
  CircularProgress,
  Divider,
  // FormControl, InputLabel, MenuItem, Select, SelectChangeEvent,
} from '@mui/material';
// components
import GoogleButton from '../../../commons/components/googleButton/GoogleButton';
import Loading from '../../../commons/components/loading/Loading';
import EasyTextField from '../../../commons/components/EasyTextField/EasyTextField';
import EmailExistModal from './EmailExistModal';
import EasyButton from '../../../commons/components/EasyButton/EasyButton';
import EasyCheckbox from '../../../commons/components/EasyCheckbox/EasyCheckbox';
import EasyDialog from '../../../commons/components/modal/EasyDialog';
import TitleContainer from '../subComponent/TitleContainer';
// redux
import { useAppDispatch, useAppSelector } from '../../../reduxStore/utils/reduxHooks';
// config
import routes from '../../../config/routes/index';
import services from '../../../api/services';
// style
import userTitleContainerStyle from '../userTitleContainerStyle';
import { Formik } from 'formik';
import { object, string } from 'yup';
import { useIntl } from 'react-intl';
import SignUpProps from './TypescriptProps';
import handlePolicy from '../utils/fuseki/handlePolicy';
import contractManagementAction from '../../../commons/components/modal/contractManagementModal/contractManagementAction';
import ContractManagementModal from '../../../commons/components/modal/contractManagementModal/ContractManagementModal';
// import { auth } from '../../../config/config-firebase';

function SignUpPage(): React.JSX.Element {
  /** react-intl */
  const { formatMessage } = useIntl();
  const id2text = (id:string) => formatMessage({ id });
  const helperTexts = {
    contactMail: id2text('signUp.page.helperTexts.mail'),
    password: id2text('signUp.page.helperTexts.password'),
    confirmPassword: id2text('signUp.page.helperTexts.confirmPassword'),
    contactName: id2text('signUp.page.helperTexts.empty'),
  };
  const regex = {
    contactMail: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
  };
  const validationSchema = object({
    contactName: string().required(helperTexts.contactName),
    contactMail: string().required(helperTexts.contactMail).matches(regex.contactMail, helperTexts.contactMail),
    password: string().required(helperTexts.password).matches(regex.password, helperTexts.password),
    confirmPassword: string().required(helperTexts.confirmPassword).test('confirmPassword', helperTexts.confirmPassword, (value, context) => context.parent.password === value),
  });
  /* 註冊狀態 */
  const signUpState = useAppSelector((state) => state.userSignUpStateStore);
  //
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useAppDispatch();

  /* 路由 */
  const navigation = useNavigate();

  /* 信箱已被使用modal / 系統出錯modal */
  const [emailExistIsOpen, setEmailExistIsOpen] = useState(false);
  const [systemErrorIsOpen, setSystemErrorIsOpen] = useState(false);
  const modalTextObj = {
    emailExist: {
      label: 'emailExist',
      title: id2text('signUp.modal.exist.title'),
      text: id2text('signUp.modal.exist.text'),
    },
    systemError: {
      label: 'systemError',
      title: id2text('signUp.modal.systemError.title'),
      text: id2text('signUp.modal.systemError.text'),
    },
  };
  const [modalObj, setModalObj] = useState(modalTextObj.emailExist);
  /* 按鈕loading */
  const [buttonIsLoading, setButtonIsLoading] = useState(false);

  /* 如果有在此按下google Btn 就讓用戶跳轉 */
  const user = useAppSelector((state) => state.userDataStore);
  useEffect(() => {
    if (signUpState === 'signUp') return;
    if (user != null) {
      // 如果有role 跳轉role
      if (user?.role !== undefined) {
        /* 目前有signUpState，所以可以阻擋註冊到一半被跳轉 */
        navigation(`/${services.getLocale()}${routes.HOME}`);
      } else {
        // 如果沒有role(即google初次登入) 跳轉選擇role
        // navigation(`/${services.getLocale()}${routes.REDIRECTION}`);
      }
    }
  }, [user]);

  /* 輸入欄位驗證陣列 */
  const ValidationGroup = [
    {
      label: id2text('signUp.page.contactName.label'),
      name: 'contactName',
      type: 'text',
    },
    {
      label: id2text('signUp.page.contactMail.label'),
      type: 'text',
      name: 'contactMail',
    },
    {
      label: id2text('signUp.page.password.label'),
      type: 'password',
      name: 'password',
    },
    {
      label: id2text('signUp.page.confirmPassword.label'),
      type: 'password',
      name: 'confirmPassword',
    },
  ];
  /* 同意字體大小調整 */
  const spFontSize = {
    xl: '16px',
    lg: '16px',
    md: '16px',
    sm: '14px',
    xs: '13px',
  };

  /** 合約相關 * */
  const [isContractModalOpen, setIsContractModalOpen] = useState(false);
  const [isContractModalLoading, setIsContractModalLoading] = useState(true);

  useEffect(() => {
    handlePolicy({ cttIds: `${process.env.REACT_APP_TERMS_OF_SERVICE_ID}` as string }).then((res) => {
      dispatch(contractManagementAction.SET_CONTRACT_MANAGEMENT(res[0]));
    }).then(() => {
      setIsLoading(false);
      setIsContractModalOpen(true);
    });
  }, []);

  /* 讀取中 */
  if (isLoading) {
    return <Loading />;
  }
  return (
    <Formik
      initialValues={{
        contactName: '',
        contactMail: '',
        password: '',
        confirmPassword: '',
        policy: false,
      }}
      onSubmit={async (values) => {
        setButtonIsLoading(true);
        await SignUp({
          email: values.contactMail,
          password: values.password,
          name: values.contactName,
          navigation,
          dispatch,
        }).catch((err) => {
          if (err === 'auth/email-already-in-use') {
            setButtonIsLoading(false);
            /* 開啟視窗 */
            setModalObj(modalTextObj.emailExist);
            setEmailExistIsOpen(true);
          } else {
            setButtonIsLoading(false);
            /* 開啟視窗 */
            setModalObj(modalTextObj.systemError);
            setSystemErrorIsOpen(true);
          }
        });
      }}
      validationSchema={validationSchema}
    >
      {({
        values, touched, handleSubmit, handleBlur, handleChange, errors, setFieldValue,
      }) => (
        <form>
          {/* contract modal */}
          <ContractManagementModal
            key="ContractManagementModal"
            type="edit"
            isOpen={isContractModalOpen}
            setIsOpen={setIsContractModalOpen}
            isLoading={isContractModalLoading}
            setIsLoading={setIsContractModalLoading}
            action={() => {
              setIsContractModalOpen(false);
            }}
            secondaryAction={() => {
              navigation(`/${services.getLocale()}${routes.SIGNIN}`);
            }}
          />
          <TitleContainer isSignUp title={id2text('signUp.page.title')}>
            {/* email已被使用modal */}
            <EmailExistModal
              isOpen={emailExistIsOpen}
              setIsOpen={setEmailExistIsOpen}
              modalObj={modalObj}
            />
            {/* 系統出錯modal */}
            <EasyDialog
              isOpen={systemErrorIsOpen}
              setIsOpen={setSystemErrorIsOpen}
              title={modalObj.title}
              showCloseIcon
              body={<Typography>{modalObj.text}</Typography>}
            />
            <Grid
              width="100%"
              mt={userTitleContainerStyle.mt24px}
            >
              {/* 輸入欄位驗證 */}
              {ValidationGroup.map((item) => (
                <Grid key={item.label} mt={userTitleContainerStyle.mt16px} width="100%">
                  <EasyTextField
                    label={item.label}
                    type={item.type as 'text' | 'password'}
                    name={item.name}
                    error={Boolean(touched[(item.name) as keyof SignUpProps] && errors[(item.name) as keyof SignUpProps])}
                    onChange={handleChange}
                    onBlur={handleBlur(`${item.name}`)}
                    helperText={touched[(item.name) as keyof SignUpProps] ? errors[(item.name) as keyof SignUpProps] : ''}
                    value={values[(item.name) as keyof SignUpProps] as string}
                    fullWidth
                    disabled={buttonIsLoading}
                    InputProps={{
                      style: { height: '3.1875rem', borderRadius: '0.5rem' },
                    }}
                  />
                </Grid>
              ))}
              {/* 同意條款 */}
              <Grid
                container
                justifyContent="start"
                mt={userTitleContainerStyle.mt16px}
                width="100%"
                px="3.807%"
              >
                <EasyCheckbox
                  isChecked={values.policy}
                  onChange={() => {
                    setFieldValue('policy', !values.policy);
                  }}
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 17,
                    },
                  }}
                  title={(
                    <Grid container justifyContent="start" width="100%">
                      <Typography fontSize={spFontSize} mr="1px" noWrap>
                        {id2text('signUp.page.policy.text1')}
                      </Typography>
                      <Link to={`/${services.getLocale()}${routes.SERVICE}`} target="_blank">
                        <Typography
                          ml="0.5rem"
                          fontSize={spFontSize}
                          color="primary"
                          sx={{
                            textDecoration: 'underline',
                          }}
                        >
                          {id2text('signUp.page.policy.text2')}
                        </Typography>
                      </Link>
                      <Typography fontSize={spFontSize} ml="0.3rem" mr="0.5rem">
                        {id2text('signUp.page.policy.text3')}
                      </Typography>
                      <Link to={`/${services.getLocale()}${routes.POLICY}`} target="_blank">
                        <Typography
                          fontSize={spFontSize}
                          color="primary"
                          sx={{
                            textDecoration: 'underline',
                          }}
                        >
                          {id2text('signUp.page.policy.text4')}
                        </Typography>
                      </Link>
                    </Grid>
                        )}
                />
              </Grid>
              {/* 註冊按鈕 */}
              <Grid
                mt={userTitleContainerStyle.mt16px}
                width="100%"
              >
                <EasyButton
                  fullWidth
                  disabled={
                            !!errors.contactMail
                            || !!errors.contactName
                            || !!errors.password
                            || !!errors.confirmPassword
                            || !values.policy
                            || buttonIsLoading
                        }
                  variant="contained"
                  onClick={handleSubmit}
                  size="large"
                >
                  <Typography>{buttonIsLoading ? <CircularProgress size={16} color="inherit" /> : id2text('signUp.page.button.signUp')}</Typography>
                </EasyButton>
              </Grid>
              {/* 分隔線 */}
              <Grid
                mt={userTitleContainerStyle.mt24px}
              >
                <Divider>or</Divider>
              </Grid>
              {/* Google登入按鈕 */}
              <Grid
                mt={userTitleContainerStyle.mt24px}
              >
                <GoogleButton setIsLoading={setIsLoading} />
              </Grid>
              {/* 返回登入 */}
              <Grid
                container
                justifyContent="center"
                mt={userTitleContainerStyle.mt24px}
                width="100%"
              >
                <Typography sx={{ fontSize: '14px' }}>
                  {id2text('signUp.page.hasAccount.text')}
                </Typography>
                <Link to={`/${services.getLocale()}${routes.SIGNIN}`}>
                  <Typography
                    fontSize="14px"
                    ml="5px"
                    mr="5px"
                    sx={{
                      color: '#59D9D9',
                      textDecoration: 'underline',
                    }}
                  >
                    {id2text('signUp.page.signIn.text')}
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          </TitleContainer>
        </form>
      )}
    </Formik>

  );
}

export default SignUpPage;
