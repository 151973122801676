import React, { useEffect, useState } from 'react';
// react-router-dom
import { Link, useNavigate } from 'react-router-dom';
// mui
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Box, CircularProgress, Divider } from '@mui/material';
// utils
import SignIn from '../utils/signIn';
import validatePassword from '../utils/validatePassword';
import validateEmail from '../utils/validateEmail';
// components
import Loading from '../../../commons/components/loading/Loading';
import GoogleButton from '../../../commons/components/googleButton/GoogleButton';
import EasyTextField from '../../../commons/components/EasyTextField/EasyTextField';
import EasyButton from '../../../commons/components/EasyButton/EasyButton';
import EasyCheckbox from '../../../commons/components/EasyCheckbox/EasyCheckbox';
import VerifyModal from './VerifyModal';
import TitleContainer from '../subComponent/TitleContainer';

// hooks
import {
  useAppSelector,
  useAppDispatch,
} from '../../../reduxStore/utils/reduxHooks';
import useValidateState from '../../../hooks/useValidateState';
// config
import routes from '../../../config/routes/index';
import services from '../../../api/services';
import { auth } from '../../../config/config-firebase';
import userAct from '../userAction';
import { STAY_LOG_IN_FEA } from '../../../config/config.login';
// style
import userTitleContainerStyle from '../userTitleContainerStyle';
import { Formik } from 'formik';
import { useIntl } from 'react-intl';
import { object, string } from 'yup';

const isSignUpOpen = `${process.env.REACT_APP_SIGNUP_OPEN}` === 'true';

function SignInPage(): React.JSX.Element {
  /** react-intl */
  const { formatMessage } = useIntl();
  const id2text = (id: string) => formatMessage({ id });
  // formik
  const helperTexts = {
    email: id2text('signIn.page.helperTexts.mail'),
    password: id2text('signIn.page.helperTexts.password'),
    emailOrPassWrong: id2text('signIn.page.helperTexts.mailOrPassword'),
  };
  const regex = {
    mail: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
  };
  const validationSchema = object({
    email: string()
      .required(helperTexts.email)
      .matches(regex.mail, helperTexts.email),
    password: string()
      .required(helperTexts.password)
      .matches(regex.password, helperTexts.password),
  });
  //
  const [isLoading, setIsLoading] = useState(false);
  // 使用者執行登入動作次數
  const isLogin = localStorage.getItem('isLogin') === 'true';
  /* 按鈕loading */
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  // 維持登入30天
  const [stayLoggedIn, setStayLoggedIn] = useState(true);
  const handleStayLoggedIn = (): void => {
    setStayLoggedIn(!stayLoggedIn);
  };
  /* 一般登入 */
  const navigation = useNavigate();
  const dispatch = useAppDispatch();

  // 登入後 尚未驗證email彈窗
  const [isOpen, setIsOpen] = useState(false);
  // 登入後 如果沒有信箱驗證則彈窗提醒需要去驗證，如果有則帶到首頁
  const user = useAppSelector((state) => state.userDataStore);
  const authUser = auth.currentUser;
  // 角色不對
  const permission = useAppSelector((state) => state.permissionDataStore);

  useEffect(() => {
    if (permission === 'deny') {
      dispatch(userAct.PERMISSION_DENIAL(null));
      setButtonIsLoading(false);
      return;
    }
    if (!user && !authUser) {
      return;
    }
    if (user?.groupRole) {
      // 如果沒有role(即google初次登入) 跳轉選擇role
      navigation(`/${services.getLocale()}${routes.REDIRECTION}`);
      return;
    }
    if (authUser && !authUser?.emailVerified) {
      setIsOpen(true);
      setIsLoading(false);
    } else if (isLogin) {
      navigation(`/${services.getLocale()}${routes.SIGNIN_SUCCESS_REDIRECT}`, {
        replace: true,
      });
    }
  }, [user]);
  // 設置loading
  useEffect(() => {
    setIsLoading(false);
  }, []);

  // 讀取中
  if (isLoading) {
    return <Loading />;
  }
  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        email: '',
        password: '',
      }}
      onSubmit={async (values, { setErrors }) => {
        const errors = {} as { email: string; password: string };
        try {
          setButtonIsLoading(true);
          await SignIn(values.email, values.password, dispatch, stayLoggedIn);
          setButtonIsLoading(false);
        } catch (error) {
          console.log('sign in error', error);
          errors.email = helperTexts.emailOrPassWrong;
          errors.password = helperTexts.emailOrPassWrong;
          setErrors(errors);
          setButtonIsLoading(false);
        }
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        errors,
        touched,
        handleSubmit,
      }) => (
        <form>
          <TitleContainer title={id2text('signIn.page.title')}>
            <VerifyModal isOpen={isOpen} setIsOpen={setIsOpen} />
            {/* 帳號輸入 */}
            <Grid width="100%" mt={userTitleContainerStyle.mt40px}>
              <EasyTextField
                label={id2text('signIn.page.email.label')}
                type="text"
                name="email"
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email ? errors.email : ''}
                onChange={handleChange}
                onBlur={handleBlur('email')}
                value={values.email}
                fullWidth
                disabled={buttonIsLoading}
                InputProps={{
                  style: { height: '3.1875rem', borderRadius: '0.5rem' },
                }}
              />
            </Grid>
            {/* 密碼輸入 */}
            <Grid
              width="100%"
              position="relative"
              mt={userTitleContainerStyle.mt16px}
            >
              <EasyTextField
                label={id2text('signIn.page.password.label')}
                type="password"
                name="password"
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password ? errors.password : ''}
                fullWidth
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur('password')}
                disabled={buttonIsLoading}
                InputProps={{
                  style: { height: '3.1875rem', borderRadius: '0.5rem' },
                }}
              />
            </Grid>
            {/* 持續登入 */}
            <Grid
              width="100%"
              mt={userTitleContainerStyle.mt16px}
              container
              justifyContent={STAY_LOG_IN_FEA ? 'space-between' : 'flex-end'}
              alignItems="center"
            >
              <Box
                sx={{
                  paddingLeft: '4.072%',
                  display: STAY_LOG_IN_FEA ? 'block' : 'none',
                }}
              >
                <EasyCheckbox
                  isChecked={stayLoggedIn}
                  onChange={handleStayLoggedIn}
                  title={(
                    <Grid width="100%">
                      <Typography variant="subtitle2">
                        {id2text('signIn.page.stay.label')}
                      </Typography>
                    </Grid>
                  )}
                />
              </Box>
              <Link to={`/${services.getLocale()}${routes.FORGOTPASSWORD}`}>
                <Typography
                  variant="subtitle2"
                  color="primary"
                  sx={{
                    textDecoration: 'underline',
                  }}
                >
                  {id2text('signIn.page.forgotPassword.label')}
                </Typography>
              </Link>
            </Grid>
            {/* 登入按鈕 */}
            <Grid width="100%" mt={userTitleContainerStyle.mt16px}>
              <EasyButton
                fullWidth
                disabled={
                  buttonIsLoading || !!errors.email || !!errors.password
                }
                variant="contained"
                onClick={handleSubmit}
                color="primary"
                size="large"
              >
                <Typography>
                  {buttonIsLoading ? (
                    <CircularProgress size={16} color="inherit" />
                  ) : (
                    id2text('signIn.page.button.signIn')
                  )}
                </Typography>
              </EasyButton>
            </Grid>
            {/* 分隔線 */}
            <Grid mt={userTitleContainerStyle.mt24px}>
              <Divider sx={{ color: 'black' }}>or</Divider>
            </Grid>
            {/* Google登入 */}
            <Grid
              container
              justifyContent="center"
              width="100%"
              mt={userTitleContainerStyle.mt24px}
            >
              <GoogleButton setIsLoading={setIsLoading} />
            </Grid>
            {/* 前往註冊 */}
            {isSignUpOpen && (
              <Grid
                container
                justifyContent="center"
                mt={userTitleContainerStyle.mt24px}
                width="100%"
              >
                <Typography variant="subtitle2">
                  {id2text('signIn.page.hasAccount.label')}
                </Typography>
                <Link to={`/${services.getLocale()}${routes.SIGNUP}`}>
                  <Typography
                    fontSize="14px"
                    ml="5px"
                    mr="5px"
                    sx={{
                      color: '#59D9D9',
                      textDecoration: 'underline',
                    }}
                  >
                    {id2text('signIn.page.goToSignUp.label')}
                  </Typography>
                </Link>
              </Grid>
            )}
          </TitleContainer>
        </form>
      )}
    </Formik>
  );
}

export default SignInPage;
