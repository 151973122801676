import axios from 'axios';
import services from '../../../api/services';

interface HandleDeleteAccountProps {
  message: string;
}
const handleDeleteAccount = async (
  uid: string,
): Promise<HandleDeleteAccountProps> => {
  const response = await axios.post(services.postDeleteAccount(), {
    uid,
  }, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response.data;
};

export default handleDeleteAccount;
