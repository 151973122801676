import React from 'react';

// Images
import EyeIcon from '../../../images/icons/icon_eye/EasyEyeIconLarge.svg';
import EyePrimaryIcon from '../../../images/icons/icon_eye/EasyEyeIcon-primary.svg';

// configs
export enum IconColor {
  disabled = 'disabled',
  grey = 'grey',
  black = 'black',
  primary = 'primary',
}
enum IconSize {
  xl = 32,
  lg = 28,
  md = 24,
  sm = 20,
  xs = 16,
}

const imageSrc: { [key: string]: string } = {
  [IconColor.grey]: EyeIcon,
  [IconColor.black]: EyeIcon,
  [IconColor.primary]: EyePrimaryIcon,
};

// Typescript
type ValidSize = keyof typeof IconSize;

interface EasyBackIconProps {
  color?: IconColor;
  size?: ValidSize;
  width?: number;
  height?: number;
  style?: React.CSSProperties;
}

const EasyEyeIcon: React.FC<EasyBackIconProps> = ({
  color,
  size = 'md',
  width,
  height,
  ...props
}): React.JSX.Element => (
  <img
    src={imageSrc[color ?? IconColor.primary]}
    width={width ?? IconSize[size]}
    height={height ?? IconSize[size]}
    alt="Icon"
    {...props}
  />
);

export default EasyEyeIcon;
