import userAct from '../userAction';
// 登入驗證
import { type AppDispatch } from '../../../reduxStore/rootReducer';
// config
import routes from '../../../config/routes/index';
import { NavigateFunction } from 'react-router/dist/lib/hooks';
import { signOut } from 'firebase/auth';
import { auth } from '../../../config/config-firebase';
import services from '../../../api/services';

const SignOut = async (navigation: NavigateFunction, dispatch: AppDispatch): Promise<void> => {
  await signOut(auth)
    .then(() => {
      localStorage.removeItem('isLogin');
      localStorage.removeItem('userData');
      localStorage.removeItem('exp');
      dispatch(userAct.FIREBASE_LOGOUT_USER(null));
      dispatch(userAct.SET_SIGNUP_STATE(''));
      navigation(`/${services.getLocale()}${routes.SIGNIN}`);
    })
    .catch(() => {
      // console.log(err);
    });
};
export default SignOut;
